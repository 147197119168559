import { useEffect, useState } from "react";
import { Box, Center } from "@chakra-ui/react";
import zoom from "chartjs-plugin-zoom";
import ChartButtonFISingle from '../Button/ChartButtonFISingle';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Helpers from "../../hooks/helpers";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoom,
    Filler
);

const PortfolioPerformance = ({ chartData, customers, params }: any) => {
    const [label, setLabel] = useState<any>([]);
    const [value, setValue] = useState<any>([]);
    const [filter, setFilter] = useState<any>("MAX");
    const [currentDate, setCurrentDate] = useState<string>("");

    const getCurrentDate = () => {
        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth();
        const date = new Date().getUTCDate();
        if (month + 1 > 10) {
            const getDate = `${year}-${month + 1}-${date}`;
            setCurrentDate(getDate);
        } else {
            const getDate = `${year}-0${month + 1}-${date}`;
            setCurrentDate(getDate);
        }
    };

    const setSingleProfit = () => {
        let temp: any = {};
        let tempLabel: any = [];
        let tempValue: any = [];
        let dateRange: any;

        if (filter === '1W') {
            dateRange = Helpers.getLast7Days();
        } else if (filter === '1M'){
            dateRange = Helpers.getLast30Days()
        } else if (filter === '3M'){
            dateRange = Helpers.getLast3Month(currentDate);
        }  else if (filter === 'YTD'){
            dateRange = Helpers.getYearToDate(currentDate.split('-')[0]);
        } else if (filter === '1Y'){
            dateRange = Helpers.getLastYearDate(currentDate)
        } else if (filter === '5Y'){
            dateRange = Helpers.getLast5YearDate(currentDate)
        }
        
        chartData.forEach((el: any) => {
            el.close = el.eod_usd_price
        })

        chartData.map((el: any) => {
            if (filter !== 'MAX') {
                if (new Date(dateRange).getTime() <= new Date(el.date_summary).getTime()) {
                    if (temp[el.date_summary]) {
                        temp[el.date_summary].push(el);
                    } else {
                        temp[el.date_summary] = [];
                        temp[el.date_summary].push(el);
                    }
                }
            } else {
                if (temp[el.date_summary]) {
                    temp[el.date_summary].push(el);
                } else {
                    temp[el.date_summary] = [];
                    temp[el.date_summary].push(el);
                }
            }
        });

        temp = Object.entries(temp);

        const sortedDate = temp.sort(function (a: any, b: any) {
            return new Date(a[0]).getTime() - new Date(b[0]).getTime()
        });

        sortedDate.map((el: any, index: number) => {
            let tempData = {
                date: el[0],
                close: 0
            }

            el[1].map((le: any) => {
                if (index === 0) {
                    tempData.close += Number(le.amount * le.avg_usd_price);
                } else {
                    tempData.close += Number(le.amount * le.eod_usd_price);
                }
            });

            tempLabel.push(el[0]);
            tempValue.push(tempData.close)
        });

        const newLabel = tempLabel.map((el: any) => {
            return el = `${el.split('-')[0]} ${monthFormat(el.split('-')[1])} ${el.split('-')[2]}`
        })

        setLabel(newLabel);
        setValue(tempValue);
    }

    const setMultipleProfit = () => {
        let temp: any = {};
        let tempLabel: any = [];
        let tempValue: any = [];
        let dateRange: any;

        if (filter === '1W') {
            dateRange = Helpers.getLast7Days();
        } else if (filter === '1M'){
            dateRange = Helpers.getLast30Days()
        } else if (filter === '3M'){
            dateRange = Helpers.getLast3Month(currentDate);
        }  else if (filter === 'YTD'){
            dateRange = Helpers.getYearToDate(currentDate.split('-')[0]);
        } else if (filter === '1Y'){
            dateRange = Helpers.getLastYearDate(currentDate)
        } else if (filter === '5Y'){
            dateRange = Helpers.getLast5YearDate(currentDate)
        }

        chartData.forEach((el: any) => {
            el.close = el.eod_usd_price
        })

        chartData.map((el: any) => {
            if (filter !== 'MAX') {
                if (new Date(dateRange).getTime() <= new Date(el.date_summary).getTime()) {
                    if (temp[el.date_summary]) {
                        temp[el.date_summary].push(el);
                    } else {
                        temp[el.date_summary] = [];
                        temp[el.date_summary].push(el);
                    }
                }
            } else {
                if (temp[el.date_summary]) {
                    temp[el.date_summary].push(el);
                } else {
                    temp[el.date_summary] = [];
                    temp[el.date_summary].push(el);
                }
            }
        });

        temp = Object.entries(temp);

        const sortedDate = temp.sort(function (a: any, b: any) {
            return new Date(a[0]).getTime() - new Date(b[0]).getTime()
        });

        sortedDate.map((el: any, index: number) => {
            let tempData = {
                date: el[0],
                close: 0
            }

            el[1].map((le: any) => {
                if (index === 0) {
                    tempData.close += Number(le.amount * le.avg_usd_price);
                } else {
                    tempData.close += Number(le.amount * le.eod_usd_price);
                }
            });

            tempLabel.push(el[0]);
            tempValue.push(tempData.close)
        });

        const newLabel = tempLabel.map((el: any) => {
            return el = `${el.split('-')[0]} ${monthFormat(el.split('-')[1])} ${el.split('-')[2]}`
        })

        setLabel(newLabel);
        setValue(tempValue);
    }

    useEffect(() => {
        getCurrentDate();
        if (params !== "percentage") {
            if (customers.length > 0) {
                if (customers.length === 1) {
                    setSingleProfit();
                } else {
                    setMultipleProfit();
                }
            }
        }
    }, [customers, filter]);

    const monthFormat = (val: any) => {
        if (val == "01") return "Jan";
        if (val == "02") return "Feb";
        if (val == "03") return "Mar";
        if (val == "04") return "Apr";
        if (val == "05") return "May";
        if (val == "06") return "Jun";
        if (val == "07") return "Jul";
        if (val == "08") return "Aug";
        if (val == "09") return "Sep";
        if (val == "10") return "Oct";
        if (val == "11") return "Nov";
        if (val == "12") return "Dec";
    };

    const data: any = {
        labels: label,
        datasets: [
            {
                label: `Portfolio ${params === 'percentage' ? 'Percentage' : 'Profit'}`,
                fill: false,
                lineTension: 0.1,
                backgroundColor: `${params === 'percentage' ? 'rgba(75,192,192,0.4)' : 'rgba(77, 147, 255, 1)'}`,
                borderColor: `${params === 'percentage' ? 'rgba(75,192,192,1)' : 'rgba(77, 147, 255, 1)'}`,
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: `${params === 'percentage' ? 'rgba(75,192,192,1)' : 'rgba(77, 147, 255, 1)'}`,
                pointBackgroundColor: `${params === 'percentage' ? 'rgba(75,192,192,0.4)' : 'rgba(77, 147, 255, 1)'}`,
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: `${params === 'percentage' ? 'rgba(75,192,192,1)' : 'rgba(77, 147, 255, 1)'}`,
                pointHoverBorderColor: "rgba(220,220,220,1)",
                pointHoverBorderWidth: 3,
                pointRadius: 3,
                pointHitRadius: 10,
                data: value,
                cubicInterpolationMode: 'monotone',
            },
        ],
    };

    let tempMonths: any[] = [];

    const option: any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    usePointStyle: true,
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const currencyFormat = Helpers.currencyFormaterUSD(context.raw);
                        return ` ${currencyFormat}`;
                    },
                },
            },
        },
        scales: {
            y: {
                // offset: false,
                // gridLines: {
                //     offsetGridLines: false,
                // },
                title: {
                    display: false,
                    text: `${params === 'percentage' ? 'Percentage' : 'Profit'}`
                },
                // grid: {
                //     display: false,
                //     drawBorder: true,
                //     drawOnChartArea: true,
                //     drawTicks: true,
                // },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: false,
                    getLabelForValue: function(value: any) {
                        return value
                    },
                    callback: function (val: any, index: number, tick: any, label: any): any {
                        return this.getLabelForValue(val)
                    },
                },
            },
            x: {
                offset: false,
                gridLines: {
                    offsetGridLines: false,
                },
                title: {
                    display: true,
                },
                grid: {
                    display: false,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: false,
                    getLabelForValue: function(value: any) {
                        return value
                    },
                    callback: function (val: any, index: number, tick: any) {
                        const newLabel = Helpers.labelData(tick.length, index, this.getLabelForValue(val), filter, tempMonths);
                        return newLabel
                    },
                },
            }
        },
    };

    const chartFilterArr = [
        "1W",
        "1M",
        "3M",
        "YTD",
        "1Y",
        "5Y",
        "MAX",
    ]

    return (
        <Box>
            <Box>
                <Center my={'16px'}>
                    <ChartButtonFISingle
                        chartFilter={chartFilterArr}
                        handleChartFilter={setFilter} />
                </Center>
            </Box>
            <Box h={355} py="10px">
                <Line data={data} options={option} />
            </Box>
        </Box>
    );
};

export default PortfolioPerformance;

