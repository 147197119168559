import ExchangeList from "../../components/ExchangeList";

function Crypto() {
  return (
    <div>
      <ExchangeList instrument="crypto" />
    </div>
  );
}

export default Crypto;
