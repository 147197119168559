import axios from 'axios'

const token = "620e19e49de326.23079426";


class getFundamental {
    static async detail(code : any, market : any, token : any){
        const response = await axios(
            `https://eodhistoricaldata.com/api/fundamentals/${code}.${market}?api_token=${token}&fmt=json&period=d&order=d`
        );
        
        return response.data
    }
}

export default getFundamental