import {
    Button,
    ModalFooter,
} from "@chakra-ui/react";

const CustomModalFooter = ({
    isSubmit, onClose, value, amount, updatedData
}: any) => {
    return (
        <ModalFooter px="24px" py="16px">

            <Button
                _focus={{ outline: 'none' }}
                backgroundColor="#fff"
                color="rgba(23, 23, 23, 1)"
                fontSize="12px"
                fontWeight="600"
                border="1px"
                borderColor="#E9E9E9"
                h="32px"
                mr={3}
                onClick={onClose}
            >
                Cancel
            </Button>

           {
               value > 0 && amount > 0 && 
               <Button
                    isLoading={isSubmit ? true : false}
                    loadingText={'Submitting'}
                    _focus={{ outline: 'none' }}
                    backgroundColor="rgba(198, 179, 120, 1)"
                    color="#fff"
                    border="1px"
                    fontSize="12px"
                    fontWeight="600"
                    h="32px"
                    _hover={{
                        color: "rgba(198, 179, 120, 1)",
                        backgroundColor: "#fff",
                        border: "1px",
                    }}
                    type="submit"
                >
                    Submit
                </Button>
           }

           {
               updatedData && updatedData.trade_date && 
               <Button
                    isLoading={isSubmit ? true : false}
                    loadingText={'Submitting'}
                    _focus={{ outline: 'none' }}
                    backgroundColor="rgba(198, 179, 120, 1)"
                    color="#fff"
                    border="1px"
                    fontSize="12px"
                    fontWeight="600"
                    h="32px"
                    _hover={{
                        color: "rgba(198, 179, 120, 1)",
                        backgroundColor: "#fff",
                        border: "1px",
                    }}
                    type="submit"
                >
                    Submit
                </Button>
           }
            
        </ModalFooter>
    );
};

export default CustomModalFooter;