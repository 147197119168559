import { useEffect, useState } from 'react'
import {
    Box,
    Flex,
    Image,
    Center,
    Tr,
    Th,
    Tbody,
    Td,
} from "@chakra-ui/react";
import Helpers from "../../hooks/helpers";
import logo from "../../../assets/logo.png";
import TemplateTable from '../../components/Tables/TemplateTable';

const GeneralTemplate = ({ children, instrument, data, type, page, highlight }: any) => {
    const [dividenByYear, setDividendByYear] = useState<any>([]);
    const [newTableHead, setNewTableHead] = useState<any[]>([]);
    
    useEffect(() => {
        handleData();
    }, [data])

    const handleData = async () => {
        if (data?.SplitsDividends?.NumberDividendsByYear) {
            let entries = await  Object.entries(data?.SplitsDividends?.NumberDividendsByYear);
            await setDividendByYear(entries);
        }

        if (data?.tableHead) {
            let takeoutAction = await data.tableHead.pop();
            setNewTableHead(data.tableHead);
        }

        console.log(data, `modal`)
    }

    

    return (
        <Box py={"27px"} px={"24px"}>
            <Flex flexWrap={"wrap"} direction={"row"}>
                <Box w={"80px"}>
                    <Image src={logo} alt="Aries Logo" />
                </Box>

                <Box
                    color={"rgba(204, 204, 204, 1)"}
                    mx={"20px"}
                    borderLeft={"1px"}
                ></Box>

                <Box fontSize={"11px"} w={"calc(100% - 150px)"}>
                    <Flex>
                        <Box pr={"3px"} fontWeight={600}>
                            Consolidated Report as of 
                        </Box>
                        <Box>
                            {
                                data && data?.date && data.date.length > 0 && (
                                    <Box>
                                        {
                                            data?.date.map((el: any, index: number) => {
                                                return (
                                                    <Box>
                                                        {
                                                            index === data?.date.length - 1 && (
                                                                <Box>{` ${el}`}</Box>
                                                            )
                                                        }
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Box>
                                )
                            }
                        </Box>

                    </Flex>
                    <Flex w={"100%"} direction={"row"}>
                        {
                            instrument === 'financial-instrument' ? (
                                <Box textTransform={'capitalize'} pr={"3px"} fontWeight={600}>
                                    Financial Instrument: {type}
                                </Box>
                            ) : (
                                <Center textTransform={'capitalize'} pr={"3px"} fontWeight={600}>
                                    <Box textTransform={'capitalize'} pr={"3px"} fontWeight={600}>
                                        Financial Instrument:
                                    </Box>
                                    {
                                        type && type.length > 0 && type.map((el: any, index: number) => {
                                            console.log(type, `type`)
                                            console.log(type[type.length - 1], `type[type.length - 1]`)
                                            return (
                                                <Box key={index} textTransform={'capitalize'} pr={"3px"} fontWeight={400}>
                                                    {
                                                        type.length > 1 ? (
                                                            <Box>{index === type.length - 1 ? el.name : `${el.name},`}</Box>
                                                        ) : (
                                                            <Box>{el.name}</Box>
                                                        )
                                                    }
                                                </Box>
                                            )
                                        })
                                    }
                                </Center>
                            )
                        }
                    </Flex>
                    {
                        instrument === 'financial-instrument' ? (
                            <Flex>
                                <Box pr={"3px"} fontWeight={600}>
                                    Base CCY:
                                </Box>
                                <Box>USD</Box>
                            </Flex>
                        ) : (
                            <></>
                        )
                    }
                </Box>
            </Flex>
            <Box color={"rgba(204, 204, 204, 1)"} my={"20px"} borderTop={"1px"}></Box>
            {
                instrument === 'financial-instrument' ? (
                    <Box>
                        {
                            data?.General && page === '1' ? (
                                <Box>
                                    <Box
                                        textTransform={"uppercase"}
                                        fontWeight={600}
                                        fontSize={"16"}
                                        color={"rgba(23, 23, 23, 1)"}
                                    >
                                        General Detail
                                    </Box>
                                    <Flex
                                        flexWrap={'wrap'}
                                        justifyContent={'space-between'}
                                        gap={2}
                                        flexDirection={'row'}>
                                        <Box w={'50%'}>
                                            {data?.General?.LogoURL && (
                                                <Box p={'20px'}>
                                                    <Box w={'100%'} maxW={'120px'} h={'120px'}>
                                                        <Image
                                                            h={'120px'}
                                                            w={'full'}
                                                            src={`https://eodhistoricaldata.com/${data?.General?.LogoURL}`}
                                                            objectFit={'contain'}
                                                        />
                                                    </Box>
                                                </Box>
                                            )

                                            }

                                            {
                                                data?.General?.ISIN && (
                                                    <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                        <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>ISIN</Box>
                                                        <Box w={'50%'}>{Helpers.isNA(data?.General?.ISIN)}</Box>
                                                    </Flex>
                                                )
                                            }

                                            {
                                                data?.General?.Code && (
                                                    <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                        <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Code</Box>
                                                        <Box w={'50%'}>{Helpers.isNA(data?.General?.Code)}</Box>
                                                    </Flex>
                                                )
                                            }

                                            {
                                                data?.General?.Type && (
                                                    <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                        <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Type</Box>
                                                        <Box w={'50%'}>{Helpers.isNA(data?.General?.Type)}</Box>
                                                    </Flex>
                                                )
                                            }

                                            {
                                                data?.General?.Exchange && (
                                                    <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                        <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Exchange</Box>
                                                        <Box w={'50%'}>{Helpers.isNA(data?.General?.Exchange)}</Box>
                                                    </Flex>
                                                )
                                            }

                                            {
                                                data?.General?.Name && (
                                                    <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                        <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Name</Box>
                                                        <Box w={'50%'}>{Helpers.isNA(data?.General?.Name)}</Box>
                                                    </Flex>
                                                )
                                            }

                                            {
                                                data?.General?.IPODate && (
                                                    <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                        <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>IPODate</Box>
                                                        <Box w={'50%'}>{Helpers.isNA(data?.General?.IPODate)}</Box>
                                                    </Flex>
                                                )
                                            }

                                            {
                                                data?.General?.CountryName && (
                                                    <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                        <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>CountryName</Box>
                                                        <Box w={'50%'}>{Helpers.isNA(data?.General?.CountryName)}</Box>
                                                    </Flex>
                                                )
                                            }

                                            {
                                                data?.General?.CurrencyName && (
                                                    <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                        <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Currency</Box>
                                                        <Box w={'50%'}>{Helpers.isNA(data?.General?.CurrencyName)} / {Helpers.isNA(data?.General?.CurrencySymbol)}</Box>
                                                    </Flex>
                                                )
                                            }

                                            {
                                                data?.General?.Industry && (
                                                    <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                        <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Industry</Box>
                                                        <Box w={'50%'}>{Helpers.isNA(data?.General?.Industry)}</Box>
                                                    </Flex>
                                                )
                                            }

                                            {
                                                data?.General?.UpdatedAt && (
                                                    <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                        <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Last Update</Box>
                                                        <Box w={'50%'}>{Helpers.isNA(data?.General?.UpdatedAt)}</Box>
                                                    </Flex>
                                                )
                                            }
                                        </Box>
                                        <Box w={'50%'}></Box>
                                    </Flex>
                                </Box>
                            ) : (
                                <Box>
                                    <Box
                                        textTransform={"uppercase"}
                                        fontWeight={600}
                                        fontSize={"16"}
                                        color={"rgba(23, 23, 23, 1)"}
                                        mb={'32px'}
                                    >
                                        Data Highlight
                                    </Box>
                                    {   highlight &&
                                        <Flex
                                        flexWrap={'wrap'}
                                        justifyContent={'start'}
                                        gap={2}
                                        flexDirection={'row'}>
                                        {
                                            highlight?.performance !== '' ? (
                                                <Box w={'20%'} mb={'20px'}>
                                                    <Box
                                                        fontWeight={600}
                                                        fontSize={'14px'}
                                                        color={'rgba(23, 23, 23, 1)'}>
                                                        Performance
                                                    </Box>
                                                    <Box w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                    <Box>
                                                        {highlight.performance.map((el: any, indexPerformance: number) => {
                                                            return (
                                                                <Flex key={indexPerformance} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[0]}</Box>
                                                                    <Box>{el[1] ? el[1] : "N/A"}</Box>
                                                                </Flex>
                                                            )
                                                        })}
                                                    </Box>
                                                </Box>
                                            ) : (<></>)
                                        }

                                        {
                                            highlight.technicalAnalysis !== '' ? (
                                                <Box w={'20%'} mb={'20px'}>
                                                    <Box
                                                        fontWeight={600}
                                                        fontSize={'14px'}
                                                        color={'rgba(23, 23, 23, 1)'}>
                                                        Technical Analysis
                                                    </Box>
                                                    <Box w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                    <Box>
                                                        {highlight.technicalAnalysis.map((el: any, indextechnicalAnalysis: number) => {
                                                            return (
                                                                <Flex key={indextechnicalAnalysis} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[0]}</Box>
                                                                    <Box>{el[1] ? el[1] : "N/A"}</Box>
                                                                </Flex>
                                                            )
                                                        })}
                                                    </Box>
                                                    {
                                                        data?.Technicals?.Beta && data?.Technicals?.Beta !== '0' ? (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Beta</Box>
                                                                <Box>{data?.Technicals?.Beta}</Box>
                                                            </Flex>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }

                                                    {
                                                        data?.Technicals?.SharesShort && data?.Technicals?.SharesShort !== 0 ? (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Shares Short</Box>
                                                                <Box>{data?.Technicals?.SharesShort}</Box>
                                                            </Flex>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        data?.Technicals?.SharesShortPriorMonth && data?.Technicals?.SharesShortPriorMonth !== 0 ? (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Shares Short Prior Month</Box>
                                                                <Box>{data?.Technicals?.SharesShortPriorMonth}</Box>
                                                            </Flex>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }

                                                    {
                                                        data?.Technicals?.ShortPercent && data?.Technicals?.ShortPercent !== '0' ? (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Short Percent</Box>
                                                                <Box>{data?.Technicals?.ShortPercent}</Box>
                                                            </Flex>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }

                                                    {
                                                        data?.Technicals?.ShortRatio && data?.Technicals?.ShortRatio !== '0' ? (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Short Ratio</Box>
                                                                <Box>{data?.Technicals?.ShortRatio}</Box>
                                                            </Flex>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </Box>
                                            ) : (<></>)
                                        }

                                        {
                                            highlight.ratingAndReturns !== '' ? (
                                                <Box w={'20%'} mb={'20px'}>
                                                    <Box
                                                        fontWeight={600}
                                                        fontSize={'14px'}
                                                        color={'rgba(23, 23, 23, 1)'}>
                                                        {`Rating & Returns`}
                                                    </Box>
                                                    <Box w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                    <Box>
                                                        {highlight.ratingAndReturns.map((el: any, indexratingAndReturns: number) => {
                                                            return (
                                                                <Flex key={indexratingAndReturns} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[0]}</Box>
                                                                    <Box>{el[1] ? el[1] : "N/A"}</Box>
                                                                </Flex>
                                                            )
                                                        })}
                                                    </Box>
                                                    {
                                                        data?.AnalystRatings?.Buy && data?.Technicals?.Buy !== '0' ? (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Buy</Box>
                                                                <Box>{data?.AnalystRatings?.Buy}</Box>
                                                            </Flex>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }

                                                    {
                                                        data?.AnalystRatings?.Hold && data?.Technicals?.Hold !== '0' ? (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Hold</Box>
                                                                <Box>{data?.AnalystRatings?.Hold}</Box>
                                                            </Flex>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }

                                                    {
                                                        data?.AnalystRatings?.Rating && data?.Technicals?.Rating !== '0' ? (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Rating</Box>
                                                                <Box>{data?.AnalystRatings?.Rating}</Box>
                                                            </Flex>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }

                                                    {
                                                        data?.AnalystRatings?.Sell && data?.Technicals?.Sell !== '0' ? (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Sell</Box>
                                                                <Box>{data?.AnalystRatings?.Sell}</Box>
                                                            </Flex>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }

                                                    {
                                                        data?.AnalystRatings?.StrongBuy && data?.Technicals?.StrongBuy !== '0' ? (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Strong Buy</Box>
                                                                <Box>{data?.AnalystRatings?.StrongBuy}</Box>
                                                            </Flex>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }

                                                    {
                                                        data?.AnalystRatings?.StrongSell && data?.Technicals?.StrongSell !== '0' ? (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Strong Sell</Box>
                                                                <Box>{data?.AnalystRatings?.StrongSell}</Box>
                                                            </Flex>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </Box>
                                            ) : (<></>)
                                        }

                                        {
                                            highlight.ratioAnalysis !== '' ? (
                                                <Box w={'20%'} mb={'20px'}>
                                                    <Box
                                                        fontWeight={600}
                                                        fontSize={'14px'}
                                                        color={'rgba(23, 23, 23, 1)'}>
                                                        {'Ratio & Analysis'}
                                                    </Box>
                                                    <Box w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                    <Box>
                                                        {highlight.ratioAnalysis.map((el: any, indexratioAnalysis: number) => {
                                                            return (
                                                                <Flex key={indexratioAnalysis} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[0]}</Box>
                                                                    <Box>{el[1] ? el[1] : "N/A"}</Box>
                                                                </Flex>
                                                            )
                                                        })}
                                                    </Box>
                                                </Box>
                                            ) : (<></>)
                                        }

                                        {
                                            highlight.dividend !== '' ? (
                                                <Box w={'20%'} mb={'20px'}>
                                                    <Box
                                                        fontWeight={600}
                                                        fontSize={'14px'}
                                                        color={'rgba(23, 23, 23, 1)'}>
                                                        Dividend
                                                    </Box>
                                                    <Box w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                    <Box>
                                                        {highlight.dividend.map((el: any, indexdividend: number) => {
                                                            return (
                                                                <Flex key={indexdividend} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[0]}</Box>
                                                                    <Box>{el[1] ? el[1] : "N/A"}</Box>
                                                                </Flex>
                                                            )
                                                        })}
                                                    </Box>
                                                    {
                                                        data?.SplitsDividends?.DividendDate && data?.SplitsDividends?.DividendDate !== '0000-00-00' && (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Dividend Date</Box>
                                                                <Box>{data?.SplitsDividends?.DividendDate}</Box>
                                                            </Flex>
                                                        )
                                                    }

                                                    {
                                                        data?.SplitsDividends?.ExDividendDate && data?.SplitsDividends?.ExDividendDate !== '0000-00-00' && (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Ex Dividend Date</Box>
                                                                <Box>{data?.SplitsDividends?.ExDividendDate}</Box>
                                                            </Flex>
                                                        )
                                                    }

                                                    {
                                                        data?.SplitsDividends?.ForwardAnnualDividendRate && data?.SplitsDividends?.ForwardAnnualDividendRate !== '0' ? (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Forward Annual Dividend Rate</Box>
                                                                <Box>{data?.SplitsDividends?.ForwardAnnualDividendRate}</Box>
                                                            </Flex>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }

                                                    {   
                                                        data?.SplitsDividends?.ForwardAnnualDividendYield && data?.SplitsDividends?.ForwardAnnualDividendYield !== '0' ? (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Forward Annual Dividend Yield</Box>
                                                                <Box>{data?.SplitsDividends?.ForwardAnnualDividendYield}</Box>
                                                            </Flex>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }

                                                    {   
                                                        data?.SplitsDividends?.LastSplitDate && data?.SplitsDividends?.LastSplitDate !== '0000-00-00' && (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Last Split Date</Box>
                                                                <Box>{data?.SplitsDividends?.LastSplitDate}</Box>
                                                            </Flex>
                                                        )
                                                    }

                                                    {   
                                                        data?.SplitsDividends?.LastSplitFactor && data?.SplitsDividends?.LastSplitFactor !== '' && (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Last Split Factor</Box>
                                                                <Box>{data?.SplitsDividends?.LastSplitFactor}</Box>
                                                            </Flex>
                                                        )
                                                    }

                                                    {   
                                                        data?.SplitsDividends?.PayoutRatio && (
                                                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Payout Ratio</Box>
                                                                <Box>{data?.SplitsDividends?.PayoutRatio}</Box>
                                                                <Box></Box>
                                                            </Flex>
                                                        )
                                                    }

                                                                
                                                </Box>
                                                
                                            ) : (<></>)
                                        }

                                        {
                                            highlight.salesAndEarnings !== '' ? (
                                                <Box w={'20%'} mb={'20px'}>
                                                    <Box
                                                        fontWeight={600}
                                                        fontSize={'14px'}
                                                        color={'rgba(23, 23, 23, 1)'}>
                                                        {'Sales & Earning'}
                                                    </Box>
                                                    <Box w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                    <Box>
                                                        {highlight.salesAndEarnings.map((el: any, indexsalesAndEarnings: number) => {
                                                            return (
                                                                <Flex key={indexsalesAndEarnings} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[0]}</Box>
                                                                    <Box>{el[1] ? el[1] : "N/A"}</Box>
                                                                </Flex>
                                                            )
                                                        })}
                                                    </Box>
                                                </Box>
                                            ) : (<></>)
                                        }

                                        {   
                                            data?.SplitsDividends?.NumberDividendsByYear && (
                                                <Box w={'20%'} mb={'20px'}>
                                                    <Box
                                                        fontWeight={600}
                                                        fontSize={'14px'}
                                                        color={'rgba(23, 23, 23, 1)'}>
                                                        Number Dividends By Year
                                                    </Box>
                                                    <Box w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                    <Flex direction={'row'} flexWrap={'wrap'}>
                                                        {
                                                            dividenByYear.length > 0 && dividenByYear.map((el: any, index: number) => {
                                                                return (
                                                                    <Flex w={'50%'} key={index} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                        <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[1].Year}</Box>
                                                                        <Box>{el[1].Count}</Box>
                                                                    </Flex>
                                                                )
                                                            })
                                                        }
                                                    </Flex>
                                                </Box>
                                            )
                                        }

                                        {   
                                            data?.SplitsDividends?.NumberDividendsByYear && (
                                                <Box w={'20%'} mb={'20px'}>
                                                    <Box
                                                        fontWeight={600}
                                                        fontSize={'14px'}
                                                        color={'rgba(23, 23, 23, 1)'}>
                                                        Number Dividends By Year
                                                    </Box>
                                                    <Box w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                    <Box>
                                                        {   
                                                            data?.ESGScores?.EnvironmentScore && data?.ESGScores?.EnvironmentScore !== '0' && (
                                                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Environment Score</Box>
                                                                    <Box>{data?.ESGScores?.EnvironmentScore}</Box>
                                                                </Flex>
                                                            )
                                                        }

                                                        {   
                                                            data?.ESGScores?.GovernanceScore && data?.ESGScores?.GovernanceScore !== '0' && (
                                                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Governance Score</Box>
                                                                    <Box>{data?.ESGScores?.GovernanceScore}</Box>
                                                                </Flex>
                                                            )
                                                        }

                                                        {   
                                                            data?.ESGScores?.SocialScore && data?.ESGScores?.SocialScore !== '0' && (
                                                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Social Score</Box>
                                                                    <Box>{data?.ESGScores?.SocialScore}</Box>
                                                                </Flex>
                                                            )
                                                        }

                                                        {   
                                                            data?.ESGScores?.TotalEsg && data?.ESGScores?.TotalEsg !== '0' && (
                                                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Total Esg</Box>
                                                                    <Box>{data?.ESGScores?.TotalEsg}</Box>
                                                                </Flex>
                                                            )
                                                        }

                                                        {   
                                                            data?.ESGScores?.RatingDate && data?.ESGScores?.RatingDate !== '0' && (
                                                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Rating Date</Box>
                                                                    <Box>{data?.ESGScores?.RatingDate}</Box>
                                                                </Flex>
                                                            )
                                                        }
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    </Flex>
                                    }
                                </Box>
                            )
                        }
                    </Box>
                ) : (
                    <></>
                )
            }

            {
                instrument === 'compare' ? (
                    <Box my={'10px'}>
                        <Box
                            textTransform={"uppercase"}
                            fontWeight={600}
                            fontSize={"16"}
                            color={"rgba(23, 23, 23, 1)"}
                            mb={'32px'}
                        >
                            Performance Table
                        </Box>
                        {
                            data?.tabularData && (
                                <TemplateTable 
                                    instrument={false}
                                    tableHeadList={newTableHead.length > 0 ? newTableHead : data.tableHead}
                                    pages={1}
                                    currentPage={1}
                                    handlePageChange={null}
                                    customTableHead={null}
                                    >
                                    <Tbody>
                                        {data.tabularData.map((el: any, index: number) => {
                                            return (
                                                <>
                                                    <Tr key={index}>
                                                        <Td textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.name}</Td>
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastWeek}</Td>
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastMonth}</Td>
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last3Month}</Td>
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.YTD}</Td>
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastYear}</Td>
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last5Year}</Td>
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.maxData}</Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td textTransform={'capitalize'} fontSize={'12px'} p={'12px'}></Td> 
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastWeek === 'N/A' ? el.lastWeek : Helpers.currencyFormater(el.Val[0].lastWeek, el.Val[0].currency)}</Td>
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastMonth === 'N/A' ? el.lastMonth : Helpers.currencyFormater(el.Val[0].lastMonth, el.Val[0].currency)}</Td>
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last3Month === 'N/A' ? el.last3Month : Helpers.currencyFormater(el.Val[0].last3Month, el.Val[0].currency)}</Td>
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.YTD === 'N/A' ? el.YTD : Helpers.currencyFormater(el.Val[0].YTD, el.Val[0].currency)}</Td>
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastYear === 'N/A' ? el.lastYear : Helpers.currencyFormater(el.Val[0].lastYear, el.Val[0].currency)}</Td>
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last5Year === 'N/A' ? el.last5Year : Helpers.currencyFormater(el.Val[0].last5Year, el.Val[0].currency)}</Td>
                                                        <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.maxData === 'N/A' ? el.maxData : Helpers.currencyFormater(el.Val[0].Max, el.Val[0].currency)}</Td>
                                                    </Tr>
                                                </>
                                            );
                                        })}
                                    </Tbody>
                                </TemplateTable>
                            )
                        }
                    </Box>
                ) : (
                    <></>
                )
            }

            <Box>
                <Box
                    textTransform={"uppercase"}
                    fontWeight={600}
                    fontSize={"16"}
                    color={"rgba(23, 23, 23, 1)"}
                    mb={'10px'}
                >
                    Performance Chart
                </Box>
                <Box
                    mb={'20px'}
                    color={"rgba(116, 116, 116, 1)"} 
                    fontSize={'12px'}>
                    {
                        data && data?.date && data.date.length > 0 && (
                            <Box>
                                {
                                    <Flex>
                                        <Box pr={'3px'}>as of</Box>
                                        <Box>
                                            {
                                                data?.date[0]
                                            }
                                        </Box>
                                        <Box px={'3px'}> - </Box>
                                        <Box>
                                            {
                                                data?.date[data?.date.length - 1]
                                            }
                                        </Box>
                                    </Flex>
                                }
                            </Box>
                        )
                    }
                </Box>
                {children}
            </Box>
            <Box>
                <Box
                    color={"rgba(204, 204, 204, 1)"}
                    my={"20px"}
                    borderBottom={"1px"}
                ></Box>
                <Box fontSize={"11px"} color={"rgba(116, 116, 116, 1)"}>
                    © 2022 Aries Investment Pte. Ltd.
            </Box>
            </Box>
        </Box>
    );
};

export default GeneralTemplate;
