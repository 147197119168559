import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import React from "react";

const AuthProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
    }

    setLoading(false);
  }, []);

  if (loading) {
    return <div>Authenticating</div>;
  }

  return <div>{children}</div>;
};

export default AuthProvider;
