import { Tr, Tbody, Td, Tooltip } from "@chakra-ui/react";
import Helpers from "../../../hooks/helpers";

const TablePortfolioTransaction = ({list}: any) => {
    return (
        <Tbody>
            {list.length > 0 && list.map((el: any, index: number) => {
                return (
                    <Tr key={index}>
                        <Td textTransform="capitalize" p="12px">
                            {el.created_at}
                        </Td>
                        <Td textTransform="capitalize" p="12px">
                            {el.name && el.name.length > 20 ?
                                <Tooltip
                                    placement='right'
                                    hasArrow
                                    label={el.name}
                                    bg='#fff' color='#000'>
                                    {Helpers.shortenText(el.name, "150px")}
                                </Tooltip>
                                : el.name}
                        </Td>

                        <Td textTransform="capitalize" p="12px">
                            {el.trade_date}
                        </Td>
                        <Td
                            textTransform="capitalize"
                            textAlign={"center"}
                            p="12px"
                        >
                            {el.action.toLowerCase()}
                        </Td>
                        <Td
                            textTransform="capitalize"
                            textAlign={"center"}
                            p="12px"
                        >
                            {el.currency}
                        </Td>
                        <Td
                            textTransform="capitalize"
                            textAlign={"right"}
                            p="12px"
                        >
                            {Number(el.amount)}
                        </Td>
                        <Td
                            textTransform="capitalize"
                            textAlign={"right"}
                            p="12px"
                        >
                            {Number(el.value).toFixed(2)}
                        </Td>
                        <Td
                            textTransform="capitalize"
                            p="12px"
                            textAlign={"right"}
                        >
                            {el.action === "BUY"
                                ? `-${Number(el.total).toFixed(2)}`
                                : Number(el.total).toFixed(2)}
                        </Td>
                    </Tr>
                );
            })}
        </Tbody>
    );
};

export default TablePortfolioTransaction;