import {
    Input,
    FormControl,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Flex,
    Box,
    FormLabel
} from "@chakra-ui/react";
import CalculateAssetBox from "../DetailBox/CalculateAssetBox";
import AmountAsset from "../Tooltip/AmountAsset";
import CustomFormHelper from "../Form/CustomFormHelper";
import Helpers from "../../hooks/helpers";

const UpdateAssetModal = ({
    isOpen,
    onClose,
    formDuplicate,
    handleAddDuplicate,
    asset,
    availableFundVal,
    setValue2,
    setAmount2,
    assetCurrency,
    assetCountry,
    value2,
    amount2,
    totalUSD2,
    USDValue,
    isSubmit
}: any) => {
    return (
        <Modal isCentered={false} size="md" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    px="24px"
                    py="16px"
                    fontSize="16px"
                    fontWeight="600"
                    borderBottom="1px"
                    borderColor="#E9E9E9"
                >
                    Add Asset
        </ModalHeader>
                <ModalCloseButton
                    _focus={{ outline: "none" }}
                    fontSize="8px"
                    maxW="10px"
                    maxH="10px"
                    mt="15px"
                    mr="20px"
                />
                <CalculateAssetBox
                    title={[
                        "Available fund",
                        "Current Price",
                        "Asset Price USD",
                        `Total ${assetCountry ? assetCurrency : ""}`,
                        "Total USD",
                    ]}
                    content={[
                        Helpers.currencyFormaterUSD(availableFundVal),
                        asset
                            ? Helpers.currencyFormater(asset?.updated_value, asset?.currency)
                            : 0,
                        Helpers.currencyFormaterUSD(USDValue),
                        asset
                            ? Helpers.currencyFormater(value2 * amount2, asset?.currency)
                            : 0,
                        Helpers.currencyFormaterUSD(totalUSD2),
                    ]}
                />
                <form
                    onSubmit={formDuplicate.handleSubmit((values: any) =>
                        handleAddDuplicate(values)
                    )}
                >
                    <ModalBody pt={0}>
                        <Flex justifyContent={"space-between"}>
                            <Box w={"full"}>
                                <FormControl pt="16px">
                                    <AmountAsset labelName={"Amount"} typeName={asset?.type} />
                                    <Input
                                        fontSize="14px"
                                        px="12px"
                                        id="amount"
                                        type="number"
                                        step="any"
                                        placeholder="Input Fund"
                                        {...formDuplicate.register("amount", { required: true })}
                                        onChange={(e) => {
                                            setAmount2(Number(e.target.value));
                                        }}
                                    />
                                    <CustomFormHelper
                                        helperText={
                                            asset?.type === "CC"
                                                ? `Current Amount : ${Number(asset?.amount)}`
                                                : `Current Amount : ${Number(asset?.amount)}`
                                        }
                                    />
                                </FormControl>
                                <FormControl pt="16px">
                                    <AmountAsset
                                        labelName={"Asset Price"}
                                        currency={asset?.currency}
                                    />
                                    <Input
                                        fontSize="14px"
                                        px="12px"
                                        id="value"
                                        type="number"
                                        step="any"
                                        placeholder="Asset Price"
                                        {...formDuplicate.register("value", { required: true })}
                                        onChange={(e) => {
                                            setValue2(Number(e.target.value));
                                        }}
                                    />
                                </FormControl>

                                <FormControl pt="16px">
                                    <FormLabel
                                        fontSize="14px"
                                        mb="8px"
                                        htmlFor="Market"
                                    >
                                        Transaction Date
                                    </FormLabel>
                                    <Input
                                        fontSize="14px"
                                        px="12px"
                                        id="trade_date"
                                        type="date"
                                        placeholder="Transaction Date"
                                        {...formDuplicate.register("trade_date", { required: true })}
                                    // onChange={(e) => {
                                    //     setValue(e.target.value);
                                    // }}
                                    />
                                </FormControl>
                            </Box>
                        </Flex>
                    </ModalBody>

                    <ModalFooter px="24px" py="16px">
                        <Button
                            backgroundColor="#fff"
                            color="rgba(23, 23, 23, 1)"
                            fontSize="12px"
                            fontWeight="600"
                            border="1px"
                            borderColor="#E9E9E9"
                            h="32px"
                            mr={3}
                            onClick={onClose}
                        >
                            Cancel
            </Button>
                        <Button
                            _focus={{ outline: 'none' }}
                            isLoading={isSubmit ? true : false}
                            loadingText={'Submitting'}
                            backgroundColor="rgba(198, 179, 120, 1)"
                            color="#fff"
                            border="1px"
                            fontSize="12px"
                            fontWeight="600"
                            h="32px"
                            _hover={{
                                color: "rgba(198, 179, 120, 1)",
                                backgroundColor: "#fff",
                                border: "1px",
                            }}
                            type="submit"
                        >
                            Submit
            </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default UpdateAssetModal;
