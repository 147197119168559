import { Thead, Tr, Th } from "@chakra-ui/react";

const CustomTableHeadTransactions = ({ tableHeadList }: any) => {
    return (
        <Thead bg="rgba(246, 246, 246, 1)">
            <Tr>
                <Th
                    textTransform="capitalize"
                    w={"120px"}
                    maxW="120px"
                    p="12px"
                >
                    Date
                        </Th>
                <Th textTransform="capitalize" p="12px">
                    Name
                        </Th>
                <Th
                    textTransform="capitalize"
                    w={"120px"}
                    maxW="120px"
                    p="12px"
                >
                    Trade Date
                        </Th>
                <Th
                    textTransform="capitalize"
                    textAlign={"center"}
                    p="12px"
                >
                    Action
                        </Th>
                <Th
                    textTransform="capitalize"
                    textAlign={"center"}
                    minW={"130px"}
                    p="12px"
                >
                    Currency (CCY)
                        </Th>
                <Th
                    textTransform="capitalize"
                    textAlign={"right"}
                    minW={"130px"}
                    p="12px"
                >
                    Amount (QTY)
                        </Th>
                <Th
                    textTransform="capitalize"
                    textAlign={"right"}
                    minW={"130px"}
                    p="12px"
                >
                    Value USD (Price)
                        </Th>
                <Th
                    textTransform="capitalize"
                    textAlign={"right"}
                    minW={"200px"}
                    p="12px"
                >
                    Settlement Amount USD (Total)
                        </Th>
                <Th
                    textTransform="capitalize"
                    textAlign={"right"}
                    p="12px"
                >
                    Action
                        </Th>
            </Tr>
        </Thead>
    );
};

export default CustomTableHeadTransactions;