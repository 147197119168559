import TransactionTable from "../../components/Tables/TransactionsTable";
function Transactions({ customer, customerTransactions }: any) {
  return (
    <div>
      <TransactionTable customer={customer} customerTransactions={customerTransactions} />
    </div>
  );
}

export default Transactions;
