import ExchangeList from "../../components/ExchangeList";

function MutualFund() {
  return (
    <div>
      <ExchangeList instrument="mutual-funds" />
    </div>
  );
}

export default MutualFund;
