import ExchangeList from "../../components/ExchangeList";

function ETF() {
  return (
    <div>
      <ExchangeList instrument="ETF" />
    </div>
  );
}

export default ETF;
