import { Box, Heading, Text, Image, Center } from '@chakra-ui/react';
import bgEmpty from "../../assets/empty-data.png"

const EmptyData = () => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Center><Image src={bgEmpty} /></Center>
      <Text fontSize="18px" mt={3} mb={2}>
        Not Found
      </Text>
      <Text color={'gray.500'} mb={6}>
        The data you're looking for does not seem to exist
      </Text>
    </Box>
  );
}

export default EmptyData;