import ExchangeList from "../../components/ExchangeList";

function Forex() {
  return (
    <div>
      <ExchangeList instrument="forex" />
    </div>
  );
}

export default Forex;
