import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
    Table,
    Thead,
    Tr,
    Tbody,
    Td,
} from "@chakra-ui/react";
import CustomerAssetTableHead from "./TableHead/CustomerAssetTableHead";
import Helpers from "../../hooks/helpers";
import AssetAction from "../../components/Tables/ActionColumn/AssetAction";
import qoreContext from "../../common/qoreContext";

const CustomerAssetTable = ({
    buy2,
    sell,
    getAssetData,
    data,
    formSell,
    formDuplicate,
    setValue,
    setValue2,
    setValue3,
    setAmount,
    setAmount2,
    setAmount3,
    availableFund,
    customer
}: any) => {
    const params = useParams()
    const user = qoreContext.useCurrentUser();
    const nav = useNavigate();
    const handleTransactionDetail = (el: any) => {
        nav(`/management/fund-management/${params.company}/transactions-log/${el.code}?id=${el.customer_id}`);
    };
    const getTransactions = async () => {

        try {
            let customerCondition = { $and: [{ rm_id: user?.user?.id }] };
            let response;
            response = await qoreContext.client
                .table("customers")
                .readRows({
                    condition: customerCondition,
                    populate: ["customer_id_transactions_items"],
                })
                .toPromise();
            
            const filteredLogs = response?.data?.nodes.filter((el: any) => {
                if (el.id === customer.id) {
                    return el
                }
            })

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getTransactions();
    }, [user?.user?.id]);

    return (
        <Table variant="simple" size="md" fontSize="12px">
            <Thead bg="rgba(246, 246, 246, 1)">
                <CustomerAssetTableHead />
            </Thead>
            <Tbody>
                {data?.map((el: any, index: number) => {
                    return (
                        <Tr key={index}>
                            <Td textTransform={"capitalize"} p={"12px"}>
                                {el.name}
                            </Td>
                            <Td textTransform={"capitalize"} p={"12px"}>
                                {el.type}
                            </Td>
                            <Td textTransform={"capitalize"} p={"12px"}>
                                {el.code}
                            </Td>
                            <Td textTransform={"capitalize"} p={"12px"}>
                                {Number(el.amount)}
                            </Td>
                            <Td textTransform={"capitalize"} p={"12px"}>
                                {Helpers.currencyFormater(el.updated_value, el.currency)}
                            </Td>
                            <Td textTransform={"capitalize"} p={"12px"}>
                                {Helpers.currencyFormater(
                                    el.updated_value * el.amount,
                                    el.currency
                                )}
                            </Td>
                            <Td textTransform={"capitalize"} p={"12px"}>
                                {Helpers.currencyFormaterUSD(el.updated_usd_value * el.amount)}
                            </Td>
                            <Td textTransform={"capitalize"} p={"12px"}>
                                {el.updated_at}
                            </Td>
                            <AssetAction
                                handleTransactionDetail={handleTransactionDetail}
                                availableFund={availableFund}
                                buy2={buy2}
                                sell={sell}
                                el={el}
                                getAssetData={getAssetData}
                                formSell={formSell}
                                formDuplicate={formDuplicate}
                                setValue3={setValue3}
                                setAmount3={setAmount3}
                                setValue2={setValue2}
                                setAmount2={setAmount2}
                                setValue={setValue}
                                setAmount={setAmount}
                            />
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
};

export default CustomerAssetTable;
