import {
    Input,
    FormControl,
    FormLabel,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import CalculateAssetBox from "../DetailBox/CalculateAssetBox";
import AmountAsset from "../Tooltip/AmountAsset";
import CustomFormHelper from "../Form/CustomFormHelper";
const SellAssetModal = ({
    isOpen,
    onClose,
    formSell,
    handleSellAsset,
    asset,
    value,
    amount3,
    setAmount3,
    value3,
    setValue3,
    isSubmit
}: any) => {

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    px="24px"
                    py="16px"
                    fontSize="16px"
                    fontWeight="600"
                    borderBottom="1px"
                    borderColor="#E9E9E9"
                >
                    Sell Asset
                </ModalHeader>
                <ModalCloseButton
                    _focus={{ outline: "none" }}
                    fontSize="8px"
                    maxW="10px"
                    maxH="10px"
                    mt="15px"
                    mr="20px"
                />
                <CalculateAssetBox
                    title={
                        [ "Type",
                        "Available Amount", 
                        "Current Price USD", 
                        `Total Sell Price`]}
                    content={
                        [   
                            asset?.type !== ""
                                    ? asset?.type === "CC"
                                        ? "Coin"
                                        : "Share"
                                    : "",

                            Number(asset?.amount), 
                            value, 
                            `${value3 * amount3}`]}
                />
                <form
                    onSubmit={formSell.handleSubmit((values: any) =>
                        handleSellAsset(values)
                    )}
                >
                    <ModalBody>
                        <FormControl pt="16px">
                            <AmountAsset labelName={'Amount'} typeName={asset?.type} />

                            <Input
                                fontSize="14px"
                                px="12px"
                                id="amount"
                                type="number"
                                step="any"
                                placeholder="Input Fund"
                                min={0}
                                max={asset?.amount}
                                {...formSell.register("amount", { required: true })}
                                onChange={(e) => {
                                    setAmount3(Number(e.target.value));
                                }}
                            />
                            <CustomFormHelper helperText={`Current Amount : ${Number(asset?.amount)}`} />
                        </FormControl>
                        <FormControl pt="16px">
                            <FormLabel
                                fontWeight={600}
                                fontSize="14px"
                                mb="8px"
                                htmlFor="assetPrice"
                            >
                                Asset Price USD
                            </FormLabel>
                            <Input
                                fontSize="14px"
                                px="12px"
                                id="assetPrice"
                                type="number"
                                step="any"
                                placeholder="Asset Price"
                                {...formSell.register("value", { required: true })}
                                onChange={(e) => {
                                    setValue3(Number(e.target.value));
                                }}
                            />
                        </FormControl>
                        <FormControl pt="16px">
                            <FormLabel
                                fontSize="14px"
                                mb="8px"
                                htmlFor="Market"
                            >
                                Transaction Date
                            </FormLabel>
                            <Input
                                fontSize="14px"
                                px="12px"
                                id="trade_date"
                                type="date"
                                placeholder="Transaction Date"
                                {...formSell.register("trade_date", { required: true })}
                            // onChange={(e) => {
                            //     setValue(e.target.value);
                            // }}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter px="24px" py="16px">
                        <Button
                            backgroundColor="#fff"
                            color="rgba(23, 23, 23, 1)"
                            fontSize="12px"
                            fontWeight="600"
                            border="1px"
                            borderColor="#E9E9E9"
                            h="32px"
                            mr={3}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            isLoading={isSubmit ? true : false}
                            loadingText={'Submitting'}
                            _focus={{outline: 'none'}}
                            backgroundColor="rgba(198, 179, 120, 1)"
                            color="#fff"
                            border="1px"
                            fontSize="12px"
                            fontWeight="600"
                            h="32px"
                            _hover={{
                                color: "rgba(198, 179, 120, 1)",
                                backgroundColor: "#fff",
                                border: "1px",
                            }}
                            type="submit"
                        >
                            Submit
                    </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default SellAssetModal;
