import { useEffect, useState } from "react";
import {
    Box,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Button,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    useColorModeValue,
    Center,
    Divider,
    Flex,
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    AspectRatio,
    Input
} from "@chakra-ui/react";
import zoom from "chartjs-plugin-zoom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Helpers from "../../hooks/helpers";
import TitleBoxDetail from '../DetailBox/TitleBoxDetail';
import ChartButtonFISingle from '../Button/ChartButtonFISingle';
import AddPlusButton from '../Button/AddPlusButton';
import { BsCardList } from "react-icons/bs";

import TemplateTable from '../Tables/TemplateTable';
import CompareAction from '../Tables/ActionColumn/CompareAction';
import GeneralTemplate from '../../common/Report/GeneralTemplate';
import LinePDFChart from '../Chart/LinePDFChart';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import { MdOutlineStackedLineChart } from "react-icons/md";
import { BiEditAlt, BiTrash } from "react-icons/bi";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoom,
    Filler
);

const CompareChart = ({ performanceData, dataChart, handleModalOpen, handleUpdateDataChart }: any) => {
    const [pdfLink, setPdfLink] = useState<string>('');
    const [customDate, setCustomDate] = useState<boolean>(false)
    const [currentDate, setCurrentDate] = useState<string>("");
    const [filter, setFilter] = useState<any>("MAX");
    const [chartLabels, setChartLabels] = useState<any>([]);
    const [tabularData, setTabularData] = useState<any>([]);
    const [datasets, setDatasets] = useState<any>([]);
    let tempMonths: any[] = [];
    const [data, setData] = useState<any>(
        { 
            labels: [], 
            datasets: [] 
        });
    
    const getDataFromTop = () => {
        return dataChart
    }

    const getCurrentDate = () => {
        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth();
        const date = new Date().getUTCDate();
        if (month + 1 > 10) {
            const getDate = `${year}-${month + 1}-${date}`;
            setCurrentDate(getDate);
        } else {
            const getDate = `${year}-0${month + 1}-${date}`;
            setCurrentDate(getDate);
        }
    };

    const getData = () => {
        if (dataChart.length > 0) {
            const data = Helpers.getFilteredData(dataChart, filter, currentDate);
            if (filter === "MAX") {
                if (data) {
                    setChartLabels(data[0]);
                    setTabularData(data[1]);
                    setDatasets(data[2]);
                }
            } else {
                if (data) {
                    setChartLabels(data[0]);
                    setDatasets(data[1]);
                }
            }
        }
    };

    useEffect(() => {
        getCurrentDate();
    }, []);

    useEffect(() => {
        getData();
    }, [dataChart, filter, currentDate]);

    useEffect(() => {
        setData(
            { 
                labels: chartLabels, 
                datasets: datasets });
    }, [chartLabels, datasets]);

    

    const options: any = {
        spanGaps: true,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                labels: {
                  usePointStyle: true,
                  padding: 20
                },
                align: 'center',
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {

                        const dataChart = getDataFromTop();
                        const month = Helpers.getFormatDateYYMMDD(context.label.split(' ')[1]);
                        const newFormat = `${context.label.split(' ')[0]}-${month}-${context.label.split(' ')[2]}`

                        let filtered = dataChart[context.datasetIndex].data.filter((el: any) => el.date === newFormat);

                        if (filtered.length > 0) {
                            return `  ${dataChart[context.datasetIndex].currency} ${filtered[0].close} - ( ${context.raw} %)`   
                        } else {
                            return `  N/A`
                        }
                        
                    },
                },
            },
        },
        scales: {
            x: {
                offset: false,
                gridLines: {
                    offsetGridLines: false,
                },
                title: {
                    display: true,
                },
                grid: {
                    display: false,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: false,
                    getLabelForValue: function(value: any) {
                        return value
                    },
                    callback: function (val: any, index: number, tick: any) {
                        const newLabel = Helpers.labelData(tick.length, index, this.getLabelForValue(val), filter, tempMonths);
                        return newLabel
                    },
                },
            },
        },
    };

    const chartFilterArr = [
        "1W",
        "1M",
        "3M",
        "YTD",
        "1Y",
        "5Y",
        "MAX",
    ]

    const tableHead = [
        "Name",
        "1W",
        "1M",
        "3M",
        "YTD",
        "1Y",
        "5Y",
        "MAX",
        "Action"
    ]

    const handleAddAsset = () => {
        handleModalOpen();
    }

    const handleDeleteRow = async (val: any) => {
        const filter = await dataChart.filter((el: any) => el.name !== val.name ? val.name : val.label);
        handleUpdateDataChart(filter);

    }

    const handlePDF = async (detail: any) => {
        downloadOpen();
    }

    const handleModalClose = () => {
        setPdfLink('');
        onClose();
    }

    const { isOpen, onOpen: downloadOpen, onClose } = useDisclosure();

    const generatePDF = async () => {

        let doc = new jsPDF({
            putOnlyUsedFonts: true,
            orientation: 'l',
        });

        let canvas = document.getElementById('report') as HTMLCanvasElement;
        window.scrollTo(0,0);
        await html2canvas(canvas, {
            allowTaint: true,
            useCORS: true,
            width: 2000,
            scale: 3,
        }).then((after) => {
            doc.addImage(
                after.toDataURL("image/png"),
                "PNG",
                5,
                5,
                480,
                190,
                "",
                "MEDIUM"
            );
        });
        
        // doc.save("myChart");
        // doc.addPage('a4', 'l');
        // let canvas2 = document.getElementById('data-report') as HTMLCanvasElement;
        
        // await html2canvas(canvas2, {
        //     allowTaint: true,
        //     useCORS: true,
        //     width: 2000,
        //     scale: 3,
        // }).then((after) => {
        //     doc.addImage(
        //         after.toDataURL("image/png"),
        //         "PNG",
        //         10,
        //         15,
        //         450,
        //         150,
        //         "",
        //         "MEDIUM"
        //     );
        // });
        let string = doc.output("datauristring");
        setPdfLink(string);
    }

    // console.log(pdfLink, `pdf`)
    // console.log(tabularData, `tabularData`)
    console.log(data, `data 1 `)   
    // console.log(dataChart, `dataChart`)  

    return (
        <Box py="10px">
            <Modal onClose={handleModalClose} size={'full'} isOpen={isOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Generate PDF</ModalHeader>
                    <ModalCloseButton _focus={{ outline: 'none'}} />
                    <ModalBody>
                        {   pdfLink !== '' ? (
                            <Box mt={'24px'}>
                                <AspectRatio ratio={1}>
                                    <iframe
                                        src={pdfLink}
                                        allowFullScreen
                                    />
                                </AspectRatio>
                            </Box>
                        ) : (
                            <Box>
                                {
                                    tabularData ? (
                                        <Box>
                                            {
                                                tabularData.length > 1 ? (
                                                    <Box
                                                        bg={useColorModeValue("white", "gray.800")}
                                                        boxShadow={"lg"}
                                                        rounded={"md"}
                                                        px="24px"
                                                        mr={'24px'}
                                                        h={'fit-content'}
                                                    >
                                                        <TitleBoxDetail
                                                            fsIcon={"16"}
                                                            iconTitle={BsCardList}
                                                            boxTitle={`Preview`}
                                                        /> 

                                                        <Box maxW={'90%'} id={'report'}>
                                                            <GeneralTemplate 
                                                                page={'1'}
                                                                type={tabularData}
                                                                data={{
                                                                    tabularData: tabularData,
                                                                    tableHead: tableHead,
                                                                    date: data.labels
                                                                }}
                                                                instrument={'compare'}>
                                                                    <Box h={355}>
                                                                        <Line options={options} data={data} />
                                                                    </Box>
                                                            </GeneralTemplate>
                                                        </Box>

                                                        {/* <Box id={'data-report'}>
                                                            <GeneralTemplate 
                                                                page={'2'}
                                                                highlight={
                                                                    {
                                                                        performance : performance.length > 0 ? performance : '',
                                                                        technicalAnalysis : technicalAnalysis.length > 0 ? technicalAnalysis : '',
                                                                        ratingAndReturns: ratingAndReturns.length > 0 ? ratingAndReturns : '',
                                                                        ratioAnalysis: ratioAnalysis.length > 0 ? ratioAnalysis : '',
                                                                        salesAndEarnings: salesAndEarnings.length > 0 ? salesAndEarnings : '',
                                                                        dividend: salesAndEarnings.length > 0 ? salesAndEarnings : '',

                                                                    }
                                                                }
                                                                type={instrument}
                                                                data={dataDetail}
                                                                instrument={'financial-instrument'}>
                                                            </GeneralTemplate>
                                                        </Box> */}
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        bg={useColorModeValue("white", "gray.800")}
                                                        boxShadow={"lg"}
                                                        rounded={"md"}
                                                        px="24px"
                                                        mr={'24px'}
                                                        h={'fit-content'}
                                                    >
                                                        <TitleBoxDetail
                                                            fsIcon={"16"}
                                                            iconTitle={BsCardList}
                                                            boxTitle={`Preview`}
                                                        /> 

                                                        <Box maxW={'90%'} id={'report'}>
                                                            <GeneralTemplate 
                                                                page={'1'}
                                                                type={tabularData}
                                                                data={{
                                                                    tabularData: tabularData,
                                                                    tableHead: tableHead
                                                                }}
                                                                instrument={'compare'}>
                                                                    <Box h={355}>
                                                                        <Line options={options} data={data} />
                                                                    </Box>
                                                            </GeneralTemplate>
                                                        </Box>

                                                        {/* <Box id={'data-report'}>
                                                            <GeneralTemplate 
                                                                page={'2'}
                                                                highlight={
                                                                    {
                                                                        performance : performance.length > 0 ? performance : '',
                                                                        technicalAnalysis : technicalAnalysis.length > 0 ? technicalAnalysis : '',
                                                                        ratingAndReturns: ratingAndReturns.length > 0 ? ratingAndReturns : '',
                                                                        ratioAnalysis: ratioAnalysis.length > 0 ? ratioAnalysis : '',
                                                                        salesAndEarnings: salesAndEarnings.length > 0 ? salesAndEarnings : '',
                                                                        dividend: salesAndEarnings.length > 0 ? salesAndEarnings : '',

                                                                    }
                                                                }
                                                                type={instrument}
                                                                data={dataDetail}
                                                                instrument={'financial-instrument'}>
                                                            </GeneralTemplate>
                                                        </Box> */}
                                                    </Box>
                                                )
                                            }
                                        </Box>
                                    ) : (
                                        <Box
                                            bg={useColorModeValue("white", "gray.800")}
                                            boxShadow={"lg"}
                                            rounded={"md"}
                                            px="24px"
                                            mr={'24px'}
                                            h={'fit-content'}
                                        >
                                            <TitleBoxDetail
                                                fsIcon={"16"}
                                                iconTitle={BsCardList}
                                                boxTitle={`Preview`}
                                            /> 

                                            <Box maxW={'90%'} id={'report'}>
                                                <GeneralTemplate 
                                                    page={'1'}
                                                    type={tabularData}
                                                    data={{
                                                        tabularData: tabularData,
                                                        tableHead: tableHead
                                                    }}
                                                    instrument={'compare'}>
                                                        <Box h={355}>
                                                            <Line options={options} data={data} />
                                                        </Box>
                                                </GeneralTemplate>
                                            </Box>

                                            {/* <Box id={'data-report'}>
                                                <GeneralTemplate 
                                                    page={'2'}
                                                    highlight={
                                                        {
                                                            performance : performance.length > 0 ? performance : '',
                                                            technicalAnalysis : technicalAnalysis.length > 0 ? technicalAnalysis : '',
                                                            ratingAndReturns: ratingAndReturns.length > 0 ? ratingAndReturns : '',
                                                            ratioAnalysis: ratioAnalysis.length > 0 ? ratioAnalysis : '',
                                                            salesAndEarnings: salesAndEarnings.length > 0 ? salesAndEarnings : '',
                                                            dividend: salesAndEarnings.length > 0 ? salesAndEarnings : '',

                                                        }
                                                    }
                                                    type={instrument}
                                                    data={dataDetail}
                                                    instrument={'financial-instrument'}>
                                                </GeneralTemplate>
                                            </Box> */}
                                        </Box>
                                    )
                                }
                            </Box>
                        )
                            
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                            fontSize={'14px'}
                            mr={'20px'}
                            _focus={{ outline: 'none'}}
                            onClick={handleModalClose}>Close</Button>
                        {
                            pdfLink !== '' ? (
                                <></>
                            ) : (
                                <Button
                                    _hover={{
                                        color: 'rgba(151, 133, 77, 1)',
                                        backgroundColor: '#fff',
                                        borderColor: 'rgba(151, 133, 77, 1)'
                                    }}
                                    py={'8px'}
                                    px={'12px'} 
                                    borderColor={'#fff'}
                                    color={'#fff'}
                                    backgroundColor={'rgba(151, 133, 77, 1)'}
                                    variant={'outline'}
                                    fontSize={'14px'}
                                    onClick={generatePDF}
                                    >Generate</Button>
                            )
                        }
                        
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Box
                w={"full"}
                bg={useColorModeValue("white", "gray.800")}
                boxShadow={"lg"}
                rounded={"md"}
                px="0"
            >
                <Box px={'24px'}>
                    <TitleBoxDetail
                        fsIcon={"20px"}
                        iconTitle={MdOutlineStackedLineChart}
                        boxTitle={`Chart Comparison`}
                    />
                </Box>
                 <Flex>
                    <Box mr={0} ml={'auto'}>
                        <Button
                            _hover={{
                                color: '#fff',
                                backgroundColor: 'rgba(151, 133, 77, 1)'
                            }}
                            _focus={{outline: 'none'}}
                            py={'8px'}
                            px={'12px'} 
                            borderColor={'rgba(151, 133, 77, 1)'}
                            color={'rgba(151, 133, 77, 1)'}
                            variant={'outline'}
                            fontSize={'12px'}
                            onClick={() => handlePDF(dataChart)}>
                            Download Instrument
                        </Button>
                    </Box>
                </Flex>
                <Box w={{ base: "full" }} px={"24px"}>
                    <Center my={'16px'}>
                        <ChartButtonFISingle
                            chartFilter={chartFilterArr}
                            handleChartFilter={setFilter} />
                    </Center>

                    <Box h={355}>
                        <Line options={options} data={data} />
                    </Box>
                    <Box my={'16px'}>
                        <Divider></Divider>
                    </Box>
                    <Box pb={'20px'}>
                        <Flex direction="row" py="20px">
                            <Stack spacing={3} maxW="230px" mr="12px">
                                <Box 
                                    fontWeight={'bold'}
                                    fontSize={'16px'}
                                    color={'rgba(23, 23, 23, 1)'}>Asset List</Box>
                            </Stack>
                            <Stack ml="auto" mr="0" direction="row" spacing={4}>
                                <AddPlusButton
                                    handleOpenModal={() => handleAddAsset() }
                                    buttonText={'Add Asset to Compare'}
                                />
                            </Stack>
                        </Flex>
                        <TemplateTable 
                            instrument={false}
                            tableHeadList={tableHead}
                            pages={1}
                            currentPage={1}
                            handlePageChange={null}
                            customTableHead={null}
                            >
                            <Tbody>
                                {tabularData.map((el: any, index: number) => {
                                    return (
                                        <>
                                            <Tr key={index}>
                                                <Td textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.name}</Td>
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastWeek}</Td>
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastMonth}</Td>
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last3Month}</Td>
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.YTD}</Td>
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastYear}</Td>
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last5Year}</Td>
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.maxData}</Td>
                                                <Td textAlign={'right'} p={'12px'}>
                                                    <Button
                                                        onClick={(e) => handleDeleteRow(el)}
                                                        w="32px"
                                                        h="32px"
                                                        p="0"
                                                        textAlign={'center'}
                                                        border="1px"
                                                        borderColor="#E9E9E9"
                                                        backgroundColor="#fff"
                                                        _focus={{ outline: "none" }}
                                                    >
                                                        <BiTrash fontSize="14px" />
                                                    </Button>
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td textTransform={'capitalize'} fontSize={'12px'} p={'12px'}></Td> 
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastWeek === 'N/A' ? el.lastWeek : Helpers.currencyFormater(el.Val[0].lastWeek, el.Val[0].currency)}</Td>
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastMonth === 'N/A' ? el.lastMonth : Helpers.currencyFormater(el.Val[0].lastMonth, el.Val[0].currency)}</Td>
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last3Month === 'N/A' ? el.last3Month : Helpers.currencyFormater(el.Val[0].last3Month, el.Val[0].currency)}</Td>
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.YTD === 'N/A' ? el.YTD : Helpers.currencyFormater(el.Val[0].YTD, el.Val[0].currency)}</Td>
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastYear === 'N/A' ? el.lastYear : Helpers.currencyFormater(el.Val[0].lastYear, el.Val[0].currency)}</Td>
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last5Year === 'N/A' ? el.last5Year : Helpers.currencyFormater(el.Val[0].last5Year, el.Val[0].currency)}</Td>
                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.maxData === 'N/A' ? el.maxData : Helpers.currencyFormater(el.Val[0].Max, el.Val[0].currency)}</Td>
                                                <Td p={0}></Td>
                                            </Tr>
                                        </>
                                    );
                                })}
                            </Tbody>
                        </TemplateTable>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CompareChart;
