import { useState } from 'react'
import { Thead, Tr, Th, Box, Flex } from "@chakra-ui/react";
import { FaSort } from "react-icons/fa";

const TableHeadCustomerHoldings = (
    { 
        handleSortedByName,
        handleSortedByValue
    }: any) => {
    
    const [orderName, setOrderName] = useState<string>('ASC');
    const [orderValue, setOrderValue] = useState<string>('ASC');


    return (
        <Thead bg="rgba(246, 246, 246, 1)">
            <Tr>
                <Th 
                    _hover={{cursor: 'pointer'}}
                    onClick={() => {
                        handleSortedByName(orderName);
                        orderName === 'ASC' ? setOrderName('DESC') : setOrderName('ASC');
                    }}
                    textTransform="capitalize" p="12px">
                    <Flex justifyContent={'space-between'}>
                        <Box>
                            Name
                        </Box>
                        <Box>
                            <FaSort color={'rgba(116, 116, 116, 1)'} fontSize={16} />
                        </Box>
                    </Flex>
                    
                </Th>
                <Th
                    w={130}
                    textTransform={'initial'}
                    textAlign={"right"}
                    p="12px"
                >
                    Value of Holdings
                </Th>
                <Th
                    w={120}
                    _hover={{cursor: 'pointer'}}
                    onClick={() => {
                        handleSortedByValue(orderValue);
                        orderValue === 'ASC' ? setOrderValue('DESC') : setOrderValue('ASC');
                    }}
                    textTransform="capitalize"
                    textAlign={"right"}
                    p="12px"
                >
                    <Flex justifyContent={'space-between'}>
                        <Box>
                            Holding %
                        </Box>
                        <Box>
                            <FaSort color={'rgba(116, 116, 116, 1)'} fontSize={16} />
                        </Box>
                    </Flex>
                </Th>
            </Tr>
        </Thead>
    );
};

export default TableHeadCustomerHoldings;