import {
    Box,
    AccordionPanel,
    Table,
} from "@chakra-ui/react";
import PaginatorTableMarket from "../../components/Tables/PaginatorTableMarket";
import { useSelector } from 'react-redux';
import { useState, useEffect } from "react";
import TableHeadCustomerHoldings from '../../components/Tables/TableHead/TableHeadCustomerHoldings';
import TableHoldingsCustomer from '../../components/Tables/TableBody/TableHoldingsCustomer';

const CustomerHoldings = () => {
    const [holdingsData, setHoldingsData] = useState<any[]>([]);

    const { dataFilterCustomer, dataAssetSummary } = useSelector((state: any) => state.rm);

    useEffect(() => {
        if (dataAssetSummary.length > 0) {
            getHoldings()
        }
    }, [dataAssetSummary.length])

    const getHoldings = () => {
        let totalHoldings = 0

        dataFilterCustomer.forEach((el: any) => {
            el.customer_id_items.forEach((asset: any) => {
                totalHoldings += Number(asset.amount * asset.updated_usd_value);
            })
        })

        let tempData: any = { }

        dataFilterCustomer.forEach((el: any) => {
            el.customer_id_items.forEach((asset: any) => {
                if (tempData[asset.name]) {
                    tempData[asset.name].list.push(asset);
                    tempData[asset.name].total += Number(asset.amount * asset.updated_usd_value);
                    tempData[asset.name].percentage += Number((asset.amount * asset.updated_usd_value) / totalHoldings * 100);
                } else {
                    tempData[asset.name] = {
                        list: [],
                        total: 0,
                        percentage: 0
                    }

                    tempData[asset.name].list.push(asset);
                    tempData[asset.name].total += Number(asset.amount * asset.updated_usd_value);
                    tempData[asset.name].percentage += Number((asset.amount * asset.updated_usd_value) / totalHoldings * 100);
                }
            })
        })

        const entries = Object.entries(tempData);   
        setHoldingsData(entries);
    }

    const [list, setList] = useState<any[]>([]);
    const [limit, setLimit] = useState<number>(10);
    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        if (dataFilterCustomer.length) {
            if (currentPage !== 1) {
                setCurrentPage(1)
            }
        }
    }, [dataFilterCustomer.length])

    useEffect(() => {
        getLimit()
    }, [holdingsData, currentPage]);

    const handlePageChange = (nextPage: number) => {
        setCurrentPage(nextPage);
    };

    const getLimit = async () => {
        let temp: any = []

        holdingsData.forEach((el: any) => {
            temp.push(el)
        })

        setPages(Math.ceil(temp.length / limit));
        if (limit > 0) {
            temp = temp.splice((currentPage - 1) * limit, limit);
        }
        setList(temp);
    }

    const handleSortedByName = (order: any) => {
        if (order === 'ASC') {
            const sortedList = holdingsData.sort();
            setHoldingsData(sortedList);
            getLimit();
        } else {
            const sortedList = holdingsData.sort().reverse();
            setHoldingsData(sortedList);
            getLimit();
        }
    }

    const handleSortedByValue = (order: any) => {
        if (order === 'ASC') {
            const sortData = sortedList(holdingsData);
            setHoldingsData(sortData);
            getLimit();
        } else {
            const sortData = sortedList(holdingsData).reverse();
            setHoldingsData(sortData);
            getLimit();
        }
    }

    const sortedList = (arr: any) => {
        arr.sort(function (a: any, b: any) {
            if (a[1].total > b[1].total) {
                return -1;
            }
            if (b[1].total > a[1].total) {
                return 1;
            }
            return 0;
        });

        return arr
    }

    return (
        <AccordionPanel pb={4}>
            { list && list.length > 0 ? (
                <Box>
                    <Box my={5}>
                        <Table variant="simple" size="md" fontSize="12px">
                            <TableHeadCustomerHoldings 
                            handleSortedByValue={handleSortedByValue}
                            handleSortedByName={handleSortedByName} />
                            <TableHoldingsCustomer holdingsData={list} />
                        </Table>
                        <PaginatorTableMarket
                            pages={pages}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                        />
                    </Box>
                </Box>
            ) : (
                <Box>
                    There is no Customer yet
                </Box>
            )
            }
        </AccordionPanel>
    );
};

export default CustomerHoldings;