import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import {
    Flex,
    Box,
    useColorModeValue,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Divider
} from "@chakra-ui/react";
import Helpers from '../../hooks/helpers';

const PerformanceDetail = () => {

    const { dataFilterCustomer, dataAssetSummary } = useSelector((state: any) => state.rm);
    const [yesterdayPerformance, setYesterdayPerformance] = useState<any[]>([]);
    const [lastWeekPerformance, setLastWeekPerformance] = useState<any[]>([]);
    const [lastMonthPerformance, setLastMonthPerformance] = useState<any[]>([]);
    const [lastYearPerformance, setLastYearPerformance] = useState<any[]>([]);
    
    useEffect(() => {
        if (dataAssetSummary.length > 0) {
            getPerformance()
        }
    }, [dataAssetSummary.length])

    const getPerformance = () => {
        if (dataFilterCustomer.length > 1) {
            getMultiplePerformance()
        } else {
            getSinglePerformance()
        }
    }

    const getMultiplePerformance = () => {
        let tempLastUpdateData: any = [];
        let tempTwoDaysAgoDate: any = '';
        let tempTwoDaysAgoData: any = [];

        dataAssetSummary.forEach((asset: any, index: number) => {
            // console.log(asset,` asset`)
            if (asset.date_summary === dataAssetSummary[dataAssetSummary.length - 1].date_summary) {
                tempLastUpdateData.push(asset);
                if (tempTwoDaysAgoDate === '' ) {
                    if (!dataAssetSummary[index - 1]?.date_summary) {
                        tempTwoDaysAgoDate += dataAssetSummary[0].date_summary
                    } else {
                        tempTwoDaysAgoDate += dataAssetSummary[index - 1].date_summary
                    }
                }
            }
        })

        dataAssetSummary.forEach((asset: any, index: number) => {
            // console.log(asset, `asset`)
            if (asset.date_summary === tempTwoDaysAgoDate) {
                tempTwoDaysAgoData.push(asset);
            }
        })

        let yesterdayPerformance = 0;
        let yesterdayPerformanceStartingPoint = 0;

        if (tempTwoDaysAgoData.length > 0) {
            tempTwoDaysAgoData.forEach((el: any) => {
                yesterdayPerformanceStartingPoint += Number(el.amount * el.eod_usd_price)
            })
        }

        if (tempLastUpdateData.length > 0) {
            tempLastUpdateData.forEach((el: any) => {
                yesterdayPerformance += Number(el.amount * el.eod_usd_price)
            })
        }

        let yesterdayProfit = Helpers.currencyFormaterUSD(Number(yesterdayPerformance - yesterdayPerformanceStartingPoint))
        let yesterdayPerformancePercentage = `${Number((yesterdayPerformance / yesterdayPerformanceStartingPoint - 1) * 100).toFixed(2)} %`;
        setYesterdayPerformance([yesterdayProfit, yesterdayPerformancePercentage])
    }

    const getSinglePerformance = async () => {

        const yesterdayPerformance = await getDataPerformance('yesterday');
        await setYesterdayPerformance(yesterdayPerformance);

        const lastWeekPerformance = await getDataPerformance('lastweek');
        await setLastWeekPerformance(lastWeekPerformance);

        const lastMonthPerformance = await getDataPerformance('lastmonth');
        await setLastMonthPerformance(lastMonthPerformance);

        const lastYearPerformance = await getDataPerformance('lastyear');
        await setLastYearPerformance(lastYearPerformance);
    }

    const getDataPerformance = async (params: any) => {
        let tempLastUpdateData: any = []
        let tempStartingDate: any;
        if (params === 'yesterday') {
            tempStartingDate = await Helpers.dateFormat(Helpers.getLastDay());
        } else if (params === 'lastweek'){
            tempStartingDate = await Helpers.dateFormat(Helpers.getLast7Days());
        } else if (params === 'lastmonth'){
            tempStartingDate = await Helpers.dateFormat(Helpers.getLast30Days());
        }  else if (params === 'lastyear'){
            tempStartingDate = await Helpers.dateFormat(Helpers.getLast365Days());
        }

        let tempStartingData: any = [];

        await dataAssetSummary.forEach((asset: any, index: number) => {
            if (asset.date_summary === dataAssetSummary[dataAssetSummary.length - 1].date_summary) {
                tempLastUpdateData.push(asset);
            }
        })

        await dataAssetSummary.forEach((asset: any, index: number) => {
            if (asset.date_summary === tempStartingDate) {
                tempStartingData.push(asset);
            }
        })

        if (tempStartingData.length > 0) {
            let performance = 0;
            let performanceStartingPoint = 0;
    
            if (tempStartingData.length > 0) {
                tempStartingData.forEach((el: any) => {
                    performanceStartingPoint += Number(el.amount * el.eod_usd_price)
                })
            }
    
            if (tempLastUpdateData.length > 0) {
                tempLastUpdateData.forEach((el: any) => {
                    performance += Number(el.amount * el.eod_usd_price)
                })
            }
    
            let Profit = Helpers.currencyFormaterUSD(Number(performance - performanceStartingPoint))
            let performancePercentage = `${Number((performance / performanceStartingPoint - 1) * 100).toFixed(2)} %`;
            return [Profit, performancePercentage]   
        } else {
            return ['N/A', 'N/A']
        }
    }

    // const getLastMonthPerformance = async () => {
    //     let tempLastUpdateData: any = []
    //     let tempLastMonthDate: any = await Helpers.dateFormat(Helpers.getLast7Days());
    //     let tempLastMonthData: any = [];

    //     await dataAssetSummary.forEach((asset: any, index: number) => {
    //         if (asset.date_summary === dataAssetSummary[dataAssetSummary.length - 1].date_summary) {
    //             tempLastUpdateData.push(asset);
    //         }
    //     })

    //     await dataAssetSummary.forEach((asset: any, index: number) => {
    //         if (asset.date_summary === tempLastWeekDate) {
    //             tempLastWeekData.push(asset);
    //         }
    //     })

    //     let lastWeekPerformance = 0;
    //     let lastWeekPerformanceStartingPoint = 0;

    //     if (tempLastWeekData.length > 0) {
    //         tempLastWeekData.forEach((el: any) => {
    //             lastWeekPerformanceStartingPoint += Number(el.amount * el.eod_usd_price)
    //         })
    //     }

    //     if (tempLastUpdateData.length > 0) {
    //         tempLastUpdateData.forEach((el: any) => {
    //             lastWeekPerformance += Number(el.amount * el.eod_usd_price)
    //         })
    //     }

    //     let lastWeekProfit = Helpers.currencyFormaterUSD(Number(lastWeekPerformance - lastWeekPerformanceStartingPoint))
    //     let lastWeekPerformancePercentage = `${Number((lastWeekPerformance / lastWeekPerformanceStartingPoint - 1) * 100).toFixed(2)} %`;
        
    //     return [lastWeekProfit, lastWeekPerformancePercentage]

    //     return []
    // }

    return (
        <AccordionPanel pb={4}>
            <Flex direction={'row'} justifyContent={'space-between'}>
                <Box
                    fontSize={'12px'}
                    color={'rgba(23, 23, 23, 1)'}>
                    Yesterday
                </Box>
                {
                    yesterdayPerformance.length > 0 && (
                        <Box 
                            color={
                            yesterdayPerformance[1].toString()[0] !== '-' ? 'rgba(50, 153, 34, 1);' : 'rgba(224, 49, 56, 1);'} 
                            fontSize={'12px'}>
                            {`${yesterdayPerformance[0]} (${yesterdayPerformance[1]})`}
                        </Box>
                    )
                }
            </Flex>
            <Divider my={'12px'} orientation='horizontal' />
            <Flex direction={'row'} justifyContent={'space-between'}>
                <Box
                    fontSize={'12px'}
                    color={'rgba(23, 23, 23, 1)'}>
                        Last Week
                    </Box>
                    {
                        lastWeekPerformance.length > 0 && (
                            <Box 
                                color={
                                    lastWeekPerformance[1].toString()[0] !== '-' ? 'rgba(50, 153, 34, 1);' : 'rgba(224, 49, 56, 1);'} 
                                fontSize={'12px'}>
                                {`${lastWeekPerformance[0]} (${lastWeekPerformance[1]})`}
                            </Box>
                        )
                    }
            </Flex>
            <Divider my={'12px'} orientation='horizontal' />
            <Flex direction={'row'} justifyContent={'space-between'}>
                <Box
                    fontSize={'12px'}
                    color={'rgba(23, 23, 23, 1)'}>
                    Last Month
                </Box>
                {
                    lastMonthPerformance.length > 0 && (
                        <Box 
                            color={
                                lastMonthPerformance[1].toString()[0] !== '-' ? 'rgba(50, 153, 34, 1);' : 'rgba(224, 49, 56, 1);'} 
                            fontSize={'12px'}>
                            {`${lastMonthPerformance[0]} (${lastMonthPerformance[1]})`}
                        </Box>
                    )
                }
            </Flex>
            <Divider my={'12px'} orientation='horizontal' />
            <Flex direction={'row'} justifyContent={'space-between'}>
                <Box
                    fontSize={'12px'}
                    color={'rgba(23, 23, 23, 1)'}>
                    Last Year
                </Box>
                {
                    lastYearPerformance.length > 0 && (
                        <Box 
                            color={
                                lastYearPerformance[1].toString()[0] !== '-' ? 'rgba(50, 153, 34, 1);' : 'rgba(224, 49, 56, 1);'} 
                            fontSize={'12px'}>
                            {`${lastYearPerformance[0]} (${lastYearPerformance[1]})`}
                        </Box>
                    )
                }
            </Flex>
        </AccordionPanel>
    );
};

export default PerformanceDetail;