import { Tr, Tbody, Td, Button } from "@chakra-ui/react";

import { BiTrash } from "react-icons/bi";
import Helpers from "../../../hooks/helpers";

const TableWatchList = (
    {
        watchListData,
        handleDeleteRow, 
        val,
        filter
    }: any) => {
        console.log(watchListData, `watchListData`)
    return (
        <Tbody>
            {
                watchListData.length > 0 && (
                    watchListData.map((el: any, index: number) => {
                        return (
                            <Tr
                                key={index}>
                                <Td textTransform="capitalize" p="12px"> {el.name} </Td>
                                <Td p="12px" textAlign={'right'}>
                                    {
                                        el.currency === 'IDR' && (
                                            Helpers.currencyFormater(el.price, el.currency)
                                        )
                                    }

                                    {
                                        el.currency === 'USD' && (
                                            Helpers.currencyFormaterUSD(el.price)
                                        )
                                    }

                                    {   
                                        el.currency !== 'USD' && el.currency !== 'IDR' && (
                                            Helpers.currencyFormater(el.price, el.currency)
                                        )
                                    }
                                </Td>
                                <Td 
                                    color={el?.performance?.toString()[0] !== '-' ? 'rgba(50, 153, 34, 1);' : 'rgba(224, 49, 56, 1);'} 
                                    p="12px" 
                                    textAlign={'right'} 
                                    w={'32px'} 
                                    maxW={'32px'}> 
                                    {el.performance} %
                                </Td>
                                <Td p="12px" textAlign={'right'} w={'32px'} maxW={'32px'}>
                                    <Button
                                        onClick={(e) => handleDeleteRow(el)}
                                        w="32px"
                                        h="32px"
                                        p="0"
                                        textAlign={'center'}
                                        border="1px"
                                        borderColor="#E9E9E9"
                                        backgroundColor="#fff"
                                        _focus={{ outline: "none" }}
                                    >
                                        <BiTrash fontSize="14px" />
                                    </Button>
                                </Td>
                            </Tr>
                        )
                    })
                )
            }

        </Tbody>
    );
};

export default TableWatchList;