import React from 'react';
import { CircularProgress, Flex } from "@chakra-ui/react";
const LocalLoader = () => {
    return (
        <Flex>
            <CircularProgress mx='auto' isIndeterminate color='#c6b378' />
        </Flex>
    );
};

export default LocalLoader;