import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DatePicker } from "chakra-ui-date-input";
import BarChart from "../../components/Chart/BarChart";
import LinePDFChart from '../Chart/LinePDFChart';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
const token = "620e19e49de326.23079426";
import {
    Box,
    Center,
    useColorModeValue,
    Flex,
    Stack,
    Spacer,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    AspectRatio,
    Input
} from "@chakra-ui/react";
import TitleBoxDetail from "../DetailBox/TitleBoxDetail";
import { BsCardList } from "react-icons/bs";
import { BiData } from "react-icons/bi";
import ChartButtonFISingle from "../../components/Button/ChartButtonFISingle";
import Helpers from "../../hooks/helpers";
import GeneralTickers from "../FinancialInstrumentDetails/GeneralTickers";
import { AiOutlineAreaChart } from "react-icons/ai";
import IndexComponents from './IndexComponents';
import GeneralTemplate from '../../common/Report/GeneralTemplate';

function DetailTicket({
    oldestValue,
    newestValue,
    dataFetch,
    yesterdayValue,
    fromDate,
    code,
    dataChart,
    filterString,
    handleChartFilter,
    chartFilterArr,
    setToDate,
    toDate,
    setFromDate,
    instrument
}: any) {
    const params = useParams();
    const [dataDetail, setDataDetail] = useState<any>({});
    const [highlights, setHighlights] = useState<any>([]);
    const [technicals, setTechnicals] = useState<any>([]);
    const [performance, setPerformance] = useState<any>([]);
    const [technicalAnalysis, setTechnicalAnalysis] = useState<any>([]);
    const [ratingAndReturns, setRatingAndReturns] = useState<any>([]);
    const [ratioAnalysis, setRatioAnalysis] = useState<any>([]);
    const [dividend, setDividend] = useState<any>([]);
    const [salesAndEarnings, setSalesAndEarnings] = useState<any>([]);
    const [currentDate, setCurrentDate] = useState<string>("");
    const [lastPrice, setLastPrice] = useState<any>("");
    const [yesterday, setYesterday] = useState<any>("");
    const [lastWeek, setLastWeek] = useState<any>("");
    const [lastMonth, setLastMonth] = useState<any>("");
    const [last3Month, setLast3Month] = useState<any>("");
    const [lastYear, setLastYear] = useState<any>("");
    const [YTD, setYTD] = useState<any>("");
    const [weekHigh52, set52WeekHigh] = useState<any>(0);
    const [weekLow52, set52WeekLow] = useState<any>(0);
    const [avg10, setAvg10] = useState<any>(0);
    const [avg20, setAvg20] = useState<any>(0);
    const [avg50, setAvg50] = useState<any>(0);
    const [avg100, setAvg100] = useState<any>(0);
    const [avg200, setAvg200] = useState<any>(0);
    const [dividenByYear, setDividendByYear] = useState<any>([]);
    const [pdfLink, setPdfLink] = useState<string>('');
    const [customDate, setCustomDate] = useState<boolean>(false)

    useEffect(() => {
        getDetail();
    }, []);
    useEffect(() => {
        getCurrentDate();
    }, []);

    useEffect(() => {
        getMarketPerformance(dataFetch, currentDate);
        getTechnicalAnalysis(dataFetch);
    }, [dataFetch, currentDate]);

    const getCurrentDate = () => {
        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth();
        const date = new Date().getUTCDate();

        const getDate = `${year}-${month}-${date}`;
        setCurrentDate(getDate);
    };

    const getTechnicalAnalysis = (dataFetch: any) => {
        if (dataDetail.Technicals) {
            setAvg50(dataDetail.Technicals["50DayMA"]);
            setAvg200(dataDetail.Technicals["200DayMA"]);
        }

        if (dataFetch.length > 0) {
            let avg10DayArr = [];
            let avg20DayArr = [];
            let avg100DayArr = [];

            if (dataFetch.length <= 10) {
                for (let i = 0; i < 10; i++) {
                    avg10DayArr.push(dataFetch[i]);
                }
            }

            if (dataFetch.length <= 20) {
                for (let i = 0; i < 20; i++) {
                    avg20DayArr.push(dataFetch[i]);
                }
            }

            if (dataFetch.length <= 100) {
                for (let i = 0; i < 100; i++) {
                    avg100DayArr.push(dataFetch[i]);
                }
            }

            let avg10DayValue = 0;
            let avg20DayValue = 0;
            let avg100DayValue = 0;

            if (avg10DayArr.length > 0) {
                avg10DayArr.forEach((el: any) => {
                    avg10DayValue += Number(el.close);
                });
            }

            if (avg20DayArr.length > 0) {
                avg20DayArr.forEach((el: any) => {
                    avg20DayValue += Number(el.close);
                });
            }

            if (avg100DayArr.length > 0) {
                avg100DayArr.forEach((el: any) => {
                    avg100DayValue += Number(el.close);
                });
            }

            if (avg10DayValue > 0) {
                setAvg10(avg10DayValue / 10);
            }

            if (avg20DayValue > 0) {
                setAvg20(avg20DayValue / 20);
            }

            if (avg100DayValue > 0) {
                setAvg100(avg100DayValue / 100);
            }
        }
    };

    const getMarketPerformance = (dataFetch: any, date: any) => {
        if (dataDetail.Technicals) {
            set52WeekHigh(dataDetail.Technicals["52WeekHigh"].toFixed(2));
            set52WeekLow(dataDetail.Technicals["52WeekLow"].toFixed(2));
        }
        const year = new Date().getUTCFullYear();
        if (dataFetch.length > 0) {
            setLastPrice(`${dataFetch[0].close.toFixed(2)}`);
            setYesterday(
                `${Helpers.percentageFormater(dataFetch[0].close, dataFetch[1].close)}%`
            );
        }
        const lastWeek = getLastWeek(date, year);

        const filterDataLastWeek = dataFetch.filter((el: any) => {
            return el.date >= lastWeek;
        });

        if (filterDataLastWeek.length > 0) {
            const valueLastWeek = Math.abs(
                filterDataLastWeek[0].close -
                filterDataLastWeek[filterDataLastWeek.length - 1].close
            ).toFixed(2);
            const percentageLastWeek = `${Helpers.percentageFormater(
                filterDataLastWeek[0].close,
                filterDataLastWeek[filterDataLastWeek.length - 1].close
            )}%`;
            setLastWeek(percentageLastWeek);
        }

        const lastMonth = getLastMonth(date, year);

        const filterDataLastMonth = dataFetch.filter((el: any) => {
            return el.date >= lastMonth;
        });

        if (filterDataLastMonth.length > 0) {
            const valueLastMonth = Math.abs(
                filterDataLastMonth[0].close -
                filterDataLastMonth[filterDataLastMonth.length - 1].close
            ).toFixed(2);
            const percentageLastMonth = `${Helpers.percentageFormater(
                filterDataLastMonth[0].close,
                filterDataLastMonth[filterDataLastMonth.length - 1].close
            )}%`;
            setLastMonth(percentageLastMonth);
        }

        const last3Month = getLast3Month(date, year);

        const filterDataLast3Month = dataFetch.filter((el: any) => {
            return el.date >= last3Month;
        });

        if (filterDataLast3Month.length > 0) {
            const valueLast3Month = Math.abs(
                filterDataLast3Month[0].close -
                filterDataLast3Month[filterDataLast3Month.length - 1].close
            ).toFixed(2);
            const percentageLast3Month = `${Helpers.percentageFormater(
                filterDataLast3Month[0].close,
                filterDataLast3Month[filterDataLast3Month.length - 1].close
            )}%`;
            setLast3Month(percentageLast3Month);
        }

        const lastYearDate = getLastYearDate(date, year);

        const filterDataLastYearDate = dataFetch.filter((el: any) => {
            return el.date >= lastYearDate;
        });

        const d = new Date();
        d.setFullYear(d.getFullYear() - 1);

        if (filterDataLastYearDate.length > 0) {
            const valueLastYearDate = Math.abs(
                filterDataLastYearDate[0].close -
                filterDataLastYearDate[filterDataLastYearDate.length - 1].close
            ).toFixed(2);
            const percentageLastYearDate = `${Helpers.percentageFormater(
                filterDataLastYearDate[0].close,
                filterDataLastYearDate[filterDataLastYearDate.length - 1].close
            )}%`;
            setLastYear(percentageLastYearDate);
        }

        const yearToDate = getYearToDate(year);

        const filterDataYearToDate = dataFetch.filter((el: any) => {
            return el.date >= yearToDate;
        });

        if (filterDataLastYearDate.length > 0) {
            const valueYearToDate = Math.abs(
                filterDataYearToDate[0].close -
                filterDataYearToDate[filterDataYearToDate.length - 1].close
            ).toFixed(2);
            const percentageYearToDate = `${Helpers.percentageFormater(
                filterDataYearToDate[0].close,
                filterDataYearToDate[filterDataYearToDate.length - 1].close
            )}%`;
            setYTD(percentageYearToDate);
        }
    };

    const getLastWeek = (date: any, year: any) => {
        const now = new Date();
        const newDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 7
        );
        if (newDate.getMonth() < 10) {
            return `${newDate.getFullYear()}-0${newDate.getMonth() + 1
                }-${newDate.getDate()}`;
        } else {
            return `${newDate.getFullYear()}-${newDate.getMonth() + 1
                }-${newDate.getDate()}`;
        }
    };

    const getLastMonth = (date: any, year: any) => {
        let lastMonth = JSON.parse(JSON.stringify(date.split("-")));
        if (new Date().getMonth() === 0) {
            lastMonth[1] = "12";
            lastMonth[0] = year - 1;
        } else {
            lastMonth[1] = new Date().getMonth().toString();
        }
        if (lastMonth[1] < 10) {
            lastMonth[1] = `0${lastMonth[1]}`;
        }
        lastMonth = lastMonth.join("-");
        return lastMonth;
    };

    const getLast3Month = (date: any, year: any) => {
        let newDate = JSON.parse(JSON.stringify(date.split("-")));
        if (new Date().getMonth() - 2 === 0) {
            newDate[1] = "12";
            newDate[0] = year - 1;
        } else if (new Date().getMonth() - 2 < 0) {
            newDate[1] = (new Date().getMonth() - 2 + 12).toString();
            newDate[0] = year - 1;
        } else {
            newDate[1] = (new Date().getMonth() - 2).toString();
        }
        if (newDate[1] < 10) {
            newDate[1] = `0${newDate[1]}`;
        }
        newDate = newDate.join("-");

        return newDate;
    };

    const getLastYearDate = (date: any, year: any) => {
        let newDate = JSON.parse(JSON.stringify(date.split("-")));
        if (new Date().getMonth() - 11 === 0) {
            newDate[1] = "12";
            newDate[0] = year - 1;
        } else if (new Date().getMonth() - 11 < 0) {
            newDate[1] = (new Date().getMonth() - 11 + 12).toString();
            newDate[0] = year - 1;
        } else {
            newDate[1] = (new Date().getMonth() - 11).toString();
        }
        if (newDate[1] < 10) {
            newDate[1] = `0${newDate[1]}`;
        }
        newDate = newDate.join("-");
        return newDate;
    };

    const getYearToDate = (year: any) => {
        let newDate = `${year}-01-01`;

        return newDate;
    };

    const getDatesBetween = (
        startDate: Date,
        endDate: Date,
        includeEndDate?: boolean
    ) => {
        const dates = [];
        while (startDate < endDate) {
            dates.push(new Date(startDate));
            startDate.setDate(startDate.getDate() + 1);
        }
        if (includeEndDate) dates.push(endDate);
        return dates;
    };

    useEffect(() => {
        if (dataDetail.Highlights) {
            setHighlights(Object.entries(dataDetail.Highlights));
        }

        if (dataDetail.Technicals) {
            setTechnicals(Object.entries(dataDetail.Technicals));
        }

        if (dataFetch) {
            setPerformance([
                ["Last Price", lastPrice],
                ["1D (%)", yesterday],
                ["1W (%)", lastWeek],
                ["1M (%)", lastMonth],
                ["3M (%)", last3Month],
                ["1Y (%)", lastYear],
                ["YTD (%)", YTD],
                ["52W High", weekHigh52],
                ["52W Low", weekLow52],
            ]);
        }

        setTechnicalAnalysis([
            ["14D RSI", 0],
            ["MA 10D", avg10],
            ["MA 20D", avg20],
            ["MA 50D", avg50],
            ["MA 100D", avg100],
            ["MA 200D", avg200],
        ]);

        if (dataDetail.AnalystRatings === undefined || !dataDetail.AnalystRatings) {
            setRatingAndReturns([
                ["JB Rating", 0],
                ["Best Target Price", "N/A"],
                ["Potential Return(%)", 0],
            ]);
        } else {
            setRatingAndReturns([
                ["JB Rating", 0],
                ["Best Target Price", dataDetail.AnalystRatings["TargetPrice"] ? dataDetail.AnalystRatings["TargetPrice"] : "N/A"],
                ["Potential Return(%)", 0],
            ]);
        }

        if (dataDetail.Highlights) {
            setRatioAnalysis([
                ["BBG Est PE", dataDetail.Highlights["PERatio"] === null || !dataDetail.Highlights["PERatio"] ? "N/A" : dataDetail.Highlights["PERatio"]],
                ["BBG Est PB", dataDetail.Valuation["PriceBookMRQ"] === null ? "N/A" : dataDetail.Valuation["PriceBookMRQ"]],
                ["ROE(%)", dataDetail.Highlights["ReturnOnEquityTTM"] === null ? "N/A" : dataDetail.Highlights["ReturnOnEquityTTM"]],
                ["Debt/Ta(%)", 0],
            ]);
            setDividend([
                ["BBG Est DVD YIELD (%)", dataDetail.Highlights["DividendYield"] === null ? "N/A" : dataDetail.Highlights["DividendYield"]],
                ["DVD Freq", 0],
                ["Next DVD ex date", 0],
            ]);
        }

        setSalesAndEarnings([
            ["Sales Growth (%)", "0%"],
            ["Est. EPS Growth (%)", "0%"],
            ["Next Earnings Date", 0],
        ]);
    }, [dataDetail, weekLow52, avg10]);

    const getDetail = async () => {
        try {
            if (instrument === 'index') {
                const response = await fetch(
                    `https://eodhistoricaldata.com/api/fundamentals/${params.market}.INDX?api_token=${token}&fmt=json`
                );
                if (response) {
                    let data = await response.json();
                    setDataDetail(data);
                }
            } else if (instrument === 'crypto'){
                const response = await fetch(
                    `https://eodhistoricaldata.com/api/fundamentals/${params.detail}.CC?api_token=${token}&fmt=json`
                );
                if (response) {
                    let data = await response.json();
                    setDataDetail(data);
                }
            } else {
                const response = await fetch(
                    `https://eodhistoricaldata.com/api/fundamentals/${params.detail}.${params.market}?api_token=${token}&fmt=json`
                );
                if (response) {
                    let data = await response.json();
                    setDataDetail(data);
                }
            }
        } catch (error) {
            console.log(error, `ini errrrrrooorrr`);
        }
    };

    useEffect(() => {
        if (dataDetail?.SplitsDividends?.NumberDividendsByYear) {
            let entries = Object.entries(dataDetail?.SplitsDividends?.NumberDividendsByYear);
            setDividendByYear(entries);
        }
    }, [dataDetail?.SplitsDividends?.NumberDividendsByYear]);

    const handlePDF = async (detail: any) => {
        onOpen();
    }

    const { isOpen, onOpen, onClose } = useDisclosure();

    const generatePDF = async () => {

        let doc = new jsPDF({
            putOnlyUsedFonts: true,
            orientation: 'l',
        });

        let canvas = document.getElementById('report') as HTMLCanvasElement;
        window.scrollTo(0,0);
        await html2canvas(canvas, {
            allowTaint: true,
            useCORS: true,
            width: 2000,
            scale: 3,
        }).then((after) => {
            doc.addImage(
                after.toDataURL("image/png"),
                "PNG",
                10,
                15,
                480,
                210,
                "",
                "MEDIUM"
            );
        });
        
        // doc.save("myChart");
        doc.addPage('a4', 'l');
        let canvas2 = document.getElementById('data-report') as HTMLCanvasElement;
        
        await html2canvas(canvas2, {
            allowTaint: true,
            useCORS: true,
            width: 2000,
            scale: 3,
        }).then((after) => {
            doc.addImage(
                after.toDataURL("image/png"),
                "PNG",
                10,
                15,
                450,
                150,
                "",
                "MEDIUM"
            );
        });
        let string = doc.output("datauristring");
        setPdfLink(string);
    }

    const handleModalClose = () => {
        setPdfLink('');
        onClose();
    }

    return (
        <div>
            <Modal onClose={handleModalClose} size={'full'} isOpen={isOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Generate PDF</ModalHeader>
                    <ModalCloseButton _focus={{ outline: 'none'}} />
                    <ModalBody>
                        {   pdfLink !== '' ? (
                            <Box mt={'24px'}>
                                <AspectRatio ratio={1}>
                                    <iframe
                                        src={pdfLink}
                                        allowFullScreen
                                    />
                                </AspectRatio>
                            </Box>
                        ) : (
                            <Box
                                bg={useColorModeValue("white", "gray.800")}
                                boxShadow={"lg"}
                                rounded={"md"}
                                px="24px"
                                mr={'24px'}
                                h={'fit-content'}
                            >
                                <TitleBoxDetail
                                    fsIcon={"16"}
                                    iconTitle={BsCardList}
                                    boxTitle={`Preview`}
                                /> 

                                <Box maxW={'90%'} id={'report'}>
                                    <GeneralTemplate 
                                        page={'1'}
                                        type={instrument}
                                        data={dataDetail}
                                        instrument={'financial-instrument'}>
                                            <Box>
                                                <LinePDFChart filterString={filterString} dataChart={dataChart} code={code} />
                                            </Box>
                                    </GeneralTemplate>
                                </Box>

                                <Box id={'data-report'}>
                                    <GeneralTemplate 
                                        page={'2'}
                                        highlight={
                                            {
                                                performance : performance.length > 0 ? performance : '',
                                                technicalAnalysis : technicalAnalysis.length > 0 ? technicalAnalysis : '',
                                                ratingAndReturns: ratingAndReturns.length > 0 ? ratingAndReturns : '',
                                                ratioAnalysis: ratioAnalysis.length > 0 ? ratioAnalysis : '',
                                                salesAndEarnings: salesAndEarnings.length > 0 ? salesAndEarnings : '',
                                                dividend: salesAndEarnings.length > 0 ? salesAndEarnings : '',

                                            }
                                        }
                                        type={instrument}
                                        data={dataDetail}
                                        instrument={'financial-instrument'}>
                                    </GeneralTemplate>
                                </Box>
                            </Box>
                        )
                            
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                            fontSize={'14px'}
                            mr={'20px'}
                            _focus={{ outline: 'none'}}
                            onClick={handleModalClose}>Close</Button>
                        {
                            pdfLink !== '' ? (
                                <></>
                            ) : (
                                <Button
                                    _hover={{
                                        color: 'rgba(151, 133, 77, 1)',
                                        backgroundColor: '#fff',
                                        borderColor: 'rgba(151, 133, 77, 1)'
                                    }}
                                    py={'8px'}
                                    px={'12px'} 
                                    borderColor={'#fff'}
                                    color={'#fff'}
                                    backgroundColor={'rgba(151, 133, 77, 1)'}
                                    variant={'outline'}
                                    fontSize={'14px'}
                                    onClick={generatePDF}
                                    >Generate</Button>
                            )
                        }
                        
                    </ModalFooter>
                </ModalContent>
            </Modal>
            

            <Flex justifyContent={'flex-end'} flexWrap={'wrap'} py={6}>
                {
                    dataDetail.General && (
                        <Box
                            w={['280px', 'full']}
                            maxW={'280px'}
                            bg={useColorModeValue("white", "gray.800")}
                            boxShadow={"lg"}
                            rounded={"md"}
                            px="24px"
                            mr={'24px'}
                            h={'fit-content'}
                        >

                            <TitleBoxDetail
                                fsIcon={"16"}
                                iconTitle={BsCardList}
                                boxTitle={`General Detail`}
                            /> 

                            <GeneralTickers  
                                yesterdayValue={yesterdayValue}
                                oldestValue={oldestValue}
                                newestValue={newestValue}
                                dataDetail={dataDetail} />
                        </Box>
                    )
                }

                {
                    dataChart && dataChart.length > 0 && (
                        <Box 
                            w={dataDetail.General ? ['calc(100% - 310px)', 'full'] : ['full','full']}
                            maxW={dataDetail.General ? 'calc(100% - 310px)' : 'full'}
                        >
                            <Box
                                bg={useColorModeValue("white", "gray.800")}
                                boxShadow={"lg"}
                                rounded={"md"}
                                px="24px"
                                h={'fit-content'}
                                mb={'24px'}
                            >
                                <TitleBoxDetail
                                    fsIcon={"20px"}
                                    iconTitle={AiOutlineAreaChart}
                                    boxTitle={`Chart`}
                                />
                                <Flex>
                                    <Box mr={0} ml={'auto'}>
                                        <Button
                                            _hover={{
                                                color: '#fff',
                                                backgroundColor: 'rgba(151, 133, 77, 1)'
                                            }}
                                            _focus={{outline: 'none'}}
                                            py={'8px'}
                                            px={'12px'} 
                                            borderColor={'rgba(151, 133, 77, 1)'}
                                            color={'rgba(151, 133, 77, 1)'}
                                            variant={'outline'}
                                            fontSize={'12px'}
                                            onClick={() => handlePDF(dataChart)}>
                                            Download
                                        </Button>
                                    </Box>
                                </Flex>
                                <Box w={{ base: "full" }}>
                                    <Center py="20px">
                                        <Stack className={'datepicker-wrapper'} spacing={3} mr="12px" direction="row">
                                        <Input
                                            color={'rgba(116, 116, 116, 1)'}
                                            h={'32px'}
                                            fontSize="14px"
                                            value={fromDate}
                                            onChange={(e) => {
                                                setCustomDate(true);
                                                setFromDate(e.target.value)
                                            }}
                                            cursor={'pointer'}
                                            _focus={{outline: 'none'}}
                                            px={'12px'}
                                            type={'date'}/>
                                        <Box h={'32px'} fontWeight={600} lineHeight={'32px'}>
                                            {'-'}
                                        </Box>
                                        <Input
                                            color={'rgba(116, 116, 116, 1)'}
                                            cursor={'pointer'}
                                            _focus={{outline: 'none'}}
                                            h={'32px'}
                                            fontSize="14px"
                                            value={toDate}
                                            onChange={(e) => {
                                                setCustomDate(true);
                                                setToDate(e.target.value);
                                            }}
                                            px={'12px'}
                                            type={'date'}/>
                                        </Stack>

                                        <ChartButtonFISingle
                                            setCustomDate={setCustomDate}
                                            customDate={customDate}
                                            chartFilter={chartFilterArr}
                                            handleChartFilter={handleChartFilter} />
                                    </Center>
                                    <Flex mr={'5px'}>
                                        <Box
                                            ml="auto"
                                            mr="0"
                                            fontSize={{ base: "14px", lg: "16px" }}
                                            color={
                                                newestValue - oldestValue >= 0
                                                ? useColorModeValue("green.500", "green.300")
                                                : useColorModeValue("red.500", "red.300")
                                            }
                                            fontWeight={"1000"}
                                        >
                                        {`${Math.abs(newestValue - oldestValue).toFixed(
                                            2
                                        )} (${Helpers.percentageFormater(newestValue, oldestValue)}%)`}
                                        </Box>
                                    </Flex>
                                </Box>

                                <BarChart filterString={filterString} dataChart={dataChart} code={code} />
                            </Box>

                            {
                                instrument !== 'index' && instrument !== 'crypto' && (
                                    <Box
                                        w={['full']}
                                        bg={useColorModeValue("white", "gray.800")}
                                        boxShadow={"lg"}
                                        rounded={"md"}
                                        px="24px"
                                        mb={'24px'}
                                        >
                                        {dataDetail.General && (
                                            <Box>
                                                <TitleBoxDetail
                                                    fsIcon={"16"}
                                                    iconTitle={BiData}
                                                    boxTitle={`Data Highlight`}
                                                />

                                                <Flex gap={1} justifyContent={'space-between'} flexWrap={'wrap'} mt={'24px'} width={'full'}>
                                                    {
                                                        performance.length > 0 && (
                                                            <Box mb={'32px'} w={'48%'}>
                                                                <Box
                                                                    fontWeight={600}
                                                                    fontSize={'14px'}
                                                                    color={'rgba(23, 23, 23, 1)'}>
                                                                    Performance
                                                                </Box>
                                                                <Box  w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                                {performance.map((el: any, indexPerformance: number) => {
                                                                    return (
                                                                        <Flex key={indexPerformance} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[0]}</Box>
                                                                            <Box>{el[1] ? el[1] : "N/A"}</Box>
                                                                        </Flex>
                                                                    )
                                                                })}
                                                            </Box>
                                                            
                                                        )
                                                    }
                                                    <Spacer />
                                                    {
                                                        technicalAnalysis.length > 0 && (
                                                            <Box mb={'32px'} w={'48%'}>
                                                                <Box
                                                                    fontWeight={600}
                                                                    fontSize={'14px'}
                                                                    color={'rgba(23, 23, 23, 1)'}>
                                                                    Technical Analysis
                                                                </Box>
                                                                <Box  w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                                {technicalAnalysis.map((el: any, indexTechnical: number) => {
                                                                    return (
                                                                        <Flex key={indexTechnical} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[0]}</Box>
                                                                            <Box>{el[1] ? el[1] : "N/A"}</Box>
                                                                        </Flex>
                                                                    )
                                                                })}

                                                                {
                                                                    dataDetail?.Technicals?.Beta && dataDetail?.Technicals?.Beta !== '0' ? (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Beta</Box>
                                                                            <Box>{dataDetail?.Technicals?.Beta}</Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }

                                                                {
                                                                    dataDetail?.Technicals?.SharesShort && dataDetail?.Technicals?.SharesShort !== 0 ? (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Shares Short</Box>
                                                                            <Box>{dataDetail?.Technicals?.SharesShort}</Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                                {
                                                                    dataDetail?.Technicals?.SharesShortPriorMonth && dataDetail?.Technicals?.SharesShortPriorMonth !== 0 ? (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Shares Short Prior Month</Box>
                                                                            <Box>{dataDetail?.Technicals?.SharesShortPriorMonth}</Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }

                                                                {
                                                                    dataDetail?.Technicals?.ShortPercent && dataDetail?.Technicals?.ShortPercent !== '0' ? (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Short Percent</Box>
                                                                            <Box>{dataDetail?.Technicals?.ShortPercent}</Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }

                                                                {
                                                                    dataDetail?.Technicals?.ShortRatio && dataDetail?.Technicals?.ShortRatio !== '0' ? (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Short Ratio</Box>
                                                                            <Box>{dataDetail?.Technicals?.ShortRatio}</Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </Box>
                                                            
                                                        )
                                                    }
                                                    <Spacer />
                                                    {
                                                        ratingAndReturns.length > 0 && (
                                                            <Box mb={'32px'} w={'48%'}>
                                                                <Box
                                                                    fontWeight={600}
                                                                    fontSize={'14px'}
                                                                    color={'rgba(23, 23, 23, 1)'}>
                                                                    {`Rating & Returns`}
                                                                </Box>
                                                                <Box  w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                                {ratingAndReturns.map((el: any, indexRating: number) => {
                                                                    return (
                                                                        <Flex key={indexRating} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[0]}</Box>
                                                                            <Box>{el[1] ? el[1] : "N/A"}</Box>
                                                                        </Flex>
                                                                    )
                                                                })}
                                                                {
                                                                    dataDetail?.AnalystRatings?.Buy && dataDetail?.Technicals?.Buy !== '0' ? (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Buy</Box>
                                                                            <Box>{dataDetail?.AnalystRatings?.Buy}</Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }

                                                                {
                                                                    dataDetail?.AnalystRatings?.Hold && dataDetail?.Technicals?.Hold !== '0' ? (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Hold</Box>
                                                                            <Box>{dataDetail?.AnalystRatings?.Hold}</Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }

                                                                {
                                                                    dataDetail?.AnalystRatings?.Rating && dataDetail?.Technicals?.Rating !== '0' ? (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Rating</Box>
                                                                            <Box>{dataDetail?.AnalystRatings?.Rating}</Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }

                                                                {
                                                                    dataDetail?.AnalystRatings?.Sell && dataDetail?.Technicals?.Sell !== '0' ? (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Sell</Box>
                                                                            <Box>{dataDetail?.AnalystRatings?.Sell}</Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }

                                                                {
                                                                    dataDetail?.AnalystRatings?.StrongBuy && dataDetail?.Technicals?.StrongBuy !== '0' ? (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Strong Buy</Box>
                                                                            <Box>{dataDetail?.AnalystRatings?.StrongBuy}</Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }

                                                                {
                                                                    dataDetail?.AnalystRatings?.StrongSell && dataDetail?.Technicals?.StrongSell !== '0' ? (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Strong Sell</Box>
                                                                            <Box>{dataDetail?.AnalystRatings?.StrongSell}</Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </Box>
                                                            
                                                        )
                                                    }
                                                    <Spacer />
                                                    {
                                                        ratioAnalysis.length > 0 && (
                                                            <Box mb={'32px'} w={'48%'}>
                                                                <Box
                                                                    fontWeight={600}
                                                                    fontSize={'14px'}
                                                                    color={'rgba(23, 23, 23, 1)'}>
                                                                    {'Ratio & Analysis'}
                                                                </Box>
                                                                <Box  w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                                {ratioAnalysis.map((el: any, indexRatio: number) => {
                                                                    return (
                                                                        <Flex key={indexRatio} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[0]}</Box>
                                                                            <Box>{el[1] ? el[1] : "N/A"}</Box>
                                                                        </Flex>
                                                                    )
                                                                })}
                                                            </Box>
                                                            
                                                        )
                                                    }
                                                    <Spacer />
                                                    {
                                                        dividend.length > 0 && (
                                                            <Box mb={'32px'} w={'48%'}>
                                                                <Box
                                                                    fontWeight={600}
                                                                    fontSize={'14px'}
                                                                    color={'rgba(23, 23, 23, 1)'}>
                                                                    Dividend
                                                                </Box>
                                                                <Box  w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                                {dividend.map((el: any, indexDividend: number) => {
                                                                    return (
                                                                        <Flex key={indexDividend} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[0]}</Box>
                                                                            <Box>{el[1] ? el[1] : "N/A"}</Box>
                                                                        </Flex>
                                                                    )
                                                                })}

                                                                {
                                                                    dataDetail?.SplitsDividends?.DividendDate && dataDetail?.SplitsDividends?.DividendDate !== '0000-00-00' && (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Dividend Date</Box>
                                                                            <Box>{dataDetail?.SplitsDividends?.DividendDate}</Box>
                                                                        </Flex>
                                                                    )
                                                                }

                                                                {
                                                                    dataDetail?.SplitsDividends?.ExDividendDate && dataDetail?.SplitsDividends?.ExDividendDate !== '0000-00-00' && (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Ex Dividend Date</Box>
                                                                            <Box>{dataDetail?.SplitsDividends?.ExDividendDate}</Box>
                                                                        </Flex>
                                                                    )
                                                                }

                                                                {
                                                                    dataDetail?.SplitsDividends?.ForwardAnnualDividendRate && dataDetail?.SplitsDividends?.ForwardAnnualDividendRate !== '0' ? (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Forward Annual Dividend Rate</Box>
                                                                            <Box>{dataDetail?.SplitsDividends?.ForwardAnnualDividendRate}</Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }

                                                                {   
                                                                    dataDetail?.SplitsDividends?.ForwardAnnualDividendYield && dataDetail?.SplitsDividends?.ForwardAnnualDividendYield !== '0' ? (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Forward Annual Dividend Yield</Box>
                                                                            <Box>{dataDetail?.SplitsDividends?.ForwardAnnualDividendYield}</Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }

                                                                {   
                                                                    dataDetail?.SplitsDividends?.LastSplitDate && dataDetail?.SplitsDividends?.LastSplitDate !== '0000-00-00' && (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Last Split Date</Box>
                                                                            <Box>{dataDetail?.SplitsDividends?.LastSplitDate}</Box>
                                                                        </Flex>
                                                                    )
                                                                }

                                                                {   
                                                                    dataDetail?.SplitsDividends?.LastSplitFactor && dataDetail?.SplitsDividends?.LastSplitFactor !== '' && (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Last Split Factor</Box>
                                                                            <Box>{dataDetail?.SplitsDividends?.LastSplitFactor}</Box>
                                                                        </Flex>
                                                                    )
                                                                }

                                                                {   
                                                                    dataDetail?.SplitsDividends?.PayoutRatio && (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Payout Ratio</Box>
                                                                            <Box>{dataDetail?.SplitsDividends?.PayoutRatio}</Box>
                                                                        </Flex>
                                                                    )
                                                                }

                                                                {   
                                                                    dataDetail?.SplitsDividends?.NumberDividendsByYear && (
                                                                        <Box>
                                                                            <Accordion defaultIndex={[0]} allowMultiple>
                                                                                <AccordionItem>
                                                                        
                                                                                    <AccordionButton 
                                                                                        _focus={{outline: 'none'}}
                                                                                        px={0} my={2} fontSize={"12px"} justifyContent={'space-between'}>
                                                                                        <Box>
                                                                                            Number Dividends By Year
                                                                                        </Box>
                                                                                        <AccordionIcon />
                                                                                    </AccordionButton>
                                                                             
                                                                                    <AccordionPanel pb={4}>
                                                                                    {
                                                                                            dividenByYear.length > 0 && dividenByYear.map((el: any, index: number) => {
                                                                                                return (
                                                                                                    <Flex key={index} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                                                        <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[1].Year}</Box>
                                                                                                        <Box>{el[1].Count}</Box>
                                                                                                    </Flex>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </AccordionPanel>
                                                                                </AccordionItem>
                                                                                </Accordion>
                                                                        </Box>
                                                                    )
                                                                }
                                                            </Box>
                                                            
                                                        )
                                                    }
                                                    <Spacer />
                                                    {
                                                        salesAndEarnings.length > 0 && (
                                                            <Box mb={'32px'} w={'48%'}>
                                                                <Box
                                                                    fontWeight={600}
                                                                    fontSize={'14px'}
                                                                    color={'rgba(23, 23, 23, 1)'}>
                                                                    {'Sales & Earning'}
                                                                </Box>
                                                                <Box  w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                                {salesAndEarnings.map((el: any, indexSales: number) => {
                                                                    return (
                                                                        <Flex key={indexSales} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>{el[0]}</Box>
                                                                            <Box>{el[1] ? el[1] : "N/A"}</Box>
                                                                        </Flex>
                                                                    )
                                                                })}
                                                            </Box>
                                                            
                                                        )
                                                    }
                                                    <Spacer />

                                                    {
                                                        dataDetail?.ESGScores && (
                                                            <Box mb={'32px'} w={'48%'}>
                                                                <Box
                                                                    fontWeight={600}
                                                                    fontSize={'14px'}
                                                                    color={'rgba(23, 23, 23, 1)'}>
                                                                    Risk Score
                                                                </Box>
                                                                <Box  w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />
                                                                {   
                                                                    dataDetail?.ESGScores?.EnvironmentScore && dataDetail?.ESGScores?.EnvironmentScore !== '0' && (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Environment Score</Box>
                                                                            <Box>{dataDetail?.ESGScores?.EnvironmentScore}</Box>
                                                                        </Flex>
                                                                    )
                                                                }

                                                                {   
                                                                    dataDetail?.ESGScores?.GovernanceScore && dataDetail?.ESGScores?.GovernanceScore !== '0' && (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Governance Score</Box>
                                                                            <Box>{dataDetail?.ESGScores?.GovernanceScore}</Box>
                                                                        </Flex>
                                                                    )
                                                                }

                                                                {   
                                                                    dataDetail?.ESGScores?.SocialScore && dataDetail?.ESGScores?.SocialScore !== '0' && (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Social Score</Box>
                                                                            <Box>{dataDetail?.ESGScores?.SocialScore}</Box>
                                                                        </Flex>
                                                                    )
                                                                }

                                                                {   
                                                                    dataDetail?.ESGScores?.TotalEsg && dataDetail?.ESGScores?.TotalEsg !== '0' && (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Total Esg</Box>
                                                                            <Box>{dataDetail?.ESGScores?.TotalEsg}</Box>
                                                                        </Flex>
                                                                    )
                                                                }

                                                                {   
                                                                    dataDetail?.ESGScores?.RatingDate && dataDetail?.ESGScores?.RatingDate !== '0' && (
                                                                        <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                                                            <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Rating Date</Box>
                                                                            <Box>{dataDetail?.ESGScores?.RatingDate}</Box>
                                                                        </Flex>
                                                                    )
                                                                }
                                                            </Box>
                                                            
                                                        )
                                                    }
                                                </Flex>
                                            </Box>
                                        )}
                                    </Box>
                                )
                            }

                            {
                                instrument === 'index' && (
                                    <Box
                                        w={['full']}
                                        bg={useColorModeValue("white", "gray.800")}
                                        boxShadow={"lg"}
                                        rounded={"md"}
                                        px="24px"
                                        >
                                        {dataDetail.Components && dataDetail.Components['0'] && (
                                            <IndexComponents dataDetail={dataDetail} />
                                        )}
                                    </Box>
                                )
                            }
                        </Box>
                    )
                }
            </Flex>
        </div>
    );
}

export default DetailTicket;
