import { QoreClient } from "@feedloop/qore-client";
import createQoreContext from "@feedloop/qore-react";
import Cookies from "js-cookie";
// https://staging-qore-data-city-731808.qore.dev
const client = new QoreClient({
  endpoint:
    process.env.QORE_ENDPOINT ||
    "https://staging-qore-data-city-731808.qore.dev",
  // "http://localhost:8080",
  getToken: () => {
    return Cookies.get("token");
  },
  onError: (err) => {
    console.error(err);
  },
});

const qoreContext = createQoreContext(client);
export default qoreContext;
