import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./app/home/HomePage";
import AboutPage from "./app/common/AboutPage";
import qoreContext from "./app/common/qoreContext";
import LoginPage from "./app/auth/LoginPage";
import ForgetPassword from "./app/auth/ForgetPassword"
import RegisterPage from "./app/auth/RegisterPage";
import { ErrorBoundary } from "react-error-boundary";
import AuthProvider from "./app/auth/AuthProvider";

export default function App() {
  return (
    <main className="container">
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => {
          return (
            <div>
              <p>There was an error: {error.message}</p>
              <button onClick={resetErrorBoundary}>Try again</button>
            </div>
          );
        }}
      >
        <qoreContext.context.Provider value={{ client: qoreContext.client }}>
          <BrowserRouter>
            <Routes>
              <Route
                path="*"
                element={
                  <AuthProvider>
                    <HomePage />
                  </AuthProvider>
                }
              />
              <Route path="/about" element={<AboutPage />} />

              <Route path="/login" element={<LoginPage />} />
              <Route path="/forget-password" element={<ForgetPassword />} />

              <Route path="/register" element={<RegisterPage />} />
            </Routes>
          </BrowserRouter>
        </qoreContext.context.Provider>
      </ErrorBoundary>
    </main>
  );
}
