import { useLocation, useParams } from "react-router-dom";
import ExchangeMarket from "../../components/ExchangeMarket";

function FinancialInstrumentExchangeMarket() {
  const params = useParams();
  const location = useLocation();
  const instrument = location.pathname.split("/")[2];
  return (
    <div>
      <ExchangeMarket params={params} instrument={instrument} />
    </div>
  );
}

export default FinancialInstrumentExchangeMarket;
