import { useEffect, useState } from "react";
import { Box, Flex, Stack } from "@chakra-ui/react";
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Helpers from "../../hooks/helpers";
import SingleAssetChartDetail from "../../components/DetailBox/SingleAssetChartDetail";

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

const SingleDoughnutChart = ({ allocationData, handleSingle, chartTitle }: any) => {
  const [typeLabel, setTypeLabel] = useState<any[]>([]);
  const [typeDataData, setTypeDataData] = useState<any[]>([]);
  const [typeTotalValue, setTypeTotalValue] = useState<number>(0);

  console.log(allocationData, `datanee`)

  const backgroundColor = [
    "rgba(0, 44, 112, 1)",
    "rgba(0, 63, 160, 1)",
    "rgba(0, 82, 207, 1)",
    "rgba(0, 101, 255, 1)",
    "rgba(77, 147, 255, 1)",
    "rgba(153, 193, 255, 1)",
    "rgba(230, 240, 255, 1)",
    "rgba(99, 22, 25, 1)",
    "rgba(140, 31, 35, 1)",
    "rgba(182, 40, 46, 1)",
    "rgba(224, 49, 56, 1)",
    "rgba(233, 111, 116, 1)",
    "rgba(243, 173, 175, 1)",
    "rgba(252, 234, 235, 1)",
    "rgba(105, 50, 2, 1)",
    "rgba(149, 71, 3, 1)",
    "rgba(194, 92, 4, 1)",
    "rgba(238, 113, 5, 1)",
    "rgba(243, 156, 80, 1)",
    "rgba(248, 198, 155, 1)",
    "rgba(253, 241, 230, 1)",
    "rgba(108, 85, 0, 1)",
    "rgba(154, 120, 0, 1)",
    "rgba(199, 156, 0, 1)",
    "rgba(245, 192, 0, 1)",
    "rgba(248, 211, 77, 1)",
    "rgba(251, 230, 153, 1)",
    "rgba(254, 249, 230, 1)",
    "rgba(22, 67, 15, 1)",
    "rgba(31, 96, 21, 1)",
    "rgba(41, 124, 28, 1)",
    "rgba(50, 153, 34, 1)",
    "rgba(112, 184, 100, 1)",
    "rgba(173, 214, 167, 1)",
    "rgba(235, 245, 233, 1)",
    "rgba(58, 39, 98, 1)",
    "rgba(82, 56, 140, 1)",
    "rgba(107, 72, 181, 1)",
    "rgba(131, 89, 223, 1)",
    "rgba(168, 139, 233, 1)",
    "rgba(205, 189, 242, 1)",
    "rgba(243, 238, 252, 1)",
    "rgba(72, 15, 106, 1)",
    "rgba(103, 21, 150, 1)",
    "rgba(133, 27, 195, 1)",
    "rgba(164, 33, 240, 1)",
    "rgba(191, 100, 245, 1)",
    "rgba(219, 166, 249, 1)",
    "rgba(246, 233, 254, 1)",
    "rgba(94, 13, 79, 1)",
    "rgba(134, 19, 112, 1)",
    "rgba(173, 24, 146, 1)",
    "rgba(213, 30, 179, 1)",
    "rgba(226, 98, 202, 1)",
    "rgba(238, 165, 225, 1)",
    "rgba(251, 233, 247, 1)",
    "rgba(23, 23, 23, 1)",
    "rgba(69, 69, 69, 1)",
    "rgba(116, 116, 116, 1)",
    "rgba(162, 162, 162, 1)",
    "rgba(208, 208, 208, 1)",
    "rgba(221, 221, 221, 1)",
    "rgba(233, 233, 233, 1)",
    "rgba(246, 246, 246, 1)",
    "rgba(0, 74, 112, 1)",
    "rgba(0, 105, 160, 1)",
    "rgba(0, 137, 207, 1)",
    "rgba(0, 168, 255, 1)",
    "rgba(77, 194, 255, 1)",
    "rgba(153, 220, 255, 1)",
    "rgba(230, 246, 255, 1)",
    "rgba(33, 92, 24, 1)",
    "rgba(48, 131, 35, 1)",
    "rgba(62, 170, 45, 1)",
    "rgba(76, 209, 55, 1)",
    "rgba(130, 223, 115, 1)",
    "rgba(183, 237, 175, 1)",
    "rgba(237, 250, 235, 1)",
    "rgba(99, 78, 19, 1)",
    "rgba(141, 111, 28, 1)",
    "rgba(183, 144, 36, 1)",
    "rgba(225, 177, 44, 1)",
    "rgba(234, 200, 107, 1)",
    "rgba(243, 224, 171, 1)",
    "rgba(252, 247, 234, 1)",
    "rgba(86, 30, 46, 1)",
    "rgba(123, 43, 66, 1)",
    "rgba(159, 56, 85, 1)",
    "rgba(196, 69, 105, 1)",
    "rgba(214, 125, 150, 1)",
    "rgba(231, 181, 195, 1)",
    "rgba(249, 236, 240, 1)",
    "rgba(0, 92, 93, 1)",
    "rgba(0, 132, 132, 1)",
    "rgba(0, 171, 172, 1)",
    "rgba(0, 210, 211, 1)",
    "rgba(77, 224, 224, 1)",
    "rgba(153, 237, 237, 1)",
    "rgba(230, 251, 251, 1)",
    "rgba(28, 28, 54, 1)",
    "rgba(40, 40, 77, 1)",
    "rgba(52, 52, 99, 1)",
    "rgba(64, 64, 122, 1)",
    "rgba(121, 121, 162, 1)",
    "rgba(179, 179, 202, 1)",
    "rgba(236, 236, 242, 1)",
  ];

  const borderColor = [
    "rgba(0, 44, 112, .2)",
    "rgba(0, 63, 160, .2)",
    "rgba(0, 82, 207, .2)",
    "rgba(0, 101, 255, .2)",
    "rgba(77, 147, 255, .2)",
    "rgba(153, 193, 255, .2)",
    "rgba(230, 240, 255, .2)",
    "rgba(99, 22, 25, .2)",
    "rgba(140, 31, 35, .2)",
    "rgba(182, 40, 46, .2)",
    "rgba(224, 49, 56, .2)",
    "rgba(233, 111, 116, .2)",
    "rgba(243, 173, 175, .2)",
    "rgba(252, 234, 235, .2)",
    "rgba(105, 50, 2, .2)",
    "rgba(149, 71, 3, .2)",
    "rgba(194, 92, 4, .2)",
    "rgba(238, 113, 5, .2)",
    "rgba(243, 156, 80, .2)",
    "rgba(248, 198, 155, .2)",
    "rgba(253, 241, 230, .2)",
    "rgba(108, 85, 0, .2)",
    "rgba(154, 120, 0, .2)",
    "rgba(199, 156, 0, .2)",
    "rgba(245, 192, 0, .2)",
    "rgba(248, 211, 77, .2)",
    "rgba(251, 230, 153, .2)",
    "rgba(254, 249, 230, .2)",
    "rgba(22, 67, 15, .2)",
    "rgba(31, 96, 21, .2)",
    "rgba(41, 124, 28, .2)",
    "rgba(50, 153, 34, .2)",
    "rgba(112, 184, 100, .2)",
    "rgba(173, 214, 167, .2)",
    "rgba(235, 245, 233, .2)",
    "rgba(58, 39, 98, .2)",
    "rgba(82, 56, 140, .2)",
    "rgba(107, 72, 181, .2)",
    "rgba(131, 89, 223, .2)",
    "rgba(168, 139, 233, .2)",
    "rgba(205, 189, 242, .2)",
    "rgba(243, 238, 252, .2)",
    "rgba(72, 15, 106, .2)",
    "rgba(103, 21, 150, .2)",
    "rgba(133, 27, 195, .2)",
    "rgba(164, 33, 240, .2)",
    "rgba(191, 100, 245, .2)",
    "rgba(219, 166, 249, .2)",
    "rgba(246, 233, 254, .2)",
    "rgba(94, 13, 79, .2)",
    "rgba(134, 19, 112, .2)",
    "rgba(173, 24, 146, .2)",
    "rgba(213, 30, 179, .2)",
    "rgba(226, 98, 202, .2)",
    "rgba(238, 165, 225, .2)",
    "rgba(251, 233, 247, .2)",
    "rgba(23, 23, 23, .2)",
    "rgba(69, 69, 69, .2)",
    "rgba(116, 116, 116, .2)",
    "rgba(162, 162, 162, .2)",
    "rgba(208, 208, 208, .2)",
    "rgba(221, 221, 221, .2)",
    "rgba(233, 233, 233, .2)",
    "rgba(246, 246, 246, .2)",
    "rgba(0, 74, 112, .2)",
    "rgba(0, 105, 160, .2)",
    "rgba(0, 137, 207, .2)",
    "rgba(0, 168, 255, .2)",
    "rgba(77, 194, 255, .2)",
    "rgba(153, 220, 255, .2)",
    "rgba(230, 246, 255, .2)",
    "rgba(33, 92, 24, .2)",
    "rgba(48, 131, 35, .2)",
    "rgba(62, 170, 45, .2)",
    "rgba(76, 209, 55, .2)",
    "rgba(130, 223, 115, .2)",
    "rgba(183, 237, 175, .2)",
    "rgba(237, 250, 235, .2)",
    "rgba(99, 78, 19, .2)",
    "rgba(141, 111, 28, .2)",
    "rgba(183, 144, 36, .2)",
    "rgba(225, 177, 44, .2)",
    "rgba(234, 200, 107, .2)",
    "rgba(243, 224, 171, .2)",
    "rgba(252, 247, 234, .2)",
    "rgba(86, 30, 46, .2)",
    "rgba(123, 43, 66, .2)",
    "rgba(159, 56, 85, .2)",
    "rgba(196, 69, 105, .2)",
    "rgba(214, 125, 150, .2)",
    "rgba(231, 181, 195, .2)",
    "rgba(249, 236, 240, .2)",
    "rgba(0, 92, 93, .2)",
    "rgba(0, 132, 132, .2)",
    "rgba(0, 171, 172, .2)",
    "rgba(0, 210, 211, .2)",
    "rgba(77, 224, 224, .2)",
    "rgba(153, 237, 237, .2)",
    "rgba(230, 251, 251, .2)",
    "rgba(28, 28, 54, .2)",
    "rgba(40, 40, 77, .2)",
    "rgba(52, 52, 99, .2)",
    "rgba(64, 64, 122, .2)",
    "rgba(121, 121, 162, .2)",
    "rgba(179, 179, 202, .2)",
    "rgba(236, 236, 242, .2)",
  ];

  useEffect(() => {
    let tempValue = 0;
    typeDataData.map((el: any) => {
      tempValue += Number(el);
    });
    setTypeTotalValue(tempValue);
  }, [typeDataData]);

  useEffect(() => {
    setTypeLabelAndData();
  }, [allocationData]);

  const setTypeLabelAndData = () => {
    let label: any = [];
    let data: any = [];
    allocationData.map((el: any) => {
      if (el.length > 0) {
        label.push(el[0]);
        let tempData = 0;
        el[1].map((le: any) => {
          tempData += Number(le.amount) * Number(le.updated_usd_value);
        });
        data.push(tempData);
      }
    });
    setTypeLabel(label);
    setTypeDataData(data);
  };

  const typeData = {
    labels: typeLabel,
    datasets: [
      {
        label: "# of Votes",
        data: typeDataData,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };

  const typeOption: any = {
    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
      title: {
        display: false,
        text: "Asset Type",
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            var label = context.label,
              currentValue = context.raw,
              total = context.chart._metasets[context.datasetIndex].total;

            var percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );

            return label + ": $" + currentValue + " (" + percentage + "%)";
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <Flex direction={"row"} justifyContent={"space-around"} pt="10px" pb={20}>
      <Box>
        <Box w={"300px"} h={"200px"}>
          <Doughnut
            width={"150px"}
            height={"150px"}
            options={typeOption}
            data={typeData}
          />
        </Box>
        <Stack direction={"column"} spacing={"15px"} pl="10px" pt={"40px"}>
          {typeLabel.map((el: any, index: number) => {
            return (
              <SingleAssetChartDetail
                handleSingle={handleSingle}
                key={index}
                backgroundColor={backgroundColor[index]}
                el={el}
                chartTitle={chartTitle}
                percentage={`${(
                  (typeDataData[index] / typeTotalValue) *
                  100
                ).toFixed(2)} %`}
                value={Helpers.currencyFormaterUSD(typeDataData[index])}
              />
            );
          })}
        </Stack>
      </Box>
    </Flex>
  );
};

export default SingleDoughnutChart;
