import { Button } from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";

const AddPlusButton = ({ handleOpenModal, buttonText }: any) => {
  return (
    <Button
      onClick={handleOpenModal}
      leftIcon={<BsPlusLg fontSize="10px" />}
      fontWeight="600"
      fontSize="12px"
      color="#fff"
      h="32px"
      minW="150px"
      _focus={{ outline: "none" }}
      border="1px"
      _hover={{
        color: "rgba(198, 179, 120, 1)",
        backgroundColor: "#fff",
        border: "1px",
      }}
      backgroundColor="rgba(198, 179, 120, 1)"
    >
      {buttonText}
    </Button>
  );
};

export default AddPlusButton;
