import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    SimpleGrid,
    ListItem,
    List,
    Icon,
    Flex
} from '@chakra-ui/react';
import { BiReceipt } from "react-icons/bi";

const CalculateAssetBox = ({ title, content} : any) => {
    return (
        <Accordion allowToggle>
            <AccordionItem borderBottom={'none'} borderTop={'none'}>
                <AccordionButton 
                     py='16px'
                     pr={'26px'}
                     pl={0}
                     bg='rgba(246, 246, 246, 1)'
                    _expanded={{ bg: 'rgba(246, 246, 246, 1)' }}
                    _focus={{outline: 'none'}}>
                        <Flex
                            px='26px' 
                            fontWeight={600} 
                            fontSize={"12px"} 
                            flex='1' textAlign='left'>
                            <Icon as={BiReceipt} color={'rgba(161, 146, 98, 1)'} fontSize={"16px"}  />
                            <Box pl={'10px'}>Calculation</Box>
                        </Flex>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel 
                    pl={0}
                    pt={0}
                    pb={0}
                    bg='rgba(246, 246, 246, 1)' >
                    
                    <Box 
                        ml={'26px'}
                        mr={'14px'}
                        bg='rgba(246, 246, 246, 1)'
                        w={'auto'} borderBottom={'1px'} borderColor='gray.200'>
                    </Box>
                    <Box px='26px' py='16px' >
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                            <List color={'rgba(116, 116, 116, 1)'} spacing={2}>
                                {title && title.map((el: any, index: number) => {
                                    return <ListItem key={index} fontSize={'12px'}>{el}</ListItem>
                                })}
                            </List>
                            <List color={'rgba(23, 23, 23, 1)'} spacing={2}>
                                {content && content.map((el: any, index: number) => {
                                   return <ListItem key={index} fontSize={'12px'}>{el}</ListItem>
                                })}
                            </List>
                        </SimpleGrid>
                    </Box>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

export default CalculateAssetBox;