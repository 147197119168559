import {
    border,
    Box,
    Flex,
    Spacer,
    Stack,
    Circle,
    Center,
  } from "@chakra-ui/react";
  import { useNavigate } from "react-router-dom";
  
  const DashboardChartDetail = ({
    backgroundColor,
    el,
    value,
    percentage,
    handleSingle,
    chartTitle,
    handleIsOpen,
    isOpen,
  }: any) => {
    const nav = useNavigate();
  
    const handleNav = (params: any) => {
      console.log(el, `ini apaan?`)
      if (el === "Other") {
        handleIsOpen(true);
      } else if (params === "Hide") {
        handleIsOpen(false);
      } else {
        nav(`${el === 'Mutual Fund' ? 'mutual-fund' : el}`, { state: chartTitle });
      }
      // handleSingle(el)
    };
    return (
      <Flex
        direction={"column"}
        // _hover={{ transform: "scale(1.05)", transition: '0.3s ease-in-out' }}
        // cursor={"pointer"}
        // onClick={handleNav}
      >
        <Center w={"full"} textAlign={"left"} justifyContent={"start"}>
          <Circle size="10px" bg={backgroundColor} />
          <Center>
            <Box fontWeight={600} fontSize={"12px"} pl="10px">
              {el}
            </Box>
            <Box px={"5px"}>-</Box>
            <Box fontSize={"12px"} color={"rgba(116, 116, 116, 1)"}>
              {percentage}
            </Box>
          </Center>
        </Center>
        <Box fontSize={"12px"} pl="20px" color={"rgba(116, 116, 116, 1)"}>
          {value}
        </Box>
      </Flex>
    );
  };
  
  export default DashboardChartDetail;
  