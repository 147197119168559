
import {
    Flex,
    Box,
    useColorModeValue,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Divider,
    Stack,
    Checkbox,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    Button,
    Portal,
    AvatarBadge,
    Center,
    useDisclosure,
} from "@chakra-ui/react";

import SearchPopover from '../SearchPopover';

const ListCustomer = (
    {
        setSearchFilter,
        searchFilter,
        allChecked,
        isIndeterminate,
        setCheckedItems,
        setCustomers,
        customers,
        checkedItems,
        allCustomers,
        setCustomersValues,
        customer,
    }: any
) => {

    return (
        <AccordionPanel pb={4}>
            <SearchPopover setSearchFilter={setSearchFilter} />
            <Flex flexDirection={'column'}>
                <Box w={'full'}>
                    <Stack
                        mt={2}
                        mb={1}
                        w={'full'}
                        // spacing={1}
                        className="filter-checkbox-wrapper"
                    >
                        {searchFilter ? (
                            <Center>
                                <Box
                                    mt={'12px'}
                                    ml={'5px'}
                                    px="10px"
                                    py="8px"
                                    fontSize={'12px'}>Filtering...</Box>
                            </Center>
                        ) : (
                            <Checkbox
                                isChecked={allChecked}
                                isIndeterminate={isIndeterminate}
                                mt={'12px'}
                                ml={'5px'}
                                px="10px"
                                py="8px"
                                w={'fit-content'}
                                _hover={{
                                    backgroundColor: "rgba(249, 247, 242, 1)",
                                }}
                                size="sm"
                                className="filter-checkbox"
                                onChange={(e) => {
                                    let temp = [];
                                    for (let i = 0; i < customers.length; i++) {
                                        temp.push(e.target.checked);
                                    }
                                    setCheckedItems(temp);
                                    if (!e.target.checked) {
                                        setCustomers([]);
                                    } else {
                                        let names: any = [];
                                        customers.forEach((el: any) => {
                                            names.push(el.name);
                                        });
                                        setCustomers(names);
                                    }
                                }}
                            >
                                Select All
                            </Checkbox>
                        )}
                    </Stack>
                </Box>

                <Box my={2} border="1px" borderColor="gray.200" />

                <Box w={'full'}>
                    <Stack
                        shouldWrapChildren={true}
                        wrap={'wrap'}
                        mt={2}
                        mb={1}
                        spacing={1}
                        className="filter-checkbox-wrapper"
                        isInline={true}
                    >
                        {customers.length > 0 ? customers.map((el: any, index: number) => {
                            return (
                                <Checkbox
                                    key={index}
                                    isChecked={checkedItems[allCustomers.indexOf(el)]}
                                    value={el.name}
                                    mt={'12px'}
                                    ml={'5px'}
                                    px="10px"
                                    py="8px"
                                    _hover={{
                                        backgroundColor: "rgba(249, 247, 242, 1)",
                                    }}
                                    className="filter-checkbox"
                                    size="sm"
                                    onChange={(e) => {
                                        const leng = checkedItems.length;
                                        let temp = [];
                                        for (let i = 0; i < leng; i++) {
                                            if (i === allCustomers.indexOf(el)) {
                                                temp.push(e.target.checked);
                                            } else {
                                                temp.push(checkedItems[i]);
                                            }
                                        }
                                        setCheckedItems(temp);
                                        let countTemp = temp.filter((e) => e === true);
                                        // if (countTemp) {
                                        //     setCountFilterChecked(countTemp.length);
                                        // }
                                        setCustomersValues(e.target.value);
                                    }}
                                >
                                    {el.name}
                                </Checkbox>
                            );
                        }) : (
                            <Center my={'10px'}>
                                <Box fontSize={'12px'}>Not Found</Box>
                            </Center>
                        )
                        }
                    </Stack>
                </Box>
            </Flex>
        </AccordionPanel>
    );
};

export default ListCustomer;