import { Table, Thead, Tr, Tbody, Td } from "@chakra-ui/react";
import CustomerFundLogTableHead from "./TableHead/CustomerFundLogTableHead";
import Helpers from "../../hooks/helpers";

const FundLogTable = ({transactions} : any) => {

    return (
        <Table
            variant="simple"
            size="md"
            fontSize="12px"
            mt="0"
            mb="20px">
            <Thead bg="rgba(246, 246, 246, 1)">
                <CustomerFundLogTableHead />
            </Thead>
            <Tbody>
                {transactions && transactions.length > 0 && transactions.reverse().map((el: any, index: number) => {
                    return (
                        <Tr key={index}>
                            <Td textTransform='capitalize' p='12px'>{el.action}</Td>
                            <Td textTransform='capitalize' textAlign='center' p='12px'>USD</Td>
                            <Td textTransform='capitalize' p='12px'>
                                {/* {el.action === "WITHDRAWAL"
                                    ? `-${Number(el.amount).toFixed(2)}`
                                    : Number(el.amount).toFixed(2)} */}
                                {el.action === "WITHDRAWAL"
                                    ? `- ${Helpers.currencyFormaterUSD(Number(el.amount))}`
                                    : ` ${Helpers.currencyFormaterUSD(Number(el.amount))}` }
                         
                            </Td>
                            <Td textTransform='capitalize' p='12px'>
                                {Helpers.currencyFormaterUSD(el.previous_balance)}
                                {/* {Number(el.previous_balance).toFixed(2)} */}
                            </Td>
                            <Td textTransform='capitalize' p='12px'>
                                {Helpers.currencyFormaterUSD(el.balance)}
                                {/* {Number(el.balance).toFixed(2)} */}
                            </Td>
                            <Td textTransform='capitalize' p='12px'>{el.created_at}</Td>
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
}

export default FundLogTable;
