import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Select,
    Flex,
    FormControl,
    FormLabel,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Stack,
    InputGroup,
    InputRightElement,
    CircularProgress,
    Input,
    Fade, ScaleFade, Slide, SlideFade,
    useDisclosure,
    Collapse,
    Badge,
    Text,
    FormErrorMessage,
    Icon
} from "@chakra-ui/react";
import SearchListMarket from '../FilterAndSearch/SearchListMarket';
import { FiSearch } from "react-icons/fi";
import getAPI from '../../API/getList';
import DefaultListBox from '../FilterAndSearch/ListBox/DefaultListBox';
import { CloseIcon } from '@chakra-ui/icons';

function SearchCode({
    isOpen,
    form,
    handleAddAsset,
    alredyOnWatch,
    handleFetchList,
    setInstrumentList,
    handleFetchMarket,
    setMarketList,
    typeData,
    assetFetch,
    handleAssetValue,
    marketDataList,
    setIsLoadMarket,
    setIsLoadAsset,
    isLoadMarket,
    isLoadAsset,
    handleModalClose,
    handleFetchType,
    fetchType,
    indexList,
    handleFetchIndex,
    setAssetCountry,
    setAssetCurrency,
    setAssetName,
    addMarket,
    setMarket
}: any) {
    const [search, setSearch] = useState<string>('');
    const [searchLoad, setSearchLoad] = useState<boolean>(false);
    const [toogle, setToogle] = useState<boolean>(false);
    const [dataFiltered, setDataFiltered] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [customSelectedItems, setCustomSelectedItem] = useState<any>({});
    const { watchList, rm_id } = useSelector((state: any) => state.rm);

    const { isOpen: collpaseOpen, onToggle } = useDisclosure();

    useEffect(() => {
        if (search.length > 2) {
            getListResult();
        }
    }, [search])

    const getListResult = async () => {
        setSearchLoad(true);
        const getList = await getAPI.getListBySearch(search);
        setDataFiltered(getList);
        setSearchLoad(false);
        if (!toogle) {
            setToogle(true);
            onToggle();
        }
    }

    const handleSelectedItemsChange = async (selectedItems: any) => {
        if (selectedItems) {
            await setCustomSelectedItem(selectedItems);
            await setSearch('');
            await setDataFiltered([]);
            if (toogle) {
                await onToggle();
                await setToogle(false);
            }
            await form.setValue("code", selectedItems.Code);
            await form.setValue("exchange_market", selectedItems.Exchange);
            await form.setValue("name", selectedItems.Name);
            await form.setValue("type", selectedItems.Type);
            await form.setValue("currency", selectedItems.Currency);
            await setAssetCountry(selectedItems.Country);
            await setAssetCurrency(selectedItems.Currency);
            await setAssetName(selectedItems.Name);
            if (addMarket) {
                setMarket(selectedItems.Exchange);
                await handleAssetValue({
                    code: selectedItems.Code,
                    market: selectedItems.Exchange
                });
            } else {
                await handleAssetValue(selectedItems.Code);
            }
        }
    };

    const handleRemoveSelectedItems = async () => {
        await setSearchLoad(false);
        await setSearch('');
        await setDataFiltered([]);
        if (toogle) {
            await onToggle();
            await setToogle(false);
        } else {
            await setCustomSelectedItem({});
        }
        await setAssetCountry('');
        await setAssetCurrency('');
        await setAssetName('');
        await handleAssetValue('');
        setCustomSelectedItem({});
    }

    return (
        <Box>
            <Flex justifyContent={"space-between"}>
                <Box w={"full"}>
                    {
                        !customSelectedItems.Code ? (
                            <FormControl pt="16px">
                                <FormLabel
                                    fontSize="14px"
                                    mb="8px"
                                    htmlFor="Type"
                                >
                                    Search By Name or Code
                            </FormLabel>
                                <Stack spacing={3}>
                                    <InputGroup>
                                        <InputRightElement
                                            pointerEvents="none"
                                            children={
                                                searchLoad ?
                                                    <CircularProgress size='20px' mx='auto' isIndeterminate color='#c6b378' />
                                                    :
                                                    <FiSearch color="rgba(162, 162, 162, 1)" />}
                                        />
                                        <Input
                                            _focus={{ outline: "none" }}
                                            placeholder="Search By Name or Code"
                                            fontSize="12px"
                                            onChange={(e: any) => {
                                                setSearch(e.target.value);
                                            }}
                                        />
                                    </InputGroup>
                                </Stack>
                            </FormControl>
                        ) : (
                            <Box>
                                <Flex
                                    alignItems={'center'}
                                    py={'12px'}
                                    px={'14px'}
                                    borderColor={alredyOnWatch ? 'rgba(233, 111, 116, 1)!important' : 'rgba(231, 231, 231, 1)!important'}
                                    border={'1px'} flexDir="row" justifyContent={'space-between'}>
                                    <Box>
                                        <Flex alignItems={'center'} flexDir="row">
                                            <Badge
                                                fontWeight={400}
                                                rounded={'md'}
                                                p={'2px 5px'}
                                                variant={'outline'}
                                                colorScheme={'blue'}>
                                                {customSelectedItems.Code}
                                            </Badge>
                                            <Text mx={'5px'} fontSize={'12px'} textTransform={'capitalize'} >{customSelectedItems.Name?.toLowerCase()}</Text>
                                        </Flex>
                                    </Box>
                                    <Box mr={'5px'} ml={'auto'}>
                                        <Badge
                                            fontWeight={400}
                                            rounded={'md'}
                                            p={'2px 5px'}
                                            borderColor={'rgba(231, 231, 231, 1)'}
                                            color={'black'}
                                            fontSize={'12px'}
                                            variant={'outline'}>
                                            {customSelectedItems.Exchange}
                                        </Badge>
                                    </Box>
                                    <Box mr={'5px'} ml={0}>
                                        <Badge
                                            fontWeight={400}
                                            rounded={'md'}
                                            p={'2px 5px'}
                                            colorScheme='green'
                                            fontSize={'12px'}
                                            color={'green'}
                                            variant='subtle'>
                                            {customSelectedItems.Currency}
                                        </Badge>
                                    </Box>
                                    <Box
                                        _hover={{ cursor: 'pointer' }}
                                        onClick={handleRemoveSelectedItems}
                                        mr={0} ml={0}>
                                        <Icon color={'rgba(198, 179, 120, 1)'} as={CloseIcon} fontSize={'10px'} />
                                    </Box>
                                </Flex>
                                <Box>
                                    {
                                        alredyOnWatch && (
                                            <Box
                                                pt={'10px'}
                                                color={'rgba(233, 111, 116, 1)!important'}
                                                fontSize={'12px'}>
                                                Already on Watchlist.
                                            </Box>
                                        )
                                    }
                                </Box>
                            </Box>

                        )
                    }

                </Box>
            </Flex>

            <Collapse in={collpaseOpen} animateOpacity>
                <Box px={'24px'} backgroundColor={'rgba(245, 245, 245, 1)'} py={'16px'}>
                    <Box mb={'12px'} fontSize={14}>
                        Search results for "{search}"
                </Box>
                    <Box rounded='md' boxShadow='lg' px={'4px'} backgroundColor={'rgba(255,255,255, 1)'}>
                        <DefaultListBox handleSelectedItemsChange={handleSelectedItemsChange} list={dataFiltered} />
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
}

export default SearchCode;
