import {
  Box,
  Flex,
  Stack,
  Select,
  Image,
  Center,
  useColorModeValue,
  Tr,
  Td,
  Tbody,
  Button,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import Helpers from "../../hooks/helpers";
import TemplateTable from "../../components/Tables/TemplateTable";
import { Line } from "react-chartjs-2";

import logo from "../../../assets/logo.png";

const TamplatePDF = ({
  tableHead,
  tabularData,
  optionsPercentage,
  dataPercentage,
  customer,
}: any) => {
  const listCustomer = (customer: any) => {
    let tempCustomer = "";
    if (customer.length > 3) {
      for (let i = 0; i < customer.length; i++) {
        if (i < 2) {
          tempCustomer += `${customer[i].name}, `;
        } else if (i === 2) {
          tempCustomer += `${customer[i].name} `;
        } else {
          tempCustomer += `and Others`;
          break;
        }
      }
    } else {
      for (let i = 0; i < customer.length; i++) {
        if (i == customer.length - 1) {
          tempCustomer += `${customer[i].name}`;
        } else {
          tempCustomer += `${customer[i].name}, `;
        }
      }
    }
    return tempCustomer;
  };

  return (
    <Box py={"27px"} px={"24px"}>
      <Flex flexWrap={"wrap"} direction={"row"}>
        <Box w={"80px"}>
          <Image src={logo} alt="Aries Logo" />
        </Box>

        <Box
          color={"rgba(204, 204, 204, 1)"}
          mx={"20px"}
          borderLeft={"1px"}
        ></Box>

        <Box fontSize={"11px"} w={"calc(100% - 150px)"}>
          <Flex>
            <Box pr={"3px"} fontWeight={600}>
              Consolidated Report as of{" "}
            </Box>
            <Box>
              {dataPercentage.labels[dataPercentage.labels.length - 1]
                .split(" ")
                .reverse()
                .join(" ")}
            </Box>
          </Flex>
          <Flex w={"100%"} direction={"row"}>
            <Box pr={"3px"} fontWeight={600}>
              Customer:{" "}
            </Box>
            <Box>{listCustomer(customer)}</Box>
          </Flex>
          <Flex>
            <Box pr={"3px"} fontWeight={600}>
              Base CCY:{" "}
            </Box>
            <Box>USD</Box>
          </Flex>
        </Box>
      </Flex>
      <Box color={"rgba(204, 204, 204, 1)"} my={"20px"} borderTop={"1px"}></Box>
      <Box>
        <Box
          textTransform={"uppercase"}
          fontWeight={600}
          fontSize={"16"}
          color={"rgba(23, 23, 23, 1)"}
        >
          Performance Portfolio
        </Box>
        <Box mb={"10px"} fontSize={"11px"} color={"rgba(116, 116, 116, 1)"}>
          As of{" "}
          {dataPercentage.labels[dataPercentage.labels.length - 1]
            .split(" ")
            .reverse()
            .join(" ")}
        </Box>
        <Box id={"tabular"} h={200} py="10px">
          <TemplateTable
            instrument={""}
            tableHeadList={tableHead}
            pages={1}
            currentPage={1}
            handlePageChange={null}
            customTableHead={null}
          >
            <Tbody>
              {tabularData.length > 0 &&
                tabularData.map((el: any, index: number) => {
                  return (
                    <>
                      <Tr key={index}>
                        <Td
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.name}
                        </Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.lastWeek}
                        </Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.lastMonth}
                        </Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.last3Month}
                        </Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.YTD}
                        </Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.lastYear}
                        </Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.last5Year}
                        </Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.maxData}
                        </Td>
                        <Td p={0}></Td>
                      </Tr>
                      <Tr>
                        <Td p={0}></Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.lastWeek === "N/A"
                            ? el.lastWeek
                            : Helpers.currencyFormaterUSD(el.Val[0].lastWeek)}
                        </Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.lastMonth === "N/A"
                            ? el.lastMonth
                            : Helpers.currencyFormaterUSD(el.Val[0].lastMonth)}
                        </Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.last3Month === "N/A"
                            ? el.last3Month
                            : Helpers.currencyFormaterUSD(el.Val[0].last3Month)}
                        </Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.YTD === "N/A"
                            ? el.YTD
                            : Helpers.currencyFormaterUSD(el.Val[0].YTD)}
                        </Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.lastYear === "N/A"
                            ? el.lastYear
                            : Helpers.currencyFormaterUSD(el.Val[0].lastYear)}
                        </Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.last5Year === "N/A"
                            ? el.last5Year
                            : Helpers.currencyFormaterUSD(el.Val[0].last5Year)}
                        </Td>
                        <Td
                          textAlign={"right"}
                          textTransform={"capitalize"}
                          fontSize={"12px"}
                          p={"12px"}
                          w={"150px"}
                        >
                          {el.maxData === "N/A"
                            ? el.maxData
                            : Helpers.currencyFormaterUSD(el.Val[0].Max)}
                        </Td>
                        <Td p={0}></Td>
                      </Tr>
                    </>
                  );
                })}
            </Tbody>
          </TemplateTable>
        </Box>
      </Box>
      <Box>
        <Box
          textTransform={"uppercase"}
          fontWeight={600}
          fontSize={"16"}
          color={"rgba(23, 23, 23, 1)"}
        >
          Performance Chart
        </Box>
        <Box mb={"10px"} fontSize={"11px"} color={"rgba(116, 116, 116, 1)"}>
          As of{" "}
          {dataPercentage.labels[dataPercentage.labels.length - 1]
            .split(" ")
            .reverse()
            .join(" ")}
        </Box>
        <Box h={300}>
          {" "}
          <Line options={optionsPercentage} data={dataPercentage} />{" "}
        </Box>
      </Box>
      <Box
        color={"rgba(204, 204, 204, 1)"}
        my={"20px"}
        borderBottom={"1px"}
      ></Box>
      <Box fontSize={"11px"} color={"rgba(116, 116, 116, 1)"}>
        © 2022 Aries Investment Pte. Ltd.
      </Box>
    </Box>
  );
};

export default TamplatePDF;
