import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  Center,
  Image,
  Flex,
  Badge,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import qoreContext from "../common/qoreContext";
import logo from "../../assets/logo.svg";
import * as BiIcons from "react-icons/bi";
import * as FiIcon from "react-icons/fi";

const ForgetPassword = () => {
  const [mode, setState] = useState(`hide`);

  const form = useForm({
    defaultValues: { email: "", password: "" },
  });

  useEffect(() => {
    if (Cookies.get("email") && Cookies.get("password")) {
      form.setValue("email", Cookies.get("email"));
      form.setValue("password", Cookies.get("password"));
    }
  }, []);

  const [isChecked, setChecked] = useState(false);

  const nav = useNavigate();

  function toggleMode() {
    let newMode = mode === "show" ? "hide" : "show";
    setState(newMode);
  }

  type formLogin = {
    email: string;
    password: string;
  };

  const onSubmit = async (values: formLogin) => {
    try {
      const token = await qoreContext.client.authenticate(
        values.email,
        values.password
      );
      if (isChecked) {
        Cookies.set("email", values.email);
        Cookies.set("password", values.password);
        Cookies.set("token", token, { expires: 7, secure: true });
      } else {
        Cookies.set("token", token, { expires: 7, secure: true });
      }
      nav("/");
    } catch (e) {
      if (e instanceof Error) {
        alert(e.message);
      }
      return;
    }
  };

  return (
    <Center h="100vh" w="100%" className="bg-login">
      <Box
        p="5"
        maxW="440px"
        w="100%"
        borderWidth="1px"
        bg="#fff"
        borderRadius="10px"
      >
        <Center>
          <Image borderRadius="md" src={logo} />
        </Center>
        <Box my='5'>
            <Text>
                Please Enter your email address to reset your password
            </Text>
        </Box>
        <form onSubmit={form.handleSubmit((values) => onSubmit(values))}>
          <FormControl mb="24px" isRequired>
            <div className="icon-wrapper left">
              <FiIcon.FiMail />
            </div>
            <Input
              id="email"
              type="email"
              placeholder={`Fill Email address...`}
              fontSize="14px"
              color="rgba(162, 162, 162, 1)"
              pl="40px"
              {...form.register("email", { required: true })}
            />
          </FormControl>

          <Button
            boxShadow="lg"
            bg="#c6b378"
            color="#fff"
            w="100%"
            h="40px"
            size="lg"
            type="submit"
            my="24px"
            fontSize="14px"
            fontWeight="extrabold"
            _hover={{
              bg: "white",
              color: "#c6b378",
              border: "1px solid #c6b378",
            }}
            disabled={form.formState.isSubmitting}
          >
            Send Password Reset Link
          </Button>
        </form>
        <Box pb={'5px'} textAlign={'center'} fontSize={'12px'} fontWeight={'400'} color={'#747474'}> 
          <Link to={'/'}>Back to Login</Link>
      </Box>
      </Box>
    </Center>
  );
}

export default ForgetPassword