import  { useState } from 'react';
import { Table, Thead, Tr, Tbody, Td, Avatar, 
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  Icon,
  Flex
} from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import qoreContext from "../../common/qoreContext";
import AdminUserTableHead from "./TableHead/AdminUserTableHead";
import CustomerUserTableHead from "./TableHead/CustomerUserTableHead";
import UserAction from "./ActionColumn/UserAction";
import ConfirmationRemoveModal from '../Modal/ConfirmationRemoveModal';
import { BiTrash } from "react-icons/bi";

const TableUser = ({
  list,
  onOpen,
  updateUser,
  afterDelete,
  instrument,
}: any) => {
  const { deleteRow } = qoreContext.table(instrument).useDeleteRow();
  const [confirmation, setConfirmation] = useState<string>('');
  const [el, setEl] = useState<any>({});
  const [isError, setIsError] = useState<boolean>(false);

  const { isOpen, onOpen: modalOpen, onClose } = useDisclosure();

  const handleUpdate = (el: any) => {
    onOpen();
    updateUser(el);
  };

  const handleConfirmation = (params: any) => {
    if (confirmation === params.name) {
      return true
    } else {
      return false
    }
  }

  const handeDelete = async (el: any) => {
    setConfirmation('');
    await setEl(el);
    setIsError(false);
    modalOpen();
  };

  const handleAfterConfirmation = async (el: any) => {
    const response = await handleConfirmation(el);
    setIsError(false);
    if (response) {
      const response = await deleteRow(el.id);
      afterDelete();
    } else {
      setIsError(true)
    }
  }

  const handleCloseModal = () => {
    setConfirmation('');
    onClose();
  }

  return (
    <Table variant="simple" size="md" fontSize="12px">
      <Modal isCentered isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader 
                px='24px'
                py='16px'
                fontSize='16px'
                fontWeight='600'
                borderBottom='1px' 
                borderColor='#E9E9E9' >
                <Flex alignItems={'center'} gap={'1'}>
                  <Icon color={'rgba(224, 49, 56, 1)'} fontSize={'20px'} as={BiTrash}></Icon>
                  Are you sure want to delete this user ?
                </Flex>
            </ModalHeader>
            <ModalCloseButton _focus={{ outline: 'none'}} fontSize='8px' maxW='10px' maxH='10px' mt='15px' mr='20px' />
            <ConfirmationRemoveModal
              isError={isError}
              el={el}
              handleAfterConfirmation={handleAfterConfirmation}
              confirmation={confirmation}
              setConfirmation={setConfirmation} 
              onClose={onClose}/>
        </ModalContent>
      </Modal>

      <Thead bg="rgba(246, 246, 246, 1)">
        {instrument === "users" ? (
          <AdminUserTableHead />
        ) : (
          <CustomerUserTableHead />
        )}
      </Thead>
      {instrument === "users" ? (
        <Tbody>
          {list.map((el: any, index: number) => {
            return (
              <Tr key={index}>
                <Td p="12px" w="40px" maxW="40px">
                  <Avatar
                    className="default-profile"
                    size={"sm"}
                    src={el.profile_pic ? el.profile_pic : <FaUser />}
                  />
                </Td>
                <Td p="12px">{el.name}</Td>
                <Td p="12px">{el.role}</Td>
                <Td p="12px">{el.external_id}</Td>
                <Td p="12px">{el.created_at}</Td>
                <UserAction
                  handeDelete={handeDelete}
                  val={el}
                  handleUpdate={handleUpdate}
                />
              </Tr>
            );
          })}
        </Tbody>
      ) : (
        <Tbody>
          {list.length > 0 &&
            list.map((el: any, index: number) => {
              return (
                <Tr key={index}>
                  <Td p="12px" w="40px" maxW="40px">
                  <Avatar
                    className={el.avatar ? "" : "default-profile"}
                    size={"sm"}
                    bg={ el.avatar ? "transparent" : "" }
                    backgroundColor={ el.avatar ? "transparent" : "" }
                    src={el.avatar? el.avatar : <FaUser />}
                  />
                </Td>
                  <Td p="12px">{el.name}</Td>
                  <UserAction
                    handeDelete={handeDelete}
                    val={el}
                    handleUpdate={handleUpdate}
                  />
                </Tr>
              );
            })}
        </Tbody>
      )}
    </Table>
  );
};

export default TableUser;
