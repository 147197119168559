import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const token = "620e19e49de326.23079426";
import {
    Box,
    Center,
    useColorModeValue,
} from "@chakra-ui/react";
import getAPI from "../API/getList";
import filterAPI from "../API/filterAPI";
import EmptyData from "./EmptyData";
import LocalLoader from "./Loader/LocalLoader";
import TampleTable from "./Tables/TemplateTable";
import TableEMBody from "./Tables/TableBody/TableEMBody";
import SearchExchangeList from "./FilterAndSearch/SearchExchangeList";

function ExchangeMarket({ params, instrument }: any) {
    const [list, setList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [search, setSearch] = useState<string>("");
    const [searchFilter, setSearchFilter] = useState<string>("Code");
    const [allData, setAllData] = useState<any[]>([]);
    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(25);

    useEffect(() => {
        getList();
    }, [search, currentPage, searchFilter]);

    const handlePageChange = (nextPage: number) => {
        setCurrentPage(nextPage);
    };

    const handleSearchFilter = (value: string) => {
        setSearchFilter(value);
    };

    // useEffect(() => {
    //     if (allData.length > 0) {
    //         handleSearch();
    //     }
    // }, [search]);

    // const handleSearch = () => {
    //     try {
    //         let temp: any = [];

    //         if (searchFilter === 'Name') {
    //             allData.forEach((el: any) => {
    //                 if (el.Name.toLowerCase().includes(search.toLowerCase())) {

    //                 }
    //             })
    //         }

    //         console.log(allData, `allData`)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const getList = async () => {
        try {
            let data;
            setIsLoading(true);
            if (allData.length > 0) {
                data = JSON.parse(JSON.stringify(allData));
            } else {
                if (instrument === "stock") {
                    data = await getAPI.getStockMarket(params);
                } else if (instrument === "ETF") {
                    data = await getAPI.getETFMarket(params);
                } else if (instrument === "mutual-funds") {
                    data = await getAPI.getMutualFundMarket(params);
                }
                setAllData(data);
            }

            let result = await filterAPI.exchangeMarketSearch(
                search,
                searchFilter,
                data
            );

            setPages(Math.ceil(result.length / limit));

            if (limit > 0) {
                result = result.splice((currentPage - 1) * limit, limit);
            }

            setList(result);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    let tableHeadList: any = [
        "Isin",
        "Name",
        "Code",
    ]




    return (
        <div>
            <Center py={6}>
                <Box
                    w={"full"}
                    bg={useColorModeValue("white", "gray.800")}
                    boxShadow={"lg"}
                    rounded={"md"}
                    px="24px"
                >
                    <SearchExchangeList
                        setCurrentPage={setCurrentPage}
                        setSearch={setSearch}
                        handleSearchFilter={handleSearchFilter} />

                    {isLoading ? (
                        <Box py="10"> {LocalLoader()} </Box>
                    ) : (
                        <>
                            {list.length > 0 ? (
                                <>
                                    <TampleTable
                                        customTableHead={false}
                                        instrument={instrument}
                                        pages={pages}
                                        currentPage={currentPage}
                                        handlePageChange={handlePageChange}
                                        tableHeadList={tableHeadList}>
                                        <TableEMBody list={list} params={params} instrument={instrument} />
                                    </TampleTable>

                                </>
                            ) : (
                                <EmptyData />
                            )}
                        </>
                    )}
                </Box>
            </Center>
        </div>
    );
}

export default ExchangeMarket;
