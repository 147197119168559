import { 
    Tr, Th
} from "@chakra-ui/react";

const CustomerUserTableHead = () => {
    return (
        <Tr>
            <Th textTransform='capitalize' w={'60px'} p='12px' >Avatar</Th>
            <Th textTransform='capitalize' p='12px'>Display Name</Th>
            <Th textTransform='capitalize' p='12px' maxW='70px' textAlign='center'>Action</Th>
        </Tr>
    );
};

export default CustomerUserTableHead;