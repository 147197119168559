import {
    Td,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Button,
    IconButton,
    Portal,
    Stack,
} from "@chakra-ui/react";
import { HiDotsHorizontal } from "react-icons/hi";
import { AiOutlineLogin } from "react-icons/ai";
import { BiHistory } from "react-icons/bi";
import { RiFundsLine, RiExchangeFundsFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const FundAction = ({ open1, open2, el, getCustomerData, takeFund }: any) => {
    const nav = useNavigate();
    const handleDetail = (params: string) => {
        nav(params);
    };

    return (
        <Td p="12px" maxW="30px" textAlign="center" className="user-action-wrapper">
            <Popover placement="left">
                <PopoverTrigger>
                    <IconButton
                        minW="24px"
                        h="24px"
                        border="1px"
                        borderColor="#E9E9E9"
                        variant="ghost"
                        aria-label="open menu"
                        _focus={{ outline: "none" }}
                        icon={<HiDotsHorizontal width="10px" />}
                        zIndex={9}
                    />
                </PopoverTrigger>
                <Portal>
                    <PopoverContent maxW="170px" _focus={{ outline: "none" }}>
                        <PopoverBody p="0">
                            <Stack direction="column" spacing={0}>
                                <Button
                                    _focus={{outline: 'none'}}
                                    onClick={() => {
                                        getCustomerData(el.id);
                                        open1();
                                    }}
                                    leftIcon={
                                        <RiFundsLine
                                            color="rgba(162, 162, 162, 1)"
                                            fontSize="16px"
                                        />
                                    }
                                    _hover={{ backgroundColor: "rgba(249, 247, 242, 1)" }}
                                    color="rgba(69, 69, 69, 1)"
                                    fontSize="14px"
                                    fontWeight="400"
                                    p="16px"
                                    w="100%"
                                    justifyContent="flex-start"
                                    variant="ghost"
                                >
                                    Add Fund
                                </Button>
                                
                                {
                                    takeFund !== 0 &&

                                    <Button
                                        _focus={{outline: 'none'}}
                                        onClick={() => {
                                            getCustomerData(el.id);
                                            open2();
                                        }}
                                        leftIcon={
                                            <RiExchangeFundsFill
                                                color="rgba(162, 162, 162, 1)"
                                                fontSize="16px"
                                            />
                                        }
                                        _hover={{ backgroundColor: "rgba(249, 247, 242, 1)" }}
                                        color="rgba(69, 69, 69, 1)"
                                        fontSize="14px"
                                        fontWeight="400"
                                        my="0"
                                        p="16px"
                                        w="100%"
                                        justifyContent="flex-start"
                                        variant="ghost"
                                    >
                                        Take Fund
                                    </Button>
                                }
                                
                                <Button
                                    _focus={{outline: 'none'}}
                                    onClick={() =>
                                        handleDetail(
                                            `/management/fund-management/${el.name.split(' ').join('-')}?id=${el.id}`
                                        )
                                    }
                                    leftIcon={
                                        <AiOutlineLogin
                                            color="rgba(162, 162, 162, 1)"
                                            fontSize="16px"
                                        />
                                    }
                                    _hover={{ backgroundColor: "rgba(249, 247, 242, 1)" }}
                                    color="rgba(69, 69, 69, 1)"
                                    fontSize="14px"
                                    fontWeight="400"
                                    my="0"
                                    p="16px"
                                    w="100%"
                                    justifyContent="flex-start"
                                    variant="ghost"
                                >
                                    Fund Detail
                                </Button>
                            </Stack>
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover>
        </Td>
    );
};

export default FundAction;
