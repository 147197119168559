import { FETCH_FINANCIAL_INSTRUMENT } from "../actionTypes/index";


const initialize = {
    financialInstrument: {},
}

const financialInstrumentReducer = (state = initialize, action) => {
    switch (action.type) {
      case FETCH_FINANCIAL_INSTRUMENT:
        return { ...state, products: action.payload }
      default:
        return state;
    }
  }

  export default financialInstrumentReducer