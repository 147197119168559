import { useEffect, useState } from "react";
import qoreContext from "../../common/qoreContext";
import { Table, Stack, Flex, Center, Box, useColorModeValue } from "@chakra-ui/react";
import { DatePicker } from "chakra-ui-date-input";
import LocalLoader from "../../components/Loader/LocalLoader";
import EmptyData from "../../components/EmptyData";
import TitleBoxDetail from "../DetailBox/TitleBoxDetail";
import { RiExchangeDollarLine } from "react-icons/ri";
import PaginatorTableMarket from "./PaginatorTableMarket";
import NoDataFoundTransaction from "../StatusPage/NoDataFoundTransaction";
import Helpers from "../../hooks/helpers";
import TablePortfolioTransaction from './TableBody/TablePortfolioTransaction';
import TableHeadPortfolioTransaction from './TableHead/TableHeadPortfolioTransaction';
import FilterDateTable from '../FilterAndSearch/FilterDateTable';
function TransactionTable({ customer, customerTransactions }: any) {
    const [transactions, setTransactions] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [boxIsLoading, setBoxIsLoading] = useState<boolean>(true);
    const [tableIsLoading, setTableIsLoading] = useState<boolean>(true);
    const [noDataFound, setNoDataFound] = useState<boolean>(true);
    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");

    const [list, setList] = useState<any[]>([]);
    const [limit, setLimit] = useState<number>(10);
    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const handlePageChange = (nextPage: number) => {
        setCurrentPage(nextPage);
    };
    const user = qoreContext.useCurrentUser();

    const getTransactions = async (customerTransactions: any, customer: any) => {
        try {
            setBoxIsLoading(true);
            if (customerTransactions.length > 0 && customer.length > 0) {

                let filterTransactions = await customerTransactions.filter((e: any) => {
                    return customer.indexOf(e.name) > -1;
                });

                let tempResult: any = [];
                filterTransactions.map((el: any) => {
                    el.customer_id_transactions_items.map((le: any) => {
                        tempResult.push(le);
                    });
                });

                const sortData = await tempResult.sort(function(a: any, b: any){
                    return new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf();
                });

                setTransactions(sortData);
                if (tempResult.length > 0) {
                    if(tempResult[0].created_at){
                        await setToDate(tempResult[0].created_at.split("-").reverse().join("/"));
                        await setFromDate(tempResult[tempResult.length - 1].created_at.split("-").reverse().join("/"));
                    }
                    const dateList = await getListDate(tempResult[tempResult.length - 1].created_at.split("-").reverse().join("/"), tempResult[0].created_at.split("-").reverse().join("/"));
                    const filterByDate = getDataBeetweenDate(tempResult, dateList);
                    const listFiltered = Helpers.filterPagination(tempResult, filterByDate, limit, currentPage);
                    setPages(Math.ceil(tempResult.length / limit));
                    setList(listFiltered);
                }

                if (tempResult.length > 0) {
                    setNoDataFound(false);
                } else {
                    setNoDataFound(true);
                }

                setIsLoading(false);
            }

            setBoxIsLoading(false);
        } catch (error) {
            setNoDataFound(true);
            console.log(error);
        }
    };

    const getDaysArray = (start: any, end: any) => {
        for(var arr = [] ,dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1 )){
            arr.push(new Date(dt));
        }
        return arr;
    };

    const getListDate = (fromDate: any, toDate: any) => {
        let daylist = getDaysArray(new Date(fromDate.split("/").reverse().join("-")),new Date(toDate.split("/").reverse().join("-")));
        let dateToDate = daylist.map((v)=>v.toISOString().slice(0,10));
        return dateToDate
    }

    const getDataBeetweenDate = (dataList: any, dateList: any) => {
        const intersection = dataList.filter((element: any) => dateList.includes(element.created_at));
        return intersection
    }

    useEffect(() => { getListByDate()}, [fromDate, toDate]);
    useEffect(() => { getList() }, [currentPage]);
    useEffect(() => { setCurrentPage(1) }, [transactions]);

    useEffect(() => {
        getTransactions(customerTransactions, customer);
    }, [customerTransactions, customer])

    const getList = async () => {
        setTableIsLoading(true)
        let result:any = [];

        transactions.forEach((el: any) => {
            result.push(el)
        })

        const dateList = getListDate(fromDate, toDate);
        const filterByDate = getDataBeetweenDate(result, dateList);
        const listFiltered = Helpers.filterPagination(result, filterByDate, limit, currentPage);
        setList(listFiltered);     
        setTimeout( () => {
            setTableIsLoading(false);
        }, 300 )
    }

    const getListByDate = () => {
        setTableIsLoading(true);
        let result:any = [];

        transactions.forEach((el: any) => {
            result.push(el)
        });

        const dateList = getListDate(fromDate, toDate);
        const filterByDate = getDataBeetweenDate(result, dateList);

        setPages(Math.ceil(filterByDate.length / limit));

        const listFiltered = Helpers.filterPagination(result, filterByDate, limit, currentPage);
        setList(listFiltered); 
        setCurrentPage(1);  
        setTableIsLoading(false); 
    }

    return (
        <div>
            <Center pl="3" py={6} pb={150}>
                {!isLoading ? (
                    !noDataFound ? (
                        <Box
                            w={"full"}
                            bg={useColorModeValue("white", "gray.800")}
                            boxShadow={"lg"}
                            rounded={"md"}
                            px="24px"
                        >
                            { boxIsLoading ? (
                                <Box py="10"> {LocalLoader()} </Box>
                            ) : (
                                <>  
                                    <Box w={{ base: "full" }}>
                                        <TitleBoxDetail
                                            fsIcon={"16"}
                                            iconTitle={RiExchangeDollarLine}
                                            boxTitle={"Transactions"}
                                        />
                                        <FilterDateTable 
                                            setToDate={setToDate}
                                            setFromDate={setFromDate}
                                            toDate={toDate}
                                            fromDate={fromDate} />
                                    </Box>
                                    {
                                        !tableIsLoading ? (
                                            list.length > 0 ? (
                                            <Box>
                                                <Table
                                                    variant="simple"
                                                    size="md"
                                                    fontSize="12px"
                                                    mt="10px"
                                                    mb="20px"
                                                >
                                                    <TableHeadPortfolioTransaction />
                                                    <TablePortfolioTransaction list={list} />
                                                </Table>
                                                <PaginatorTableMarket
                                                    pages={pages}
                                                    currentPage={currentPage}
                                                    handlePageChange={handlePageChange}
                                                />
                                            </Box>
                                        ) : (
                                            <Box my={20}>
                                                <EmptyData />
                                            </Box>
                                        )) : (
                                            <Box py="30"> {LocalLoader()} </Box>
                                        )
                                    }
                                </>
                            )}
                        </Box>
                    ) : (
                        <NoDataFoundTransaction instrument={"Transaction"} />
                    )
                ) : (
                    <Box py="10"> {LocalLoader()} </Box>
                )}
            </Center>
        </div>
    );
}

export default TransactionTable;
