import { useEffect, useState } from "react";
import qoreContext from "../../common/qoreContext";
import {
    Center,
    Box,
    useColorModeValue,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import TabPanelContent from "../../components/Tabs/TabPanelContent";
import LocalLoader from "../../components/Loader/LocalLoader";
import DetailPerformance from "../../components/DetailBox/DetailPerformance";
import NoDataFoundTransaction from "../../components/StatusPage/NoDataFoundTransaction";

function Performance({ customer }: any) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [noDataFound, setNoDataFound] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);

    const user = qoreContext.useCurrentUser();

    // const getData = async () => {
    //   try {
    //     setIsLoading(true);
    //     let result;
    //     let customerCondition = { $and: [{ rm_id: user?.user?.id }] };
    //     if (customer.length > 0) {
    //       let tempOr: any = { $or: [] };
    //       await customer.forEach((el: any) => {
    //         tempOr["$or"].push({ name: el });
    //       });
    //       customerCondition["$and"].push(tempOr);
    //     }

    //     if (user?.user?.id) {
    //       const response = await qoreContext.client
    //         .table("customers")
    //         .readRows({
    //           condition: customerCondition,
    //           populate: ["customer_id_performances_items"],
    //         })
    //         .toPromise();
    //       if (response?.data?.nodes) {
    //         result = JSON.parse(JSON.stringify(response?.data?.nodes));
    //         setData(result);
    //         if (response?.data?.nodes.length > 0) {
    //           let temp = true;
    //           response?.data?.nodes.forEach((le: any) => {
    //             if (le.customer_id_performances_items.length > 0) {
    //               temp = false;
    //             }
    //           });
    //           setNoDataFound(temp);
    //         }
    //         setIsLoading(false);
    //       }
    //     }
    //   } catch (error) { }
    // };

    // useEffect(() => {
    //   getData();
    // }, [user?.user?.id, customer]);

    return (
        <Center flexDirection={'column'} pl="2" py={6}>
            {!isLoading ? (
                !noDataFound ? (
                    <>
                        <Box
                            w={"full"}
                            bg={useColorModeValue("white", "gray.800")}
                            boxShadow={"lg"}
                            rounded={"md"}
                            px={'0'}
                            pb="24px"
                        >
                            {isLoading ? (
                                <Box py="10"> {LocalLoader()} </Box>
                            ) : (
                                <Tabs isLazy={true}>
                                    <TabList px="24px">
                                        <Tab
                                            _selected={{
                                                borderColor: "#C6B378",
                                                fontWeight: "600",
                                            }}
                                            fontSize="14px"
                                            px="0"
                                            pb="12px"
                                            mt="10px"
                                            mr="10px"
                                            _focus={{ outline: "none" }}
                                        >
                                            Percentage
                                        </Tab>
                                        <Tab
                                            _selected={{
                                                borderColor: "#C6B378",
                                                fontWeight: "600",
                                            }}
                                            fontSize="14px"
                                            px="0"
                                            pb="12px"
                                            mt="10px"
                                            mx="10px"
                                            _focus={{ outline: "none" }}
                                        >
                                            Profit
                                        </Tab>
                                    </TabList>

                                    <TabPanels>
                                        <TabPanel
                                            as={AnimatePresence}
                                            exitBeforeEnter
                                            pt="0"
                                            px="0"
                                        >
                                            <TabPanelContent>
                                                <Box px={'24px'} w={{ base: "full" }}>
                                                    <DetailPerformance data={data} params={'percentage'} />
                                                </Box>
                                            </TabPanelContent>
                                        </TabPanel>
                                        <TabPanel as={AnimatePresence} exitBeforeEnter>
                                            <TabPanelContent>
                                                <Box px={'24px'} w={{ base: "full" }}>
                                                    <DetailPerformance data={data} params={'values'} />
                                                </Box>
                                            </TabPanelContent>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            )}
                        </Box>
                    </>
                ) : (
                    <NoDataFoundTransaction instrument={"Performances"} />
                )
            ) : (
                <Box py="10"> {LocalLoader()} </Box>
            )}
        </Center>
    );
}

export default Performance;
