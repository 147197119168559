import {
    Box,
    useColorModeValue,
} from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import TitleBoxDetail from '../../components/DetailBox/TitleBoxDetail';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Animation,
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );


import { BiBarChartAlt2 } from "react-icons/bi";

const HorizontalChart = (
    {
        chartData,
    }: any) => {

    const options: any = {
        Animation,
        indexAxis: 'y' as const,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'left' as const,
                labels: {
                    usePointStyle: true,
                    padding: 20
                },
                align: 'center',
            },
            title: {
                display: true,
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        return ` ${context.dataset.label[0]}: ${context.raw} %`
                        
                    },
                },
            },
        },
        scales: {
            x: {
                offset: false,
                gridLines: {
                    offsetGridLines: false,
                },
                title: {
                    display: true,
                },
                grid: {
                    display: false,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                },
            },
            y: {
                title: {
                    display: true,
                },
                grid: {
                    display: false,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: false,
                    callback: function (val: any, index: number, tick: any) {
                        return ``;
                    },
                },
            }
        }
    }

    return (
        <Box mt={'24px'} py="10px">
            <Box
                w={"full"}
                bg={useColorModeValue("white", "gray.800")}
                boxShadow={"lg"}
                rounded={"md"}
                px="0"
            >
                <Box px={'24px'}>
                    <TitleBoxDetail
                        fsIcon={"20px"}
                        iconTitle={BiBarChartAlt2}
                        boxTitle={`Asset Distribution Chart`}
                    />
                </Box>
                <Box w={{ base: "full" }} px={"24px"} pb={'24px'}>
                    <Bar data={chartData} options={options}></Bar>
                </Box>
            </Box>
        </Box>
    );
};

export default HorizontalChart;