import { Stack, Flex, Box } from "@chakra-ui/react";
import { DatePicker } from "chakra-ui-date-input";

const FilterDateTable = ({fromDate, toDate, setFromDate, setToDate }: any) => {
    return (
        <Flex direction="row" py="10px">
            <Stack
                fontSize="14px"
                fontFamily="Inter"
                h="32px"
                className="datepicker-wrapper"
                spacing={3}
                mr="12px"
                direction="row"
            >
                <DatePicker
                    defaultValue={fromDate.split("-").reverse().join("/")}
                    placeholder="From"
                    name="fromDate"
                    onChange={(date: string) =>
                        setFromDate(date.split("/").reverse().join("-"))
                    }
                />

                <Box lineHeight={'32px'} fontWeight={600}>
                    {'-'}
                </Box>

                <DatePicker
                    defaultValue={toDate.split("-").reverse().join("/")}
                    placeholder="To"
                    name="toDate"
                    onChange={(date: string) =>
                        setToDate(date.split("/").reverse().join("-"))
                    }
                />
            </Stack>
        </Flex>
    );
};

export default FilterDateTable;