import { useState } from "react";
import {
    Box,
    Flex,
    useDisclosure
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import PortfolioPerformance from "../Chart/PortfolioPerformance";
import getAPI from "../../API/getList";
import PortfolioCompareChart from '../Chart/PortfolioCompareChart';
import { useSelector } from 'react-redux';

function DetailPerformance({ asset, data, params }: any) {

    const form = useForm({
        defaultValues: {
            type: "",
            name: "",
            code: "",
            currency: "",
            exchange_market: "",
        },
    });
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [indexList, setIndexList] = useState<any>([]);
    const [marketFetch, setMarketFetch] = useState<boolean>(true);
    const [assetFetch, setAssetFetch] = useState<boolean>(true);

    const [typeData, setTypeData] = useState<any[]>([]);
    const [marketDataList, setMarketDataList] = useState<any[]>([]);

    const [chartData, setChartData] = useState<any>([]);
    const { dataFilterCustomer, dataAssetSummary } = useSelector((state: any) => state.rm);

    const handleOpenModal = () => {
        getIndexList();
        onOpen();
    }

    const getIndexList = async () => {
        const response = await getAPI.getIndex();
        setIndexList(response)
    }

    const handleModalClose = () => {
        form.setValue("code", "");
        form.setValue("exchange_market", "");
        form.setValue("name", "");
        form.setValue("type", "");
        setMarketDataList([]);
        setMarketFetch(true);
        setAssetFetch(true);
        setTypeData([])
        setMarketDataList([]);
        onClose();
    }

    const handleFetchIndex = async (val: any) => {
        setAssetFetch(false)
    }

    const handleAddAsset = async (values: any) => {
        try {
            const params = { code: values.code, market: values.exchange_market };
            const EODValue = await getAPI.getAllEOD(params);
            let temp = [...chartData, { name: values.name, data: EODValue, currency: values.currency }];
            if (temp.length > 1) {
                temp = temp.sort((a, b) => a.data.length - b.data.length);
            }
            setChartData(temp);
            form.setValue("code", "");
            form.setValue("exchange_market", "");
            form.setValue("name", "");
            form.setValue("type", "");
            setAssetFetch(true)
            onClose();
        } catch (error) {
            console.log("error");
        }
    };
    return (
        <Box>
            {params !== 'percentage' ? (
                <Flex
                    direction={'row'}
                    py={'20px'}>
                    <Box
                        ml={0}
                        mr={'auto'}
                        color={'rgba(23, 23, 23, 1)'}
                        fontWeight={600}
                        fontSize={'16px'}>
                        Portfolio Profit
                    </Box>
                </Flex>
            ) : <></> }

            {
                params !== 'percentage' ? (
                    <PortfolioPerformance chartData={dataAssetSummary} customers={dataFilterCustomer} params={params} />
                ) : (
                    <PortfolioCompareChart dataFilterCustomer={dataFilterCustomer} dataAssetSummary={dataAssetSummary} />
                )
            }
        </Box>
    );
}

export default DetailPerformance;
