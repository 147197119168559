import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
const token = "620e19e49de326.23079426";
import DetailTicket from "../../components/FinancialInstrumentDetails/DetailTicket";
import DetailTicketForex from "../../components/FinancialInstrumentDetails/DetailTicketForex";
import DetailCrypto from "../../components/FinancialInstrumentDetails/DetailTicketCrypto";
import GetEOD from '../../API/getEOD';
import Helpers from "../../hooks/helpers";

function Detail({ instrument }: any) {
    const params = useParams();
    const [allData, setAllData] = useState<any[]>([]);
    const [dataFetch, setDataFetch] = useState<any[]>([]);
    const [list, setList] = useState<any[]>([]);
    const [filterString, setFilterString] = useState<string>("MAX")
    const [oldestDate, setOldestDate] = useState<string>("");
    const [newestDate, setNewestDate] = useState<string>("");
    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [oldestValue, setOldestValue] = useState<number>(0);
    const [newestValue, setNewestValue] = useState<number>(0);
    const [yesterdayValue, setYesterdayValue] = useState<number>(0);

    useEffect(() => {
        getList();
    }, [fromDate, toDate]);

    const handleChartFilter = (limit: string) => {
        setFilterString(limit);
        const year = new Date().getFullYear();
        if (limit === "1M") {       
            const lastMonth = Helpers.dateFormat(Helpers.getLast30Days());
            setFromDate(lastMonth);
            setToDate(newestDate);
        } else if (limit === "3M") {
            let newDate = JSON.parse(JSON.stringify(toDate.split("-")));
            if (new Date().getMonth() - 2 === 0) {
                newDate[1] = "12";
                newDate[0] = year - 1;
            } else if (new Date().getMonth() - 2 < 0) {
                newDate[1] = (new Date().getMonth() - 2 + 12).toString();
                newDate[0] = year - 1;
            } else {
                newDate[1] = (new Date().getMonth() - 2).toString();
            }
            if (newDate[1] < 10) {
                newDate[1] = `0${newDate[1]}`;
            }
            if (newDate[2] > 28 && newDate[1] === "02") {
                newDate[2] = `01`;
                newDate[1] = `03`
            }
            newDate = newDate.join("-");
            setFromDate(newDate);
            setToDate(newestDate);
        } else if (limit === "1Y") {
            const lastYear = Helpers.dateFormat(Helpers.getLast365Days());
            setFromDate(lastYear);
            setToDate(newestDate);
        } else if (limit === "YTD") {
            setFromDate(`${year}-1-1`);
            setToDate(newestDate);
        } else if (limit === "MAX") {
            setFromDate(oldestDate);
            setToDate(newestDate);
        }
    };

    const getList = async () => {
        try {
            let data;
            if (list.length === 0) {
                if (instrument === 'index') {
                    data = await GetEOD.maximumList(instrument, params.market, 'INDX', token);
                    setDataFetch(data);
                    setOldestDate(data[data.length - 1].date);
                    setNewestDate(data[0].date);
                    setFromDate(data[data.length - 1].date);
                    setToDate(data[0].date);
                } else {
                    data = await GetEOD.maximumList(instrument, params.detail, params.market, token)
                    setDataFetch(data)
                    setOldestDate(data[data.length - 1].date);
                    setNewestDate(data[0].date);
                    setFromDate(data[data.length - 1].date);
                    setToDate(data[0].date);
                }
            } else {

                if (instrument === 'index') {
                    data = await GetEOD.fromDate(instrument, params.market, 'INDX', token, fromDate, toDate)
                } else {
                    data = await GetEOD.fromDate(instrument, params.detail, params.market, token, fromDate, toDate)
                }
            }
            let result: any[] = [];
            setAllData(data);
            result = data

            if (result[0].price) {
                setOldestValue(result[result.length - 1].price);
                setYesterdayValue(result[1].price);
                setNewestValue(result[0].price);
            } else {
                setOldestValue(result[result.length - 1].close);
                setYesterdayValue(result[1].close);
                setNewestValue(result[0].close);
            }
            setToDate(data[0].date);
            setFromDate(data[data.length - 1].date);
            setList(result);
        } catch (error) {
            console.log(error);
        }
    };

    const chartFilterArr = [
        "1M",
        "3M",
        "1Y",
        "YTD",
        "MAX"
    ]

    return (
        <div>
            {
                instrument === 'stock' ?
                    <DetailTicket
                        instrument={instrument}
                        code={params.detail}
                        dataChart={allData}
                        filterString={filterString}
                        handleChartFilter={handleChartFilter}
                        chartFilterArr={chartFilterArr}
                        setToDate={setToDate}
                        toDate={toDate}
                        setFromDate={setFromDate}
                        fromDate={fromDate}
                        newestValue={newestValue}
                        oldestValue={oldestValue}
                        yesterdayValue={yesterdayValue}
                        dataFetch={dataFetch}
                    />
                    :
                    <></>
            }

            {
                instrument === 'index' ?
                    <DetailTicket
                        instrument='index'
                        code={params.detail}
                        dataChart={allData}
                        filterString={filterString}
                        handleChartFilter={handleChartFilter}
                        chartFilterArr={chartFilterArr}
                        setToDate={setToDate}
                        toDate={toDate}
                        setFromDate={setFromDate}
                        fromDate={fromDate}
                        newestValue={newestValue}
                        oldestValue={oldestValue}
                        yesterdayValue={yesterdayValue}
                        dataFetch={dataFetch}
                    />
                    :
                    <></>
            }

            {
                instrument === 'forex' ?
                    <DetailTicketForex 
                        code={params.detail}
                        dataChart={allData}
                        filterString={filterString}
                        handleChartFilter={handleChartFilter}
                        chartFilterArr={chartFilterArr}
                        setToDate={setToDate}
                        toDate={toDate}
                        setFromDate={setFromDate}
                        fromDate={fromDate}
                        newestValue={newestValue}
                        oldestValue={oldestValue}
                        yesterdayValue={yesterdayValue}
                        dataFetch={dataFetch}
                    />
                    :
                    <></>
            }

{
                instrument === 'crypto' ?
                    <DetailTicket
                        code={params.detail}
                        dataChart={allData}
                        filterString={filterString}
                        handleChartFilter={handleChartFilter}
                        chartFilterArr={chartFilterArr}
                        setToDate={setToDate}
                        toDate={toDate}
                        setFromDate={setFromDate}
                        fromDate={fromDate}
                        newestValue={newestValue}
                        oldestValue={oldestValue}
                        yesterdayValue={yesterdayValue}
                        dataFetch={dataFetch}
                        instrument={'crypto'}
                    />
                    :
                    <></>
            }

            {
                instrument === 'etf' ?
                    <DetailTicket
                        code={params.detail}
                        dataChart={allData}
                        filterString={filterString}
                        handleChartFilter={handleChartFilter}
                        chartFilterArr={chartFilterArr}
                        setToDate={setToDate}
                        toDate={toDate}
                        setFromDate={setFromDate}
                        fromDate={fromDate}
                        newestValue={newestValue}
                        oldestValue={oldestValue}
                        yesterdayValue={yesterdayValue}
                        dataFetch={dataFetch}
                    />
                    :
                    <></>
            }

{
                instrument === 'mutualfund' ?
                    <DetailTicket
                        code={params.detail}
                        dataChart={allData}
                        filterString={filterString}
                        handleChartFilter={handleChartFilter}
                        chartFilterArr={chartFilterArr}
                        setToDate={setToDate}
                        toDate={toDate}
                        setFromDate={setFromDate}
                        fromDate={fromDate}
                        newestValue={newestValue}
                        oldestValue={oldestValue}
                        yesterdayValue={yesterdayValue}
                        dataFetch={dataFetch}
                    />
                    :
                    <></>
            }
        </div>
    );
}

export default Detail;
