import { Tr, Tbody, Td } from "@chakra-ui/react";

const TableIndexComponents = ({ list }: any) => {
    return (
        <Tbody>
            { list.length > 0 && list.map((el: any, index: number) => {
                return (
                <Tr
                    key={index}>
                        <Td textTransform="capitalize" p="12px">{el[1].Code}</Td>
                        <Td textTransform="capitalize" p="12px">{el[1].Name}</Td>
                        <Td textTransform="capitalize" p="12px">{el[1].Exchange}</Td>
                        <Td textTransform="capitalize" p="12px">{el[1].Sector}</Td>
                        <Td textTransform="capitalize" p="12px">{el[1].Industry}</Td>
                </Tr>
                );
            })}
        </Tbody>
    );
};

export default TableIndexComponents;