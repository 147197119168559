import {
    Heading,
    Avatar,
    Box,
    Center,
    Image,
    Flex,
    Text,
    Stack,
    Button,
    useColorModeValue,
    SimpleGrid,
    List,
    ListItem,
    Icon
} from "@chakra-ui/react";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";

import Helpers from "../../hooks/helpers";

const GeneralTickers = (
    {
        dataDetail,
        newestValue,
        yesterdayValue
    } : any) => {
        console.log(dataDetail, `dataDetail`)
    return (
        <Flex flexDirection={'column'} py={6}>
            {   dataDetail.General.LogoURL && (
                <Center>
                    <Box w={'100%'} maxW={'120px'} h={'120px'}>
                        <Image
                            h={'120px'}
                            w={'full'}
                            src={`https://eodhistoricaldata.com/${dataDetail.General.LogoURL}`}
                            objectFit={'contain'}
                        />
                    </Box>
                </Center>
            )
                
            }

            <Center mt={'16px'} >
                {
                    dataDetail?.General?.CurrencyName === 'Indonesian Rupiah' && dataDetail?.General?.Exchange !== 'INDX' ?
                    (
                        <Box
                            fontSize={"24px"}
                            color={useColorModeValue("black.500", "black.300")}
                            fontWeight={600}
                            mb={"4"}
                        >
                            {Helpers.currencyFormaterIDR(newestValue)} 
                        </Box>
                    )

                    :

                    <Box
                        fontSize={"24px"}
                        color={useColorModeValue("black.500", "black.300")}
                        fontWeight={600}
                        mb={"4"}
                    >
                        {
                            Helpers.currencyFormaterMarket(newestValue)
                        }
                    </Box>
                }

                <Box
                    fontSize={"16px"}
                    color={
                        newestValue - yesterdayValue >= 0
                            ? useColorModeValue("green.500", "green.300")
                            : "rgba(224, 49, 56, 1)"
                    }
                    fontWeight={400}
                    mb={"4"}
                    ml={'8px'}
                >
                    {`${( newestValue / yesterdayValue ).toFixed(2)}%`}
                    
                    {newestValue - yesterdayValue >= 0
                        ? BiUpArrowAlt && (
                            <Icon mr="1" fontSize="14px" as={BiUpArrowAlt} />
                        )
                        : BiDownArrowAlt && (
                            <Icon mr="1" fontSize="14px" as={BiDownArrowAlt} />
                        )}
                </Box>
            </Center>

            <Box  w={'100%'} my={'24px'} border="1px" borderColor="gray.200" />

            {
                dataDetail?.General?.Type === 'Crypto' ? (
                    <Box>
                        
                        {
                            dataDetail?.General?.Name && (
                                <Flex w={'full'} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Name</Box>
                                    <Box w={'50%'}>{Helpers.isNA(dataDetail?.General?.Name)}</Box>
                                </Flex>
                            )
                        }

                        {
                            dataDetail?.General?.Category && (
                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Category</Box>
                                <Box w={'50%'}>{Helpers.isNA(dataDetail?.General?.Category)}</Box>
                            </Flex>
                            )
                        }

                        {
                            dataDetail?.General?.Type && (
                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Type</Box>
                                <Box w={'50%'}>{Helpers.isNA(dataDetail?.General?.Type)}</Box>
                            </Flex>
                            )
                        }

                        {
                            dataDetail?.General?.WebURL && (
                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>WebURL</Box>
                                <Box w={'50%'} color={'rgba(151, 133, 77, 1)'}>
                                    <a href={`${dataDetail?.General?.WebURL}`}>
                                        Link
                                    </a>
                                </Box>
                            </Flex>
                            )
                        }
                        
                    </Box>
                ) : (
                    <Box>
                        {
                            dataDetail?.General?.ISIN && (
                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>ISIN</Box>
                                <Box w={'50%'}>{Helpers.isNA(dataDetail?.General?.ISIN)}</Box>
                            </Flex>
                            )
                        }
                        
                        {
                            dataDetail?.General?.Name && (
                                <Flex w={'full'} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}> { dataDetail?.General?.Exchange === 'INDX' || dataDetail?.General?.Exchange === 'FOREX' ? 'Name' : 'Company Name'  }</Box>
                                    <Box w={'50%'}>{Helpers.isNA(dataDetail?.General?.Name)}</Box>
                                </Flex>
                            )
                        }

                        {
                            dataDetail?.General?.Code && (
                                <Flex w={'full'} my={2} fontSize={"12px"} justifyContent={'normal'}>
                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Ticker</Box>
                                    <Box w={'50%'}>{dataDetail?.General?.Code ? dataDetail?.General?.Code : "N/A"}</Box>
                                </Flex>
                            )
                        }
                        
                        {
                            dataDetail?.General?.CurrencyName && (
                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>CCY</Box>
                                    <Box w={'50%'}>
                                        {`${Helpers.isNA(dataDetail?.General?.CurrencyName)} 
                                            / 
                                        ${Helpers.isNA(dataDetail?.General?.CurrencySymbol)}`}
                                    </Box>
                                </Flex>
                            )
                        }

                        {
                            dataDetail?.General?.Exchange && (
                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Exchange</Box>
                                    <Box w={'50%'}>{dataDetail?.General?.Exchange}</Box>
                                </Flex>
                            )
                        }

                        {
                            dataDetail?.General?.Fiscal_Year_End && (
                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Fiscal Year End</Box>
                                    <Box w={'50%'}>{dataDetail?.General?.Fiscal_Year_End}</Box>
                                </Flex>
                            )
                        }

                        {
                            dataDetail?.General?.Fund_Category && (
                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Fund Category</Box>
                                    <Box w={'50%'}>{dataDetail?.General?.Fund_Category}</Box>
                                </Flex>
                            )
                        }

                        {
                            dataDetail?.General?.Fund_Style && (
                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Fund Style</Box>
                                    <Box w={'50%'}>{dataDetail?.General?.Fund_Style}</Box>
                                </Flex>
                            )
                        }

                        {
                            dataDetail?.MutualFund_Data?.Inception_Date && (
                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Inception Date</Box>
                                    <Box w={'50%'}>{dataDetail?.MutualFund_Data?.Inception_Date}</Box>
                                </Flex>
                            )
                        }

                        {
                            dataDetail?.Highlights?.MarketCapitalization && (
                            <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Market Cap</Box>
                                <Box>
                                    {/* {Helpers.isNA(dataDetail?.Highlights?.MarketCapitalization)} */}
                                    {Helpers.currencyFormaterMarket(dataDetail?.Highlights?.MarketCapitalization)} 
                                </Box>
                            </Flex>
                            )
                        }

                        {
                            dataDetail?.General?.Industry && (
                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Industry</Box>
                                    <Box>{Helpers.isNA(dataDetail?.General?.Industry)}</Box>
                                </Flex>
                            )
                        }

                        {
                            dataDetail?.General?.AddressData?.Country && (
                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Geography</Box>
                                    <Box>{Helpers.isNA(dataDetail?.General?.AddressData?.Country)}</Box>
                                </Flex>
                            )
                        }

                        {
                            dataDetail?.General?.UpdatedAt && (
                                <Flex my={2} fontSize={"12px"} justifyContent={'normal'}>
                                    <Box w={'50%'} color={"rgba(116, 116, 116, 1)"}>Last Update</Box>
                                    <Box>{Helpers.isNA(dataDetail?.General?.UpdatedAt)}</Box>
                                </Flex>
                            )
                        }
                    </Box>
                )
            }
        </Flex>
    );
};

export default GeneralTickers;