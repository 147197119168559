import { useState, useEffect } from "react";
import {
    Box,
    Center,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    useDisclosure,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Collapse,
    Checkbox, CheckboxGroup,
    Stack,
    Flex,
    Button
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import TableUser from "../../components/Tables/TableUser";
import PaginatorTableMarket from "../../components/Tables/PaginatorTableMarket";
import qoreContext from "../../common/qoreContext";
import TableUserFilter from "../../components/FilterAndSearch/TableUserFilter";
import LocalLoader from "../../components/Loader/LocalLoader";
import EmptyData from "../../components/EmptyData";
import UserAdminModal from "../../components/Modal/UserAdminModal";


const AdminUserManagement = () => {
    const user = qoreContext.useCurrentUser();
    const { insertRow, status } = qoreContext.table("users").useInsertRow();
    const { updateRow } = qoreContext.table("users").useUpdateRow();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [modalMode, setModalMode] = useState<boolean>(true);

    const [idRows, setIdRows] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [filterFor, setFilterFor] = useState<string>("");

    const [pages, setPages] = useState<number>(0);
    const [limit, setLimit] = useState<number>(25);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [data, setData] = useState<any[]>([]);
    const [image, setImage] = useState<any>("");
    const [list, setList] = useState<any[]>([]);
    const [permission, setPermission] = useState<any>([])


    function encodeImageFileAsURL(element: any) {
        try {
            let file = element.files[0];
            if (file.size > 500000) {
                throw { name: "File too Big" };
            }
            let reader = new FileReader();
            reader.onloadend = function () {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        } catch (error: any) {
            if (error.name === "File too Big") {
                onClose();
            }
        }
    }

    const form = useForm({
        defaultValues: {
            name: "",
            external_id: "",
            profile_pic: "",
            password: "",
            permission: "",
            role: "89c110db-e36a-48fa-b63d-43a3b5fc20dd",
        },
    });

    const emptyFormValue = () => {
        form.setValue("name", "");
        form.setValue("external_id", "");
        form.setValue("profile_pic", "");
        form.setValue("password", "");
        form.setValue("permission", "");
    }

    const handleAddRM = async (values: any) => {

        try {
            if (values.name && values.external_id && values.password) {
                const data: object = {
                    name: values.name,
                    external_id: values.external_id,
                    profile_pic: image,
                    password: values.password,
                    role: values.role,
                    permission: values.permission
                };
        
                const response = await insertRow(data);
        
                getList();
                emptyFormValue();
                onClose();
            } else {
                throw { error: values }
            }
        } catch (error) {
            console.log(error, `eelol`)
        }
    };

    const handlePageChange = (nextPage: number) => {
        setCurrentPage(nextPage);
    };

    useEffect(() => { getList() }, [user?.user?.id]);
    useEffect(() => { setCurrentPage(1); }, []);
    useEffect(() => { setUpFilter(modalMode) }, [filterFor]);
    useEffect(() => { setList(data) }, [data, search]);
    useEffect(() => { searchFilter(data) }, [search]);

    const setUpFilter = (modalMode: any) => {
        if (modalMode) setFilterFor("Add New User")
    }


    const getList = async () => {
        try {
            setIsLoading(true)
            const response = await qoreContext.client
                .table("users")
                .readRows()
                .toPromise();

            let result;

            if (response?.data?.nodes) {
                setPages(Math.ceil(response?.data?.nodes.length / limit));
                result = JSON.parse(JSON.stringify(response?.data?.nodes));
                setData(result);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateUser = async (el: any) => {
        let newPermission: any = []
        let permission = el.permission.split(',')
        if (permission.length > 0) {
            permission.forEach((el: any) => {
                if (el === 'true') {
                    el = true
                } else {
                    el = false
                }
                newPermission.push(el)
            });
        }
        
        setFilterFor(`Edit User ${el.name ? el.name : ""}`)

        const data: object = {
            name: el.name,
            external_id: el.external_id,
            profile_pic: el.profile_pic,
            password: el.password,
            permission: permission,
            role: el.role,
        };

        form.setValue("name", `${el.name ? el.name : `Please Fill the Name Field`}`);
        form.setValue("external_id", `${el.external_id}`);
        form.setValue("profile_pic", `${el.profile_pic}`);
        form.setValue("password", `${el.password}`);
        setImage(el.profile_pic)
        setIdRows(el.id)
        setModalMode(false)

    }

    const tryAddUser = () => {
        setModalMode(true)
        setFilterFor("Add New User")
        emptyFormValue();
        setImage("")
    }

    const handleUpdateRM = async (values: any, idRows: string) => {
        const data: object = {
            name: values.name,
            external_id: values.external_id,
            profile_pic: image,
            // password: values.password,
            permission: permission.join(','),
            role: values.role,
        };

        // console.log(values.password)

        const response = await updateRow(idRows, data);

        getList();
        emptyFormValue();
        onClose();
    }

    const afterDelete = () => {
        getList();
    }

    const searchFilter = async (data: any) => {
        let result;
        setIsLoading(true);

        if (search !== "") {
            result = await data.filter((el: any) =>
                el.name.toLowerCase().includes(search.toLowerCase())
            );
        }

        if (result) {
            setList(result)
        } else {
            setList(data)
        }

        setIsLoading(false);
    }

    const handlePermission = (params: any) => {
        setPermission(params)
    }


    return (
        <div>
            <Modal
                size={'xl'} 
                isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader
                        px='24px'
                        py='16px'
                        fontSize='16px'
                        fontWeight='600'
                        borderBottom='1px'
                        borderColor='#E9E9E9' >
                        {filterFor}
                    </ModalHeader>
                    <ModalCloseButton _focus={{ outline: 'none' }} fontSize='8px' maxW='10px' maxH='10px' mt='15px' mr='20px' />
                        <UserAdminModal
                            handlePermission={handlePermission}
                            isOpen={isOpen}
                            form={form}
                            modalMode={modalMode}
                            handleAddRM={handleAddRM}
                            handleUpdateRM={handleUpdateRM}
                            encodeImageFileAsURL={encodeImageFileAsURL}
                            idRows={idRows}
                            image={image}
                            onClose={onClose} />
                </ModalContent> 
            </Modal>

            <Center pl="3" py={6}>
                <Box
                    w={"full"}
                    bg={useColorModeValue("white", "gray.800")}
                    boxShadow={"lg"}
                    rounded={"md"}
                    px="24px"
                >
                    <Box w={{ base: "full" }}>
                        <TableUserFilter
                            buttonText={"Add New User"}
                            tryAddUser={tryAddUser}
                            setSearch={setSearch}
                            onOpen={onOpen} />
                    </Box>

                    {isLoading ? (
                        <Box py="10"> {LocalLoader()} </Box>
                    ) : (
                        <>
                            {list.length > 0 ?
                                <>
                                    <TableUser
                                        list={list}
                                        instrument={"users"}
                                        onOpen={onOpen}
                                        updateUser={updateUser}
                                        afterDelete={afterDelete} />

                                    <PaginatorTableMarket
                                        pages={pages}
                                        currentPage={currentPage}
                                        handlePageChange={handlePageChange}
                                    />
                                </>
                                :
                                <EmptyData />
                            }
                        </>
                    )}

                </Box>
            </Center>
        </div>
    );
};

export default AdminUserManagement;
