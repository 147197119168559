import {
    Flex,
    useDisclosure,
    Center,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import getAPI from "../../API/getList";
import CompareChart from "../../components/Chart/CompareChart";
import { useSelector, useDispatch } from 'react-redux';
import SearchCodeModal from '../../components/Modal/SearchCodeModal';
import CompareNoDataFound from "../../components/StatusPage/CompareNoDataFound";
import CompareAssetModal from "../../components/Modal/CompareAssetModal";
import { BiFilterAlt } from "react-icons/bi";

import GetEOD from "../../API/getEOD";
import Helpers from "../../hooks/helpers";
function Compare() {
    const [isLoadMarket, setIsLoadMarket] = useState<boolean>(false);
    const [isLoadAsset, setIsLoadAsset] = useState<boolean>(false);
    const [alredyOnWatch, setAlredyOnWatch] = useState<boolean>(false);
    const form = useForm({
        defaultValues: {
            type: "",
            name: "",
            code: "",
            currency: "",
            exchange_market: "",
        },
    });
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [insturmentList, setInstrumentList] = useState<string>("");
    const [marketList, setMarketList] = useState<string>("");
    const [marketFetch, setMarketFetch] = useState<boolean>(true);
    const [assetFetch, setAssetFetch] = useState<boolean>(true);

    const [typeData, setTypeData] = useState<any[]>([]);
    const [typeName, setTypeName] = useState<string>("");
    const [marketDataList, setMarketDataList] = useState<any[]>([]);
    const [market, setMarket] = useState<string>("");
    const [assetValue, setAssetValue] = useState<number>(0);

    const [chartData, setChartData] = useState<any>([]);

    useEffect(() => {
        getInstrumentStatus();
    }, [insturmentList]);

    useEffect(() => {
        getMarketListStatus();
    }, [marketList]);

    const getInstrumentStatus = () => {
        if (insturmentList !== "") {
            setMarketFetch(false);
        }
    };

    const getMarketListStatus = () => {
        if (marketList !== "") {
            setAssetFetch(false);
        }
    };

    const handleAddAsset = async (values: any) => {
        try {
            const params = { code: values.code, market: values.exchange_market };
            const EODValue = await getAPI.getAllEOD(params);
            let temp = [...chartData, { name: values.name, data: EODValue, currency: values.currency }];
            if (temp.length > 1) {
                temp = temp.sort((a, b) => a.data.length - b.data.length);
            }
            setChartData(temp);
            await form.setValue("code", "");
            await form.setValue("exchange_market", "");
            await form.setValue("name", "");
            await form.setValue("type", "");
            await form.setValue("currency", "");
            await setAssetFetch(true)
            await onClose();
        } catch (error) {
            console.log("error");
        }
    };

    const handleFetchList = async (value: any) => {
        if (value === "stock") {
            setTypeName("stock");
            setTypeData(await getAPI.getStockList());
        } else if (value === "ETF") {
            setTypeName("ETF");
            setTypeData(await getAPI.getETFList());
        } else if (value === "forex") {
            setTypeName("forex");
            setTypeData(await [{ Code: "FOREX", Name: "FOREX" }]);
        } else if (value === "CC") {
            setTypeName("CC");
            setTypeData(await [{ Code: "CC", Name: "Cryptocurrencies" }]);
        } else if (value === "mutualFund") {
            setTypeName("mutualFund");
            setTypeData(await getAPI.getMutualFundList());
        } else if (value === "bond") {
            setTypeName("bond");
            setTypeData(
                await [
                    { Code: "BOND", Name: "Bond Virtual Exchange & Government Bonds" },
                ]
            );
        }
    };

    const handleFetchMarket = async (value: any) => {
        let params = {
            market: value,
        };
        setMarket(value);
        if (typeName === "stock") {
            setMarketDataList(await getAPI.getStockMarket(params));
        } else if (typeName === "ETF") {
            setMarketDataList(await getAPI.getETFMarket(params));
        } else if (typeName === "forex") {
            setMarketDataList(await getAPI.getForexMarket());
        } else if (typeName === "CC") {
            setMarketDataList(await getAPI.getCryptoMarket());
        } else if (typeName === "mutualFund") {
            setMarketDataList(await getAPI.getMutualFundMarket(params));
        } else if (typeName === "bond") {
            setMarketDataList(await getAPI.getBondMarket());
        }
    };

    const handleAssetValue = async (value: any) => {
        const params = { code: value, market };
        const data = await getAPI.getEOD(params);
        if (data.close) {
            setAssetValue(data.close);
        } else {
            setAssetValue(data.price);
        }
    };

    const handleModalClose = () => {
        form.setValue("code", "");
        form.setValue("exchange_market", "");
        form.setValue("name", "");
        form.setValue("type", "");
        setMarketDataList([]);
        setInstrumentList('');
        setMarketList('')
        setMarketFetch(true);
        setAssetFetch(true);
        setTypeData([])
        setTypeName('')
        setMarketDataList([]);
        setMarket('');
        setAssetValue(0)
        onClose();
    }

    const handleModalOpen = async () => {
        form.setValue("code", "");
        form.setValue("exchange_market", "");
        form.setValue("name", "");
        form.setValue("type", "");
        setMarketDataList([]);
        setInstrumentList('');
        setMarketList('')
        setMarketFetch(true);
        setAssetFetch(true);
        setTypeData([])
        setTypeName('')
        setMarketDataList([]);
        setMarket('');
        setAssetValue(0)
        onOpen();
    }

    const handleUpdateDataChart = async (val: any) => {
        await setChartData(val);
    }

    return (
        <div>
            {/* <CompareAssetModal
                isOpen={isOpen}
                onClose={onClose}
                form={form}
                handleAddAsset={handleAddAsset}
                handleFetchList={handleFetchList}
                setInstrumentList={setInstrumentList}
                marketFetch={marketFetch}
                handleFetchMarket={handleFetchMarket}
                setMarketList={setMarketList}
                typeData={typeData}
                assetFetch={assetFetch}
                handleAssetValue={handleAssetValue}
                marketDataList={marketDataList}
                setIsLoadMarket={setIsLoadMarket}
                setIsLoadAsset={setIsLoadAsset}
                isLoadMarket={isLoadMarket}
                isLoadAsset={isLoadAsset}
                handleModalClose={handleModalClose}
            />
             */}
             <SearchCodeModal 
                modalType={'compare'}
                setAlredyOnWatch={setAlredyOnWatch}
                alredyOnWatch={alredyOnWatch}
                isOpen={isOpen}
                onClose={onClose}
                form={form}
                handleAddAsset={handleAddAsset}
                handleFetchList={handleFetchList}
                setInstrumentList={setInstrumentList}
                marketFetch={marketFetch}
                handleFetchMarket={handleFetchMarket}
                setMarketList={setMarketList}
                typeData={typeData}
                assetFetch={assetFetch}
                handleAssetValue={handleAssetValue}
                marketDataList={marketDataList}
                setIsLoadMarket={setIsLoadMarket}
                setIsLoadAsset={setIsLoadAsset}
                isLoadMarket={isLoadMarket}
                isLoadAsset={isLoadAsset}
                handleModalClose={handleModalClose}
            />
            <Center>
                {chartData?.length > 0 ? (
                    <Flex direction={"column"} w={"100%"}>
                        <CompareChart handleUpdateDataChart={handleUpdateDataChart} handleModalOpen={handleModalOpen} onOpen={onOpen} dataChart={chartData} />
                    </Flex>
                ) : (
                    <CompareNoDataFound onOpen={onOpen} />
                )}
            </Center>
        </div>
    );
}

export default Compare;
