import { useEffect, useState, useCallback } from "react";
import { Box, Center, useColorModeValue } from "@chakra-ui/react";
import TableMarketFilter from "./FilterAndSearch/TableMarketFilter";
import getAPI from "../API/getList";
import LocalLoader from "./Loader/LocalLoader";
import filterAPI from "../API/filterAPI";
import EmptyData from "./EmptyData";
import TampleTable from "./Tables/TemplateTable";
import TableMarketBody from "./Tables/TableBody/TableMarketBody";
import TableMarketListBody from "./Tables/TableBody/TableMarketListBody";

function ExchangeList({ instrument }: any) {
    const [list, setList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [allData, setAllData] = useState<any[]>([]);
    const [search, setSearch] = useState<string>("");
    const [currency, setCurrency] = useState<any[]>([]);
    const [country, setCountry] = useState<any[]>([]);
    const [exchange, setExchange] = useState<any[]>([]);
    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(25);
    const [searchCurrency, setSearchCurrency] = useState<string>("");

    const [checkedItems, setCheckedItems] = useState<any[]>([]);
    const [checkedCountry, setCheckedCountry] = useState<any[]>([]);
    const [checkedExchange, setCheckedExchange] = useState<any[]>([]);
    const allChecked = checkedItems.every(Boolean);
    const allCheckedCountry = checkedCountry.every(Boolean);
    const allcheckedExchange = checkedExchange.every(Boolean);
    const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
    const isIndeterminateCountry =
        checkedCountry.some(Boolean) && !allCheckedCountry;
    const isIndeterminateExchange =
        checkedExchange.some(Boolean) && !allcheckedExchange;

    const [currencies, setCurrencies] = useState<any[]>([]);
    const [allCurrencies, setAllCurrencies] = useState<any[]>([]);
    const [countries, setCountries] = useState<any[]>([]);
    const [allExchanges, setAllExchanges] = useState<any[]>([]);
    const [exchanges, setExchanges] = useState<any[]>([]);
    const [allCountries, setAllCountries] = useState<any[]>([]);

    function onlyUnique(value: any, index: number, self: any) {
        return self.indexOf(value) === index;
    }

    useEffect(() => {
        getList();
    }, [currency, country, exchange, currentPage]);

    useEffect(() => {
        handleSearch();
    }, [search, currentPage]);

    const handleSearch = async () => {
        if (allData.length > 0) {
            let newList: any = []
            if (search === '') {
                setPages(Math.ceil(allData.length / limit));
                allData.forEach((el: any) => {
                    newList.push(el);
                })
                if (limit > 0) {
                    newList = newList.splice((currentPage - 1) * limit, limit);
                }
                
                setList(newList);
            } else {
                let allSearch: any = []
                let searchListByName: any = [];
                let searchListByCode: any = [];

                allData.forEach((el: any) => {
                    if (el.Name.toLowerCase().includes(search.toLowerCase())) {
                        searchListByName.push(el);
                    } else if (el.Code.toLowerCase().includes(search.toLowerCase())) {
                        searchListByCode.push(el);
                    }
                })

                if (searchListByName.length > 0) {
                    searchListByName.forEach((el: any) => {
                        allSearch.push(el)
                    })
                } 
                if (searchListByCode.length > 0) {
                    searchListByCode.forEach((el: any) => {
                        allSearch.push(el)
                    })
                } 

                setPages(Math.ceil(allSearch.length / limit));
                setCurrentPage(1);
                setList(allSearch);
            }
        }
    }

    const fetchFilterData = useCallback(async () => {
        if (allData.length > 0) {
            const data = await filterAPI.allFilterData(allData, searchCurrency);
            if ( allCurrencies.length === 0 && allCountries.length === 0 && allExchanges.length === 0 ) {
                setAllCurrencies(data ? (data[0] ? data[0] : []) : []);
                setAllCountries(data ? (data[1] ? data[1] : []) : []);
                setAllExchanges(data ? (data[2] ? data[2] : []) : []);
                if (allExchanges.length > 0) {
                    if (allExchanges[2][0] === 'INDX' && allExchanges[2][1] === 'INDX') {
                        setAllExchanges([]);
                    }
                }
            };

            if (allExchanges.length > 0) {
                if (allExchanges[0] === 'INDX' || allExchanges[0] === 'FOREX') {
                    setAllExchanges([]);
                };
            }

            if (allCurrencies.length > 0) {
                setAllCurrencies(data ? (data[0] ? data[0] : []) : []);
            }

            if (allCountries.length > 0) {
                setAllCountries(data ? (data[1] ? data[1] : []) : []);
            }

            if (instrument === "bonds") {
                setCurrencies(data ? data[0] : []);
                setCountries(data ? data[1] : []);
                setExchanges(data ? data[2] : []);
            } else if (instrument === "stock") {
                setCurrencies(data ? data[0] : []);
                setCountries(data ? data[1] : []);
            } else if (instrument === "ETF") {
                setCurrencies(data ? data[0] : []);
            } else if (instrument === "crypto") {
                setCurrencies(data ? data[0] : []);
            } else if (instrument === "forex") {
                setCurrencies(data ? data[0] : []);
                setCountries(data ? data[1] : []);
                setExchanges([
                    'Dollar',
                    'Euro',
                    'Other'
                ]);

                setAllExchanges([
                    'Dollar',
                    'Euro',
                    'Other'
                ]);
                
            } else if (instrument === "fund") {
                setCurrencies(data ? data[0] : []);
                setCountries(data ? data[1] : []);
            } else if (instrument === 'index') {
                setCurrencies(data ? data[0] : []);
                setCountries(data ? data[1] : []);
                setExchanges([
                    'US Markets',
                    'Europe Markets',
                    'Asia Markets',
                    'Other'
                ]);

                setAllExchanges([
                    'US Markets',
                    'Europe Markets',
                    'Asia Markets',
                    'Other'
                ]);
            }
        } 
    }, [allData, searchCurrency]);

    useEffect(() => {
        if (allData.length > 0) {
            fetchFilterData();
        }
    }, [fetchFilterData, allData.length]);

    const fetchFilterChecked = useCallback(async () => {
        if (instrument !== 'index') {
            if (currencies.length > 0 && countries.length > 0 && exchanges.length > 0) {
                const data = await filterAPI.allFilterChecked(
                    currencies,
                    countries,
                    exchanges
                );

                if (
                    checkedCountry.length === 0 &&
                    checkedItems.length === 0 &&
                    checkedExchange.length === 0
                ) {
                    setCheckedItems(data ? data[0] : []);
                    setCheckedCountry(data ? data[1] : []);
                    setCheckedExchange(data ? data[2] : []);
                }
            } else if (
                currencies.length > 0 &&
                countries.length > 0 &&
                exchanges.length === 0
            ) {
                const data = await filterAPI.allFilterChecked(currencies, countries, []);
                if (checkedCountry.length === 0 && checkedItems.length === 0) {
                    setCheckedItems(data ? data[0] : []);
                    setCheckedCountry(data ? data[1] : []);
                }
            } else if (
                currencies.length > 0 &&
                countries.length === 0 &&
                exchanges.length === 0
            ) {
                const data = await filterAPI.allFilterChecked(currencies, [], []);
                if (checkedItems.length === 0) {
                    setCheckedItems(data ? data[0] : []);
                }
            } else if (
                currencies.length === 0 &&
                countries.length > 0 &&
                exchanges.length === 0
            ) {
                const data = await filterAPI.allFilterChecked([], countries, []);
                if (checkedCountry.length === 0) {
                    setCheckedCountry(data ? data[0] : []);
                }
            }
        } else {
            // setCheckedItems(allData[0]);
            const data = await filterAPI.allFilterChecked(
                currencies,
                countries,
                exchanges
            );
        }
    }, [countries, currencies, exchanges]);

    useEffect(() => {
        fetchFilterChecked();
    }, [fetchFilterChecked]);

    const handlePageChange = (nextPage: number) => {
        setCurrentPage(nextPage);
    };

    const setCurrencyValues = (value: string) => {
        if (currency.find((el: any) => el === value)) {
            setCurrency(currency.filter((el: any) => el !== value));
        } else {
            setCurrency([...currency, value]);
        }
    };

    const setCountryValues = (value: string) => {
        if (country.find((el: any) => el === value)) {
            setCountry(country.filter((el: any) => el !== value));
        } else {
            setCountry([...country, value]);
        }
    };

    const setExchangeValues = (value: string) => {
        if (instrument !== 'index') {
            if (exchange.find((el: any) => el === value)) {
                setExchange(exchange.filter((el: any) => el !== value));
            } else {
                setExchange([...exchange, value]);
            }
        } else {
            if (exchange.find((el: any) => el === value)) {
                setExchange(exchange.filter((el: any) => el !== value));
            } else {
                setExchange([...exchange, value]);
            }
        }
    };

    const getList = async () => {
        try {
            let data;
            setIsLoading(true);
            if (allData.length > 0) {
                data = JSON.parse(JSON.stringify(allData));
            } else {
                if (instrument === "bonds") {
                    data = await getAPI.getBondMarket();
                } else if (instrument === "stock") {
                    data = await getAPI.getStockList();
                } else if (instrument === "ETF") {
                    data = await getAPI.getETFList();
                } else if (instrument === "crypto") {
                    data = await getAPI.getCryptoMarket();
                } else if (instrument === "forex") {
                    data = await getAPI.getForexMarket();
                } else if (instrument === "mutual-funds") {
                    data = await getAPI.getMutualFundList();
                } else if (instrument === 'index') {
                    data = await getAPI.getIndex();
                }
                setAllData(data);
            }

            let result;

            if (instrument === 'forex') {
                result = await filterAPI.forexRegion(
                    country,
                    currency,
                    exchange,
                    data,
                    search
                );
            } else {
                result = await filterAPI.filterList(
                    country,
                    currency,
                    exchange,
                    data,
                    search
                );
            }

            if (result) {
                setPages(Math.ceil(result.length / limit));

                if (limit > 0) {
                    result = result.splice((currentPage - 1) * limit, limit);
                }
                setList(result);
                setIsLoading(false);   
            }
        } catch (error) {
            console.log(error);
        }
    };

    const ExchangeMarketTableHead = [
        "Name",
        "Code",
        "OperatingMIC",
        "Country",
        "Currency"
    ]

    let MarketListTableHead: any;

    if (instrument === 'bonds') {
        MarketListTableHead = [
            "Code",
            "Name",
            "Country",
            "Exchange",
            "Currency"
        ];
    } else if (instrument === 'forex'){
        MarketListTableHead = [
            "Code",
            "Name",
            "Currency"
        ];  
    } else if (instrument === 'crypto'){
        MarketListTableHead = [
            "Code",
            "Name",
        ]; 
    } else if (instrument === 'index'){
        MarketListTableHead = [
            "Code",
            "Name",
            "Country",
            "Currency"
        ];
    }


    return (
        <div>
            <Center py={6}>
                <Box
                    w={"full"}
                    bg={useColorModeValue("white", "gray.800")}
                    boxShadow={"lg"}
                    rounded={"md"}
                    px="24px"
                >
                    <Box w={{ base: "full" }}>
                        <TableMarketFilter
                            instrument={instrument}
                            setSearch={setSearch}
                            setSearchCurrency={setSearchCurrency}
                            allChecked={allChecked}
                            isIndeterminate={isIndeterminate}
                            currencies={
                                currencies ? (currencies.length > 0 ? currencies : []) : []
                            }
                            countries={
                                countries ? (countries.length > 0 ? countries : []) : []
                            }
                            exchanges={
                                exchanges ? (exchanges.length > 0 ? exchanges : []) : []
                            }
                            setCheckedItems={setCheckedItems}
                            setCheckedCountry={setCheckedCountry}
                            checkedItems={checkedItems}
                            setCurrency={setCurrency}
                            setCurrencyValues={setCurrencyValues}
                            allCheckedCountry={allCheckedCountry}
                            checkedCountry={checkedCountry}
                            setCountry={setCountry}
                            setCountryValues={setCountryValues}
                            setExchange={setExchange}
                            setExchangeValues={setExchangeValues}
                            setCheckedExchange={setCheckedExchange}
                            checkedExchange={checkedExchange}
                            allcheckedExchange={allcheckedExchange}
                            searchCurrency={searchCurrency}
                            allCountries={allCountries}
                            allCurrencies={allCurrencies}
                            allExchanges={allExchanges}
                            isIndeterminateCountry={isIndeterminateCountry}
                            isIndeterminateExchange={isIndeterminateExchange}
                        />
                    </Box>

                    {isLoading ? (
                        <Box py="10"> {LocalLoader()} </Box>
                    ) : (
                        <>
                            {list.length > 0 ? (
                                <>
                                    {
                                        instrument === "stock" ||
                                            instrument === "ETF" ||
                                            instrument === "mutual-funds" ? (
                                            <TampleTable
                                                instrument={instrument}
                                                customTableHead={'index'}
                                                tableHeadList={ExchangeMarketTableHead}
                                                pages={pages}
                                                currentPage={currentPage}
                                                handlePageChange={handlePageChange}
                                            >
                                                <TableMarketBody list={list} instrument={instrument} />
                                            </TampleTable>
                                        ) : (
                                            <TampleTable
                                                instrument={instrument}
                                                customTableHead={'index'}
                                                tableHeadList={MarketListTableHead}
                                                pages={pages}
                                                currentPage={currentPage}
                                                handlePageChange={handlePageChange}
                                            >
                                                <TableMarketListBody list={list} instrument={instrument} />
                                            </TampleTable>
                                        )}
                                </>
                            ) : (
                                <EmptyData />
                            )}
                        </>
                    )}
                </Box>
            </Center>
        </div>
    );
}

export default ExchangeList;
