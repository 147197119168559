import qoreContext from "../qoreContext";
import {
    Box,
    useColorModeValue,
    Center,
    Flex,
    Button
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import TitleBoxDetail from '../../components/DetailBox/TitleBoxDetail';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);


import { BiBarChartAlt2 } from "react-icons/bi";
import Helpers from "../../hooks/helpers";
import HorizontalChart from "../../components/Chart/HorizontalChart";

function PortofolioSummary({ customer, customerData }: any) {
    const user = qoreContext.useCurrentUser();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [noDataFound, setNoDataFound] = useState<boolean>(true);

    const [search, setSearch] = useState<string>("");

    const [limit, setLimit] = useState<number>(25);
    const [pages, setPages] = useState<number>(0);

    const [filter, setFilter] = useState<any>("MAX");

    const [data, setData] = useState<any[]>([]);
    const [allLabel, setAllLabel] = useState<any[]>([]);
    const [label, setLabel] = useState<any[]>([]);
    const [stock, setStock] = useState<any[]>([]);
    const [ETF, setETF] = useState<any[]>([]);
    const [forex, setForex] = useState<any[]>([]);
    const [CC, setCC] = useState<any[]>([]);
    const [mutualFund, setMutualFund] = useState<any[]>([]);
    const [bond, setBond] = useState<any[]>([]);

    const [topPerformer, setTopPerformer] = useState<any[]>([])
    const [topPerformerDataChart, setTopPerformerDataChart] = useState<object>({
        labels: [],
        datasets: []
    })

    const monthFormat = (val: any) => {
        if (val == "01") return "Jan";
        if (val == "02") return "Feb";
        if (val == "03") return "Mar";
        if (val == "04") return "Apr";
        if (val == "05") return "May";
        if (val == "06") return "Jun";
        if (val == "07") return "Jul";
        if (val == "08") return "Aug";
        if (val == "09") return "Sep";
        if (val == "10") return "Oct";
        if (val == "11") return "Nov";
        if (val == "12") return "Dec";
    };

    const getData = async () => {
        try {
            setIsLoading(true);
            if (user?.user?.id) {
                let result;
                const response = await qoreContext.client
                    .table("asset_summary")
                    .readRows({ condition: { rm_id_asset_summary: user?.user?.id } })
                    .toPromise();
                if (response?.data?.nodes) {
                    setPages(Math.ceil(response?.data?.nodes.length / limit));

                    result = JSON.parse(JSON.stringify(response?.data?.nodes));
                    if (search !== "") {
                        result = result.filter((el: any) =>
                            el.name.toLowerCase().includes(search.toLowerCase())
                        );
                    }
                    console.log(result, `result`)
                    setData(result);
                    if (response?.data?.nodes.length > 0) {
                        setNoDataFound(false);
                    }
                    setIsLoading(false);
                }
            }
        } catch (error) { }
    };

    let existingMonth: any[] = [];

    const setChartDataValue = async () => {
        let tempLabel: any[] = [];
        let tempStock: any[] = [];
        let tempETF: any[] = [];
        let tempBond: any[] = [];
        let tempForex: any[] = [];
        let tempCC: any[] = [];
        let tempMutualFund: any[] = [];
        let tempData: any = { }
    
        data.forEach((el: any, index: number) => {
            if (el.date_summary) {
                let flag = false;
                existingMonth.forEach((le: any) => {
                    if (`${le.split("-")[0]}` === `${monthFormat(el.date_summary.split("-")[1])} ${el.date_summary.split("-")[0]}`) {
                        flag = true;
                    }
                });

                if (!flag) {
                    tempLabel.push(`${monthFormat(el.date_summary.split("-")[1])} ${el.date_summary.split("-")[0]}`);
                    existingMonth.push(`${monthFormat(el.date_summary.split("-")[1])} ${el.date_summary.split("-")[0]}`);

                }

                if(tempData[`${monthFormat(el.date_summary.split("-")[1])} ${el.date_summary.split("-")[0]}`]){
                    tempData[`${monthFormat(el.date_summary.split("-")[1])} ${el.date_summary.split("-")[0]}`].push(el)
                } else {
                    tempData[`${monthFormat(el.date_summary.split("-")[1])} ${el.date_summary.split("-")[0]}`] = []
                    tempData[`${monthFormat(el.date_summary.split("-")[1])} ${el.date_summary.split("-")[0]}`].push(el)
                }
            }
        });

        const filteredCustomer = customerData.filter((obj: any) => customer.includes(obj.name));

        existingMonth.forEach((el: any) => {
            let totalStock = 0
            let totalEtf = 0
            let totalBonds = 0
            let totalCC = 0
            let totalForex = 0
            let totalMutualFund = 0
            tempData[el].forEach((le: any) => {
                filteredCustomer.forEach((customer: any) => {
                    if (le.customer_id === customer.id && 
                        `${el.split("-")[0]}` === `${monthFormat(le.date_summary.split("-")[1])} ${le.date_summary.split("-")[0]}`) {
                        if (le.type.toLowerCase() === 'stock' || le.type.toLowerCase() === 'common stock') {
                            totalStock += Number(le.eod_usd_price * le.amount)
                        } else if (le.type.toLowerCase() === 'etf') {
                            totalEtf += Number(le.eod_usd_price * le.amount)
                        } else if (le.type.toLowerCase() === 'cc') {
                            totalCC += Number(le.eod_usd_price * le.amount)
                        } else if (le.type.toLowerCase() === 'forex') {
                            totalForex += Number(le.eod_usd_price * le.amount)
                        } else if (le.type.toLowerCase()=== 'bond') {
                            totalBonds += Number(le.eod_usd_price * le.amount)
                        } else if (le.type.toLowerCase() === 'mutualfund') {
                            totalMutualFund += Number(le.eod_usd_price * le.amount)
                        }
                    }
                })
            })

            tempStock.push(totalStock)
            tempETF.push(totalEtf)
            tempBond.push(totalBonds)
            tempForex.push(totalForex)
            tempCC.push(totalCC)
            tempMutualFund.push(totalMutualFund)

        })
   
        if (filter === "1M") {
            tempStock = tempStock.splice(tempStock.length - 1, 1);
            tempETF = tempETF.splice(tempETF.length - 1, 1);
            tempMutualFund = tempMutualFund.splice(tempMutualFund.length - 1, 1);
            tempCC = tempCC.splice(tempCC.length - 1, 1);
            tempBond = tempBond.splice(tempBond.length - 1, 1);
            tempForex = tempForex.splice(tempForex.length - 1, 1);
            tempLabel = tempLabel.splice(tempLabel.length - 1, 1);
        } else 
        if (filter === "3M") {
            if (existingMonth.length > 3) {
                tempStock = tempStock.splice(tempStock.length - 3, 3);
                tempETF = tempETF.splice(tempETF.length - 3, 3);
                tempMutualFund = tempMutualFund.splice(tempMutualFund.length - 3, 3);
                tempCC = tempCC.splice(tempCC.length - 3, 3);
                tempBond = tempBond.splice(tempBond.length - 3, 3);
                tempForex = tempForex.splice(tempForex.length - 3, 3);
                tempLabel = tempLabel.splice(tempLabel.length - 3, 3);
            }
        } else if (filter === "6M") {
            tempStock = tempStock.splice(tempStock.length - 6, 6);
            tempETF = tempETF.splice(tempETF.length - 6, 6);
            tempMutualFund = tempMutualFund.splice(tempMutualFund.length - 6, 6);
            tempCC = tempCC.splice(tempCC.length - 6, 6);
            tempBond = tempBond.splice(tempBond.length - 6, 6);
            tempForex = tempForex.splice(tempForex.length - 6, 6);
            tempLabel = tempLabel.splice(tempLabel.length - 6, 6);
        } else if (filter === "1Y") {
            tempStock = tempStock.splice(tempStock.length - 12, 12);
            tempETF = tempETF.splice(tempETF.length - 12, 12);
            tempMutualFund = tempMutualFund.splice(tempMutualFund.length - 12, 12);
            tempCC = tempCC.splice(tempCC.length - 12, 12);
            tempBond = tempBond.splice(tempBond.length - 12, 12);
            tempForex = tempForex.splice(tempForex.length - 12, 12);
            tempLabel = tempLabel.splice(tempLabel.length - 12, 12);
        }

        await getCustomers();
        setStock(tempStock);
        setETF(tempETF);
        setMutualFund(tempMutualFund);
        setCC(tempCC);
        setBond(tempBond);
        setForex(tempForex);
        setLabel(tempLabel);
        if (filter === "MAX") {
            setAllLabel(tempLabel);
        }
    };

    const getCustomers = async () => {
        try {
            if (customer.length > 0) {
                if (customerData.length > 0) {
                    let totalNet = 0
                    let totalStock = 0
                    let stockList: any = []
                    let totalEtf = 0
                    let EtfList: any = []
                    let totalCrypto = 0
                    let ccList: any = []
                    let totalBonds = 0
                    let bondsList: any = []
                    let totalMutualFunds = 0
                    let mutualFundsList: any = []
                    let totalForex = 0
                    let forexList: any = []

                    const topSixData = customerData.filter((obj: any) => customer.includes(obj.name));

                    if (topSixData.length > 0) {
                        topSixData.forEach((el: any) => {
                            el.customer_id_items.forEach((item : any) => {
                                if (item.type.toLowerCase() === 'stock' || item.type === 'Common Stock') {
                                    totalStock += Number(item.updated_usd_value * item.amount);
                                    stockList.push(item)
                                } else if(item.type.toLowerCase() === 'etf'){
                                    totalEtf += Number(item.updated_usd_value * item.amount)
                                    EtfList.push(item)
                                } else if(item.type.toLowerCase() === 'cc'){
                                    totalCrypto += Number(item.updated_usd_value * item.amount)
                                    ccList.push(item)
                                } else if(item.type.toLowerCase() === 'mutualfund'){
                                    totalMutualFunds += Number(item.updated_usd_value * item.amount)
                                    bondsList.push(item)
                                } else if(item.type.toLowerCase() === 'bond'){
                                    totalBonds += Number(item.updated_usd_value * item.amount)
                                    mutualFundsList.push(item)
                                } else if(item.type.toLowerCase() === 'forex'){
                                    totalForex += Number(item.updated_usd_value * item.amount)
                                    forexList.push(item)
                                }
                            })
                        })
                    }

                    // console.log(totalStock, `totalStock`)

                    totalNet = Number(totalStock + totalEtf + totalCrypto + totalBonds + totalMutualFunds + totalForex)

                    let topPerformer = [
                        {   
                            type: 'Stock',
                            totalValue: totalStock,
                            percentage: Number(Helpers.percentagePart(totalStock, totalNet)),
                            list: stockList
                        },
                        {   
                            type: 'ETF',
                            totalValue: totalEtf,
                            percentage: totalEtf > 0 ? Number(Helpers.percentagePart(totalEtf, totalNet)) : 0,
                            list: EtfList
                        },
                        {   
                            type: 'CC',
                            totalValue: totalCrypto,
                            percentage: totalCrypto > 0 ? Number(Helpers.percentagePart(totalCrypto, totalNet)) : 0,
                            list: ccList
                        },
                        {   
                            type: 'BONDS',
                            totalValue: totalBonds,
                            percentage: totalBonds > 0 ? Number(Helpers.percentagePart(totalBonds, totalNet)) : 0,
                            list: bondsList
                        },
                        {   
                            type: 'Mutual Funds',
                            totalValue: totalMutualFunds,
                            percentage: totalMutualFunds > 0 ? Number(Helpers.percentagePart(totalMutualFunds, totalNet)) : 0,
                            list: mutualFundsList
                        },
                        {   
                            type: 'FOREX',
                            totalValue: totalForex,
                            percentage: totalForex > 0 ? Number(Helpers.percentagePart(totalForex, totalNet)) : 0,
                            list: forexList
                        },
                    ]

                    totalNet = Number(totalStock + totalEtf + totalCrypto + totalBonds + totalMutualFunds + totalForex);
                    const sortData = topPerformer.sort((a: any, b: any) => a.percentage - b.percentage).reverse();

                    let topPerformerChartData = [
                        {
                            label: [sortData[0].type],
                            data: [sortData[0].percentage],
                            backgroundColor: "rgba(0, 101, 255, 1)",
                        },
                        {
                            label: [sortData[1].type],
                            data: [sortData[1].percentage],
                            backgroundColor: "rgba(77, 147, 255, 1)",
                        },
                        {
                            label: [sortData[2].type],
                            data: [sortData[2].percentage],
                            backgroundColor: "rgba(131, 89, 223, 1)",
                        },
                        {
                            label: [sortData[3].type],
                            data: [sortData[3].percentage],
                            backgroundColor: "rgba(168, 139, 233, 1)",
                        },
                        {
                            label: [sortData[4].type],
                            data: [sortData[4].percentage],
                            backgroundColor: "rgba(112, 184, 100, 1)",
                        },
                        {
                            label: [sortData[5].type],
                            data: [sortData[5].percentage],
                            backgroundColor: "rgba(173, 214, 167, 1)",
                        },
                    ];
                    await setTopPerformer(topPerformerChartData);

                    let dataHorizontalChart: object = {
                        labels: [
                                `Performer`,
                        ],
                        datasets: topPerformerChartData,
                    }

                    await setTopPerformerDataChart(dataHorizontalChart)
                }
            }
        } catch (error) { }
    };

    let datasets = [
        {
            label: "Stock",
            data: stock,
            backgroundColor: "rgba(0, 101, 255, 1)",
        },
        {
            label: "ETF",
            data: ETF,
            backgroundColor: "rgba(77, 147, 255, 1)",
        },
        {
            label: "CC",
            data: CC,
            backgroundColor: "rgba(131, 89, 223, 1)",
        },
        {
            label: "Forex",
            data: forex,
            backgroundColor: "rgba(168, 139, 233, 1)",
        },
        {
            label: "Mutual Fund",
            data: mutualFund,
            backgroundColor: "rgba(112, 184, 100, 1)",
        },
        {
            label: "Bonds",
            data: bond,
            backgroundColor: "rgba(173, 214, 167, 1)",
        },
    ];

    const chartData: any = {
        labels: label,
        datasets: datasets,
    };

    const options: any= {
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    padding: 20
                },
                align: 'center',
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        return ` ${context.dataset.label}: ${Helpers.currencyFormaterUSD(context.raw)}`
                    },
                },
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    useEffect(() => {
        getData();
    }, [user?.user?.id, customerData.length, customer.length]);

    useEffect(() => {
        getCustomers();
    }, [customer.length, customerData.length])

    useEffect(() => {
        setChartDataValue();
    }, [data, filter]);

    const chartFilterArr = [
        "1M",
        "3M",
        "6M",
        "YTD",
        "1Y",
        "5Y",
        "MAX",
    ]

    const handleActiveBUtton = (params: any) => {
        setFilter(params)
    }

    // console.log(chartData, `chartData`)

    return (
        <Center px={'10px'}>
            <Flex direction={"column"} w={"100%"}>
                <Box py="10px">
                    <Box
                        w={"full"}
                        bg={useColorModeValue("white", "gray.800")}
                        boxShadow={"lg"}
                        rounded={"md"}
                        px="0"
                        mt={'24px'}
                    >
                        <Box px={'24px'}>
                            <TitleBoxDetail
                                fsIcon={"20px"}
                                iconTitle={BiBarChartAlt2}
                                boxTitle={`Summary Chart`}
                            />
                        </Box>
                        
                        <Box w={{ base: "full" }} px={"24px"} pb={'24px'}>
                            <Center mb={'44px'} mt={'24px'} mx={'auto'}>
                                {
                                    chartFilterArr.map((el: any, index: number) => {
                                        return (
                                            <Button
                                                key={index}
                                                backgroundColor={filter === el ? 'rgba(233, 233, 233, 1)' : 'transparent'}
                                                _focus={{outline: 'none'}}
                                                _hover={{backgroundColor: 'rgba(233, 233, 233, 1)', transform: 'scale(1.14)'}}
                                                fontSize={'12px'}
                                                h={'32px'}
                                                w={'32px'}
                                                mr="5px" onClick={(e) => {
                                                    setFilter(el);
                                                    handleActiveBUtton(el);
                                                }}>
                                                {el}
                                            </Button>
                                        )
                                    })
                                }
                            </Center>

                            <Bar data={chartData} options={options}></Bar>
                        </Box>
                    </Box>
                </Box>

                {
                    topPerformer.length > 0 && topPerformerDataChart && (
                        <HorizontalChart chartData={topPerformerDataChart} options={options} />
                    )
                }

                
            </Flex>
        </Center>
    );
}

export default PortofolioSummary;
