import {
    Box,
    Flex,
    Badge,
    VStack,
    StackDivider,
    Text,
} from "@chakra-ui/react";
import InfiniteScroll from 'react-infinite-scroll-component';

const DefaultListBox = ({list, handleSelectedItemsChange, currentData}: any) => {
    const fetchMoreData = () => {
        setTimeout(() => {
            list.concat(Array.from({ length: 20 }))
        }, 500);
    };
    return (
        <VStack
            divider={<StackDivider borderColor='gray.200' />}
            spacing={4}
            align='stretch'
            mt={1}
            className="filter-checkbox-wrapper"
            overflowY="scroll"
            maxH="400px"
        >
            {
                list && list.length > 0 && (
                    <Box>
                        <InfiniteScroll
                            dataLength={list.length}
                            next={fetchMoreData}
                            hasMore={true}
                            loader={<h4></h4>}
                            >
                            {
                                list.map((el: any, index: number) => {
                                    return (
                                        <Box 
                                            py={3} key={index} 
                                            onClick={() => handleSelectedItemsChange(el)}
                                            cursor={'pointer'}
                                            _hover={{
                                                backgroundColor: "rgba(249, 247, 242, 1)",
                                            }}>
                                            <Flex flexDir="row" justifyContent={'space-between'}>

                                                {/* <Badge rounded={'full'} p={'2px 5px'} fontSize={'12px'}>{el.Code}</Badge> */}
                                                <Box>
                                                    <Flex alignItems={'center'} flexDir="row">
                                                        <Badge 
                                                            fontWeight={400}
                                                            rounded={'md'} 
                                                            p={'2px 5px'} 
                                                            variant={'outline'}
                                                            colorScheme={'blue'}>
                                                            {el.Code}
                                                        </Badge>
                                                        <Text mx={'5px'} fontSize={'12px'} textTransform={'capitalize'} >{el.Name?.toLowerCase()}</Text>
                                                    </Flex>
                                                </Box>
                                                <Box mr={'5px'} ml={'auto'}>
                                                    <Badge 
                                                        fontWeight={400}
                                                        rounded={'md'} 
                                                        p={'2px 5px'} 
                                                        borderColor={'rgba(231, 231, 231, 1)'}
                                                        color={'black'}
                                                        fontSize={'12px'}
                                                        variant={'outline'}>
                                                        {el.Exchange}
                                                    </Badge>
                                                </Box>
                                                <Box mr={0} ml={0}>
                                                    <Badge 
                                                        fontWeight={400}
                                                        rounded={'md'} 
                                                        p={'2px 5px'} 
                                                        colorScheme='green' 
                                                        fontSize={'12px'}
                                                        color={'green'}
                                                        variant='subtle'>
                                                        {el.Currency}
                                                    </Badge>
                                                </Box>
                                                {/* <Badge rounded={'full'} p={'2px 5px'} variant='subtle' colorScheme='teal' fontSize={'12px'}>{el.Currency}</Badge> */}
                                            </Flex>
                                        </Box>
                                    )
                                })
                            }
                        </InfiniteScroll>
                    </Box>
                )
            }
        </VStack>
    );
};

export default DefaultListBox;