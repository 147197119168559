import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Thead, Tr, Tbody, Td, Image, Avatar } from "@chakra-ui/react";
import qoreContext from "../../common/qoreContext";
import FundAction from "./ActionColumn/FundAction";
import Helpers from "../../hooks/helpers";
import { FaUser } from "react-icons/fa";
import FundManagementTableHead from "./TableHead/FundManagementTableHead";

function FundManagementTable({
  list,
  addFund,
  takeFund,
  getCustomerData,
}: any) {
  const user = qoreContext.useCurrentUser();
  const nav = useNavigate();
  const [limit, setLimit] = useState<number>(25);
  const [pages, setPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<any[]>([]);
  const [customerId, setCustomerId] = useState<number>(0);

  const getData = async (id: any) => {
    const response = await qoreContext.client
      .table("assets")
      .readRows({ condition: { customer_id: id } })
      .toPromise();

    if (response?.data?.nodes) {
      setData(response?.data?.nodes);
    }
  };

  const [assets, setAssets] = useState<any[]>([]);

  const handleDetail = (params: string) => {
    nav(params);
  };

  return (
    <Table variant="simple" size="md" fontSize="12px">
      <Thead bg="rgba(246, 246, 246, 1)">
        <FundManagementTableHead />
      </Thead>
      <Tbody>
        {list.map((el: any, index: number) => {
          let allocatedFunds: number = 0;
          el.customer_id_items.forEach((le: any) => {
            allocatedFunds += le.amount * le.updated_usd_value;
          });
          console.log(Helpers.currencyFormaterUSD(
            el.unallocated_funds - el.allocated_funds + allocatedFunds
          ))
          return (
            <Tr key={index}>
              <Td p="12px" w="60px" maxW="40px">
                {el.avatar ? (
                  <Image src={el.avatar} mx="auto" />
                ) : (
                  <Avatar
                    className="default-profile"
                    size={"sm"}
                    icon={<FaUser />}
                  />
                )}
              </Td>
              <Td p="12px">{el.name}</Td>
              <Td p="12px">{el.customer_id_items.length}</Td>
              <Td p="12px">
                {Helpers.currencyFormaterUSD(
                  el.unallocated_funds - el.allocated_funds
                )}
              </Td>
              <Td p="12px">{Helpers.currencyFormaterUSD(allocatedFunds)}</Td>
              <Td p="12px">
                  
                {Helpers.currencyFormaterUSD(
                  el.unallocated_funds - el.allocated_funds + allocatedFunds
                )}
              </Td>
              <Td p="12px">{Helpers.currencyFormaterUSD(el.funds)}</Td>
              <FundAction
                open1={addFund.onOpen}
                open2={takeFund.onOpen}
                el={el}
                getCustomerData={getCustomerData}
                takeFund={el.unallocated_funds - el.allocated_funds}
              />
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

export default FundManagementTable;
