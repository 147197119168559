import {
  Box,
  Center,
  useColorModeValue,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { BsCardList } from "react-icons/bs";
import TitleBoxDetail from "./TitleBoxDetail";

function DetailCustomerAsset(
    {unallocatedFunds, allocatedFunds, totalFunds, profit, initialFunds} : any) {

    return (
        <div>
            <Center pl="3" py={6}>
                <Box
                    w={"full"}
                    bg={useColorModeValue("white", "gray.800")}
                    boxShadow={"lg"}
                    rounded={"md"}
                    px="24px"
                >
                    <TitleBoxDetail 
                    fsIcon={'16'}
                    iconTitle={BsCardList}
                    boxTitle={'Asset Detail'}/>
                    
                    <Box>
                        <Flex pb="20px" alignItems="center" direction="row">
                            <Box w="30%">
                                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                                    <List spacing={2}>
                                        <ListItem fontSize={'12px'}>Unallocated Funds</ListItem>
                                        <ListItem fontSize={'12px'}>Allocated Funds</ListItem>
                                        <ListItem fontSize={'12px'}>Total Funds</ListItem>
                                        <ListItem fontSize={'12px'}>Initial Funds</ListItem>
                                        <ListItem fontSize={'12px'}>Profit</ListItem>
                                    </List>
                                    <List spacing={2}>
                                        <ListItem fontSize={'12px'}> {unallocatedFunds} </ListItem>
                                        <ListItem fontSize={'12px'}> {allocatedFunds} </ListItem>
                                        <ListItem fontSize={'12px'}>{totalFunds}</ListItem>
                                        <ListItem fontSize={'12px'}> {initialFunds} </ListItem>
                                        <ListItem fontSize={'12px'}>{profit}</ListItem>
                                    </List>
                                </SimpleGrid>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Center>
        </div>
    );
}

export default DetailCustomerAsset;
