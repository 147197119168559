import axios from 'axios';
import { useState, useEffect, useCallback } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCustomer,
  fetchAssetSummary,
  fetchFilterCustomer,
  fetchDataFilterCustomer,
  fetchAssetAllocation,
  fetchWatchList,
  fetchRmId,
  fetchRole,
} from "../store/actionCreator/rm";
import Cookies from "js-cookie";
import NotFound from "../common/NotFound";

import qoreContext from "../common/qoreContext";
import Layout from "../components/Layout";
import Dashboard from "./Dashboard";

import Bond from "../common/Financial Instrument/Bond";
import Crypto from "../common/Financial Instrument/Crypto";
import Forex from "../common/Financial Instrument/Forex";
import MutualFund from "../common/Financial Instrument/MutualFunds";
import Stock from "../common/Financial Instrument/Stock";
import ETF from "../common/Financial Instrument/ETF";
import Detail from "../common/Financial Instrument/Detail";
import Index from "../common/Financial Instrument/Index";
import DetailETF from "../common/Financial Instrument/DetailETF";
import FinancialInstrumentExchangeMarket from "../common/Financial Instrument/FinancialInstrumentExchangeMarket";
import DetailMutualFund from "../common/Financial Instrument/DetailMutualFund";
import DetailBond from "../common/Financial Instrument/DetailBond";
import DetailCrypto from "../common/Financial Instrument/DetailCrypto";
import DetailForex from "../common/Financial Instrument/DetailForex";

import RMUserManagement from "../common/Management/RMUserManagement";
import AdminUserManagement from "../common/Management/AdminUserManagement";
import FundManagement from "../common/Management/FundManagement";
import CustomerAssets from "../common/Management/CustomerAssets";
import CustomerFundLog from "../common/Management/CustomerFundLog";
import TransactionsLog from "../common/Management/TransactionsLog/TransactionsLog";

import AssetAllocation from "../common/Portfolio/AssetAllocation";
import Transactions from "../common/Portfolio/Transactions";
import FundLog from "../common/Portfolio/FundLog";
import Holdings from "../common/Portfolio/Holdings";
import Performance from "../common/Portfolio/Performance";

import { useIdleTimer } from "react-idle-timer";
import { useSearchParams } from "react-router-dom";
import AllocationDetail from "../common/Portfolio/AllocationDetail";
import Compare from "../common/Insight/Compare";
import PortofolioSummary from "../common/Analytic/PortofolioSummary";

import GeneratePDF from "../common/Report/GeneratePDF";

export default function HomePage() {
  const [customerTransactions, setCustomerTransactions] = useState<any[]>([]);
  const location = useLocation();
  const user = qoreContext.useCurrentUser();
  const nav = useNavigate();
  if (!Cookies.get("remember_me")) {
    const handleOnIdle = (event: any) => {
      Cookies.remove("token");
      nav("/login");
    };

    const handleOnActive = (event: any) => {
      // console.log("user is active", event);
    };

    const handleOnAction = (event: any) => {
      // console.log("user did something hehe", event);
      // const minute = new Date(new Date().getTime() + 60 * 60 * 1000);
      // Cookies.set("token", Cookies.get("token"), {
      //     expires: minute,
      //     secure: true,
      // });
      // console.log("time remaining", getRemainingTime());
    };

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
      timeout: 1000 * 60 * 15,
      onIdle: handleOnIdle,
      onActive: handleOnActive,
      onAction: handleOnAction,
      debounce: 500,
    });
  }

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [customers, setCustomers] = useState<any[]>([]);
  const [customer, setCustomer] = useState<any[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>("");

  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const allChecked = checkedItems.every(Boolean);

  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  const [allTransactions, setAllTransactions] = useState<any[]>([]);
  const [allCustomers, setAllCustomers] = useState<any[]>([]);

  const setCustomersValues = (value: string) => {
    if (customer.find((el: any) => el === value)) {
      setCustomer(customer.filter((el: any) => el !== value));
    } else {
      setCustomer([...customer, value]);
    }
  };

  const getCustomers = async () => {
    try {
      if (user?.user?.id) {
        setIsLoading(true);
        let result;
        let response;
        // if (allCustomers.length === 0) {
        response = await qoreContext.client
          .table("customers")
          .readRows({
            condition: { rm_id: user?.user?.id },
            populate: [
              "customer_id_items",
              "customer_id_transactions_items",
              "customer_id_performances_items",
              // "customer_asset_id_items"
            ],
          })
          .toPromise();
        if (response?.data?.nodes) {
          setCustomerTransactions(response?.data?.nodes);
          result = JSON.parse(JSON.stringify(response?.data?.nodes));
          setData(result);
          setAllCustomers(result);
        }
        // } else {
        //   response = await qoreContext.client
        //     .table("customers")
        //     .readRows({
        //       condition: { rm_id: user?.user?.id },
        //     })
        //     .toPromise();
        // }
        if (response?.data?.nodes) {
          result = JSON.parse(JSON.stringify(response?.data?.nodes));
          setData(result);
        }

        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomers();
  }, [user?.user?.id]);

  useEffect(() => {
    getUpdatedData();
  }, [isUpdate]);

  const getUpdatedData = async () => {
    await getCustomers();
    setIsUpdate(false);
  };

  const fetchFilterData = useCallback(async () => {
    try {
      if (allCustomers.length > 0) {
        let tempAllCustomers = allCustomers.filter((el: any) => {
          return el.name.toUpperCase().includes(searchFilter.toUpperCase());
        });
        setCustomers(tempAllCustomers);
      }
    } catch (error) {
      console.log(error, "ini error");
    }
  }, [allCustomers, searchFilter]);

  useEffect(() => {
    fetchFilterData();
  }, [fetchFilterData]);

  const fetchFilterChecked = useCallback(async () => {
    if (checkedItems.length === 0) {
      let tempChecked = [];
      for (let i = 0; i < customers.length; i++) {
        tempChecked.push(true);
        customer.push(customers[i].name);
      }
      setCheckedItems(tempChecked);
    }
  }, [customers]);

  useEffect(() => {
    fetchFilterChecked();
  }, [fetchFilterChecked]);

  const { dataCustomers } = useSelector((state: any) => state.rm);
  const dispatch: any = useDispatch();

  useEffect(() => dispatch(fetchCustomer(data)), [data]);
  useEffect(() => {
    dispatchFilterCustomer();
  }, [customer.length]);

  const dispatchFilterCustomer = async () => {
    await dispatch(fetchFilterCustomer(customer));
    await dispatch(fetchDataFilterCustomer([dataCustomers, customer]));
    const filteredCustomer = dataCustomers.filter((obj: any) =>
      customer.includes(obj.name)
    );
    const response = await qoreContext.client
      .table("asset_summary")
      .readRows({ condition: { rm_id_asset_summary: user?.user?.id } })
      .toPromise();

    let tempCustomerId: any = [];
    await filteredCustomer.forEach((el: any) => {
      tempCustomerId.push(el.id);
    });

    await dispatch(fetchRole(user?.user?.role?.name));
    await dispatch(fetchRmId(user?.user?.id));

    await dispatch(fetchAssetSummary([response?.data?.nodes, tempCustomerId]));
    if (filteredCustomer.length > 0) {
      await dispatch(fetchAssetAllocation(filteredCustomer));
    }

    const getWatchList = await qoreContext.client
      .table("watch_list")
      .readRows({ condition: { rm_watch_list: user?.user?.id } })
      .toPromise();

    if (getWatchList?.data?.nodes) {
      if (getWatchList?.data?.nodes.length > 0) {
        await dispatch(fetchWatchList(getWatchList?.data?.nodes));
      } else {
        await dispatch(fetchWatchList([]));
      }
    }
  };

  // useEffect(() => {
  //   test();
  // }, [customer]);

  // const test = async () => {
  //   const token = "620e19e49de326.23079426";
  //   const data = await axios.get(`https://eodhistoricaldata.com/api/fundamentals/DHAMX.US?api_token=${token}&fmt=json`);
  //   const dataEOd = await axios.get(`https://eodhistoricaldata.com/api/fundamentals/AAPL.US?api_token=${token}&fmt=json`);
  //   console.log(data.data, `mutual fund`)
  //   console.log(dataEOd.data, 'stock fundemental')
  // }

  return (
    <div>
      <Layout
        setSearchFilter={setSearchFilter}
        searchFilter={searchFilter}
        allChecked={allChecked}
        isIndeterminate={isIndeterminate}
        setCheckedItems={setCheckedItems}
        setCustomers={setCustomer}
        customers={customers}
        customer={customer}
        checkedItems={checkedItems}
        allCustomers={allCustomers}
        setCustomersValues={setCustomersValues}
        location={location.pathname.split("/")[1]}
      >
        <Routes>
          <Route
            path="/"
            element={
              <Dashboard customers={customer} customerData={dataCustomers} />
            }
          />
          {/* Management */}
          <Route
            path="management/user-management"
            element={<RMUserManagement getCustomers={getCustomers} />}
          />
          <Route
            path="management/admin-management"
            element={<AdminUserManagement />}
          />
          <Route
            path="management/fund-management"
            element={<FundManagement />}
          />
          <Route
            path="management/fund-management/:company"
            element={<CustomerAssets setIsUpdate={setIsUpdate} />}
          />

          <Route
            path="management/fund-management/:company/:id"
            element={<CustomerFundLog />}
          />

          <Route
            path="management/fund-management/:company/transactions-log/:code"
            element={<TransactionsLog customer={customer} />}
          />

          {/* Financial Instrument */}

          <Route path="financial-instrument/bonds" element={<Bond />} />
          <Route
            path="financial-instrument/bonds/:market/:detail"
            // element={<Detail />}
            element={<DetailBond />}
          />

          <Route path="financial-instrument/crypto" element={<Crypto />} />
          <Route
            path="financial-instrument/crypto/:detail"
            element={<Detail instrument={"crypto"} />}
          />

          <Route path="financial-instrument/forex" element={<Forex />} />
          <Route
            path="financial-instrument/forex/:detail"
            element={<Detail instrument={"forex"} />}
          />

          <Route
            path="financial-instrument/mutual-funds"
            element={<MutualFund />}
          />
          <Route
            path="financial-instrument/mutual-funds/:market"
            element={<FinancialInstrumentExchangeMarket />}
          />

          <Route
            path="financial-instrument/mutual-funds/:market/:detail"
            // element={<DetailMutualFund />}
            element={<Detail instrument={"mutualfund"} />}
          />

          <Route path="financial-instrument/stock" element={<Stock />} />
          <Route
            path="financial-instrument/stock/:market"
            element={<FinancialInstrumentExchangeMarket />}
          />
          <Route
            path="financial-instrument/stock/:market/:detail"
            element={<Detail instrument={"stock"} />}
          />

          <Route path="/financial-instrument/ETF" element={<ETF />} />
          <Route
            path="financial-instrument/ETF/:market"
            element={<FinancialInstrumentExchangeMarket />}
          />
          <Route
            path="financial-instrument/ETF/:market/:detail"
            element={<Detail instrument={"etf"} />}
          />

          <Route path="financial-instrument/index" element={<Index />} />

          <Route
            path="financial-instrument/index/:market"
            element={<Detail instrument={"index"} />}
          />

          {/* Portfolio */}
          <Route
            path="portfolio/asset-allocation"
            element={<AssetAllocation customer={customer} />}
          />

          <Route
            path="portfolio/asset-allocation/:detail"
            element={<AllocationDetail customer={customer} />}
          />

          <Route
            path="portfolio/transactions"
            element={
              <Transactions
                customer={customer.length > 0 ? customer : []}
                customerTransactions={customerTransactions}
              />
            }
          />
          <Route
            path="portfolio/performance"
            element={<Performance customer={customer} />}
          />
          <Route
            path="portfolio/holdings"
            element={<Holdings customer={customer} />}
          />

          <Route path="insight/comparison" element={<Compare />} />

          {/* <Route path="*" element={<NotFound />} /> */}

          {/* analytics */}

          <Route
            path="analytics/portofolio-summary"
            element={
              <PortofolioSummary customer={customer} customerData={data} />
            }
          />

          <Route
            path="reports/generate-reports"
            element={
              <GeneratePDF customers={customer} customerData={dataCustomers} />
            }
          />
        </Routes>
      </Layout>
    </div>
  );
}
