import { 
    Accordion,
} from "@chakra-ui/react";
import AccordionBox from './DashboardBox/AccordionBox';
import PerformanceDetail from './DashboardBox/PerformanceDetail';
import AssetAllocationDetail from './DashboardBox/AssetAllocationDetail';


import { BiBarChartAlt, BiPieChartAlt } from "react-icons/bi";

const LeftSideBox = () => {
    return (
        <Accordion 
            defaultIndex={[0, 1]} allowMultiple>
                <AccordionBox
                    boxTitle={'Performance'}
                    iconTitle={BiBarChartAlt}>
                    <PerformanceDetail />
                </AccordionBox>
                <AccordionBox
                    boxTitle={'Asset Allocation'}
                    iconTitle={BiPieChartAlt}>
                    <AssetAllocationDetail />
                </AccordionBox>
        </Accordion>
    );
};

export default LeftSideBox;