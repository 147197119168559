import { Thead, Tr, Th } from "@chakra-ui/react";

const TableHeadWatchList = ({ tableHeadList }: any) => {
    return (
        <Thead bg="rgba(246, 246, 246, 1)">
            <Tr>
                <Th textTransform="capitalize" p="12px">
                    Name
                </Th>
                <Th
                    textTransform="capitalize"
                    textAlign={"right"}
                    p="12px"
                >
                    Price
                </Th>
                <Th
                    textTransform="capitalize"
                    textAlign={"right"}
                    p="12px"
                >
                    Performance
                </Th>
                <Th
                    textTransform="capitalize"
                    textAlign={"right"}
                    p="12px"
                >
                    Action
                </Th>
            </Tr>
        </Thead>
    );
};

export default TableHeadWatchList;