import { useSelector } from 'react-redux';
import SidebarContent from "./SidebarContent";
import MobileNav from "./MobileNav";

import { ReactNode, useState } from "react";
import { Box, Drawer, DrawerContent, useDisclosure } from "@chakra-ui/react";
import FilterCustomer from "../components/FilterAndSearch/FilterCustomer";

import { ToastContainer } from 'react-toastify';
import qoreContext from "../common/qoreContext";

function Layout({
  children,
  setSearchFilter,
  searchFilter,
  allChecked,
  isIndeterminate,
  setCheckedItems,
  setCustomers,
  customers,
  checkedItems,
  allCustomers,
  setCustomersValues,
  location,
  customer,
}: {
  children: ReactNode;
  setSearchFilter: any;
  searchFilter: any;
  allChecked: any;
  isIndeterminate: any;
  setCheckedItems: any;
  setCustomers: any;
  customers: any;
  checkedItems: any;
  allCustomers: any;
  setCustomersValues: any;
  location: any;
  customer: any;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [sidebarMode, setSidebarMode] = useState<boolean>(false)
  const user = qoreContext.useCurrentUser();
  return (
    <Box boxShadow="lg" minH="100vh" bg="rgba(250, 250, 250, 1)">
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        sidebarMode={sidebarMode}
        setSidebarMode={setSidebarMode}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav sidebarMode={sidebarMode} onOpen={onOpen} />
      <Box ml={{ base: 0, md: sidebarMode ? 10 : 60 }} p="4" mr={"3px"}>
        {location === "financial-instrument" ||
        location === "management" ||
        user?.user?.id === 'admin' ||
        location === "insight" ? (
          ""
        ) : (
          <Box>
            { allCustomers.length > 0 && (
                <FilterCustomer
                  setSearchFilter={setSearchFilter}
                  searchFilter={searchFilter}
                  allChecked={allChecked}
                  isIndeterminate={isIndeterminate}
                  setCheckedItems={setCheckedItems}
                  setCustomers={setCustomers}
                  customers={customers}
                  checkedItems={checkedItems}
                  allCustomers={allCustomers}
                  setCustomersValues={setCustomersValues}
                  customer={customer}
                />
            )
              
            }
          </Box>
        )}
        {children}
      </Box>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        />
    </Box>
  );
}

export default Layout;
