import { useState } from 'react'
import {
    Flex,
    Button,
    Select,
    Box
} from "@chakra-ui/react";

const WatchListButton = (
    {handleChartFilter, chartFilter}: any) => {

    const [filterType, setFilterType] = useState<string>("1D")

    const handleActiveBUtton = (params: any) => {
        setFilterType(params)
    }

    return (
        <Flex ml="auto" mr="0">
            {
                chartFilter.map((el: any, index: number) => {
                    if 
                        (
                        el === '1D' ||
                        el === '1M' ||
                        el === '3M' ||
                        el === 'YTD'
                        ) {
                        return (
                            <Button
                                key={index}
                                backgroundColor={filterType === el ? 'rgba(233, 233, 233, 1)' : 'transparent'}
                                _focus={{outline: 'none'}}
                                _hover={{backgroundColor: 'rgba(233, 233, 233, 1)', transform: 'scale(1.14)'}}
                                fontSize={'12px'}
                                h={'32px'}
                                w={'32px'}
                                mr="5px" onClick={(e) => {
                                    handleChartFilter(el);
                                    handleActiveBUtton(el);
                                }}>
                                {el}
                            </Button>
                        )
                    }
                })
            }

            <Select 
                onChange={(e) => {
                    handleChartFilter(e.target.value);
                    handleActiveBUtton(e.target.value);
                }}
                h={'32px'}
                w={'90px'}
                fontWeight={600}
                _focus={{outline: 'none'}}
                _hover={{backgroundColor: 'rgba(233, 233, 233, 1)', transform: 'scale(1.14)'}}
                fontSize={'12px'}
                placeholder='Range'>
                { chartFilter.map((el: any, index: number) => {
                    if 
                        (
                        el === '1Y' ||
                        el === 'MAX'
                        ) {
                        return (
                            <option 
                                key={index}
                                value={el}> 
                                    {el} 
                            </option>
                        )
                    } 
                })
            }
            </Select>
        </Flex>
    );
};

export default WatchListButton;