import {
    Flex,
    useDisclosure,
    Center,
    Box,
    AccordionPanel,
    Button,
    Table,
} from "@chakra-ui/react";
import { 
    fetchWatchList
} from '../../store/actionCreator/rm';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import qoreContext from "../../common/qoreContext";
import getAPI from "../../API/getList";
import SearchCodeModal from '../../components/Modal/SearchCodeModal';
import WatchListModal from "../../components/Modal/WatchListModal";
import WatchListButton from '../../components/Button/WatchListButton';
import TableHeadWatchList from '../../components/Tables/TableHead/TableHeadWatchList';
import TableWatchList from '../../components/Tables/TableBody/TableWatchList';
import NoDataFound from '../../components/StatusPage/NoDataFound';

import { BiFilterAlt } from "react-icons/bi";

import GetEOD from "../../API/getEOD";
import Helpers from "../../hooks/helpers";


function WatchListBox() {
    const [isLoadIndex, setIsLoadIndex] = useState<boolean>(false);
    const [isLoadMarket, setIsLoadMarket] = useState<boolean>(false);
    const [isLoadAsset, setIsLoadAsset] = useState<boolean>(false);
    const [alredyOnWatch, setAlredyOnWatch] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('1D');
    const [watchListData, setWatchListData] = useState<any[]>([]);
    const [fetchType, setFetchType] = useState<string>('');

    const form = useForm({
        defaultValues: {
            type: "",
            name: "",
            code: "",
            currency: "",
            exchange_market: "",
        },
    });
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [insturmentList, setInstrumentList] = useState<string>("");
    const [indexList, setIndexList] = useState<any>([]);
    const [marketList, setMarketList] = useState<string>("");
    const [marketFetch, setMarketFetch] = useState<boolean>(true);
    const [assetFetch, setAssetFetch] = useState<boolean>(true);

    const [typeData, setTypeData] = useState<any[]>([]);
    const [typeName, setTypeName] = useState<string>("");
    const [marketDataList, setMarketDataList] = useState<any[]>([]);
    const [market, setMarket] = useState<string>("");
    const [assetValue, setAssetValue] = useState<number>(0);

    const [chartData, setChartData] = useState<any>([]); 
    const user = qoreContext.useCurrentUser();
    const { insertRow: insertWatchList } = qoreContext
        .table("watch_list")
        .useInsertRow();
    const { deleteRow } = qoreContext.table('watch_list').useDeleteRow();
    const { watchList, rm_id } = useSelector((state: any) => state.rm);

    const dispatch: any = useDispatch();

    useEffect(() => {
        if (watchList) {
            getEODWatchList()
        }
    }, [watchList.length, filter]);

    const someAsyncFunc = async ( val: any ) => {
        if (val.rm_watch_list === rm_id) {
            const token = "620e19e49de326.23079426";
            let tempStartingDate: any;
            const today = Helpers.dateFormat(new Date());
            const year = new Date().getFullYear();
            if (filter !== 'MAX') {
                if (filter === '1D') {
                    tempStartingDate = today
                } else if (filter === '1M') {
                    tempStartingDate = Helpers.dateFormat(Helpers.getLast30Days());
                } else if (filter === '3M'){
                    let newDate = JSON.parse(JSON.stringify(today.split("-")));
                    if (new Date().getMonth() - 2 === 0) {
                        newDate[1] = "12";
                        newDate[0] = year - 1;
                    } else if (new Date().getMonth() - 2 < 0) {
                        newDate[1] = (new Date().getMonth() - 2 + 12);
                        newDate[0] = year - 1;
                    } else {
                        newDate[1] = (new Date().getMonth() - 2);
                    }
                    if (newDate[1] < 10) {
                        newDate[1] = `0${newDate[1]}`;
                    }
                    if (newDate[2] > 28 && newDate[1] === "02") {
                        newDate[2] = `01`;
                        newDate[1] = `03`
                    }
                    tempStartingDate = newDate.join("-");
                } else if (filter === '1Y'){
                    let newDate = JSON.parse(JSON.stringify(today.split("-")));
                    if (new Date().getMonth() - 11 === 0) {
                        newDate[1] = "12";
                        newDate[0] = year - 1;
                    } else if (new Date().getMonth() - 11 < 0) {
                        newDate[1] = (new Date().getMonth() - 1);
                        newDate[0] = year - 1;
                    } else {
                        newDate[1] = (new Date().getMonth() - 11);
                    }
                    if (newDate[1] < 10) {
                        newDate[1] = `0${newDate[1]}`;
                    }
                    if (newDate[2] > 28 && newDate[1] === "02") {
                        newDate[2] = `01`;
                        newDate[1] = `03`;
                    }
                    tempStartingDate = newDate.join("-");
                } else if (filter === 'YTD'){
                    tempStartingDate = `${year}-01-01`;
                }

                let eodValue = await GetEOD.fromDate(val.type, val.code, val.exchange_market, token, tempStartingDate, today);
                let performance = Number(((eodValue[0].close / eodValue[eodValue.length - 1].close) - 1) * 100).toFixed(2);

                let temp: any;
                temp = { 
                    name: val.name, 
                    data: eodValue, 
                    currency: val.currency,
                    performance: performance,
                    price: eodValue[0].close,
                    id: val.id
                };  

                return temp

            } else {
                const params = { code: val.code, market: val.exchange_market };
                const eODValue = await getAPI.getAllEOD(params);

                let performance = Number(((eODValue[eODValue.length - 1].close / eODValue[0].close ) - 1) * 100).toFixed(2);
                let temp: any;
                temp = { 
                    name: val.name, 
                    data: eODValue, 
                    currency: val.currency,
                    performance: performance,
                    price: eODValue[eODValue.length - 1].close,
                    id: val.id
                };  

                return temp
            }
        }
        
    }

    const getEODWatchList = async () => {
        const response  = await Promise.all(watchList.map((v: any)=> someAsyncFunc(v))).then((resolvedValues) => {
            return resolvedValues
        });

        let temp: any = []

        if (response.length > 0) {
            response.forEach((el: any) => {
                if (el) {
                    temp.push(el)
                }
            })
        }
        setWatchListData(temp);
    }

    useEffect(() => {
        getInstrumentStatus();
    }, [insturmentList]);

    useEffect(() => {
        getMarketListStatus();
    }, [marketList]);

    const getInstrumentStatus = () => {
        if (insturmentList !== "") {
            setMarketFetch(false);
        }
    };

    const getMarketListStatus = () => {
        if (marketList !== "") {
            setAssetFetch(false);
        }
    };

    const handleFetchIndex = async (val: any) => {
        setAssetFetch(false)
    }

    const handleAddAsset = async (values: any) => {
        try {

            if (watchList.length > 0) {
                const filterWatchList = await watchList.filter((el: any) => el.code === values.code && el.exchange_market === values.exchange);
                if (filterWatchList.length > 0) {
                    setAlredyOnWatch(true);
                } else {
                    await insertWatchList({
                        rm_watch_list: rm_id,
                        code: values.code,
                        currency: values.currency,
                        type: values.type,
                        exchange_market: values.exchange_market,
                        name: values.name
                    });

                    await getWatchList();
                }
            } else {
                
                await insertWatchList({
                    rm_watch_list: rm_id,
                    code: values.code,
                    currency: values.currency,
                    type: values.type,
                    exchange_market: values.exchange_market,
                    name: values.name
                });

                await getWatchList();
            }


        } catch (error) {
            console.log("error");
        }
    };

    const getWatchList = async () => {
        const getWatchList = await qoreContext.client
        .table("watch_list")
        .readRows({ condition: { rm_watch_list: rm_id } })
        .toPromise();
        if (getWatchList?.data?.nodes) {
            if (getWatchList?.data?.nodes.length > 0) {
                await dispatch(fetchWatchList(getWatchList?.data?.nodes));
            }
        }

        await form.setValue("code", "");
        await form.setValue("exchange_market", "");
        await form.setValue("name", "");
        await form.setValue("type", "");
        await form.setValue("currency", "");
        setFetchType('');
        onClose();
    }

    const handleFetchType = async (value: any) => {
        if (value === 'index') {
            setFetchType(value);
            getIndexList();
            setIsLoadIndex(true);
        } else {
            setFetchType(value);
        }
    }

    const getIndexList = async () => {
        const response = await getAPI.getIndex();
        setIndexList(response)
    }
    

    const handleFetchList = async (value: any) => {
        if (value === "stock") {
            setTypeName("stock");
            setTypeData(await getAPI.getStockList());
        } else if (value === "ETF") {
            setTypeName("ETF");
            setTypeData(await getAPI.getETFList());
        } else if (value === "forex") {
            setTypeName("forex");
            setTypeData(await [{ Code: "FOREX", Name: "FOREX" }]);
        } else if (value === "CC") {
            setTypeName("CC");
            setTypeData(await [{ Code: "CC", Name: "Cryptocurrencies" }]);
        } else if (value === "mutualFund") {
            setTypeName("mutualFund");
            setTypeData(await getAPI.getMutualFundList());
        } else if (value === "bond") {
            setTypeName("bond");
            setTypeData(
                await [
                    { Code: "BOND", Name: "Bond Virtual Exchange & Government Bonds" },
                ]
            );
        }
    };

    const handleFetchMarket = async (value: any) => {
        let params = {
            market: value,
        };
        setMarket(value);
        if (typeName === "stock") {
            setMarketDataList(await getAPI.getStockMarket(params));
        } else if (typeName === "ETF") {
            setMarketDataList(await getAPI.getETFMarket(params));
        } else if (typeName === "forex") {
            setMarketDataList(await getAPI.getForexMarket());
        } else if (typeName === "CC") {
            setMarketDataList(await getAPI.getCryptoMarket());
        } else if (typeName === "mutualFund") {
            setMarketDataList(await getAPI.getMutualFundMarket(params));
        } else if (typeName === "bond") {
            setMarketDataList(await getAPI.getBondMarket());
        }
    };

    const handleAssetValue = async (value: any) => {
        const params = { code: value, market };
        const data = await getAPI.getEOD(params);
        if (data.close) {
            setAssetValue(data.close);
        } else {
            setAssetValue(data.price);
        }
    };

    const handleModalClose = () => {
        form.setValue("code", "");
        form.setValue("exchange_market", "");
        form.setValue("name", "");
        form.setValue("type", "");
        setMarketDataList([]);
        setInstrumentList('');
        setMarketList('')
        setMarketFetch(true);
        setAssetFetch(true);
        setTypeData([])
        setTypeName('')
        setMarketDataList([]);
        setMarket('');
        setAssetValue(0)
        onClose();
    }

    const handleModalOpen = async () => {
        form.setValue("code", "");
        form.setValue("exchange_market", "");
        form.setValue("name", "");
        form.setValue("type", "");
        setMarketDataList([]);
        setInstrumentList('');
        setMarketList('')
        setMarketFetch(true);
        setAssetFetch(true);
        setTypeData([])
        setTypeName('')
        setMarketDataList([]);
        setMarket('');
        setAssetValue(0)
        onOpen();
    }

    const handleUpdateDataChart = async (val: any) => {
        await setChartData(val);
    }

    const handleDeleteRow = async (val: any) => {
        const response = await deleteRow(val.id);

        const getWatchList = await qoreContext.client
            .table("watch_list")
            .readRows({ condition: { rm_watch_list: rm_id} })
            .toPromise();
        if (getWatchList?.data?.nodes) {
            if (getWatchList?.data?.nodes.length > 0) {
                await dispatch( fetchWatchList(getWatchList?.data?.nodes) )
            }
        }
    }

    const watchListFilter = [
        "1D",
        "1M",
        "3M",
        "YTD",
        "1Y",
        "MAX",
    ]

    return (
        <AccordionPanel pb={4}>
            {/* <WatchListModal
                fetchType={fetchType}
                handleFetchType={handleFetchType}
                isOpen={isOpen}
                onClose={onClose}
                form={form}
                handleAddAsset={handleAddAsset}
                handleFetchList={handleFetchList}
                setInstrumentList={setInstrumentList}
                marketFetch={marketFetch}
                handleFetchMarket={handleFetchMarket}
                setMarketList={setMarketList}
                typeData={typeData}
                assetFetch={assetFetch}
                handleAssetValue={handleAssetValue}
                marketDataList={marketDataList}
                setIsLoadMarket={setIsLoadMarket}
                setIsLoadAsset={setIsLoadAsset}
                isLoadMarket={isLoadMarket}
                isLoadAsset={isLoadAsset}
                handleModalClose={handleModalClose}
                indexList={indexList}
                handleFetchIndex={handleFetchIndex}
            /> */}

            <SearchCodeModal 
                modalType={'watchlist'}
                setAlredyOnWatch={setAlredyOnWatch}
                alredyOnWatch={alredyOnWatch}
                fetchType={fetchType}
                handleFetchType={handleFetchType}
                isOpen={isOpen}
                onClose={onClose}
                form={form}
                handleAddAsset={handleAddAsset}
                handleFetchList={handleFetchList}
                setInstrumentList={setInstrumentList}
                marketFetch={marketFetch}
                handleFetchMarket={handleFetchMarket}
                setMarketList={setMarketList}
                typeData={typeData}
                assetFetch={assetFetch}
                handleAssetValue={handleAssetValue}
                marketDataList={marketDataList}
                setIsLoadMarket={setIsLoadMarket}
                setIsLoadAsset={setIsLoadAsset}
                isLoadMarket={isLoadMarket}
                isLoadAsset={isLoadAsset}
                handleModalClose={handleModalClose}
                indexList={indexList}
                handleFetchIndex={handleFetchIndex}
            />
        { watchListData && watchListData.length > 0 ? (
                <Box>
                    <Flex>
                        <Button
                            onClick={onOpen}
                            _focus={{ otuline: 'none' }}
                            size={'sm'}
                            py={'8px'}
                            border={'1px'}
                            borderColor={'rgba(233, 233, 233, 1)'}
                            fontSize={'12px'}
                            fontWeight={400}
                            leftIcon={<BiFilterAlt />}
                            color={'rgba(116, 116, 116, 1)'}
                            backgroundColor={'transparent'}
                            variant='solid'>
                            Filter asset
                        </Button>
                        <WatchListButton
                            chartFilter={watchListFilter}
                            handleChartFilter={setFilter} />
                    </Flex>
                    <Box my={5}>
                        <Table variant="simple" size="md" fontSize="12px">
                            <TableHeadWatchList />
                            <TableWatchList handleDeleteRow={handleDeleteRow} filter={filter} watchListData={watchListData} />
                        </Table>
                    </Box>
                </Box>
            ) : (
                <Center>
                    <NoDataFound instrument={'Watch List'} onOpen={onOpen} />
                </Center>
            )
        }
        </AccordionPanel>
    );
}

export default WatchListBox;
