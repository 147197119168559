import { 
    FETCH_CUSTOMERS, 
    FETCH_ASSET_SUMMARY,
    FETCH_WATCH_LIST,
    FETCH_ASSET_ALLOCATION,
    FETCH_FILTER_CUSTOMERS,
    FETCH_DATA_FILTER_CUSTOMERS,
    FETCH_VALUE_NETWORTH, 
    FETCH_LABEL_NETWORTH,
    FETCH_TOTAL_NETWORTH,
    FETCH_RM_ID,
    FETCH_ROLE
} from "../actionTypes/index";

import Helpers from '../../hooks/helpers';

export const fetchRmId = (payload: any) => {
    return async (dispatch: any, getState: any) => {
                try {
                    if (payload) {
                        dispatch({ type: FETCH_RM_ID, payload: payload });
                    }
                } catch (error) {
                    console.log(error, `error`)
                } 
      }
}

export const fetchRole = (payload: any) => {
    return async (dispatch: any, getState: any) => {
                try {
                    if (payload) {
                        dispatch({ type: FETCH_ROLE, payload: payload });
                    }
                } catch (error) {
                    console.log(error, `error`)
                } 
      }
}

export const fetchCustomer = (payload: any) => {
    return async (dispatch: any, getState: any) => {
                try {
                    if (payload.length > 0) {
                        dispatch({ type: FETCH_CUSTOMERS, payload: payload });
                    }
                } catch (error) {
                    console.log(error, `error`)
                } 
      }
}

export const fetchAssetSummary = (payload: any) => {
    return async (dispatch: any, getState: any) => {
                try {
                    if (payload.length > 0) {
                        console.log(payload, `payload`)
                        const filteredCustomer = payload[0].filter((obj: any) => payload[1].includes(obj.customer_id));
                        // console.log(filteredCustomer, `filteredCustomer`)
                        dispatch({ type: FETCH_ASSET_SUMMARY, payload: filteredCustomer });
                    }
                } catch (error) {
                    console.log(error, `error`)
                } 
      }
}

export const fetchAssetAllocation = (payload: any) => {
    return async (dispatch: any, getState: any) => {
                try {
                    if (payload.length > 0) {
                        console.log(payload, `payload`)
                        const chartData = await Helpers.getAssetAllocation(payload);
                        console.log(chartData, `chartData`)
                        dispatch({ type: FETCH_ASSET_ALLOCATION, payload: chartData });
                    }
                } catch (error) {
                    console.log(error, `error`)
                } 
      }
}

export const fetchWatchList = (payload: any) => {
    return async (dispatch: any, getState: any) => {
                try {
                    if (payload.length > 0) {
                        dispatch({ type: FETCH_WATCH_LIST, payload: payload });
                    }
                } catch (error) {
                    console.log(error, `error`)
                } 
      }
}

export const fetchFilterCustomer = (payload: any) => {
    return async (dispatch: any, getState: any) => {
        try {
            if (payload.length > 0) {
                dispatch({type: FETCH_FILTER_CUSTOMERS, payload: payload})
            }
        } catch (error) {
            console.log(error, `error`)
        } 
    }
}

export const fetchDataFilterCustomer = (payload: any) => {
    return async (dispatch: any, getState: any) => {
        try {
            if (payload.length > 0) {
                const filteredCustomer = payload[0].filter((obj: any) => payload[1].includes(obj.name));
                dispatch({type: FETCH_DATA_FILTER_CUSTOMERS, payload: filteredCustomer})
            }
        } catch (error) {
            console.log(error, `error`)
        } 
    }
}

export const fetchNetWorthLabel = (payload: any) => {
    return async (dispatch: any, getState: any) => {
                try {
                    if (payload.length > 0) {
                        dispatch({ type: FETCH_LABEL_NETWORTH, payload: payload });
                    }
                } catch (error) {
                    console.log(error, `error`)
                } 
      }
}

export const fetchNetWorthValue = (payload: any) => {
    return async (dispatch: any, getState: any) => {
                try {
                    if (payload.length > 0) {
                        dispatch({ type: FETCH_VALUE_NETWORTH, payload: payload });
                    }
                } catch (error) {
                    console.log(error, `error`)
                } 
      }
}

export const fetchTotalNetworth = (payload: any) => {
    return async (dispatch: any, getState: any) => {
                try {
                    if (payload > 0) {
                        dispatch({ type: FETCH_TOTAL_NETWORTH, payload: payload });
                    }
                } catch (error) {
                    console.log(error, `error`)
                } 
      }
}


