import { useState, useEffect } from 'react'
import {
    Box,
    Stack,
    Checkbox,
    Input,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    Button,
    Portal,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Flex,
    InputGroup,
    InputRightElement,
    AvatarBadge
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { BiFilterAlt } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";

function TableCustomerAssetFilter({
    setSearch,
    setSearchFilter,
    searchFilter,
    allChecked,
    isIndeterminate,
    setCheckedItems,
    setType,
    types,
    checkedItems,
    allTypes,
    setTypeValues,
    onOpen,
    availableFund
}: any) {

    const [countFilterChecked, setCountFilterChecked] = useState<number>(0)

    return (
        <Flex direction="row" py="20px">
            <Stack spacing={3} maxW="230px" mr="12px">
                <InputGroup>
                    <InputRightElement
                        pointerEvents="none"
                        h="32px"
                        children={<FiSearch color="rgba(162, 162, 162, 1)" />}
                    />
                    <Input
                        _focus={{ outline: "none" }}
                        placeholder="Search by Name"
                        h="32px"
                        onChange={(e: any) => {
                            setSearch(e.target.value);
                        }}
                    />
                </InputGroup>
            </Stack>
            <Popover>
                <PopoverTrigger>
                    <Box position={'relative'}>
                        <Button
                            minW="32px"
                            w="32px"
                            h="32px"
                            p="0"
                            border="1px"
                            borderColor="#E9E9E9"
                            backgroundColor="#fff"
                            _focus={{ outline: "none" }}
                        >
                            <BiFilterAlt fontSize="12px" />
                        </Button>
                        {countFilterChecked !== 0 && 
                            <AvatarBadge 
                                fontSize={'12px'}
                                fontWeight={600}
                                color={'rgba(224, 49, 56, 1)'}
                                top={-2}
                                right={-2}
                                borderRadius={'md'}
                                borderColor='papayawhip' 
                                bg='rgba(252, 234, 235, 1)' 
                                boxSize='19px'>
                                {countFilterChecked}
                            </AvatarBadge>
                        }
                    </Box>
                </PopoverTrigger>
                <Portal>
                    <PopoverContent
                        maxW="250px"
                        boxShadow="base"
                        maxH="320px"
                        overflowY="hidden"
                        _focus={{ outline: "none" }}
                    >
                        <PopoverArrow />
                        <PopoverBody px="0">
                            <Stack spacing={3} px="10px">
                                <InputGroup>
                                    <InputRightElement
                                        pointerEvents="none"
                                        children={<FiSearch color="rgba(162, 162, 162, 1)" />}
                                    />
                                    <Input
                                        placeholder="Search by Filter"
                                        fontSize="12px"
                                        _focus={{ outline: "none" }}
                                        onChange={(e: any) => {
                                            setSearchFilter(e.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </Stack>
                            <Tabs isFitted>
                                <TabList px="10px">
                                    <Tab
                                        _selected={{
                                            borderColor: "#C6B378",
                                            fontWeight: "600",
                                        }}
                                        fontSize="12px"
                                        px="0"
                                        pb="12px"
                                        mt="10px"
                                    >
                                        Type
                                    </Tab>
                                </TabList>

                                <TabPanels>
                                    <TabPanel px="0">
                                        <Stack
                                            mt={1}
                                            spacing={1}
                                            className="filter-checkbox-wrapper"
                                        >
                                            {searchFilter ? (
                                                <></>
                                            ) : (
                                                <Checkbox
                                                    isChecked={allChecked}
                                                    isIndeterminate={isIndeterminate}
                                                    py="12px"
                                                    mt="-12px"
                                                    px="10px"
                                                    w="100%"
                                                    _hover={{
                                                        backgroundColor: "rgba(249, 247, 242, 1)",
                                                    }}
                                                    size="sm"
                                                    className="filter-checkbox"
                                                    onChange={(e) => {
                                                        let temp = [];
                                                        for (let i = 0; i < types.length; i++) {
                                                            temp.push(e.target.checked);
                                                        }
                                                        setCheckedItems(temp);
                                                        if (!e.target.checked) {
                                                            setType([]);
                                                        } else {
                                                            setType(types);
                                                        }

                                                        let countTemp = temp.filter((e) => e === true)
                                                        if (countTemp) {
                                                            setCountFilterChecked(countTemp.length)
                                                        }
                                                    }}
                                                >
                                                    Select All
                                                </Checkbox>
                                            )}
                                        </Stack>

                                        <Box border="1px" borderColor="gray.200" />
                                        <Stack
                                            mt={1}
                                            spacing={1}
                                            className="filter-checkbox-wrapper"
                                            overflowY="scroll"
                                            maxH="160px"
                                        >   
                                            {types.map((el: any, index: number) => {
                                                return (
                                                    <Checkbox
                                                        key={index}
                                                        isChecked={checkedItems[allTypes.indexOf(el)]}
                                                        value={el}
                                                        px="10px"
                                                        py="8px"
                                                        _hover={{
                                                            backgroundColor: "rgba(249, 247, 242, 1)",
                                                        }}
                                                        className="filter-checkbox"
                                                        size="sm"
                                                        onChange={(e) => {
                                                            const leng = checkedItems.length;
                                                            let temp = [];
                                                            for (let i = 0; i < leng; i++) {
                                                                if (i === allTypes.indexOf(el)) {
                                                                    temp.push(e.target.checked);
                                                                } else {
                                                                    temp.push(checkedItems[i]);
                                                                }
                                                            }
                                                            setCheckedItems(temp);
                                                            let countTemp = temp.filter((e) => e === true)
                                                            if (countTemp) {
                                                                setCountFilterChecked(countTemp.length)
                                                            }
                                                            setTypeValues(e.target.value);
                                                        }}
                                                    >
                                                        {el}
                                                    </Checkbox>
                                                );
                                            })}
                                        </Stack>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover>
            {
                availableFund !== 0 && (
                    <Stack ml="auto" mr="0" direction="row" spacing={4}>
                        <Button
                            onClick={() => {
                                onOpen();
                            }}
                            leftIcon={<BsPlusLg fontSize="10px" />}
                            fontWeight="600"
                            fontSize="12px"
                            color="#fff"
                            maxW="150px"
                            _focus={{ outline: "none" }}
                            h="32px"
                            border="1px"
                            _hover={{
                                color: "rgba(198, 179, 120, 1)",
                                backgroundColor: "#fff",
                                border: "1px",
                            }}
                            backgroundColor="rgba(198, 179, 120, 1)"
                        >
                            Add Asset
                        </Button>
                    </Stack>
                )
            }
        </Flex>
    );
}

export default TableCustomerAssetFilter;
