import { useNavigate } from "react-router-dom";
import { Box, Text, Image, Center } from "@chakra-ui/react";
import bgEmpty from "../../../assets/empty-data.png";
import AddPlusButton from "../Button/AddPlusButton";

const CompareNoDataFound = ({ onOpen }: any) => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Center>
        <Image src={bgEmpty} />
      </Center>
      <Box maxW="250px">
        <Text fontSize="16px" fontWeight="600" mt={3} mb={2}>
          Please add asset to compare
        </Text>
        <Text fontSize="14px" color={"gray.500"} mb={6}>
          You haven't added and configured any sources yet.
          <a href="">Learn more</a>
        </Text>
        <AddPlusButton
          handleOpenModal={() => onOpen()}
          buttonText={`Add New Asset to Compare`}
        />
      </Box>
    </Box>
  );
};

export default CompareNoDataFound;
