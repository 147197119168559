import { Thead, Tr, Th } from "@chakra-ui/react";

const TamplateTableHead = ({tableHeadList, instrument}: any) => {
    return (
        <Thead bg="rgba(246, 246, 246, 1)">
            {
                instrument ? (
                    <Tr>
                        {tableHeadList && tableHeadList.length > 0 && tableHeadList.map((el: any, index:number) => {
                            return <Th key={index} textTransform="capitalize" p="12px">{el}</Th>
                        })}
                    </Tr>
                ) : (
                    <Tr>
                        {tableHeadList && tableHeadList.length > 0 && tableHeadList.map((el: any, index:number) => {
                            return <Th textAlign={ index === 0 ? 'left' : 'right' } key={index} textTransform="capitalize" p="12px">{el}</Th>
                        })}
                    </Tr>
                )
            }
        </Thead>
    );
};

export default TamplateTableHead;