import { ReactNode } from "react";
import { Table } from "@chakra-ui/react";
import TamplateTableHead from "./TableHead/TamplateTableHead";
import PaginatorTableMarket from "../Tables/PaginatorTableMarket";
import CustomTableHeadTransactions from "./TableHead/CustomTableHeadTransactions";

function TableTamplate({
  children,
  customTableHead,
  tableHeadList,
  pages,
  currentPage,
  instrument,
  handlePageChange,
}: {
  children: ReactNode;
  tableHeadList: any;
  pages: any;
  customTableHead: any;
  currentPage: any;
  instrument: any;
  handlePageChange: any;
}) {
  return (
    <>
      <Table variant="simple" size="md" fontSize="12px">
        {customTableHead === "CustomTableHeadTransactions" ? (
          <CustomTableHeadTransactions />
        ) : (
          <TamplateTableHead
            tableHeadList={tableHeadList}
            instrument={instrument}
          />
        )}
        {children}
      </Table>
      {!handlePageChange ? (
        <></>
      ) : (
        <PaginatorTableMarket
          pages={pages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
}

export default TableTamplate;
