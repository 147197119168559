import axios from 'axios'

const token = "620e19e49de326.23079426";


class GetEOD {
    static async maximumList( instrument : any, code : any, market : any, token : any){
        if (instrument === 'forex') {
            const response = await axios(
                `https://eodhistoricaldata.com/api/eod/${code}.FOREX?api_token=${token}&fmt=json&period=d&order=d`
            );
            return response.data
        } else if (instrument === 'crypto') {
            const response = await axios(
                `https://eodhistoricaldata.com/api/eod/${code}.CC?api_token=${token}&fmt=json&period=d&order=d`
            );
            return response.data
        } else {
            const response = await axios(
                `https://eodhistoricaldata.com/api/eod/${code}.${market}?api_token=${token}&fmt=json&period=d&order=d`
            );
            return response.data
        }
    }

    static async fromDate(instrument : any, code : any, market : any, token : any, from: any, to: any){
        if (instrument === 'forex') {
            const response = await axios(
                `https://eodhistoricaldata.com/api/eod/${code}.FOREX?api_token=${token}&fmt=json&period=d&order=d&from=${from}&to=${to}`
              );
            return response.data
        } else if (instrument === 'crypto') {
            const response = await axios(
                `https://eodhistoricaldata.com/api/eod/${code}.CC?api_token=${token}&fmt=json&period=d&order=d&from=${from}&to=${to}`
              );
            return response.data
        } else if (instrument === 'index') {
            const response = await axios(
                `https://eodhistoricaldata.com/api/eod/${code}.INDX?api_token=${token}&fmt=json&period=d&order=d&from=${from}&to=${to}`
            )
            return response.data
        } else {
            if (from === to) {
                const response = await axios(
                    `https://eodhistoricaldata.com/api/eod/${code}.${market}?api_token=${token}&fmt=json`
                  );

                  const arr = [
                    response.data[response.data.length - 1],
                    response.data[response.data.length - 2],
                  ]

                return arr
            } else {
                const response = await axios(
                    `https://eodhistoricaldata.com/api/eod/${code}.${market}?api_token=${token}&fmt=json&period=d&order=d&from=${from}&to=${to}`
                  );
                return response.data
            }
        }
    }

    static async comparePerformance(code : any, market : any, from: any, to: any){
        const response = await axios(
            `https://eodhistoricaldata.com/api/eod/${code}.${market}?api_token=${token}&fmt=json&period=d&order=d&from=${from}&to=${to}`
        )
        return response.data
    }


}

export default GetEOD