import {
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";

const ErrorAlertComponent = ({ allError, errorClosedButton }: any) => {
  const AlertMotion = motion(Alert);

  const handleClosedButton = () => {
    errorClosedButton();
  }

  return (
    <AnimatePresence>
      {allError.map((el: any, index: number) => {
        return (
          <AlertMotion
            key={index}
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            layout
            rounded={"md"}
            color={"rgba(23, 23, 23, 1)"}
            bg={"rgba(252, 234, 235, 1)"}
            fontSize={"12px"}
            status="error"
          >
            <AlertIcon boxSize="12px" />
            <AlertDescription>{el}</AlertDescription>
            {
              errorClosedButton && (
                <CloseButton
                  onClick={() => {
                    handleClosedButton()
                  }}
                  _hover={{ transform: "scale(1.2)" }}
                  _focus={{ outline: "none" }}
                  size={"sm"}
                  position="absolute"
                  right="10px"
                  top="12px"
                />
              )
            }
            
          </AlertMotion>
        );
      })}
  </AnimatePresence>
  );
};

export default ErrorAlertComponent;
