import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DatePicker } from "chakra-ui-date-input";
import BarChart from "../../components/Chart/BarChart";
import ChartButtonFISingle from "../../components/Button/ChartButtonFISingle";
const token = "620e19e49de326.23079426";
import {
  Box,
  Center,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";

function DetailBond() {
  const params = useParams();
  const [allData, setAllData] = useState<any[]>([]);
  const [list, setList] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterString, setFilterString] = useState<string>("MAX")
  const [oldestDate, setOldestDate] = useState<string>("");
  const [newestDate, setNewestDate] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");

  useEffect(() => {
    getList();
  }, [search, currentPage, fromDate, toDate]);

  const handleChartFilter = (limit: string) => {
    setFilterString(limit)
    const year = new Date().getFullYear();
    if (limit === "1M") {
      let newDate = JSON.parse(JSON.stringify(toDate.split("-")));
      if (new Date().getMonth() === 0) {
        newDate[1] = "12";
        newDate[0] = year - 1;
      } else {
        newDate[1] = new Date().getMonth().toString();
      }
      if (newDate[1] < 10) {
        newDate[1] = `0${newDate[1]}`;
      }
      if (newDate[2] > 28) {
        newDate[2] = `01`;
        newDate[1] = `03`
      }
      newDate = newDate.join("-");
      setFromDate(newDate);
      setToDate(newestDate);
    } else if (limit === "3M") {
      let newDate = JSON.parse(JSON.stringify(toDate.split("-")));
      if (new Date().getMonth() - 2 === 0) {
        newDate[1] = "12";
        newDate[0] = year - 1;
      } else if (new Date().getMonth() - 2 < 0) {
        newDate[1] = (new Date().getMonth() - 2 + 12).toString();
        newDate[0] = year - 1;
      } else {
        newDate[1] = (new Date().getMonth() - 2).toString();
      }
      if (newDate[1] < 10) {
        newDate[1] = `0${newDate[1]}`;
      }
      if (newDate[2] > 28 && newDate[1] === "02") {
        newDate[2] = `01`;
        newDate[1] = `03`
      }
      newDate = newDate.join("-");
      setFromDate(newDate);
      setToDate(newestDate);
    } else if (limit === "1Y") {
      let newDate = JSON.parse(JSON.stringify(toDate.split("-")));
      if (new Date().getMonth() - 11 === 0) {
        newDate[1] = "12";
        newDate[0] = year - 1;
      } else if (new Date().getMonth() - 11 < 0) {
        newDate[1] = (new Date().getMonth() - 1).toString();
        newDate[0] = year - 1;
      } else {
        newDate[1] = (new Date().getMonth() - 11).toString();
      }
      if (newDate[1] < 10) {
        newDate[1] = `0${newDate[1]}`;
      }
      if (newDate[2] > 28 && newDate[1] === "02") {
        newDate[2] = `01`;
        newDate[1] = `03`;
      }
      newDate = newDate.join("-");
      setFromDate(newDate);
      setToDate(newestDate);
    } else if (limit === "YTD") {
      setFromDate(`${year}-1-1`);
      setToDate(newestDate);
    } else if (limit === "MAX") {
      setFromDate(oldestDate);
      setToDate(newestDate);
    }
  };

  const getList = async () => {
    try {
      let response;
      let data;
      if (list.length === 0) {
        response = await fetch(
          `https://eodhistoricaldata.com/api/eod/${params.detail}.${params.market}?api_token=${token}&fmt=json&period=d&order=d`
        );
        data = await response.json();
        setOldestDate(data[data.length - 1].date);
        setNewestDate(data[0].date);
        setFromDate(data[data.length - 1].date);
        setToDate(data[0].date);
      } else {
        response = await fetch(
          `https://eodhistoricaldata.com/api/eod/${params.detail}.${params.market}?api_token=${token}&fmt=json&period=d&order=d&from=${fromDate}&to=${toDate}`
        );
        data = await response.json();
      }
      let result: any[] = [];
      setAllData(data);
      result = JSON.parse(JSON.stringify(data));

      setList(result);
    } catch (error) {
      console.log(error);
    }
  };

  const chartFilterArr = [
    "1M",
    "3M",
    "1Y",
    "YTD",
    "MAX"
  ]

  return (
    <div>
      <Box
        w={"full"}
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"lg"}
        rounded={"md"}
        px="24px"
        >
        <Box w={{ base: "full" }}>
          <Center py="20px">
            <Stack className={'datepicker-wrapper'} spacing={3} mr="12px" direction="row">
              <DatePicker
                h={'32px'}
                defaultValue={fromDate.split("-").reverse().join("/")}
                placeholder="From"
                name="fromDate"
                onChange={(date: string) =>
                  setFromDate(date.split("/").reverse().join("-"))
                }
              />
              <Box h={'32px'} lineHeight={'32px'}>
                {'-'}
              </Box>
              <DatePicker
                h={'32px'}
                defaultValue={toDate.split("-").reverse().join("/")}
                placeholder="To"
                name="toDate"
                onChange={(date: string) =>
                  setToDate(date.split("/").reverse().join("-"))
                }
              />
            </Stack>
            <ChartButtonFISingle
              chartFilter={chartFilterArr}
              handleChartFilter={handleChartFilter} />
          </Center>
        </Box>
        <BarChart filterString={filterString} dataChart={allData} code={params.detail} />
      </Box>
    </div>
  );
}

export default DetailBond;
