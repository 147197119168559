import { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    Center,
    Box,
    Input, FormControl, FormLabel, Textarea,
    ModalFooter, ModalBody,
    Avatar,
    Text,
    Button,
} from "@chakra-ui/react";

const AddNewNotes = ({
    form,
    isOpen,
    onClose,
    header,
    handleNewNotes,
    handleUpdateNotes,
    modalMode,
    idRows
}: any) => {

    useEffect(() => {
        form.setValue("date_notes", "");
        form.setValue("extention", "");
        form.setValue("bank_statement", "");
        form.setValue("remarks", "");
    }, [handleNewNotes])

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    px="24px"
                    py="16px"
                    fontSize="16px"
                    fontWeight="600"
                    borderBottom="1px"
                    borderColor="#E9E9E9"
                >
                    {header}
                </ModalHeader>
                <ModalCloseButton
                    _focus={{ outline: "none" }}
                    fontSize="8px"
                    maxW="10px"
                    maxH="10px"
                    mt="15px"
                    mr="20px"
                />

                <form
                    onSubmit={form.handleSubmit((values: any) =>
                        modalMode
                            ? handleNewNotes(values)
                            : handleUpdateNotes(values, idRows)
                    )}>

                    <ModalBody>
                        <FormControl pt="16px">
                            <FormLabel fontSize="14px" mb="8px" htmlFor="name">
                                Date
                            </FormLabel>

                            <Input
                                maxDate={new Date()}
                                _focus={{outline: 'none'}}
                                cursor={'pointer'}
                                fontSize="14px"
                                px="12px"
                                id="date_notes"
                                type="date"
                                placeholder="Transaction Date"
                                {...form.register("date_notes", { required: true })}
                                // onChange={(e) => {
                                //     setValue(e.target.value);
                                // }}
                            /> 

                        </FormControl>

                        <FormControl pt="16px">
                            <FormLabel fontSize="14px" mb="8px" htmlFor="name">
                                Extension
                            </FormLabel>
                            <Input
                                _focus={{outline: 'none'}}
                                fontSize="14px"
                                px="12px"
                                id="name"
                                type="text"
                                placeholder="Fill Extension "
                                {...form.register("extention", { required: true })}
                            />
                        </FormControl>

                        <FormControl pt="16px">
                            <FormLabel fontSize="14px" mb="8px" htmlFor="name">
                                Bank Statement
                            </FormLabel>
                            <Input
                                _focus={{outline: 'none'}}
                                fontSize="14px"
                                px="12px"
                                id="name"
                                type="text"
                                placeholder="Fill Bank Statement"
                                {...form.register("bank_statement", { required: true })}
                            />
                        </FormControl>

                        <FormControl pt="16px">
                            <FormLabel fontSize="14px" mb="8px" htmlFor="name">
                                Remarks
                            </FormLabel>
                            <Textarea
                                _focus={{outline: 'none'}}
                                fontSize="14px"
                                px="12px"
                                id="name"
                                type="text"
                                placeholder="Fill The Remakrs"
                                {...form.register("remarks", { required: true })}
                            >

                            </Textarea>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter px="24px" py="16px">
                        <Button
                            backgroundColor="#fff"
                            color="rgba(23, 23, 23, 1)"
                            fontSize="12px"
                            fontWeight="600"
                            border="1px"
                            borderColor="#E9E9E9"
                            h="32px"
                            mr={3}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            backgroundColor="rgba(198, 179, 120, 1)"
                            color="#fff"
                            border="1px"
                            fontSize="12px"
                            fontWeight="600"
                            h="32px"
                            _hover={{
                                color: "rgba(198, 179, 120, 1)",
                                backgroundColor: "#fff",
                                border: "1px",
                            }}
                            type="submit"
                        >
                            Submit
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}

export default AddNewNotes

