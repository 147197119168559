import { Tr, Tbody, Td, Button } from "@chakra-ui/react";

import { BiTrash } from "react-icons/bi";
import Helpers from "../../../hooks/helpers";

const TableHoldingsCustomer = (
    {
        holdingsData
    }: any) => {
        // console.log(holdingsData, `holdingsData`)
    return (
        <Tbody>
            {
                holdingsData.length > 0 && (
                    holdingsData.map((el: any, index: number) => {
                        return (
                            <Tr
                                color={'rgba(23, 23, 23, 1)'}
                                key={index}>
                                <Td textTransform="capitalize" p="12px"> {el[0]} </Td>
                                <Td p="12px" textAlign={'right'}> { Helpers.currencyFormaterUSD(el[1].total) } </Td>
                                <Td 
                                    p="12px" 
                                    textAlign={'right'} 
                                    w={'32px'} 
                                    maxW={'32px'}> 
                                    {el[1].percentage.toFixed(2)} %
                                </Td>
                            </Tr>
                        )
                    })
                )
            }

        </Tbody>
    );
};

export default TableHoldingsCustomer;