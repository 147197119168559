
// DOMAIN STATE

export const IS_LOADING = 'status/loading'
export const IS_ERROR = 'status/error'
export const IS_SUCCESS = 'status/success'

// DOMAIN RM
export const FETCH_WATCH_LIST = 'rm/fetchWatchList';
export const FETCH_RM_ID = 'rm/fetchRMID';
export const FETCH_ROLE = 'rm/fetchRMRole';

export const FETCH_CUSTOMERS = 'rm/fetchCustomers';
export const FETCH_ASSET_SUMMARY = 'rm/fetchAssetSummary';
export const FETCH_ASSET_ALLOCATION = 'rm/fetchAssetAllocation';
export const FETCH_FILTER_CUSTOMERS = 'rm/fetchFilterCustomer';
export const FETCH_DATA_FILTER_CUSTOMERS = 'rm/fetchDataFilterCustomer';
export const FETCH_VALUE_NETWORTH = 'rm/fetchValueNetworth';
export const FETCH_LABEL_NETWORTH = 'rm/fetchLabelNetworth';
export const FETCH_TOTAL_NETWORTH = 'rm/fetchTotalNetworth';

// DOMAIN FINANCIAL INSTRUMENT

export const FETCH_FINANCIAL_INSTRUMENT = 'fi/fetchFinancialInstrument';


