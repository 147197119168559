import {
    Input,
    FormControl,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormLabel,
    Box
} from "@chakra-ui/react";
import CustomFormHelper from '../Form/CustomFormHelper';
import Helpers from '../../hooks/helpers';
import CustomModalFooter from './Modal Components/CustomModalFooter';
import ErrorAlertComponent from "../Alert/ErrorAlertComponent";

const UpdateTradeDate = (
    { onClose, isOpen, form, handleSubmitForm, modalText, updatedData, isSubmit, errorDate, setErrorDate }: any) => {
    
    const handleModalClose = () => {
        form.setValue("trade_date", "");
        setErrorDate([])
        onClose();
    }
    return (
            <Modal isOpen={isOpen} onClose={ async () => {
                await onClose();
            }}>
                <ModalOverlay />
                <ModalContent my={'auto'}>
                    <ModalHeader
                        px="24px"
                        py="16px"
                        fontSize="16px"
                        fontWeight="600"
                        borderBottom="1px"
                        borderColor="#E9E9E9"
                    >
                        {`${modalText} ${updatedData.asset_code} Trade date ${updatedData.id}`}
                    </ModalHeader>
                    <ModalCloseButton
                        _focus={{ outline: "none" }}
                        fontSize="8px"
                        maxW="10px"
                        maxH="10px"
                        mt="15px"
                        mr="20px"
                    />
                    <form onSubmit={form.handleSubmit((values: any) => handleSubmitForm(values))}>
                        <ModalBody>
                            {   errorDate.length > 0 &&
                                (<Box mt={'10px'}>
                                    <ErrorAlertComponent
                                        errorClosedButton={false}
                                        allError={errorDate}
                                    />
                                </Box>)
                            }
                            {
                                updatedData.trade_date && (
                                    <FormControl pt="16px">
                                        <FormLabel
                                            fontSize="14px"
                                            mb="8px"
                                            htmlFor="Market"
                                        >
                                            Transaction Date
                                        </FormLabel>
                                        <Input
                                            fontSize="14px"
                                            px="12px"
                                            id="trade_date"
                                            type="date"
                                            placeholder="Transaction Date"
                                            {...form.register("trade_date", { required: true })}
                                        />
                                        <CustomFormHelper 
                                            helperText={`Current Trade Date : 
                                            ${updatedData.trade_date.split('-')[2]} 
                                            ${Helpers.getFormatDate(updatedData.trade_date.split('-')[1])} 
                                            ${updatedData.trade_date.split('-')[0]} `} />
                                    </FormControl>
                                )
                            }
                        </ModalBody>

                        <CustomModalFooter isSubmit={isSubmit} onClose={handleModalClose} updatedData={updatedData} />
                    </form>
                </ModalContent>
            </Modal>
    );
};

export default UpdateTradeDate;