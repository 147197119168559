import {
    Box,
    useColorModeValue,
    Center,
} from "@chakra-ui/react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);
import TitleBoxDetail from './TitleBoxDetail';
import ChartButtonFISingle from '../Button/ChartButtonFISingle';

import { BiLineChart } from "react-icons/bi";
import Helpers from "../../hooks/helpers";

const NetWorthBox = (
    {
        chartFilterArr,
        option,
        data,
        handleActiveBUtton,
        setFilter,
        filter,
        totalSummary,
        currentDate
    }: any) => {
    
    return (
        <Box py="10px">
            <Box
                w={"full"}
                bg={useColorModeValue("white", "gray.800")}
                boxShadow={"lg"}
                rounded={"md"}
                px="0"
                mt={'24px'}
            >
                <Box px={'24px'}>
                    <TitleBoxDetail
                        fsIcon={"20px"}
                        iconTitle={BiLineChart}
                        boxTitle={`Net Worth`}
                    />
                </Box>

                <Box w={{ base: "full" }} px={"24px"} pb={'24px'}>
                    <Center my={'16px'}>
                        <Box
                            fontSize={'20px'}
                            fontWeight={600}
                            color={'rgba(23, 23, 23, 1)'}>
                            {Helpers.currencyFormaterUSD(totalSummary)}
                            <Box
                                pl={'4px'}
                                fontWeight={400}
                                display={'inline-block'}
                                fontSize={'12px'}
                                color={'rgba(116, 116, 116, 1)'}>as of {currentDate}</Box>
                        </Box>

                        <ChartButtonFISingle
                            chartFilter={chartFilterArr}
                            handleChartFilter={setFilter} />
                    </Center>

                    <Box h={300} py="10px">
                        <Line data={data} options={option} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default NetWorthBox;