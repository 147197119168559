import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
const token = "620e19e49de326.23079426";
import {
  Box,
  Center,
  useColorModeValue,
  SimpleGrid,
  List,
  ListItem,
  Flex,
  Stack,
  Spacer,
} from "@chakra-ui/react";
import getFundamental from "../../API/getFundamental";
import { DatePicker } from "chakra-ui-date-input";
import BarChart from "../../components/Chart/BarChart";
import TitleBoxDetail from "../DetailBox/TitleBoxDetail";
import { BsCardList } from "react-icons/bs";
import { BiData } from "react-icons/bi";
import ChartButtonFISingle from "../../components/Button/ChartButtonFISingle";
import Helpers from "../../hooks/helpers";
import GeneralTickers from "../FinancialInstrumentDetails/GeneralTickers";
import { AiOutlineAreaChart } from "react-icons/ai";
import IndexComponents from './IndexComponents';

function DetailTicketForex({
  oldestValue,
  newestValue,
  dataFetch,
  yesterdayValue,
  fromDate,
  code,
  dataChart,
  filterString,
  handleChartFilter,
  chartFilterArr,
  setToDate,
  toDate,
  setFromDate,
  instrument
}: any) {
const params = useParams();
const [dataDetail, setDataDetail] = useState<any>({});

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    try {
      const response = await getFundamental.detail(params.detail, "FOREX", token);
      setDataDetail(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Flex justifyContent={'flex-end'} flexWrap={'wrap'} py={6}>
                {
                    dataDetail.General && (
                        <Box
                            w={['280px', 'full']}
                            maxW={'280px'}
                            bg={useColorModeValue("white", "gray.800")}
                            boxShadow={"lg"}
                            rounded={"md"}
                            px="24px"
                            mr={'24px'}
                            h={'fit-content'}
                        >

                            <TitleBoxDetail
                                fsIcon={"16"}
                                iconTitle={BsCardList}
                                boxTitle={`General Detail`}
                            />

                            <GeneralTickers 
                                yesterdayValue={yesterdayValue}
                                oldestValue={oldestValue}
                                newestValue={newestValue}
                                dataDetail={dataDetail} />
                        </Box>
                    )
                }

                {
                    dataChart && dataChart.length > 0 && (
                        <Box 
                            w={dataDetail.General ? ['calc(100% - 310px)', 'full'] : ['full','full']}
                            maxW={dataDetail.General ? 'calc(100% - 310px)' : 'full'}
                        >
                            <Box
                                bg={useColorModeValue("white", "gray.800")}
                                boxShadow={"lg"}
                                rounded={"md"}
                                px="24px"
                                h={'fit-content'}
                                mb={'24px'}
                            >
                                <TitleBoxDetail
                                    fsIcon={"20px"}
                                    iconTitle={AiOutlineAreaChart}
                                    boxTitle={`Chart`}
                                />
                                <Box w={{ base: "full" }}>
                                    <Center py="20px">
                                        <Stack className={'datepicker-wrapper'} spacing={3} mr="12px" direction="row">
                                        <DatePicker
                                            h={'32px'}
                                            defaultValue={fromDate?.split("-").reverse().join("/")}
                                            placeholder="From"
                                            name="fromDate"
                                            onChange={(date: string) =>
                                            setFromDate(date?.split("/").reverse().join("-"))
                                            }
                                        />
                                        <Box h={'32px'} fontWeight={600} lineHeight={'32px'}>
                                            {'-'}
                                        </Box>
                                        <DatePicker
                                            h={'32px'}
                                            defaultValue={toDate?.split("-").reverse().join("/")}
                                            placeholder="To"
                                            name="toDate"
                                            onChange={(date: string) =>
                                            setToDate(date?.split("/").reverse().join("-"))
                                            }
                                        />
                                        </Stack>
                                        <ChartButtonFISingle
                                        chartFilter={chartFilterArr}
                                        handleChartFilter={handleChartFilter} />
                                    </Center>
                                    <Flex mr={'5px'}>
                                        <Box
                                            ml="auto"
                                            mr="0"
                                            fontSize={{ base: "14px", lg: "16px" }}
                                            color={
                                                newestValue - oldestValue >= 0
                                                ? useColorModeValue("green.500", "green.300")
                                                : useColorModeValue("red.500", "red.300")
                                            }
                                            fontWeight={"1000"}
                                        >
                                        {`${Math.abs(newestValue - oldestValue).toFixed(
                                            2
                                        )} (${Helpers.percentageFormater(newestValue, oldestValue)}%)`}
                                        </Box>
                                    </Flex>
                                </Box>

                                <BarChart filterString={filterString} dataChart={dataChart} code={code} />
                            </Box>

                            {
                                instrument === 'index' && (
                                    <Box
                                        w={['full']}
                                        bg={useColorModeValue("white", "gray.800")}
                                        boxShadow={"lg"}
                                        rounded={"md"}
                                        px="24px"
                                        >
                                        {dataDetail.Components && dataDetail.Components['0'] && (
                                            <IndexComponents dataDetail={dataDetail} />
                                        )}
                                    </Box>
                                )
                            }
                        </Box>
                    )
                }
            </Flex>
    </div>
  );
}

export default DetailTicketForex;
