import {
  Box,
  Stack,
  Checkbox,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Button,
  Portal,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { BiFilterAlt } from "react-icons/bi";

function TableMarketFilter({
  instrument,
  setSearch,
  searchCurrency,
  setSearchCurrency,
  allChecked,
  isIndeterminate,
  isIndeterminateCountry,
  isIndeterminateExchange,
  currencies,
  countries,
  setCheckedItems,
  setCheckedCountry,
  checkedItems,
  setCurrency,
  setCurrencyValues,
  allCheckedCountry,
  checkedCountry,
  setCountry,
  setCountryValues,
  exchanges,
  setExchange,
  setExchangeValues,
  setCheckedExchange,
  checkedExchange,
  allcheckedExchange,
  allCountries,
  allCurrencies,
  allExchanges,
}: any) {
  return (
    <Flex direction="row" py="20px">
      <Stack spacing={3} maxW="230px" mr="12px">
        <InputGroup>
          <InputRightElement
            pointerEvents="none"
            h={'32px'}
            children={<FiSearch color="rgba(162, 162, 162, 1)" />}
          />
          <Input
            _focus={{ outline: "none" }}
            placeholder="Search by CODE"
            h={'32px'}
            onChange={(e: any) => {
              setSearch(e.target.value);
            }}
          />
        </InputGroup>
      </Stack>
      <Popover>
        <PopoverTrigger>
          <Button
            w="32px"
            h="32px"
            p="0"
            border="1px"
            borderColor="#E9E9E9"
            backgroundColor="#fff"
            _focus={{ outline: "none" }}
          >
            <BiFilterAlt fontSize="14px" />
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            maxW="250px"
            boxShadow="base"
            maxH="320px"
            overflowY="hidden"
            _focus={{ outline: "none" }}
          >
            <PopoverArrow />
            <PopoverBody px="0">
              <Stack spacing={3} px="10px">
                <InputGroup>
                  <InputRightElement
                    pointerEvents="none"
                    children={<FiSearch color="rgba(162, 162, 162, 1)" />}
                  />
                  <Input
                    _focus={{ outline: "none" }}
                    placeholder="Search by Filter"
                    fontSize="12px"
                    onChange={(e: any) => {
                      setSearchCurrency(e.target.value);
                    }}
                  />
                </InputGroup>
              </Stack>
              <Tabs isFitted>
                <TabList px="10px">
                  {allCurrencies.length > 0 ? (
                    <Tab
                      _selected={{
                        borderColor: "#C6B378",
                        fontWeight: "600",
                      }}
                      fontSize="12px"
                      px="0"
                      pb="12px"
                      mt="10px"
                      _focus={{ outline: "none" }}
                    >
                      Currency
                    </Tab>
                  ) : (
                    <></>
                  )}
                  {allCountries.length > 0 ? (
                    <Tab
                      _selected={{
                        borderColor: "#C6B378",
                        fontWeight: "600",
                      }}
                      fontSize="12px"
                      px="0"
                      pb="12px"
                      mt="10px"
                      _focus={{ outline: "none" }}
                    >
                      Country
                    </Tab>
                  ) : (
                    <></>
                  )}
                  {allExchanges.length > 0 ? (
                    <Tab
                      _selected={{
                        borderColor: "#C6B378",
                        fontWeight: "600",
                      }}
                      fontSize="12px"
                      px="0"
                      pb="12px"
                      mt="10px"
                    >
                      {instrument !== 'index' ? `Exchange` : `Region`}
                    </Tab>
                  ) : (
                    <></>
                  )}
                </TabList>

                <TabPanels>
                  {allCurrencies.length > 0 ? (
                    <TabPanel px="0">
                      <Stack
                        mt={1}
                        spacing={1}
                        className="filter-checkbox-wrapper"
                      >
                        {searchCurrency ? (
                          <></>
                        ) : (
                          <Checkbox
                            isChecked={allChecked}
                            isIndeterminate={isIndeterminate}
                            py="12px"
                            mt="-12px"
                            px="10px"
                            w="100%"
                            _hover={{
                              backgroundColor: "rgba(249, 247, 242, 1)",
                            }}
                            size="sm"
                            className="filter-checkbox"
                            onChange={(e) => {
                              let temp = [];
                              for (let i = 0; i < currencies.length; i++) {
                                temp.push(e.target.checked);
                              }
                              setCheckedItems(temp);
                              if (!e.target.checked) {
                                setCurrency([]);
                              } else {
                                setCurrency(currencies);
                              }
                            }}
                          >
                            Select All
                          </Checkbox>
                        )}
                      </Stack>

                      {searchCurrency ? (
                        <></>
                      ) : (
                        <Box border="1px" borderColor="gray.200" />
                      )}

                      <Stack
                        mt={1}
                        spacing={1}
                        className="filter-checkbox-wrapper"
                        overflowY="scroll"
                        maxH="160px"
                      >
                        {currencies.map((el: any, index: number) => {
                          return (
                            <Checkbox
                              key={index}
                              isChecked={
                                checkedItems[allCurrencies.indexOf(el)]
                              }
                              value={el}
                              px="10px"
                              py="8px"
                              _hover={{
                                backgroundColor: "rgba(249, 247, 242, 1)",
                              }}
                              className="filter-checkbox"
                              size="sm"
                              onChange={(e) => {
                                const leng = checkedItems.length;
                                let temp = [];
                                for (let i = 0; i < leng; i++) {
                                  if (i === allCurrencies.indexOf(el)) {
                                    temp.push(e.target.checked);
                                  } else {
                                    temp.push(checkedItems[i]);
                                  }
                                }
                                setCheckedItems(temp);
                                setCurrencyValues(e.target.value);
                              }}
                            >
                              {el}
                            </Checkbox>
                          );
                        })}
                      </Stack>
                    </TabPanel>
                  ) : (
                    <></>
                  )}
                  {allCountries.length > 0 && allCountries[0] !== 'Unknown' ? (
                    <TabPanel px="0">
                      <Stack
                        mt={1}
                        spacing={1}
                        className="filter-checkbox-wrapper"
                      >
                        {searchCurrency ? (
                          <></>
                        ) : (
                          <Checkbox
                            isChecked={allCheckedCountry}
                            isIndeterminate={isIndeterminateCountry}
                            py="12px"
                            mt="-12px"
                            px="10px"
                            w="100%"
                            _hover={{
                              backgroundColor: "rgba(249, 247, 242, 1)",
                            }}
                            size="sm"
                            className="filter-checkbox"
                            onChange={(e) => {
                              let temp = [];
                              for (let i = 0; i < checkedCountry.length; i++) {
                                temp.push(e.target.checked);
                              }
                              setCheckedCountry(temp);
                              if (!e.target.checked) {
                                setCountry([]);
                              } else {
                                setCountry(countries);
                              }
                            }}
                          >
                            Select All
                          </Checkbox>
                        )}
                      </Stack>
                      {searchCurrency ? (
                        <></>
                      ) : (
                        <Box border="1px" borderColor="gray.200" />
                      )}

                      <Stack
                        mt={1}
                        spacing={1}
                        className="filter-checkbox-wrapper"
                        overflowY="scroll"
                        maxH="160px"
                      >
                        {countries.map((el: any, index: number) => {
                          return (
                            <Checkbox
                              key={index}
                              isChecked={
                                checkedCountry[allCountries.indexOf(el)]
                              }
                              value={el}
                              px="10px"
                              py="8px"
                              _hover={{
                                backgroundColor: "rgba(249, 247, 242, 1)",
                              }}
                              className="filter-checkbox"
                              size="sm"
                              onChange={(e) => {
                                const leng = checkedCountry.length;
                                let temp = [];
                                for (let i = 0; i < leng; i++) {
                                  if (i === allCountries.indexOf(el)) {
                                    temp.push(e.target.checked);
                                  } else {
                                    temp.push(checkedCountry[i]);
                                  }
                                }
                                setCheckedCountry(temp);
                                setCountryValues(e.target.value);
                              }}
                            >
                              {el}
                            </Checkbox>
                          );
                        })}
                      </Stack>
                    </TabPanel>
                  ) : (
                    <></>
                  )}
                  {allExchanges.length > 0 ? (
                    <TabPanel px="0">
                      <Stack
                        mt={1}
                        spacing={1}
                        className="filter-checkbox-wrapper"
                      >
                        {searchCurrency ? (
                          <></>
                        ) : (
                          <Checkbox
                            isChecked={allcheckedExchange}
                            isIndeterminate={isIndeterminateExchange}
                            py="12px"
                            mt="-12px"
                            px="10px"
                            w="100%"
                            _hover={{
                              backgroundColor: "rgba(249, 247, 242, 1)",
                            }}
                            size="sm"
                            className="filter-checkbox"
                            onChange={(e) => {
                              let temp = [];
                              for (let i = 0; i < checkedExchange.length; i++) {
                                temp.push(e.target.checked);
                              }
                              setCheckedExchange(temp);
                              if (!e.target.checked) {
                                setExchange([]);
                              } else {
                                setExchange(exchanges);
                              }
                            }}
                          >
                            Select All
                          </Checkbox>
                        )}
                      </Stack>

                      {searchCurrency ? (
                        <></>
                      ) : (
                        <Box border="1px" borderColor="gray.200" />
                      )}

                      <Stack
                        mt={1}
                        spacing={1}
                        className="filter-checkbox-wrapper"
                        overflowY="scroll"
                        maxH="160px"
                      >
                        {exchanges.map((el: any, index: number) => {
                          return (
                            <Checkbox
                              key={index}
                              isChecked={
                                checkedExchange[allExchanges.indexOf(el)]
                              }
                              value={el}
                              px="10px"
                              py="8px"
                              _hover={{
                                backgroundColor: "rgba(249, 247, 242, 1)",
                              }}
                              className="filter-checkbox"
                              size="sm"
                              onChange={(e) => {
                                const leng = checkedExchange.length;
                                let temp = [];
                                for (let i = 0; i < leng; i++) {
                                  if (i === allExchanges.indexOf(el)) {
                                    temp.push(e.target.checked);
                                  } else {
                                    temp.push(checkedExchange[i]);
                                  }
                                }
                                setCheckedExchange(temp);
                                setExchangeValues(e.target.value);
                              }}
                            >
                              {el}
                            </Checkbox>
                          );
                        })}
                      </Stack>
                    </TabPanel>
                  ) : (
                    <></>
                  )}
                </TabPanels>
              </Tabs>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Flex>
  );
}

export default TableMarketFilter;
