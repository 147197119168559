import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import qoreContext from "../../common/qoreContext";
import { Table, Thead, Tr, Th, Tbody, Td, Stack, Flex, Tooltip } from "@chakra-ui/react";
import { DatePicker } from "chakra-ui-date-input";
import { Center, Box, useColorModeValue, Icon } from "@chakra-ui/react";
import LocalLoader from "../../components/Loader/LocalLoader";
import EmptyData from "../../components/EmptyData";
import NoDataFound from "../../components/StatusPage/NoDataFound";
import TitleBoxDetail from "../DetailBox/TitleBoxDetail";
import { RiExchangeDollarLine } from "react-icons/ri";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import PaginatorTableMarket from "./PaginatorTableMarket";
import Helpers from "../../hooks/helpers";
import DougnutChartAssetAllocationDetail from "../Chart/DougnutChartAssetAllocationDetail";
import DoughnutChart from "../Chart/DoughnutChart";
import SingleDoughnutChart from "../Chart/SingleDoughnutChart";

function AssetAllocationTable({ customer, dataTable, type }: any) {
    const [typeLabel, setTypeLabel] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [list, setList] = useState<any[]>([]);
    const [allocationData, setAllocationData] = useState<any>([]);
    const [allTransactions, setAllTransactions] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [noDataFound, setNoDataFound] = useState<boolean>(true);
    const [search, setSearch] = useState<string>("");
    const [oldestDate, setOldestDate] = useState<string>("");
    const [newestDate, setNewestDate] = useState<string>("");
    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [totalValue, setTotalValue] = useState<number>(0);
    const [customers, setCustomers] = useState<any[]>([]);
    const [asset, setAsset] = useState<any>("");

    const getTotalValue = () => {
        if (asset.length > 0) {
            let tempValue = 0;
            asset.forEach((el: any) => {
                tempValue += el.total;
            });
            setTotalValue(tempValue);
        }
    };

    const [limit, setLimit] = useState<number>(25);
    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const handlePageChange = (nextPage: number) => {
        setCurrentPage(nextPage);
    };
    const user = qoreContext.useCurrentUser();

    useEffect(() => {
        getCustomers();
    }, [user?.user?.id, customer]);

    useEffect(() => {
        handleList(data);
    }, [data]);

    useEffect(() => {
        getAssets();
    }, [customers]);

    useEffect(() => {
        getTotalValue();
    }, [asset]);

    const getAssets = () => {
        try {
            let assets: any = [];
            let ETF: any = [];
            let ETFValue: any = 0;
            let stock: any = [];
            let stockValue: any = 0;
            let fund: any = [];
            let fundValue: any = 0;
            let bond: any = [];
            let bondValue: any = 0;
            let forex: any = [];
            let forexValue: any = 0;
            let crypto: any = [];
            let cryptoValue: any = 0;

            customers.map((el: any) => {
                if (el.customer_id_items.length > 0) {
                    el.customer_id_items.map((le: any) => {
                        if (le.type === "ETF") {
                            ETF.push(le);
                            ETFValue += le.amount * le.updated_usd_value;
                        } else if (le.type === "stock") {
                            stock.push(le);
                            stockValue += le.amount * le.updated_usd_value;
                        } else if (le.type === "forex") {
                            forex.push(le);
                            forexValue += le.amount * le.updated_usd_value;
                        } else if (le.type === "CC") {
                            crypto.push(le);
                            cryptoValue += le.amount * le.updated_usd_value;
                        } else if (le.type === "mutualFund") {
                            fund.push(le);
                            fundValue += le.amount * le.updated_usd_value;
                        } else if (le.type === "bond") {
                            bond.push(le);
                            bondValue += le.amount * le.updated_usd_value;
                        }
                    });
                }
            });
            if (ETF.length > 0) {
                assets.push({ name: "ETF", data: ETF, total: ETFValue });
            }
            if (stock.length > 0) {
                assets.push({ name: "Stock", data: stock, total: stockValue });
            }
            if (fund.length > 0) {
                assets.push({ name: "Mutual Fund", data: fund, total: fundValue });
            }
            if (bond.length > 0) {
                assets.push({ name: "Bonds", data: bond, total: bondValue });
            }
            if (forex.length > 0) {
                assets.push({ name: "Forex", data: forex, total: forexValue });
            }
            if (crypto.length > 0) {
                assets.push({ name: "Crypto", data: crypto, total: cryptoValue });
            }
            setAsset(assets);
        } catch (error) { }
    };

    const params = useParams();

    const handleList = (arr: any) => {
        if (arr.length > 0 && params.detail) {
            const response = arr.map((el: any) => {
                if (type === "Asset Type") {
                    if (params.detail?.toLowerCase() === 'crypto') {
                        return el.customer_id_items.filter(
                            (le: any) => le.type.toLowerCase() === 'cc'
                        );
                    } else if (params.detail?.toLowerCase() === 'bonds') {
                        return el.customer_id_items.filter(
                            (le: any) => le.type.toLowerCase() === 'bond'
                        );
                    } if (params.detail?.toLowerCase() === 'mutual-fund') {
                        return el.customer_id_items.filter(
                            (le: any) => le.type.toLowerCase() === "mutualfund"
                        );
                    } else {
                        return el.customer_id_items.filter(
                            (le: any) => le.type.toLowerCase() === params?.detail?.toLowerCase()
                        );
                    }
                } else if (type === "Geography") {
                    return el.customer_id_items.filter(
                        (le: any) =>
                            le.location.toLowerCase() === params?.detail?.toLowerCase()
                    );
                } else if (type === "Currency") {
                    return el.customer_id_items.filter(
                        (le: any) =>
                            le.currency.toLowerCase() === params?.detail?.toLowerCase()
                    );
                } else if (type === "Industry") {
                    return el.customer_id_items.filter(
                        (le: any) =>
                            le.industry.toLowerCase() === params?.detail?.toLowerCase()
                    );
                }
            });

            console.log(response, `dapet kah?`)

            let newArr = response.filter((el: any) => el.length > 0);
            let tempArr: any = [];
            newArr.forEach((element: any) => {
                element.forEach((el: any) => {
                    tempArr.push(el);
                });
            });
            let tempAllocation: any = {};
            if (type === "Asset Type") {
                tempArr.forEach((el: any) => {
                    if (tempAllocation[el.industry]) {
                        tempAllocation[el.industry].push(el);
                    } else {
                        tempAllocation[el.industry] = [];
                        tempAllocation[el.industry].push(el);
                    }
                });
            } else {
                tempArr.forEach((el: any) => {
                    if (tempAllocation[el.type]) {
                        tempAllocation[el.type].push(el);
                    } else {
                        tempAllocation[el.type] = [];
                        tempAllocation[el.type].push(el);
                    }
                });
            }
            tempAllocation = Object.entries(tempAllocation);
            setAllocationData(tempAllocation);
            setList(tempArr);
        }
    };

    const getCustomers = async () => {
        try {
            setIsLoading(true);
            let result;
            let customerCondition = { $and: [{ rm_id: user?.user?.id }] };
            if (customer.length > 0) {
                let tempOr = { $or: [] };
                await customer.forEach((el: any) => {
                    tempOr["$or"].push({ name: el });
                });
                customerCondition["$and"].push(tempOr);
            }
            if (user?.user?.id) {
                const response = await qoreContext.client
                    .table("customers")
                    .readRows({
                        condition: customerCondition,
                        populate: ["customer_id_items"],
                    })
                    .toPromise();
                if (response?.data?.nodes) {
                    // updated_usd_value * amount
                    setPages(Math.ceil(response?.data?.nodes.length / limit));
                    result = JSON.parse(JSON.stringify(response?.data?.nodes));
                    if (search !== "") {
                        result = result.filter((el: any) =>
                            el.name.toLowerCase().includes(search.toLowerCase())
                        );
                    }
                    setCustomers(result);
                    if (response?.data?.nodes.length > 0) {
                        setNoDataFound(false);
                    }
                    setData(response?.data?.nodes);
                    setIsLoading(false);
                }
            }
        } catch (error) { }
    };

    const tableHead = [
        "Name",
        "Asset Type",
        "Geography",
        "Industry",
        "% of Total Asset",
        "Market Value",
        "Market Value (USD)"
    ]

    return (
        <div>
            <Flex pl="3" py={6}>
                {!isLoading ? (
                    !noDataFound ? (
                        <>
                        <Box
                            maxW={'sm'}
                            bg={useColorModeValue("white", "gray.800")}
                            boxShadow={"lg"}
                            rounded={"md"}
                            px="24px"
                            mr={'24px'}
                        >
                            <Box w={{ base: "full" }}>
                                <TitleBoxDetail
                                    fsIcon={"16"}
                                    iconTitle={RiExchangeDollarLine}
                                    boxTitle={`Chart ${params.detail?.split('-').join(' ')}`}
                                />
                            </Box>

                            <SingleDoughnutChart allocationData={allocationData} />
                        </Box>

                        <Box
                            w={"full"}
                            bg={useColorModeValue("white", "gray.800")}
                            boxShadow={"lg"}
                            rounded={"md"}
                            px="24px"
                            >
                            {isLoading ? (
                                <Box py="10"> {LocalLoader()} </Box>
                            ) : (
                                <>
                                    <Box w={{ base: "full" }}>
                                        <TitleBoxDetail
                                            fsIcon={"16"}
                                            iconTitle={RiExchangeDollarLine}
                                            boxTitle={`Detail ${params.detail?.split('-').join(' ')}`}
                                        />
                                    </Box>
                                    <Flex  direction="row" py="20px">
                                        {/* <SingleDoughnutChart allocationData={allocationData} /> */}
                                        <Flex
                                            w={{ base: "full" }}
                                            direction="column">
                                            <Table
                                                variant="simple"
                                                size={'lg'}
                                                fontSize="12px"
                                                mb="20px"
                                            >
                                                <Thead>
                                                    <Tr>

                                                        {tableHead.map((el: any) => {
                                                            return (
                                                                <Th 
                                                                    textAlign={ el === 'Name' ? 'left' : 'right' } 
                                                                    fontSize={"12px"} 
                                                                    textTransform="capitalize" p="12px">
                                                                        {el}
                                                                </Th>
                                                            )
                                                        })}

                                                        
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {list.length > 0 &&
                                                        list.map((el: any, index: number) => {
                                                            return (
                                                                <Tr key={index}>
                                                                    <Td textTransform="capitalize" p="12px">
                                                                        {el.name && el.name.length > 20 ?
                                                                            <Tooltip
                                                                                placement='right'
                                                                                hasArrow
                                                                                label={el.name}
                                                                                bg='#fff' color='#000'>
                                                                                {Helpers.shortenText(el.name, "200px")}
                                                                            </Tooltip>
                                                                            : el.name}
                                                                    </Td>
                                                                    <Td
                                                                        textAlign="right"
                                                                        textTransform="capitalize"
                                                                        p="12px"
                                                                    >
                                                                        {el.type}
                                                                    </Td>
                                                                    <Td
                                                                        textAlign="center"
                                                                        textTransform="capitalize"
                                                                        p="12px"
                                                                    >
                                                                        {el.location}
                                                                    </Td>
                                                                    <Td
                                                                        textAlign="right"
                                                                        textTransform="capitalize"
                                                                        maxW={'120px'}
                                                                        p="12px"
                                                                    >
                                                                        {el.industry}
                                                                    </Td>
                                                                    <Td
                                                                        textAlign="right"
                                                                        textTransform="capitalize"
                                                                        p="12px"
                                                                    >
                                                                        {Helpers.percentagePart(
                                                                            el.updated_usd_value * el.amount,
                                                                            totalValue
                                                                        )}
                                    %
                                  </Td>
                                                                    <Td
                                                                        textAlign="right"
                                                                        textTransform="capitalize"
                                                                        p="12px"
                                                                    >
                                                                        {Helpers.currencyFormater(
                                                                            el.updated_value * el.amount,
                                                                            el.currency
                                                                        )}
                                                                    </Td>
                                                                    <Td
                                                                        textAlign="right"
                                                                        textTransform="capitalize"
                                                                        maxW={'100px'}
                                                                        minW={'100px'}
                                                                        p="12px"
                                                                    >
                                                                        {Helpers.currencyFormaterUSD(
                                                                            el.updated_usd_value * el.amount
                                                                        )}
                                                                    </Td>
                                                                </Tr>
                                                            );
                                                        })}
                                                </Tbody>
                                            </Table>
                                            <Box h={'100px'}>
                                                <Box 
                                                    position={'absolute'} 
                                                    right={'50px'}
                                                    bottom={'50px'}>
                                                    <PaginatorTableMarket
                                                        pages={pages}
                                                        currentPage={currentPage}
                                                        handlePageChange={handlePageChange}
                                                    />
                                                </Box>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </>
                            )}
                        </Box>
                        </>
                    ) : (
                        <NoDataFound instrument={"customers"} />
                    )
                ) : (
                    <Box py="10"> {LocalLoader()} </Box>
                )}
            </Flex>
        </div>
    );
}

export default AssetAllocationTable;
