import { useState, useEffect } from 'react';
import {
    Box,
    Center,
    useColorModeValue,
    Flex,
    Stack,
    Spacer,
} from "@chakra-ui/react";
import TampleTable from '../Tables/TemplateTable';
import TableIndexComponents from '../Tables/TableBody/TableIndexComponents';
import TitleBoxDetail from '../DetailBox/TitleBoxDetail';

import { ImTable } from "react-icons/im";

const IndexComponents = (
    {
        dataDetail
    }: any) => {

    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [list, setList] = useState<any[]>([]);
    const [componentsIndex, setComponentsIndex] = useState<any[]>([]);

    const handlePageChange = (nextPage: number) => {
        setCurrentPage(nextPage);
    };

    useEffect(() => {
        if (componentsIndex.length > 0) {
            getList();
        }
    }, [currentPage, componentsIndex.length]);

    const getList = () => {
        let list: any = []
        componentsIndex.forEach((el: any) => {
            list.push(el);
        })

        setPages(Math.ceil(list.length / limit));
        if (limit > 0) {
            list = list.splice((currentPage - 1) * limit, limit);
        }
        setList(list);
    }

    useEffect(() => {
        if (dataDetail?.Components) {
            getIndexComponents();
        }
    }, [dataDetail]);

    const getIndexComponents = () => {
        const list = Object.entries(dataDetail.Components);
        setComponentsIndex(list);
    }

    const IndexComponents = [
        "Code",
        "Name",
        "Exchange",
        "Sector",
        "Industry",
    ]
    return (
        <Box>
            <TitleBoxDetail
                fsIcon={"20px"}
                iconTitle={ImTable}
                boxTitle={`Index List`}
            />
            {
                dataDetail.Components && (
                    <TampleTable
                        instrument={'false'}
                        customTableHead={'index'}
                        tableHeadList={IndexComponents}
                        pages={pages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                    >
                        {
                            list.length > 0 && (
                                <TableIndexComponents list={list} />
                            )
                        }
                    </TampleTable>
                )
            }
        </Box>
    );
};

export default IndexComponents;