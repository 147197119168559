import {
  Stack,
  Input,
  Flex,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import AddPlusButton from "../../components/Button/AddPlusButton";

const TableUserFilter = ({
  setSearch,
  onOpen,
  buttonText,
  tryAddUser,
}: any) => {
  const handleOpenModal = () => {
    if (tryAddUser) tryAddUser();
    onOpen();
  };

  return (
    <Flex direction="row" py="20px">
      <Stack spacing={3} maxW="230px" mr="12px">
        <InputGroup>
          <InputRightElement
            pointerEvents="none"
            h="32px"
            children={<FiSearch color="rgba(162, 162, 162, 1)" />}
          />
          <Input
            placeholder="Search by Name"
            h="32px"
            onChange={(e: any) => {
              setSearch(e.target.value);
            }}
          />
        </InputGroup>
      </Stack>
      {onOpen ? (
        <Stack ml="auto" mr="0" direction="row" spacing={4}>
          <AddPlusButton
            handleOpenModal={handleOpenModal}
            buttonText={buttonText}
          />
        </Stack>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default TableUserFilter;
