import GetEOD from "../API/getEOD";

class Helpers {
    //Get List by Date
    static filterPagination(arr: any, arr2: any, limit: any, currentPage: any) {
        if (limit > 0) {
            arr = arr2.splice((currentPage - 1) * limit, limit);
        }
        return arr;
    }

    //Search By Parameter
    static searchFilter(arr: any[], params: any) {
        arr = arr.filter((el: any) =>
            el.Code.toLowerCase().includes(params.toLowerCase())
        );

        return arr;
    }
    //Sort By Name
    static sortArrayDataByName(arr: any[]) {
        arr = arr.sort((a, b) => {
            (a = a.Name.toLowerCase()), (b = b.Name.toLowerCase());
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        });
        return arr;
    }
    // isNA
    static isNA(dataDEtail: any) {
        if (!dataDEtail || dataDEtail === null) {
            return "N/A";
        } else {
            return dataDEtail;
        }
    }
    //Unique Parameter
    static onlyUnique(value: any, index: number, self: any) {
        return self.indexOf(value) === index;
    }
    //Re-Assign Array
    static arrayReAssign(arr: any[], newArr: any[], params: string) {
        arr.forEach((el) => {
            newArr.push(el[params]);
        });
        return newArr;
    }
    //Currency Formater (USD)
    static currencyFormaterUSD(val: number) {
        const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        });

        let newVal = formatter.format(val);
        return newVal;
    }

    static currencyFormater(val: number, currency: string) {
        const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency,
        });

        if (val > 0) {
            let newVal = formatter.format(val);
            if (newVal === "$0.00") {
                return ` $ ${val}`;
            } else {
                return newVal;
            }
        } else {
            let newVal = formatter.format(Math.abs(val));
            if (newVal === "$0.00") {
                return ` $ ${val}`;
            } else {
                return ` (${newVal})`;
            }
        }
    }

    static currencyFormaterIDR(val: number) {
        const formatter = new Intl.NumberFormat("ID", {
            maximumSignificantDigits: 3,
        }).format(val);
        return formatter;
    }

    static currencyFormaterMarket(val: number) {
        const formatter = new Intl.NumberFormat("IDR").format(val);
        return formatter;
    }

    static dateFormat(date: any) {
        Date.prototype.yyyymmdd = function () {
            var mm = date.getMonth() + 1; // getMonth() is zero-based
            var dd = date.getDate();

            return [
                date.getFullYear(),
                (mm > 9 ? "" : "0") + mm,
                (dd > 9 ? "" : "0") + dd,
            ].join("-");
        };

        return date.yyyymmdd();
    }

    // Forex Region

    static forexRegion(params: any) {
        if (params.length > 3) {
            let newCode = "";
            params.split("").forEach((el: any, index: number) => {
                if (index < 3) {
                    newCode += el;
                } else if (index === 3) {
                    newCode += " / " + el;
                } else {
                    newCode += el;
                }
            });

            return newCode;
        } else {
            return params;
        }
    }

    // Label Chart Split

    static labelData(
        dataLength: any,
        index: any,
        val: any,
        filter: any,
        tempMonths: any,
        tick: any
    ) {
        let labelCount;
        if (filter === "1W") {
            return this.splitLabel(val);
        } else if (filter === "1M" || filter === "3M" || filter === "6M") {
            if (dataLength >= 9) {
                labelCount = this.getLabelCount(dataLength);
                return index % labelCount === 0 ? this.splitLabel(val) : "";
            } else {
                return this.splitLabel(val)
            }
        } else if (filter === "YTD") {
            if (index === 0) {
                return val.split(" ")[0];
            }
            if (dataLength >= 9) {
                labelCount = this.getLabelCount(dataLength);
                return index % labelCount === 0 ? this.splitLabel(val) : "";
            } else {
                return this.splitLabel(val)
            }
        } else if (filter === "1Y") {
            if (index === 0) {
                let lastYear = this.getLastYearDate(new Date());
                if (val.split("")[0] === lastYear) {
                    return `${val.split(" ")[0]} ${val.split(" ")[1]}`;
                } else {
                    return val.split(" ")[0];
                }
            }

            if (dataLength >= 9) {
                if (val.split(" ")[1] === "01") {
                    return val.split(" ")[0];
                } else {
                    labelCount = this.getLabelCount(dataLength);
                    return index % labelCount === 0 ? this.splitLabel(val) : "";
                }
            } else {
                return this.splitLabel(val)
            }
        } else if (filter === "3Y" || filter === "5Y" || filter === "MAX") {
            if (filter === '3Y') {
                labelCount = this.getLabelCount(dataLength);
                if (labelCount > 3) {
                    return index % labelCount === 0 ? this.splitLabelWithYear(val) : "";
                } else {
                    return this.splitLabel(val)
                }
            } else {
                labelCount = this.getLabelCount(dataLength);
                if (labelCount >= 9) {
                    return index % labelCount === 0 ? this.splitLabelWithYear(val) : "";
                } else {
                    if (index === 0) {
                        let lastYear = this.getLastYearDate(new Date());
                        if (val.split("")[0] === lastYear) {
                            return `${val.split(" ")[0]} ${val.split(" ")[1]}`;
                        } else {
                            return val.split(" ")[0];
                        }
                    } else {
                        if (dataLength >= 9) {
                            if (val.split(" ")[1] === "01") {
                                return val.split(" ")[0];
                            } else {
                                labelCount = this.getLabelCount(dataLength);
                                return index % labelCount === 0 ? this.splitLabel(val) : "";
                            }
                        } else {
                            return this.splitLabel(val)
                        }
                    }
                }
            }
        } else {
        }
    }

    static splitLabel(params: any) {
        let newLabel = `${params.split(" ")[1]} ${params.split(" ")[2]}`;
        return newLabel;
    }

    static splitLabelWithYear(params: any) {
        let newLabel = `${params.split(" ")[0]} ${params.split(" ")[1]}`;
        return newLabel;
    }

    static getLabelCount(val: any) {
        let count = 0;
        for (let i = 0; i < val; i++) {
            if (Number(val / i) < 13 && Number(val / i) > 10) {
                count = i;
            }
        }

        if (count <= 3) {
            count = 3;
        }

        return count;
    }

    // Find Duplicate

    static findDupe(arr: any) {
        const duplicateIds = arr
            .map((v: any) => v.code)
            .filter((v: any, i: any, vIds: any) => vIds.indexOf(v) !== i);

        let holdings = {
            list: arr.filter((obj: any) => duplicateIds.includes(obj.code)),
            notDuplicate: arr.filter((obj: any) => !duplicateIds.includes(obj.code)),
            duplicate: {},
        };

        let counts: any = {};

        for (let i = 0; i < holdings.list.length; i++) {
            let element = holdings.list[i].name;
            if (counts[holdings.list[i].name]) {
                counts[element].list.push(holdings.list[i]);
            } else {
                counts[element] = { name: element, list: [holdings.list[i]] };
            }
        }

        holdings.duplicate = counts;

        return holdings;
    }

    // Holding Dupe Object
    static getAssetAllocation(assets: any) {
        let type: any = {};
        let ETF: any = [];
        let stock: any = [];
        let fund: any = [];
        let bond: any = [];
        let forex: any = [];
        let crypto: any = [];
        let country: any = {};
        let currency: any = {};
        let industry: any = {};
        let chartData: any = {};

        assets.map((el: any) => {
            el.customer_id_items.map((le: any) => {
                if (le.type === "ETF") {
                    ETF.push(le);
                } else if (le.type === "stock" || le.type === 'Common Stock') {
                    stock.push(le);
                } else if (le.type === "forex") {
                    forex.push(le);
                } else if (le.type === "CC") {
                    crypto.push(le);
                } else if (le.type === "mutualFund") {
                    fund.push(le);
                } else if (le.type === "bond") {
                    bond.push(le);
                }
                if (!country.hasOwnProperty(le.location)) {
                    country[le.location] = [];
                    country[le.location].push(le);
                } else if (country[le.location].length > 0) {
                    country[le.location].push(le);
                }
                if (!currency.hasOwnProperty(le.currency)) {
                    currency[le.currency] = [];
                    currency[le.currency].push(le);
                } else if (currency[le.currency].length > 0) {
                    currency[le.currency].push(le);
                }
                if (!industry.hasOwnProperty(le.industry)) {
                    industry[le.industry] = [];
                    industry[le.industry].push(le);
                } else if (industry[le.industry].length > 0) {
                    industry[le.industry].push(le);
                }
            });
        });
        country = Object.entries(country);
        currency = Object.entries(currency);
        industry = Object.entries(industry);

        if (ETF.length > 0) {
            type["ETF"] = ETF;
        }
        if (stock.length > 0) {
            type["Stock"] = stock;
        }
        if (fund.length > 0) {
            type["Mutual Fund"] = fund;
        }
        if (bond.length > 0) {
            type["Bonds"] = bond;
        }
        if (forex.length > 0) {
            type["Forex"] = forex;
        }
        if (crypto.length > 0) {
            type["Crypto"] = crypto;
        }
        type = Object.entries(type);

        chartData = {
            CountryAllocation: country,
            CurrencyAllocation: currency,
            IndustryAllocation: industry,
            TypeAllocation: type,
        };

        return chartData;
    }

    // Percentage
    static percentageFormater(partialValue: number, totalValue: number) {
        return ((100 * partialValue) / totalValue - 100).toFixed(2);
    }

    static percentagePart(partialValue: number, totalValue: number) {
        return ((100 * partialValue) / totalValue).toFixed(2);
    }

    static shortenText(str: any, size: any) {
        let endValue = Math.floor(parseInt(size) / 10);
        return str.substring(0, endValue) + "...";
    }

    // Dates

    static getListDate(fromDate: any, toDate: any) {
        const getDaysArray = (start: any, end: any) => {
            for (
                var arr = [], dt = new Date(start);
                dt <= new Date(end);
                dt.setDate(dt.getDate() + 1)
            ) {
                arr.push(new Date(dt));
            }
            return arr;
        };
        let daylist = getDaysArray(new Date(fromDate), new Date(toDate));
        let dateToDate = daylist.map((v) => v.toISOString().slice(0, 10));
        return dateToDate;
    }

    static getFormatDate(val: any) {
        if (val == "01") return "Jan";
        if (val == "02") return "Feb";
        if (val == "03") return "Mar";
        if (val == "04") return "Apr";
        if (val == "05") return "May";
        if (val == "06") return "Jun";
        if (val == "07") return "Jul";
        if (val == "08") return "Aug";
        if (val == "09") return "Sep";
        if (val == "10") return "Oct";
        if (val == "11") return "Nov";
        if (val == "12") return "Dec";

        return val;
    }

    static getFormatDateYYMMDD(val: any) {
        if (val == "Jan") return "01";
        if (val == "Feb") return "02";
        if (val == "Mar") return "03";
        if (val == "Apr") return "04";
        if (val == "May") return "05";
        if (val == "Jun") return "06";
        if (val == "Jul") return "07";
        if (val == "Aug") return "08";
        if (val == "Sep") return "09";
        if (val == "Oct") return "10";
        if (val == "Nov") return "11";
        if (val == "Dec") return "12";
    }

    static getYesterday(date: any) {
        date = new Date(date);
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
        let yearData = date.getFullYear();
        let month: any = date.getMonth();
        let dateData: any = date.getDate();
        if (month + 1 < 10) {
            month = `0${month + 1}`;
        }
        if (dateData < 10) {
            dateData = `0${dateData}`;
        }
        return `${yearData}-${month}-${dateData}`;
    }

    static getTommorow(date: any) {
        date = new Date(date);
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
        let yearData = date.getFullYear();
        let month: any = date.getMonth();
        let dateData: any = date.getDate();
        if (month + 1 < 10) {
            month = `0${month + 1}`;
        }
        if (dateData < 10) {
            dateData = `0${dateData}`;
        }
        return `${yearData}-${month}-${dateData}`;
    }

    static getLast7Days() {
        const now = new Date();
        let newDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 7
        );
        return newDate;
    }

    static getLastDay() {
        const now = new Date();
        let newDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 1
        );
        return newDate;
    }

    static getLastTwoDay() {
        const now = new Date();
        let newDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 2
        );
        return newDate;
    }

    static getLast30Days() {
        const now = new Date();
        let newDate = new Date(
            now.getFullYear(),
            now.getMonth() - 1,
            now.getDate()
        );
        return newDate;
    }

    static getLast365Days() {
        const now = new Date();
        let newDate = new Date(
            now.getFullYear() - 1,
            now.getMonth(),
            now.getDate()
        );
        return newDate;
    }

    static getLastWeek() {
        const now = new Date();
        let newDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 7
        );

        let day = newDate.getDay();

        if (day <= 7) {
            newDate = new Date(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate()
            );
        }

        if (newDate.getMonth() < 10) {
            return `${newDate.getFullYear()}-0${newDate.getMonth() + 1}-${newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate()
                }`;
        } else {
            return `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate()
                }`;
        }
    }

    static getLastMonth(date: any) {
        date = new Date(date);
        date = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate());
        let day = date.getDay();
        if (day === 0) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
        } else if (day === 6) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2);
        }
        let yearData = date.getFullYear();
        let month: any = date.getMonth();
        let dateData: any = date.getDate();
        if (month + 1 < 10) {
            month = `0${month + 1}`;
        }
        if (dateData < 10) {
            dateData = `0${dateData}`;
        }
        return `${yearData}-${month}-${dateData}`;
    }

    static getLast3Month(date: any) {
        date = new Date(date);
        date = new Date(date.getFullYear(), date.getMonth() - 3, date.getDate());
        let day = date.getDay();
        if (day === 0) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
        } else if (day === 6) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2);
        }
        let yearData = date.getFullYear();
        let month: any = date.getMonth();
        let dateData: any = date.getDate();
        if (month === 11) {
            month = 12;
        }
        if (month + 1 < 10) {
            month = `0${month + 1}`;
        }
        if (dateData < 10) {
            dateData = `0${dateData}`;
        }
        return `${yearData}-${month}-${dateData}`;
    }

    static getLastYearDate(date: any) {
        date = new Date(date);
        date = new Date(date.getFullYear(), date.getMonth() - 12, date.getDate());
        let yearData = date.getFullYear();
        let month: any = date.getMonth();
        let dateData: any = date.getDate();
        let day = date.getDay();
        if (day === 0) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
        } else if (day === 6) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2);
        }
        if (month + 1 < 10) {
            month = `0${month + 1}`;
        }
        if (dateData < 10) {
            dateData = `0${dateData}`;
        }
        return `${yearData}-${month}-${dateData}`;
    }

    static getYearToDate(year: any) {
        let date: any = `${year}-01-01`;
        date = new Date(date);
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        let day = date.getDay();
        if (day === 0) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
        } else if (day === 6) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2);
        }
        let yearData = date.getFullYear();
        let month: any = date.getMonth();
        let dateData: any = date.getDate();
        if (month + 1 < 10) {
            month = `0${month + 1}`;
        }
        if (dateData < 10) {
            dateData = `0${dateData}`;
        }
        return `${yearData}-${month}-${dateData}`;
    }

    static getLast3YearDate(date: any) {
        date = new Date(date);
        date = new Date(date.getFullYear() - 3, date.getMonth(), date.getDate());
        let day = date.getDay();
        if (day === 0) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
        } else if (day === 6) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2);
        }
        let yearData = date.getFullYear();
        let month: any = date.getMonth();
        let dateData: any = date.getDate();
        if (month + 1 < 10) {
            month = `0${month + 1}`;
        }
        if (dateData < 10) {
            dateData = `0${dateData}`;
        }
        return `${yearData}-${month}-${dateData}`;
    }

    static getLast5YearDate(date: any) {
        date = new Date(date);
        date = new Date(date.getFullYear() - 5, date.getMonth(), date.getDate());
        let day = date.getDay();
        if (day === 0) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
        } else if (day === 6) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2);
        }
        let yearData = date.getFullYear();
        let month: any = date.getMonth();
        let dateData: any = date.getDate();
        if (month + 1 < 10) {
            month = `0${month + 1}`;
        }
        if (dateData < 10) {
            dateData = `0${dateData}`;
        }
        return `${yearData}-${month}-${dateData}`;
    }

    static getDate(data: any, date: any, year: any, params: any) {
        if (params === "lastWeek") {
            const lastWeek = this.getLastWeek();
            const yesterday = this.getYesterday(lastWeek);
            const tommorow = this.getTommorow(lastWeek);

            const filterDataLastWeek = data.filter((el: any) => {
                return new Date(el.date).getTime() >= new Date(lastWeek).getTime();
            });

            const t = new Date().getDate() + (6 - new Date().getDay() - 1) - 7;
            const lastFriday = new Date();
            lastFriday.setDate(t);

            if (filterDataLastWeek.length > 0) {
                if (
                    filterDataLastWeek[0].date === lastWeek ||
                    tommorow === filterDataLastWeek[0].date ||
                    yesterday === filterDataLastWeek[0].date
                ) {
                    const percentageLastWeek = `${Helpers.percentageFormater(
                        filterDataLastWeek[filterDataLastWeek.length - 1].close,
                        filterDataLastWeek[0].close
                    )}%`;

                    return percentageLastWeek;
                } else {
                    return "N/A";
                }
            }
        } else if (params === "lastMonth") {
            const lastMonth = this.getLastMonth(date);
            const yesterday = this.getYesterday(lastMonth);
            const tommorow = this.getTommorow(lastMonth);
            const filterDataLastMonth = data.filter((el: any) => {
                return el.date >= lastMonth;
            });

            // console.log(filterDataLastMonth, `filterDataLastMonth`);
            // console.log(lastMonth, `lastMonth`)

            if (filterDataLastMonth.length > 0) {
                if (
                    filterDataLastMonth[0].date === lastMonth ||
                    tommorow === filterDataLastMonth[0].date ||
                    yesterday === filterDataLastMonth[0].date
                ) {
                    const percentageLastMonth = `${Helpers.percentageFormater(
                        filterDataLastMonth[filterDataLastMonth.length - 1].close,
                        filterDataLastMonth[0].close
                    )}%`;
                    return percentageLastMonth;
                } else {
                    return "N/A";
                }
            }
        } else if (params === "last3Month") {
            const last3Month = this.getLast3Month(date);
            const yesterday = this.getYesterday(last3Month);
            const tommorow = this.getTommorow(last3Month);

            const filterDataLast3Month = data.filter((el: any) => {
                return el.date >= last3Month;
            });

            if (filterDataLast3Month.length > 0) {
                if (
                    filterDataLast3Month[0].date === last3Month ||
                    tommorow === filterDataLast3Month[0].date ||
                    yesterday === filterDataLast3Month[0].date
                ) {
                    const percentageLast3Month = `${Helpers.percentageFormater(
                        filterDataLast3Month[filterDataLast3Month.length - 1].close,
                        filterDataLast3Month[0].close
                    )}%`;
                    return percentageLast3Month;
                } else {
                    return "N/A";
                }
            }
        } else if (params === "lastYear") {
            const lastYearDate = this.getLastYearDate(date);
            const yesterday = this.getYesterday(lastYearDate);
            const tommorow = this.getTommorow(lastYearDate);
            const filterDataLastYearDate = data.filter((el: any) => {
                return el.date >= lastYearDate;
            });

            if (filterDataLastYearDate.length > 0) {
                if (
                    filterDataLastYearDate[0].date === lastYearDate ||
                    tommorow === filterDataLastYearDate[0].date ||
                    yesterday === filterDataLastYearDate[0].date
                ) {
                    const percentageLastYearDate = `${Helpers.percentageFormater(
                        filterDataLastYearDate[filterDataLastYearDate.length - 1].close,
                        filterDataLastYearDate[0].close
                    )}%`;
                    return percentageLastYearDate;
                } else {
                    return "N/A";
                }
            }
        } else if (params === "YTD") {
            const yearToDate = this.getYearToDate(year);
            const yesterday = this.getYesterday(yearToDate);
            const tommorow = this.getTommorow(yearToDate);

            const filterDataYearToDate = data.filter((el: any) => {
                return el.date >= yearToDate;
            });

            // if (filterDataYearToDate.length > 0) {
            //     if (
            //         filterDataYearToDate[0].date == yearToDate ||
            //         tommorow == filterDataYearToDate[0].date ||
            //         yesterday == filterDataYearToDate[0].date
            //     ) {
            //         const percentageYearToDate = `${Helpers.percentageFormater(
            //             filterDataYearToDate[filterDataYearToDate.length - 1].close,
            //             filterDataYearToDate[0].close
            //         )}%`;
            //         return percentageYearToDate;
            //     } else {
            //         return "N/A";
            //     }
            // }
            const percentageYearToDate = `${Helpers.percentageFormater(
                filterDataYearToDate[filterDataYearToDate.length - 1].close,
                filterDataYearToDate[0].close
            )}%`;
            return percentageYearToDate;
        } else if (params === "last3Year") {
            const last3YearDate = this.getLast3YearDate(date);
            const yesterday = this.getYesterday(last3YearDate);
            const tommorow = this.getTommorow(last3YearDate);

            const filterDataLast3YearDate = data.filter((el: any) => {
                return el.date >= last3YearDate;
            });

            if (filterDataLast3YearDate.length > 0) {
                if (
                    filterDataLast3YearDate[0].date === last3YearDate ||
                    tommorow === filterDataLast3YearDate[0].date ||
                    yesterday === filterDataLast3YearDate[0].date
                ) {
                    const percentageLast3YearDate = `${Helpers.percentageFormater(
                        filterDataLast3YearDate[filterDataLast3YearDate.length - 1].close,
                        filterDataLast3YearDate[0].close
                    )}%`;
                    return percentageLast3YearDate;
                } else {
                    return "N/A";
                }
            }
        } else if (params === "last5Year") {
            const last5YearDate = this.getLast5YearDate(date);
            const yesterday = this.getYesterday(last5YearDate);
            const tommorow = this.getTommorow(last5YearDate);
            const filterDataLast5YearDate = data.filter((el: any) => {
                return new Date(el.date).getTime() >= new Date(last5YearDate).getTime();
            });

            if (filterDataLast5YearDate.length > 0) {
                if (
                    filterDataLast5YearDate[0].date === last5YearDate ||
                    tommorow === filterDataLast5YearDate[0].date ||
                    yesterday === filterDataLast5YearDate[0].date
                ) {
                    const percentageLast5YearDate = `${Helpers.percentageFormater(
                        filterDataLast5YearDate[filterDataLast5YearDate.length - 1].close,
                        filterDataLast5YearDate[0].close
                    )}%`;
                    return percentageLast5YearDate;
                } else {
                    return "N/A";
                }
            }
        }
    }

    // Compare Chart Data
    static getFilteredData(dataChart: any, params: any, currentDate: any) {
        const year = new Date().getUTCFullYear();
        const today = new Date();
        const monthFormat = (val: any) => {
            if (val == "01") return "Jan";
            if (val == "02") return "Feb";
            if (val == "03") return "Mar";
            if (val == "04") return "Apr";
            if (val == "05") return "May";
            if (val == "06") return "Jun";
            if (val == "07") return "Jul";
            if (val == "08") return "Aug";
            if (val == "09") return "Sep";
            if (val == "10") return "Oct";
            if (val == "11") return "Nov";
            if (val == "12") return "Dec";
        };
        const borderColor = [
            "rgba(77, 147, 255, 1)",
            "rgba(168, 139, 233, 1)",
            "rgba(173, 214, 167, 1)",
            "rgba(153, 193, 255, 1)",
            "rgba(205, 189, 242, 1)",
            "rgba(168, 139, 233, 1)",
        ];

        const backgroundColor = [
            "rgba(77, 147, 255, 1)",
            "rgba(168, 139, 233, 1)",
            "rgba(173, 214, 167, 1)",
            "rgba(153, 193, 255, .2)",
            "rgba(205, 189, 242, .2)",
            "rgba(168, 139, 233, .2)",
        ];

        let tempData: any = [];

        if (dataChart.length > 0) {
            if (params === "1W") {
                dataChart.forEach((el: any, index: number) => {
                    const lastWeek = Helpers.getLastWeek();
                    let noData: any = [];

                    const filterDataLastWeek = el.data.filter(
                        (le: any, index: number) => {
                            return (
                                new Date(le.date).getTime() >= new Date(lastWeek).getTime()
                            );
                        }
                    );
                    tempData.push({ name: el.name, data: filterDataLastWeek });
                });
            } else if (params === "1M") {
                dataChart.forEach((el: any, index: number) => {
                    const lastMonth = Helpers.getLastMonth(currentDate);

                    const filterDataLastMonth = el.data.filter((le: any) => {
                        return le.date >= lastMonth;
                    });
                    tempData.push({ name: el.name, data: filterDataLastMonth });
                });
            } else if (params === "3M") {
                dataChart.forEach((el: any, index: number) => {
                    const last3Month = Helpers.getLast3Month(currentDate);

                    const filterDataLastMonth = el.data.filter((le: any) => {
                        return le.date >= last3Month;
                    });
                    tempData.push({ name: el.name, data: filterDataLastMonth });
                });
            } else if (params === "YTD") {
                dataChart.forEach((el: any, index: number) => {
                    const YTD = Helpers.getYearToDate(year);

                    const filterDataYTD = el.data.filter((le: any) => {
                        return le.date >= YTD;
                    });
                    tempData.push({ name: el.name, data: filterDataYTD });
                });
            } else if (params === "1Y") {
                dataChart.forEach((el: any, index: number) => {
                    const lastYear = Helpers.getLastYearDate(currentDate);

                    const filterDataLastYear = el.data.filter((le: any) => {
                        return le.date >= lastYear;
                    });
                    tempData.push({ name: el.name, data: filterDataLastYear });
                });
            } else if (params === "3Y") {
                dataChart.forEach((el: any, index: number) => {
                    const last3Year = Helpers.getLast3YearDate(currentDate);

                    const filterDataLast3Year = el.data.filter((le: any) => {
                        return le.date >= last3Year;
                    });
                    tempData.push({ name: el.name, data: filterDataLast3Year });
                });
            } else if (params === "5Y") {
                dataChart.forEach((el: any, index: number) => {
                    const last5Year = Helpers.getLast5YearDate(currentDate);

                    const filterDataLast5Year = el.data.filter((le: any) => {
                        return le.date >= last5Year;
                    });
                    tempData.push({ name: el.name, data: filterDataLast5Year });
                });
            }

            if (params === "MAX") {
                let tempChartLabels: any = [];
                dataChart[dataChart.length - 1].data.forEach((el: any) => {
                    const date = `${el.date.split("-")[0]} ${monthFormat(
                        el.date.split("-")[1]
                    )} ${el.date.split("-")[2]}`;
                    tempChartLabels.push(date);
                });
                let tempChartData: any = [];
                let tempTabularData: any = [];

                const currentDate = new Date();
                const formatedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() > 9
                    ? currentDate.getMonth()
                    : `0${currentDate.getMonth()}`
                    }-${currentDate.getDate() > 9
                        ? currentDate.getDate()
                        : `0${currentDate.getDate()}`
                    }`;
                dataChart.forEach((el: any, index: number) => {
                    const lastWeek = this.getDate(el.data, currentDate, year, "lastWeek");
                    const lastMonth = this.getDate(
                        el.data,
                        currentDate,
                        year,
                        "lastMonth"
                    );
                    const last3Month = this.getDate(
                        el.data,
                        currentDate,
                        year,
                        "last3Month"
                    );
                    const lastYear = this.getDate(el.data, currentDate, year, "lastYear");
                    const YTD = this.getDate(el.data, currentDate, year, "YTD");
                    const last3Year = this.getDate(
                        el.data,
                        currentDate,
                        year,
                        "last3Year"
                    );
                    const last5Year = this.getDate(
                        el.data,
                        currentDate,
                        year,
                        "last5Year"
                    );

                    const maxData = `${Helpers.percentageFormater(
                        el.data[el.data.length - 1].close,
                        el.data[0].close
                    )}%`;

                    tempTabularData.push({
                        name: el.name,
                        lastWeek,
                        lastMonth,
                        last3Month,
                        lastYear,
                        YTD,
                        last3Year,
                        last5Year,
                        maxData,
                        startingPrice: 0,
                        latestPrice: 0,
                    });

                    let chartDataClose = [];
                    if (el.data.length < tempChartLabels.length) {
                        for (
                            let i = 0;
                            i < tempChartLabels.length - el.data.length - 1;
                            i++
                        ) {
                            chartDataClose.push(null);
                        }
                    }

                    for (let i = 0; i < el.data.length; i++) {
                        let initialValue: any = 0;
                        if (el.data[0].close) {
                            initialValue = el.data[0].close;
                        } else {
                            initialValue = el.data[0].price;
                        }
                        if (!el.data[i].close) {
                            let percentage = Helpers.percentageFormater(
                                el.data[i].price,
                                initialValue
                            );

                            chartDataClose.push(percentage);
                        } else {
                            let percentage = Helpers.percentageFormater(
                                el.data[i].close,
                                initialValue
                            );

                            chartDataClose.push(percentage);
                        }
                    }

                    if (chartDataClose.length > 0) {
                        tempChartData.push({
                            label: ` ${el.name}`,
                            lineTension: 0.1,
                            backgroundColor: backgroundColor[index],
                            borderColor: borderColor[index],
                            pointHoverBorderWidth: 3,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: chartDataClose,
                        });
                    }
                });

                let diffrence: any = [];
                const yearToDate = this.getYearToDate(year);
                const lastWeek = this.getLastWeek();
                const lastMonth = this.getLastMonth(today);
                const last3Month = this.getLast3Month(today);
                const lastYearDate = this.getLastYearDate(today);
                const last3YearDate = this.getLast3YearDate(today);
                const last5YearDate = this.getLast5YearDate(today);
                dataChart.forEach((el: any, index: number) => {
                    let obj: any = {
                        name: el.name,
                        YTD: "",
                        lastMonth: "",
                        last3Month: "",
                        lastWeek: "",
                        lastYear: "",
                        last3Year: "",
                        last5Year: "",
                        Max: "",
                        currency: el.currency,
                    };
                    tempTabularData.forEach((element: any) => {
                        if (element.YTD !== "N/A") {
                            const filterDataYearToDate = el.data.filter((el: any) => {
                                return el.date >= yearToDate;
                            });
                            obj.YTD = "";
                            if (filterDataYearToDate.length > 0) {
                                obj.YTD += Number(
                                    filterDataYearToDate[filterDataYearToDate.length - 1].close -
                                    filterDataYearToDate[0].close
                                );
                            }
                        } else {
                            if (obj.YTD !== "N/A") {
                                obj.YTD += "N/A";
                            }
                        }

                        if (element.lastWeek !== "N/A") {
                            const filterDataLastWeek = el.data.filter((el: any) => {
                                return (
                                    new Date(el.date).getTime() >= new Date(lastWeek).getTime()
                                );
                            });

                            obj.lastWeek = "";
                            if (filterDataLastWeek.length > 0) {
                                obj.lastWeek += Number(
                                    filterDataLastWeek[filterDataLastWeek.length - 1].close -
                                    filterDataLastWeek[0].close
                                );
                            }
                        } else {
                            if (obj.lastWeek !== "N/A") {
                                obj.lastWeek += "N/A";
                            }
                        }

                        if (element.lastMonth !== "N/A") {
                            const filterDataLastMonth = el.data.filter((el: any) => {
                                return el.date >= lastMonth;
                            });

                            obj.lastMonth = "";
                            if (filterDataLastMonth.length > 0) {
                                obj.lastMonth += Number(
                                    filterDataLastMonth[filterDataLastMonth.length - 1].close -
                                    filterDataLastMonth[0].close
                                );
                            }
                        } else {
                            if (obj.lastMonth !== "N/A") {
                                obj.lastMonth += "N/A";
                            }
                        }

                        if (element.last3Month !== "N/A") {
                            const filterDataLast3Month = el.data.filter((el: any) => {
                                return el.date >= last3Month;
                            });

                            obj.last3Month = "";
                            if (filterDataLast3Month.length > 0) {
                                obj.last3Month += Number(
                                    filterDataLast3Month[filterDataLast3Month.length - 1].close -
                                    filterDataLast3Month[0].close
                                );
                            }
                        } else {
                            if (obj.last3Month !== "N/A") {
                                obj.last3Month += "N/A";
                            }
                        }

                        if (element.lastYear !== "N/A") {
                            const filterDataLastYearDate = el.data.filter((el: any) => {
                                return el.date >= lastYearDate;
                            });

                            obj.lastYear = "";

                            if (filterDataLastYearDate.length > 0) {
                                obj.lastYear += Number(
                                    filterDataLastYearDate[filterDataLastYearDate.length - 1]
                                        .close - filterDataLastYearDate[0].close
                                );
                            }
                        } else {
                            if (obj.lastYear !== "N/A") {
                                obj.lastYear += "N/A";
                            }
                        }

                        if (element.last3Year !== "N/A") {
                            const filterDataLast3YearDate = el.data.filter((el: any) => {
                                return el.date >= last3YearDate;
                            });

                            obj.last3Year = "";
                            if (filterDataLast3YearDate.length > 0) {
                                obj.last3Year += Number(
                                    filterDataLast3YearDate[filterDataLast3YearDate.length - 1]
                                        .close - filterDataLast3YearDate[0].close
                                );
                            }
                        } else {
                            if (obj.last3Year !== "N/A") {
                                obj.last3Year += "N/A";
                            }
                        }

                        if (element.last5Year !== "N/A") {
                            const filterDataLast5YearDate = el.data.filter((el: any) => {
                                return (
                                    new Date(el.date).getTime() >=
                                    new Date(last5YearDate).getTime()
                                );
                            });
                            obj.last5Year = "";
                            if (filterDataLast5YearDate.length > 0) {
                                obj.last5Year += Number(
                                    filterDataLast5YearDate[filterDataLast5YearDate.length - 1]
                                        .close - filterDataLast5YearDate[0].close
                                );
                            }
                        } else {
                            if (obj.last5Year !== "N/A") {
                                obj.last5Year += "N/A";
                            }
                        }

                        obj.Max = "";

                        if (element.Max !== "N/A") {
                            if (el.data.length > 0) {
                                obj.Max += Number(
                                    el.data[el.data.length - 1].close - el.data[0].close
                                );
                            }
                        } else {
                            if (obj.Max !== "N/A") {
                                obj.Max += "N/A";
                            }
                        }
                    });
                    diffrence.push(obj);
                });

                tempTabularData.forEach((el: any) => {
                    diffrence.forEach((diff: any) => {
                        if (el.name === diff.name) {
                            el.Val = [];
                            el.Val.push(diff);
                        }
                    });
                });

                return [tempChartLabels, tempTabularData, tempChartData];
            } else {
                let tempChartLabels: any = [];
                tempData[tempData.length - 1].data.forEach((el: any) => {
                    const date = `${el.date.split("-")[0]} ${monthFormat(
                        el.date.split("-")[1]
                    )} ${el.date.split("-")[2]}`;
                    tempChartLabels.push(date);
                });

                //Lol

                let newLabel: any = [];

                let tempChartData: any = [];
                tempData.forEach((el: any, index: number) => {
                    let chartDataClose = [];
                    if (el.data.length < tempChartLabels.length) {
                        for (
                            let i = 0;
                            i < tempChartLabels.length - el.data.length - 1;
                            i++
                        ) {
                            chartDataClose.push(null);
                        }
                    }

                    for (let i = 0; i < el.data.length; i++) {
                        let initialValue: any = 0;
                        if (el.data[0].close) {
                            initialValue = el.data[0].close;
                        } else {
                            initialValue = el.data[0].price;
                        }

                        if (!el.data[i].close) {
                            let percentage = Helpers.percentageFormater(
                                el.data[i].price,
                                initialValue
                            );
                            chartDataClose.push(percentage);
                        } else {
                            let percentage = Helpers.percentageFormater(
                                el.data[i].close,
                                initialValue
                            );

                            chartDataClose.push(percentage);
                        }
                    }

                    if (chartDataClose.length > 0) {
                        let pointRadius;
                        if (params === "MAX" || params === "5Y" || params === "1Y") {
                            pointRadius = 1;
                        } else if (params === "3M" || params === "YTD") {
                            pointRadius = 2;
                        }

                        tempChartData.push({
                            label: ` ${el.name}`,
                            tension: 0.4,
                            lineTension: 0.3,
                            backgroundColor: backgroundColor[index],
                            borderColor: borderColor[index],
                            pointHoverBorderWidth: 3,
                            pointRadius: pointRadius,
                            pointHitRadius: 10,
                            data: chartDataClose,
                        });
                    }
                });
                return [tempChartLabels, tempChartData];
            }
        }
    }

    static getRangeFilter(currentDate: any, params: any) {
        const year = new Date().getUTCFullYear();
        let filterRange: any;

        if (params === "1W") {
            filterRange = Helpers.getLastWeek();
        } else if (params === "1M") {
            filterRange = Helpers.getLastMonth(currentDate);
        } else if (params === "3M") {
            filterRange = Helpers.getLast3Month(currentDate);
        } else if (params === "YTD") {
            filterRange = Helpers.getYearToDate(year);
        } else if (params === "1Y") {
            filterRange = Helpers.getLastYearDate(currentDate);
        } else if (params === "3Y") {
            filterRange = Helpers.getLast3YearDate(currentDate);
        } else if (params === "5Y") {
            filterRange = Helpers.getLast5YearDate(currentDate);
        }

        return filterRange;
    }

    // Compare Chart Index & Performance
    static getChartData(dataChart: any, params: any, currentDate: any) {
        const today = new Date();
        const year = new Date().getUTCFullYear();

        const borderColor = [
            "rgba(77, 147, 255, 1)",
            "rgba(168, 139, 233, 1)",
            "rgba(173, 214, 167, 1)",
            "rgba(153, 193, 255, 1)",
            "rgba(205, 189, 242, 1)",
            "rgba(168, 139, 233, 1)",
        ];

        const backgroundColor = [
            "rgba(77, 147, 255, 1)",
            "rgba(168, 139, 233, 1)",
            "rgba(173, 214, 167, 1)",
            "rgba(153, 193, 255, .2)",
            "rgba(205, 189, 242, .2)",
            "rgba(168, 139, 233, .2)",
        ];

        let tempData: any = [];
        console.log(dataChart, `Loooooooooool`) 
    
        if (dataChart.length > 0) {
            let filterRange: any = this.getRangeFilter(currentDate, params);

            dataChart.forEach((el: any) => {
                const filterData = el.data.filter((le: any) => {
                    return new Date(le.date).getTime() >= new Date(filterRange).getTime();
                });

                if (filterData.length > 0) {
                    let perFormancePercentage = 0;
                    let performanceValue = 0;

                    filterData.forEach((data: any, index: number) => {
                        performanceValue += data.close;
                        if (filterData[filterData.length - 1] === index) {
                            perFormancePercentage += data.close / filterData[0].close;
                        }
                        data.name = el.name;
                    });
                    tempData.push({
                        name: el.name,
                        data: filterData,
                        performanceValue: performanceValue,
                        perFormancePercentage,
                    });
                }
            });

            if (params === "MAX") {
                let tempChartLabels: any = [];
                const customerDate = new Date(dataChart[0].data[0].date)
                dataChart[dataChart.length - 1].data.forEach((el: any) => {
                    const date = `${el.date.split("-")[0]} ${this.getFormatDate(
                        el.date.split("-")[1]
                    )} ${el.date.split("-")[2]}`;
                    tempChartLabels.push(date); 
                });
                let tempChartData: any = [];
                let tempTabularData: any = [];

                const currentDate = new Date();
                const formatedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() > 9
                    ? currentDate.getMonth()
                    : `0${currentDate.getMonth()}`
                    }-${currentDate.getDate() > 9
                        ? currentDate.getDate()
                        : `0${currentDate.getDate()}`
                    }`;

                dataChart.forEach((el: any, index: number) => {
                    const lastWeek = this.getDate(el.data, currentDate, year, "lastWeek");
                    const lastMonth = this.getDate(
                        el.data,
                        currentDate,
                        year,
                        "lastMonth"
                    );
                    const last3Month = this.getDate(
                        el.data,
                        currentDate,
                        year,
                        "last3Month"
                    );
                    const lastYear = this.getDate(el.data, currentDate, year, "lastYear");
                    const YTD = this.getDate(el.data, currentDate, year, "YTD");
                    const last3Year = this.getDate(
                        el.data,
                        currentDate,
                        year,
                        "last3Year"
                    );
                    const last5Year = this.getDate(
                        el.data,
                        currentDate,
                        year,
                        "last5Year"
                    );

                    const maxData = `${Helpers.percentageFormater(
                        el.data[el.data.length - 1].close,
                        el.data[0].close
                    )}%`;

                    tempTabularData.push({
                        name: el.name,
                        lastWeek,
                        lastMonth,
                        last3Month,
                        lastYear,
                        YTD,
                        last3Year,
                        last5Year,
                        maxData,
                    });

                    let chartDataClose = [];
                    if (el.data.length < tempChartLabels.length) {
                        for (
                            let i = 0;
                            i < tempChartLabels.length - el.data.length - 1;
                            i++
                        ) {
                            chartDataClose.push(null);
                        }
                    }

                    for (let i = 0; i < el.data.length; i++) {
                        let initialValue: any = 0;
                        if (el.data[0].close) {
                            initialValue = el.data[0].close;
                        } else {
                            initialValue = el.data[0].price;
                        }
                        if (!el.data[i].close) {
                            let percentage = Helpers.percentageFormater(
                                el.data[i].price,
                                initialValue
                            );

                            chartDataClose.push(percentage);
                        } else {
                            let percentage = Helpers.percentageFormater(
                                el.data[i].close,
                                initialValue
                            );

                            chartDataClose.push(percentage);
                        }
                    }

                    console.log(chartDataClose, `chartDataClose`)

                    if (chartDataClose.length > 0) {
                        tempChartData.push({
                            label: ` ${el.name}`,
                            lineTension: 0.1,
                            backgroundColor: backgroundColor[index],
                            borderColor: borderColor[index],
                            pointHoverBorderWidth: 3,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: chartDataClose,
                        });
                    }
                });

                console.log(tempChartData, `tempChartData`)

                let diffrence: any = [];
                const yearToDate = this.getYearToDate(year);
                const lastWeek = this.getLastWeek();
                const lastMonth = this.getLastMonth(today);
                const last3Month = this.getLast3Month(today);
                const lastYearDate = this.getLastYearDate(today);
                const last3YearDate = this.getLast3YearDate(today);
                const last5YearDate = this.getLast5YearDate(today);
                dataChart.forEach((el: any, index: number) => {
                    let obj: any = {
                        name: el.name,
                        YTD: "",
                        lastMonth: "",
                        last3Month: "",
                        lastWeek: "",
                        lastYear: "",
                        last3Year: "",
                        last5Year: "",
                        Max: "",
                        currency: el.currency,
                    };
                    tempTabularData.forEach((element: any) => {
                        if (element.YTD !== "N/A") {
                            const filterDataYearToDate = el.data.filter((el: any) => {
                                return el.date >= yearToDate;
                            });
                            obj.YTD = "";
                            if (filterDataYearToDate.length > 0) {
                                obj.YTD += Number(
                                    filterDataYearToDate[filterDataYearToDate.length - 1].close -
                                    filterDataYearToDate[0].close
                                );
                            }
                        } else {
                            if (obj.YTD !== "N/A") {
                                obj.YTD += "N/A";
                            }
                        }

                        if (element.lastWeek !== "N/A") {
                            const filterDataLastWeek = el.data.filter((el: any) => {
                                return (
                                    new Date(el.date).getTime() >= new Date(lastWeek).getTime()
                                );
                            });

                            obj.lastWeek = "";
                            if (filterDataLastWeek.length > 0) {
                                obj.lastWeek += Number(
                                    filterDataLastWeek[filterDataLastWeek.length - 1].close -
                                    filterDataLastWeek[0].close
                                );
                            }
                        } else {
                            if (obj.lastWeek !== "N/A") {
                                obj.lastWeek += "N/A";
                            }
                        }

                        if (element.lastMonth !== "N/A") {
                            const filterDataLastMonth = el.data.filter((el: any) => {
                                return el.date >= lastMonth;
                            });

                            obj.lastMonth = "";
                            if (filterDataLastMonth.length > 0) {
                                obj.lastMonth += Number(
                                    filterDataLastMonth[filterDataLastMonth.length - 1].close -
                                    filterDataLastMonth[0].close
                                );
                            }
                        } else {
                            if (obj.lastMonth !== "N/A") {
                                obj.lastMonth += "N/A";
                            }
                        }

                        if (element.last3Month !== "N/A") {
                            const filterDataLast3Month = el.data.filter((el: any) => {
                                return el.date >= last3Month;
                            });

                            obj.last3Month = "";
                            if (filterDataLast3Month.length > 0) {
                                obj.last3Month += Number(
                                    filterDataLast3Month[filterDataLast3Month.length - 1].close -
                                    filterDataLast3Month[0].close
                                );
                            }
                        } else {
                            if (obj.last3Month !== "N/A") {
                                obj.last3Month += "N/A";
                            }
                        }

                        if (element.lastYear !== "N/A") {
                            const filterDataLastYearDate = el.data.filter((el: any) => {
                                return el.date >= lastYearDate;
                            });

                            obj.lastYear = "";

                            if (filterDataLastYearDate.length > 0) {
                                obj.lastYear += Number(
                                    filterDataLastYearDate[filterDataLastYearDate.length - 1]
                                        .close - filterDataLastYearDate[0].close
                                );
                            }
                        } else {
                            if (obj.lastYear !== "N/A") {
                                obj.lastYear += "N/A";
                            }
                        }

                        if (element.last3Year !== "N/A") {
                            const filterDataLast3YearDate = el.data.filter((el: any) => {
                                return el.date >= last3YearDate;
                            });

                            obj.last3Year = "";
                            if (filterDataLast3YearDate.length > 0) {
                                obj.last3Year += Number(
                                    filterDataLast3YearDate[filterDataLast3YearDate.length - 1]
                                        .close - filterDataLast3YearDate[0].close
                                );
                            }
                        } else {
                            if (obj.last3Year !== "N/A") {
                                obj.last3Year += "N/A";
                            }
                        }

                        if (element.last5Year !== "N/A") {
                            const filterDataLast5YearDate = el.data.filter((el: any) => {
                                return (
                                    new Date(el.date).getTime() >=
                                    new Date(last5YearDate).getTime()
                                );
                            });
                            obj.last5Year = "";
                            if (filterDataLast5YearDate.length > 0) {
                                obj.last5Year += Number(
                                    filterDataLast5YearDate[filterDataLast5YearDate.length - 1]
                                        .close - filterDataLast5YearDate[0].close
                                );
                            }
                        } else {
                            if (obj.last5Year !== "N/A") {
                                obj.last5Year += "N/A";
                            }
                        }

                        obj.Max = "";

                        if (element.Max !== "N/A") {
                            if (el.data.length > 0) {
                                obj.Max += Number(
                                    el.data[el.data.length - 1].close - el.data[0].close
                                );
                            }
                        } else {
                            if (obj.Max !== "N/A") {
                                obj.Max += "N/A";
                            }
                        }
                    });
                    diffrence.push(obj);
                });

                tempTabularData.forEach((el: any) => {
                    diffrence.forEach((diff: any) => {
                        if (el.name === diff.name) {
                            el.Val = [];
                            el.Val.push(diff);
                        }
                    });
                });

                if (dataChart.length > 1) {
                    const newChartData: any = []
                    const newTemp: any = []
                    const newLabel: any = []
                    tempChartData[0].data.forEach((el: any) => {
                        if (el) {
                            newTemp.push(el);
                        }
                    })

                    const labelLength = tempChartLabels.length - newTemp.length

                    let labelsFlag = false

                    // tempChartLabels.forEach((el: any, index: number) => {

                    //     if (index >= labelLength) {
                    //         newLabel.push(el);
                    //     }
                    // })

                    let tempLabel: any = {};
                    let temp: any;

                    let maxFilterRange: any;

                    dataChart.forEach((el: any) => {
                        const filterData = el.data.filter((le: any) => {
                            return new Date(le.date).getTime() >= new Date(dataChart[0].data[0].date).getTime();
                        });

                        if (filterData.length > 0) {
                            let perFormancePercentage = 0;
                            let performanceValue = 0;

                            filterData.forEach((data: any, index: number) => {
                                performanceValue += data.close;
                                if (filterData[filterData.length - 1] === index) {
                                    perFormancePercentage += data.close / filterData[0].close;
                                }
                                data.name = el.name;
                            });
                            tempData.push({
                                name: el.name,
                                data: filterData,
                                performanceValue: performanceValue,
                                perFormancePercentage,
                            });
                        }
                    });

                    tempData.forEach((el: any, index: number) => {
                        if (index === 0) {
                            el.data.forEach((performance: any) => {
                                const date = `${performance.date.split("-")[0]
                                    } ${this.getFormatDate(performance.date.split("-")[1])} ${performance.date.split("-")[2]
                                    }`;
                                newLabel.push(date);

                                if (tempLabel[performance.date]) {
                                    tempLabel[performance.date].eodPerformance.push(performance);
                                } else {
                                    tempLabel[performance.date] = {
                                        eodIndex: [],
                                        eodPerformance: [],
                                    };
                                    tempLabel[performance.date].eodPerformance.push(performance);
                                }
                            });
                        } else {
                            let isValidDate = new Date(tempData[0].data[0].date);
                            el.data.forEach((filterEod: any, indexFilterEod: number) => {
                                if (tempLabel[filterEod.date]) {
                                    filterEod.name = el.name;
                                    tempLabel[filterEod.date].eodIndex.push(filterEod);
                                } else {
                                    let conditionDate = new Date(filterEod.date)
                                    if (conditionDate.getTime() >= isValidDate.getTime()) {
                                        tempLabel[filterEod.date] = {
                                            eodIndex: [],
                                            eodPerformance: [],
                                        };
                                        tempLabel[filterEod.date].eodIndex.push(filterEod);
                                    }
                                }
                            });

                            temp = Object.entries(tempLabel);
                        }
                    });

                    console.log(newLabel)
                    console.log(temp, `temp`) 
                    console.log(tempChartData, `tempChartData`)

                    console.log(tempData, `tempData`)
                    newLabel.forEach((date: any) => {
                        console.log(date, `date`) 
                    })

                    temp.forEach((el: any) => {
                        newLabel.forEach((dat: any) => {
                            console.log()
                        })
                        console.log(el, `el`)
                    })

                    tempChartData.forEach((el: any, index: number) => {
                        if (index === 0) {
                            newChartData.push({
                                label: `${el.label}`,
                                lineTension: 0.1,
                                backgroundColor: backgroundColor[index],
                                borderColor: borderColor[index],
                                pointHoverBorderWidth: 3,
                                pointRadius: 1,
                                pointHitRadius: 10,
                                data: newTemp,
                            });
                        } else {
                            let newComparisonData: any = []
                            let tempComparisonData: any = []
                            let arr: any = tempData[tempChartData.length - 1].data
                            arr.forEach((el: any, index: number) => {
                                // if (index >= labelLength) {
                                //     tempComparisonData.push(el);
                                // }
                                let newPercentage = 0

                                if (index !== 0) {
                                    newPercentage += Number((( el.close / arr[0].close) - 1) * 100)
                                }

                                newComparisonData.push(newPercentage.toFixed(2))
                            })   
                            tempComparisonData[0] = 0
                            console.log(tempComparisonData, `tempComparisonData`)
                            console.log(newComparisonData, `newComparisonData`)
                            newChartData.push({
                                label: `${el.label}`,
                                lineTension: 0.1,
                                backgroundColor: backgroundColor[index],
                                borderColor: borderColor[index],
                                pointHoverBorderWidth: 3,
                                pointRadius: 1,
                                pointHitRadius: 10,
                                data: newComparisonData,
                            });                
                        }
                    })

                    console.log(newChartData, `newChartData`)

                    return [newLabel, tempTabularData, newChartData, diffrence];
                } else {
                    return [tempChartLabels, tempTabularData, tempChartData, diffrence];
                }
                // return [tempChartLabels, tempTabularData, tempChartData, diffrence];
            } else {
                if (tempData.length > 0) {
                    let tempChartLabels: any = [];
                    tempData[tempData.length - 1].data.forEach((el: any) => {
                        const date = `${el.date.split("-")[0]} ${this.getFormatDate(
                            el.date.split("-")[1]
                        )} ${el.date.split("-")[2]}`;
                        tempChartLabels.push(date);
                    });

                    let newLabel: any = [];
                    let tempLabel: any = {};
                    let temp: any;

                    tempData.forEach((el: any, index: number) => {
                        if (index === 0) {
                            el.data.forEach((performance: any) => {
                                const date = `${performance.date.split("-")[0]
                                    } ${this.getFormatDate(performance.date.split("-")[1])} ${performance.date.split("-")[2]
                                    }`;
                                newLabel.push(date);

                                if (tempLabel[performance.date]) {
                                    tempLabel[performance.date].eodPerformance.push(performance);
                                } else {
                                    tempLabel[performance.date] = {
                                        eodIndex: [],
                                        eodPerformance: [],
                                    };
                                    tempLabel[performance.date].eodPerformance.push(performance);
                                }
                            });
                        } else {
                            let isValidDate = new Date(tempData[0].data[0].date);
                            el.data.forEach((filterEod: any, indexFilterEod: number) => {
                                if (tempLabel[filterEod.date]) {
                                    filterEod.name = el.name;
                                    tempLabel[filterEod.date].eodIndex.push(filterEod);
                                } else {
                                    let conditionDate = new Date(filterEod.date)
                                    if (conditionDate.getTime() >= isValidDate.getTime()) {
                                        tempLabel[filterEod.date] = {
                                            eodIndex: [],
                                            eodPerformance: [],
                                        };
                                        tempLabel[filterEod.date].eodIndex.push(filterEod);
                                    }
                                }
                            });

                            temp = Object.entries(tempLabel);
                        }
                    });

                    if (tempData.length === 1) {
                        let tempChartData: any = [];

                        tempData.forEach((el: any, index: number) => {
                            let chartDataClose = [];
                            if (el.data.length < tempChartLabels.length) {
                                for (
                                    let i = 0;
                                    i < tempChartLabels.length - el.data.length - 1;
                                    i++
                                ) {
                                    chartDataClose.push(null);
                                }
                            }

                            for (let i = 0; i < el.data.length; i++) {
                                let initialValue: any = 0;
                                if (el.data[0].close) {
                                    initialValue = el.data[0].close;
                                } else {
                                    initialValue = el.data[0].price;
                                }

                                if (!el.data[i].close) {
                                    let percentage = Helpers.percentageFormater(
                                        el.data[i].price,
                                        initialValue
                                    );
                                    chartDataClose.push(percentage);
                                } else {
                                    let percentage = Helpers.percentageFormater(
                                        el.data[i].close,
                                        initialValue
                                    );

                                    chartDataClose.push(percentage);
                                }
                            }

                            if (chartDataClose.length > 0) {
                                let pointRadius;
                                if (params === "MAX" || params === "5Y" || params === "1Y") {
                                    pointRadius = 1;
                                } else if (params === "3M" || params === "YTD") {
                                    pointRadius = 2;
                                }

                                tempChartData.push({
                                    label: ` ${el.name}`,
                                    tension: 0.4,
                                    lineTension: 0.3,
                                    backgroundColor: backgroundColor[index],
                                    borderColor: borderColor[index],
                                    pointHoverBorderWidth: 3,
                                    pointRadius: pointRadius,
                                    pointHitRadius: 10,
                                    data: chartDataClose,
                                });
                            }
                        });

                        // let performance: any = [ Number( tempData[0].data[tempData[0].data.length - 1].close - tempData[0].data[0].close),  tempChartData[0].data[tempChartData[0].data.length - 1], tempData[0].name ];
           
                        return [tempChartLabels, tempChartData];
                        // return [tempChartLabels, tempChartData, performance];
                    } else {
                        let newChartLabels: any = [];
                        const sortedDate = temp.sort(function (a: any, b: any) {
                            return new Date(a[0]).getTime() - new Date(b[0]).getTime();
                        });

                        sortedDate.forEach((el: any) => {
                            const date = `${el[0].split("-")[0]} ${this.getFormatDate(
                                el[0].split("-")[1]
                            )} ${el[0].split("-")[2]}`;
                            newChartLabels.push(date);
                        });

                        let newTempChartData: any = [];

                        tempData.forEach((el: any, index: number) => {
                            let newChartClose: any = [];

                            if (index === 0) {
                                sortedDate.forEach((le: any) => {
                                    if (le[1].eodPerformance.length > 0) {
                                        newChartClose.push(le[1].eodPerformance[0]);
                                    } else {
                                        if (newChartClose.length > 0) {
                                            newChartClose.push(
                                                newChartClose[newChartClose.length - 1]
                                            );
                                        } else {
                                            newChartClose.push(null);
                                        }
                                    }
                                });
                            } else {

                                sortedDate.forEach((le: any, sortDateIndex: number) => {
                                    if (le[1].eodIndex.length > 0) {
                                        le[1].eodIndex.forEach((each: any) => {
                                            if (el.name === each.name && each.date === le[0]) {
                                                newChartClose.push(le[1].eodIndex[index - 1]);
                                            } else {
                                                newChartClose.push(null);
                                            }
                                        });
                                    } else {
                                        newChartClose.push(null);
                                    }
                                });
                            }


                            if (newChartClose.length > 0) {
                                let pointRadius;
                                if (params === "MAX" || params === "5Y" || params === "1Y") {
                                    pointRadius = 1;
                                } else if (params === "3M" || params === "YTD") {
                                    pointRadius = 2;
                                }

                                let tempVal: any = [];
                                let initialValue: any = 0;

                                newChartClose.forEach((le: any) => {
                                    if (le !== null) {
                                        if (le) {
                                            if (le.close) {
                                                if (tempVal.length > 0 && initialValue !== 0) {
                                                    let percentage = Helpers.percentageFormater(
                                                        le.close,
                                                        initialValue
                                                    );
                                                    tempVal.push(percentage);
                                                } else {
                                                    tempVal.push(0);
                                                    initialValue += le.close;
                                                }
                                            }
                                        }
                                    } else {
                                        tempVal.push(null);
                                    }
                                });

                                // let flag: any = false;
                                // let newVal: any = [];

                                // tempVal.forEach((percentage: any) => {
                                //     if (percentage === null) {
                                //         if (newVal.length > 0) {
                                //             let filterNewVal = newVal.filter((filterNew: any) => filterNew !== null);
                                //             newVal.push(filterNewVal.reverse()[0]);
                                //         } else {
                                //             newVal.push(percentage);
                                //         }
                                //     } else {
                                //         newVal.push(percentage);
                                //     }
                                // });

                                // console.log(newVal, el.name, `newVal`)

                                newTempChartData.push({
                                    label: ` ${el.name}`,
                                    tension: 0.4,
                                    lineTension: 0.3,
                                    backgroundColor: backgroundColor[index],
                                    borderColor: borderColor[index],
                                    pointHoverBorderWidth: 3,
                                    pointRadius: pointRadius,
                                    pointHitRadius: 10,
                                    data: tempVal,
                                });
                            }
                        });

                        let tempChartLabels: any = [];
                        dataChart[dataChart.length - 1].data.forEach((el: any) => {
                            const date = `${el.date.split("-")[0]} ${this.getFormatDate(
                                el.date.split("-")[1]
                            )} ${el.date.split("-")[2]}`;
                            tempChartLabels.push(date);
                        });
                        let tempChartData: any = [];
                        let tempTabularData: any = [];

                        const currentDate = new Date();
                        const formatedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() > 9
                            ? currentDate.getMonth()
                            : `0${currentDate.getMonth()}`
                            }-${currentDate.getDate() > 9
                                ? currentDate.getDate()
                                : `0${currentDate.getDate()}`
                            }`;
                        dataChart.forEach((el: any, index: number) => {
                            const lastWeek = this.getDate(
                                el.data,
                                currentDate,
                                year,
                                "lastWeek"
                            );
                            const lastMonth = this.getDate(
                                el.data,
                                currentDate,
                                year,
                                "lastMonth"
                            );
                            const last3Month = this.getDate(
                                el.data,
                                currentDate,
                                year,
                                "last3Month"
                            );
                            const lastYear = this.getDate(
                                el.data,
                                currentDate,
                                year,
                                "lastYear"
                            );
                            const YTD = this.getDate(el.data, currentDate, year, "YTD");
                            const last3Year = this.getDate(
                                el.data,
                                currentDate,
                                year,
                                "last3Year"
                            );
                            const last5Year = this.getDate(
                                el.data,
                                currentDate,
                                year,
                                "last5Year"
                            );

                            const maxData = `${Helpers.percentageFormater(
                                el.data[el.data.length - 1].close,
                                el.data[0].close
                            )}%`;

                            tempTabularData.push({
                                name: el.name,
                                lastWeek,
                                lastMonth,
                                last3Month,
                                lastYear,
                                YTD,
                                last3Year,
                                last5Year,
                                maxData,
                            });

                            let chartDataClose = [];
                            if (el.data.length < tempChartLabels.length) {
                                for (
                                    let i = 0;
                                    i < tempChartLabels.length - el.data.length - 1;
                                    i++
                                ) {
                                    chartDataClose.push(null);
                                }
                            }

                            for (let i = 0; i < el.data.length; i++) {
                                let initialValue: any = 0;
                                if (el.data[0].close) {
                                    initialValue = el.data[0].close;
                                } else {
                                    initialValue = el.data[0].price;
                                }
                                if (!el.data[i].close) {
                                    let percentage = Helpers.percentageFormater(
                                        el.data[i].price,
                                        initialValue
                                    );

                                    chartDataClose.push(percentage);
                                } else {
                                    let percentage = Helpers.percentageFormater(
                                        el.data[i].close,
                                        initialValue
                                    );

                                    chartDataClose.push(percentage);
                                }
                            }

                            if (chartDataClose.length > 0) {
                                tempChartData.push({
                                    label: ` ${el.name}`,
                                    lineTension: 0.1,
                                    backgroundColor: backgroundColor[index],
                                    borderColor: borderColor[index],
                                    pointHoverBorderWidth: 3,
                                    pointRadius: 1,
                                    pointHitRadius: 10,
                                    data: chartDataClose,
                                });
                            }
                        });

                        let diffrence: any = [];
                        const yearToDate = this.getYearToDate(year);
                        const lastWeek = this.getLastWeek();
                        const lastMonth = this.getLastMonth(today);
                        const last3Month = this.getLast3Month(today);
                        const lastYearDate = this.getLastYearDate(today);
                        const last3YearDate = this.getLast3YearDate(today);
                        const last5YearDate = this.getLast5YearDate(today);
                        dataChart.forEach((el: any, index: number) => {
                            let obj: any = {
                                name: el.name,
                                YTD: "",
                                lastMonth: "",
                                last3Month: "",
                                lastWeek: "",
                                lastYear: "",
                                last3Year: "",
                                last5Year: "",
                                Max: "",
                                currency: el.currency,
                            };
                            tempTabularData.forEach((element: any) => {
                                if (element.YTD !== "N/A") {
                                    const filterDataYearToDate = el.data.filter((el: any) => {
                                        return el.date >= yearToDate;
                                    });
                                    obj.YTD = "";
                                    if (filterDataYearToDate.length > 0) {
                                        obj.YTD += Number(
                                            filterDataYearToDate[filterDataYearToDate.length - 1]
                                                .close - filterDataYearToDate[0].close
                                        );
                                    }
                                } else {
                                    if (obj.YTD !== "N/A") {
                                        obj.YTD += "N/A";
                                    }
                                }

                                if (element.lastWeek !== "N/A") {
                                    const filterDataLastWeek = el.data.filter((el: any) => {
                                        return (
                                            new Date(el.date).getTime() >=
                                            new Date(lastWeek).getTime()
                                        );
                                    });

                                    obj.lastWeek = "";
                                    if (filterDataLastWeek.length > 0) {
                                        obj.lastWeek += Number(
                                            filterDataLastWeek[filterDataLastWeek.length - 1].close -
                                            filterDataLastWeek[0].close
                                        );
                                    }
                                } else {
                                    if (obj.lastWeek !== "N/A") {
                                        obj.lastWeek += "N/A";
                                    }
                                }

                                if (element.lastMonth !== "N/A") {
                                    const filterDataLastMonth = el.data.filter((el: any) => {
                                        return el.date >= lastMonth;
                                    });

                                    obj.lastMonth = "";
                                    if (filterDataLastMonth.length > 0) {
                                        obj.lastMonth += Number(
                                            filterDataLastMonth[filterDataLastMonth.length - 1]
                                                .close - filterDataLastMonth[0].close
                                        );
                                    }
                                } else {
                                    if (obj.lastMonth !== "N/A") {
                                        obj.lastMonth += "N/A";
                                    }
                                }

                                if (element.last3Month !== "N/A") {
                                    const filterDataLast3Month = el.data.filter((el: any) => {
                                        return el.date >= last3Month;
                                    });

                                    obj.last3Month = "";
                                    if (filterDataLast3Month.length > 0) {
                                        obj.last3Month += Number(
                                            filterDataLast3Month[filterDataLast3Month.length - 1]
                                                .close - filterDataLast3Month[0].close
                                        );
                                    }
                                } else {
                                    if (obj.last3Month !== "N/A") {
                                        obj.last3Month += "N/A";
                                    }
                                }

                                if (element.lastYear !== "N/A") {
                                    const filterDataLastYearDate = el.data.filter((el: any) => {
                                        return el.date >= lastYearDate;
                                    });

                                    obj.lastYear = "";

                                    if (filterDataLastYearDate.length > 0) {
                                        obj.lastYear += Number(
                                            filterDataLastYearDate[filterDataLastYearDate.length - 1]
                                                .close - filterDataLastYearDate[0].close
                                        );
                                    }
                                } else {
                                    if (obj.lastYear !== "N/A") {
                                        obj.lastYear += "N/A";
                                    }
                                }

                                if (element.last3Year !== "N/A") {
                                    const filterDataLast3YearDate = el.data.filter((el: any) => {
                                        return el.date >= last3YearDate;
                                    });

                                    obj.last3Year = "";
                                    if (filterDataLast3YearDate.length > 0) {
                                        obj.last3Year += Number(
                                            filterDataLast3YearDate[
                                                filterDataLast3YearDate.length - 1
                                            ].close - filterDataLast3YearDate[0].close
                                        );
                                    }
                                } else {
                                    if (obj.last3Year !== "N/A") {
                                        obj.last3Year += "N/A";
                                    }
                                }

                                if (element.last5Year !== "N/A") {
                                    const filterDataLast5YearDate = el.data.filter((el: any) => {
                                        return (
                                            new Date(el.date).getTime() >=
                                            new Date(last5YearDate).getTime()
                                        );
                                    });
                                    obj.last5Year = "";
                                    if (filterDataLast5YearDate.length > 0) {
                                        obj.last5Year += Number(
                                            filterDataLast5YearDate[
                                                filterDataLast5YearDate.length - 1
                                            ].close - filterDataLast5YearDate[0].close
                                        );
                                    }
                                } else {
                                    if (obj.last5Year !== "N/A") {
                                        obj.last5Year += "N/A";
                                    }
                                }

                                obj.Max = "";

                                if (element.Max !== "N/A") {
                                    if (el.data.length > 0) {
                                        obj.Max += Number(
                                            el.data[el.data.length - 1].close - el.data[0].close
                                        );
                                    }
                                } else {
                                    if (obj.Max !== "N/A") {
                                        obj.Max += "N/A";
                                    }
                                }
                            });
                            diffrence.push(obj);
                        });

                        tempTabularData.forEach((el: any) => {
                            diffrence.forEach((diff: any) => {
                                if (el.name === diff.name) {
                                    el.Val = [];
                                    el.Val.push(diff);
                                }
                            });
                        });

                        // return [tempChartLabels, tempTabularData, tempChartData, diffrence];

                        console.log(newTempChartData, `newTempChartData`)
                        console.log(newChartLabels, `newChartLabels`)
                        let newCustomerData: any = []
                        newTempChartData[0].data.forEach((el: any, index: number) => {
                            if (el.data) {
                                newCustomerData.push(el);
                            }
                        })
                        console.log(newCustomerData, `newCustomerData`)


                        if (newTempChartData.length > 0) {
                            let customerArrData: any = newTempChartData[0].data
                            let newCustomerData: any = []
                            let flag = false
                            customerArrData.forEach((el: any, index: number) => {
                                if (el === 0) {
                                    flag = true
                                    newCustomerData.push(el);
                                } else if (flag) {
                                    newCustomerData.push(el);
                                }
                            });

                            const labelLengthFiltered = newChartLabels.length - newCustomerData.length
                            const newComparisonLabelsFilterd: any = []

                            newChartLabels.forEach((el: any, index: number) => {
                                if (index >= labelLengthFiltered) {
                                    newComparisonLabelsFilterd.push(el);
                                }
                            });

                            newTempChartData.forEach((el: any, index: number) => {
                                let comparisonDataArr: any = []
                                if (index === 0) {
                                    el.data = newCustomerData
                                } else {
                                    let tempComparisonFilteredData: any = []
                                    el.data.forEach((el: any, index: number) => {
                                        if (index >= labelLengthFiltered) {
                                            tempComparisonFilteredData.push(el);
                                        }
                                    })  

                                    tempComparisonFilteredData[0] = 0
                                    el.data = tempComparisonFilteredData
                                }
                            })

                            console.log(newTempChartData, `newTempChartData`)
                            return [newComparisonLabelsFilterd, newTempChartData, tempTabularData];
                        } else {
                            return [newChartLabels, newTempChartData, tempTabularData];
                        }

                        // return [newChartLabels, newTempChartData, tempTabularData];

                    }
                }
            }
        }
    }

    static getComparisonPerformance(dataChart: any, params: any, currentDate: any) {
        const today = new Date();
        const year = new Date().getUTCFullYear();

        const borderColor = [
            "rgba(77, 147, 255, 1)",
            "rgba(168, 139, 233, 1)",
            "rgba(173, 214, 167, 1)",
            "rgba(153, 193, 255, 1)",
            "rgba(205, 189, 242, 1)",
            "rgba(168, 139, 233, 1)",
        ];

        const backgroundColor = [
            "rgba(77, 147, 255, 1)",
            "rgba(168, 139, 233, 1)",
            "rgba(173, 214, 167, 1)",
            "rgba(153, 193, 255, .2)",
            "rgba(205, 189, 242, .2)",
            "rgba(168, 139, 233, .2)",
        ];

        let tempData: any = [];

        dataChart.forEach((el: any) => {
            if (el.data.length > 0) {
                let initalValue = el.data[0].close
                let latestValue = el.data[el.data.length - 1].close
                let performancePercentage = latestValue / initalValue;;
                let performanceValue = 0;
                el.data.forEach((data: any, index: number) => {
                    performanceValue += data.close;
                    data.name = el.name;
                })
    
                tempData.push({
                    params,
                    name: el.name,
                    data: el.data,
                    performanceValue: performanceValue,
                    performancePercentage,
                });
            }
        });

        if (dataChart.length > 0) {
            if (params === 'MAX') {
                let tempChartLabels: any = []; 
                let tempChartData: any = [];
                let tempTabularData: any = [];  
                let tempLabel: any = {};
                let temp: any = []
                // dataChart[0].data.forEach((el: any) => {
                //     const date = `${el.date.split("-")[0]} ${this.getFormatDate(
                //         el.date.split("-")[1]
                //     )} ${el.date.split("-")[2]}`;
                //     tempChartLabels.push(date); 
                // });

                const currentDate = new Date();

                tempData.forEach((el: any, index: number) => {
                    if (index === 0) {
                        el.data.forEach((performance: any) => {
                            const date = `${performance.date.split("-")[0]
                                } ${this.getFormatDate(performance.date.split("-")[1])} ${performance.date.split("-")[2]
                                }`;
                                tempChartLabels.push(date);

                            if (tempLabel[performance.date]) {
                                tempLabel[performance.date].eodPerformance.push(performance);
                            } else {
                                tempLabel[performance.date] = {
                                    eodIndex: [],
                                    eodPerformance: [],
                                };
                                tempLabel[performance.date].eodPerformance.push(performance);
                            }
                        });
                    } else {
                        let isValidDate = new Date(tempData[0].data[0].date);
                        el.data.forEach((filterEod: any, indexFilterEod: number) => {
                            if (tempLabel[filterEod.date]) {
                                filterEod.name = el.name;
                                tempLabel[filterEod.date].eodIndex.push(filterEod);
                            } else {
                                let conditionDate = new Date(filterEod.date)
                                if (conditionDate.getTime() >= isValidDate.getTime()) {
                                    tempLabel[filterEod.date] = {
                                        eodIndex: [],
                                        eodPerformance: [],
                                    };
                                    tempLabel[filterEod.date].eodIndex.push(filterEod);
                                }
                            }
                        });

                        temp = Object.entries(tempLabel);
                    }
                });

                // dataChart.forEach((el: any, index: number) => {
                //     const lastWeek = this.getDate(el.data, currentDate, year, "lastWeek");
                //     const lastMonth = this.getDate(
                //         el.data,
                //         currentDate,
                //         year,
                //         "lastMonth"
                //     );
                //     const last3Month = this.getDate(
                //         el.data,
                //         currentDate,
                //         year,
                //         "last3Month"
                //     );
                //     const lastYear = this.getDate(el.data, currentDate, year, "lastYear");
                //     const YTD = this.getDate(el.data, currentDate, year, "YTD");
                //     const last3Year = this.getDate(
                //         el.data,
                //         currentDate,
                //         year,
                //         "last3Year"
                //     );
                //     const last5Year = this.getDate(
                //         el.data,
                //         currentDate,
                //         year,
                //         "last5Year"
                //     );
                //     const maxData = `${Helpers.percentageFormater(
                //         el.data[el.data.length - 1].close,
                //         el.data[0].close
                //     )}%`;
                    
                //     tempTabularData.push({
                //         name: el.name,
                //         lastWeek,
                //         lastMonth,
                //         last3Month,
                //         lastYear,
                //         YTD,
                //         last3Year,
                //         last5Year,
                //         maxData,
                //     });

                //     let chartDataClose: any = [];
                    
                //     if (index === 0) {
                //         for (let i = 0; i < el.data.length; i++) {
                //             let initialValue: any = 0;
                //             if (el.data[0].close) {
                //                 initialValue = el.data[0].close;
                //             } else {
                //                 initialValue = el.data[0].price;
                //             }
                //             if (!el.data[i].close) {
                //                 let percentage = Helpers.percentageFormater(
                //                     el.data[i].price,
                //                     initialValue
                //                 );
    
                //                 chartDataClose.push(percentage);
                //             } else {
                //                 let percentage = Helpers.percentageFormater(
                //                     el.data[i].close,
                //                     initialValue
                //                 );
    
                //                 chartDataClose.push(percentage);
                //             }
                //         }
    
                //         if (chartDataClose.length > 0) {
                //             tempChartData.push({
                //                 label: ` ${el.name}`,
                //                 lineTension: 0.1,
                //                 backgroundColor: backgroundColor[index],
                //                 borderColor: borderColor[index],
                //                 pointHoverBorderWidth: 3,
                //                 pointRadius: 1,
                //                 pointHitRadius: 10,
                //                 data: chartDataClose,
                //             });
                //         }
                //     } else { 
                //         let startingDate: any;
                        
                //         el.data.forEach((data: any) => {
                //             if (data.date === dataChart[0].data[0].date) {
                //                 startingDate = data
                //             }
                //         });

                //         if (startingDate.date) {
                //             let temp: any = []
                //             let validDate = new Date(startingDate.date)
                //             el.data.forEach((data: any) => {
                //                 let isValid = new Date(data.date)
                //                 if (isValid.getTime() >= validDate.getTime()) {
                //                     temp.push(data)
                //                 }
                //             });

                //             for (let i = 0; i < temp.length; i++) {
                //                 let initialValue: any = 0;
                //                 if (temp[0].close) {
                //                     initialValue = temp[0].close;
                //                 } else {
                //                     initialValue = temp[0].price;
                //                 }
                //                 console.log(initialValue, `initialValue`)
                //                 if (!temp[i].close) {
                //                     let percentage = Helpers.percentageFormater(
                //                         temp[i].price,
                //                         initialValue
                //                     );
        
                //                     chartDataClose.push(percentage);
                //                 } else {
                //                     let percentage = Helpers.percentageFormater(
                //                         temp[i].close,
                //                         initialValue
                //                     );
        
                //                     chartDataClose.push(percentage);
                //                 }
                //             }
                //         } else {

                //         }

                //     }

                //     console.log(chartDataClose, `chartDataClose`)
                // })

            } else {

            }
        }
    }
}

export default Helpers;
