import {
    Input,
    FormControl,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import AmountAsset from "../Tooltip/AmountAsset";

const AddFundModal = (
    {onClose, isOpen, form, handleAddFund}: any) => {
    
    return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent my={'auto'}>
                    <ModalHeader
                        px="24px"
                        py="16px"
                        fontSize="16px"
                        fontWeight="600"
                        borderBottom="1px"
                        borderColor="#E9E9E9"
                    >
                        Add Fund
                    </ModalHeader>
                    <ModalCloseButton
                        _focus={{ outline: "none" }}
                        fontSize="8px"
                        maxW="10px"
                        maxH="10px"
                        mt="15px"
                        mr="20px"
                    />
                    <form onSubmit={form.handleSubmit((values: any) => handleAddFund(values))}>
                        <ModalBody>
                            <FormControl pt="16px">
                                <AmountAsset labelName={'Fund'} currency={"USD"} />
                                <Input
                                    _focus={{outline: 'none'}}
                                    fontSize="14px"
                                    px="12px"
                                    id="fund"
                                    type="text"
                                    placeholder="Input Fund"
                                    {...form.register("fund", { required: true })}
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter px="24px" py="16px">
                            <Button
                                _focus={{outline: 'none'}}
                                backgroundColor="#fff"
                                color="rgba(23, 23, 23, 1)"
                                fontSize="12px"
                                fontWeight="600"
                                border="1px"
                                borderColor="#E9E9E9"
                                h="32px"
                                mr={3}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                _focus={{outline: 'none'}}
                                backgroundColor="rgba(198, 179, 120, 1)"
                                color="#fff"
                                border="1px"
                                fontSize="12px"
                                fontWeight="600"
                                h="32px"
                                _hover={{
                                    color: "rgba(198, 179, 120, 1)",
                                    backgroundColor: "#fff",
                                    border: "1px",
                                }}
                                type="submit"
                            >
                                Submit
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
    );
};

export default AddFundModal;