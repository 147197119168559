import {
    Input,
    FormControl,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Box
} from "@chakra-ui/react";
import Helpers from "../../hooks/helpers";
import AmountAsset from "../Tooltip/AmountAsset";
import CustomFormHelper from "../Form/CustomFormHelper";
import ErrorAlertComponent from "../Alert/ErrorAlertComponent";

const TakeFundModal = ({
    isOpen,
    onClose,
    form2,
    handleTakeFund,
    customerData,
    loadButtonStatus,
    errorClosedButton,
    takeFundError
}: any) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent my={"auto"}>
                <ModalHeader
                    px="24px"
                    py="16px"
                    fontSize="16px"
                    fontWeight="600"
                    borderBottom="1px"
                    borderColor="#E9E9E9"
                >
                    Take Fund
                </ModalHeader>
                <ModalCloseButton
                    _focus={{ outline: "none" }}
                    fontSize="8px"
                    maxW="10px"
                    maxH="10px"
                    mt="15px"
                    mr="20px"
                />

                <form
                    onSubmit={form2.handleSubmit((values: any, onError : any) => handleTakeFund(values))}
                >
                    <ModalBody>
                        {   takeFundError.length > 0 &&
                            (<Box mt={'10px'}>
                                <ErrorAlertComponent
                                    errorClosedButton={false}
                                    allError={takeFundError}
                                />
                            </Box>)
                        }
                        <FormControl pt="16px" >
                            <AmountAsset labelName={'Fund'} currency={"USD"} />
                            <Input
                                
                                _focus={{ outline: "none" }}
                                fontSize="14px"
                                px="12px"
                                id="fund"
                                type="number"
                                step={'any'}
                                placeholder="Input Fund"
                                min={0}
                                // max={
                                //     customerData?.unallocated_funds -
                                //     customerData?.allocated_funds
                                // }
                                {...form2.register("fund", { required: true })}
                            />
                            <CustomFormHelper 
                            helperText={`Available Fund : ${Helpers.currencyFormaterUSD(
                                customerData?.unallocated_funds -
                                customerData?.allocated_funds
                            )}`} />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter px="24px" py="16px">
                        <Button
                            backgroundColor="#fff"
                            color="rgba(23, 23, 23, 1)"
                            fontSize="12px"
                            fontWeight="600"
                            border="1px"
                            borderColor="#E9E9E9"
                            h="32px"
                            mr={3}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            isLoading={loadButtonStatus ? true : false}
                            loadingText='Submitting'
                            _focus={{outline: 'none'}}
                            backgroundColor="rgba(198, 179, 120, 1)"
                            color="#fff"
                            border="1px"
                            fontSize="12px"
                            fontWeight="600"
                            h="32px"
                            _hover={{
                                color: "rgba(198, 179, 120, 1)",
                                backgroundColor: "#fff",
                                border: "1px",
                            }}
                            type="submit"
                        >
                            Submit
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default TakeFundModal;
