import { useState, useEffect } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    Center,
    Box,
    Input, FormControl, FormLabel, Textarea,
    ModalFooter, ModalBody,
    Avatar,
    Text,
    useDisclosure,
    Button,
    Collapse,
    Flex
} from "@chakra-ui/react";

const DetailNotes = ({
    data,
    form,
    isOpen,
    onClose,
    header,
    handleNewNotes,
    handleUpdateNotes,
    modalMode,
    idRows
}: any) => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [singleData, setSingleData] = useState<any>({});

    const { isOpen: transitionCollapse, onToggle } = useDisclosure()
    
    useEffect(() => {
        if (data.id) {
            handleData()
        }
    }, [data]);

    const handleData = () => {
        console.log(data, `data`)
        let date = new Date(data.date_notes)
        let formatDate = date.toUTCString().split(' ')
        let newFormatDate = `${formatDate[0]} ${formatDate[1]} ${formatDate[2]} ${formatDate[3]}`
        setSingleData({
            date: newFormatDate,
            extention: data.extention,
            bankStatement: data.bank_statement,
            remarks: data.remarks
        })
        console.log(newFormatDate, `newFormatDate`)
    }

    const handleOnClosed = async () => {
        if (transitionCollapse) {
            onToggle()
        }
        onClose()
    }

    const handleEditMode = async () => {
        setEditMode(true)
        onToggle()
        form.setValue("date_notes", data.date_notes);
        form.setValue("extention", singleData.extention);
        form.setValue("bank_statement", singleData.bankStatement);
        form.setValue("remarks", singleData.remarks);
        console.log(transitionCollapse, `transitionCollapse`)
    }

    return (
        <Modal isOpen={isOpen} onClose={() => {
                handleOnClosed()
            }}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    px="24px"
                    py="16px"
                    fontSize="16px"
                    fontWeight="600"
                    borderBottom="1px"
                    borderColor="#E9E9E9"
                >
                    {header}
                </ModalHeader>
                <ModalCloseButton
                    _focus={{ outline: "none" }}
                    fontSize="8px"
                    maxW="10px"
                    maxH="10px"
                    mt="15px"
                    mr="20px"
                />

                <form
                    onSubmit={form.handleSubmit((values: any) =>
                        modalMode
                            ? handleNewNotes(values)
                            : handleUpdateNotes(values, data.id)
                    )}>

                    <ModalBody>
                        <Collapse in={transitionCollapse} animateOpacity>
                            <Box>
                                <FormControl pt="16px">
                                    <FormLabel fontSize="14px" mb="8px" htmlFor="name">
                                        Date
                                    </FormLabel>

                                    <Input
                                        _focus={{outline: 'none'}}
                                        cursor={'pointer'}
                                        fontSize="14px"
                                        px="12px"
                                        id="date_notes"
                                        type="date"
                                        placeholder="Transaction Date"
                                        {...form.register("date_notes", { required: true })}
                                        // onChange={(e) => {
                                        //     setValue(e.target.value);
                                        // }}
                                    /> 

                                </FormControl>

                                <FormControl pt="16px">
                                    <FormLabel fontSize="14px" mb="8px" htmlFor="name">
                                        Extension
                                    </FormLabel>
                                    <Input
                                        _focus={{outline: 'none'}}
                                        fontSize="14px"
                                        px="12px"
                                        id="name"
                                        type="text"
                                        placeholder="Fill Customer Name"
                                        {...form.register("extention", { required: true })}
                                    />
                                </FormControl>

                                <FormControl pt="16px">
                                    <FormLabel fontSize="14px" mb="8px" htmlFor="name">
                                        Bank Statement
                                    </FormLabel>
                                    <Input
                                        _focus={{outline: 'none'}}
                                        fontSize="14px"
                                        px="12px"
                                        id="name"
                                        type="text"
                                        placeholder="Fill Customer Name"
                                        {...form.register("bank_statement", { required: true })}
                                    />
                                </FormControl>

                                <FormControl pt="16px">
                                    <FormLabel fontSize="14px" mb="8px" htmlFor="name">
                                        Remarks
                                    </FormLabel>
                                    <Textarea
                                        _focus={{outline: 'none'}}
                                        fontSize="14px"
                                        px="12px"
                                        id="name"
                                        type="text"
                                        placeholder="Fill Customer Name"
                                        {...form.register("remarks", { required: true })}
                                    >

                                    </Textarea>
                                </FormControl>
                            </Box>
                        </Collapse>
                        {/* {
                            data?.id && transitionCollapse ? (
                                <Collapse in={transitionCollapse} animateOpacity>
                                    <Box>
                                        <FormControl pt="16px">
                                            <FormLabel fontSize="14px" mb="8px" htmlFor="name">
                                                Date
                                            </FormLabel>

                                            <Input
                                                _focus={{outline: 'none'}}
                                                cursor={'pointer'}
                                                fontSize="14px"
                                                px="12px"
                                                id="date_notes"
                                                type="date"
                                                placeholder="Transaction Date"
                                                {...form.register("date_notes", { required: true })}
                                                // onChange={(e) => {
                                                //     setValue(e.target.value);
                                                // }}
                                            /> 

                                        </FormControl>

                                        <FormControl pt="16px">
                                            <FormLabel fontSize="14px" mb="8px" htmlFor="name">
                                                Extension
                                            </FormLabel>
                                            <Input
                                                _focus={{outline: 'none'}}
                                                fontSize="14px"
                                                px="12px"
                                                id="name"
                                                type="text"
                                                placeholder="Fill Customer Name"
                                                {...form.register("extention", { required: true })}
                                            />
                                        </FormControl>

                                        <FormControl pt="16px">
                                            <FormLabel fontSize="14px" mb="8px" htmlFor="name">
                                                Bank Statement
                                            </FormLabel>
                                            <Input
                                                _focus={{outline: 'none'}}
                                                fontSize="14px"
                                                px="12px"
                                                id="name"
                                                type="text"
                                                placeholder="Fill Customer Name"
                                                {...form.register("bank_statement", { required: true })}
                                            />
                                        </FormControl>

                                        <FormControl pt="16px">
                                            <FormLabel fontSize="14px" mb="8px" htmlFor="name">
                                                Remarks
                                            </FormLabel>
                                            <Textarea
                                                _focus={{outline: 'none'}}
                                                fontSize="14px"
                                                px="12px"
                                                id="name"
                                                type="text"
                                                placeholder="Fill Customer Name"
                                                {...form.register("remarks", { required: true })}
                                            >

                                            </Textarea>
                                        </FormControl>
                                    </Box>
                                </Collapse>
                            ) : (
                                <Box>
                                    <Box my={2}>{singleData.date}</Box>
                                    <Box my={2}>{singleData.bankStatement}</Box>
                                    <Box my={2}>{singleData.extention}</Box>
                                    <Box my={2}>{singleData.remarks}</Box>
                                </Box>
                            )
                        } */}
                        <Collapse in={!transitionCollapse} animateOpacity>
                            <Box>
                                <Flex direction={'column'}>
                                    <Box
                                        fontWeight={600}
                                        fontSize={'14px'}
                                        color={'rgba(23, 23, 23, 1)'}>
                                        Date
                                    </Box>
                                    <Box fontSize={'14px'} my={2}>{singleData.date}</Box>
                                </Flex>
                                <Flex direction={'column'}>
                                    <Box
                                        fontWeight={600}
                                        fontSize={'14px'}
                                        color={'rgba(23, 23, 23, 1)'}>
                                        Extension 
                                    </Box>
                                    <Box fontSize={'14px'} my={2}>{singleData.extention}</Box>
                                </Flex>
                                <Flex direction={'column'}>
                                    <Box
                                        fontWeight={600}
                                        fontSize={'14px'}
                                        color={'rgba(23, 23, 23, 1)'}>
                                        Bank Statement  
                                    </Box>
                                    <Box fontSize={'14px'} my={2}>{singleData.bankStatement}</Box>
                                </Flex>
                                <Flex direction={'column'}>
                                    <Box
                                        fontWeight={600}
                                        fontSize={'14px'}
                                        color={'rgba(23, 23, 23, 1)'}>
                                        Remarks 
                                    </Box>
                                    <Box fontSize={'14px'} my={2}>{singleData.remarks}</Box>
                                </Flex>
                            </Box>
                        </Collapse>
                    </ModalBody>

                    <ModalFooter px="24px" py="16px">
                        <Button
                            backgroundColor="#fff"
                            color="rgba(23, 23, 23, 1)"
                            fontSize="12px"
                            fontWeight="600"
                            border="1px"
                            borderColor="#E9E9E9"
                            h="32px"
                            mr={3}
                            onClick={handleOnClosed}
                        >
                            Cancel
                        </Button>
                        {
                            !transitionCollapse && (
                                <Button
                                    backgroundColor="#fff"
                                    color="rgba(23, 23, 23, 1)"
                                    fontSize="12px"
                                    fontWeight="600"
                                    border="1px"
                                    borderColor="#E9E9E9"
                                    h="32px"
                                    mr={3}
                                    onClick={() => {
                                        handleEditMode();
                                        // onToggle();
                                        // setEditMode(true);
                                    }}
                                >
                                    Edit
                                </Button>
                            )
                        }
                        {
                            transitionCollapse && (
                                <Button
                                    backgroundColor="rgba(198, 179, 120, 1)"
                                    color="#fff"
                                    border="1px"
                                    fontSize="12px"
                                    fontWeight="600"
                                    h="32px"
                                    _hover={{
                                        color: "rgba(198, 179, 120, 1)",
                                        backgroundColor: "#fff",
                                        border: "1px",
                                    }}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            )
                        }
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}

export default DetailNotes

