import { Tr, Tbody, Td } from "@chakra-ui/react";
import Helpers from "../../../hooks/helpers";
import UserAction from '../ActionColumn/UserAction';

const TableManagementTransaction = ({ list, handleUpdateDate }: any) => {
    return (
        <Tbody>
            {list.map((el: any, index: number) => {
                return (
                    <Tr key={index}>
                        <Td textTransform="capitalize" p="12px">
                            {el.created_at}
                        </Td>
                        <Td textTransform="capitalize" p="12px">
                            {/* {el.name} */}
                            {Helpers.shortenText(el.name, "200px")}
                        </Td>
                        <Td textTransform="capitalize" p="12px">
                            {el.trade_date}
                        </Td>
                        <Td
                            textTransform="capitalize"
                            textAlign={"center"}
                            p="12px"
                        >
                            {el.action}
                        </Td>
                        <Td
                            textTransform="capitalize"
                            textAlign={"center"}
                            p="12px"
                        >
                            {el.currency}
                        </Td>
                        <Td
                            textTransform="capitalize"
                            textAlign={"right"}
                            p="12px"
                        >
                            {Number(el.amount)}
                        </Td>
                        <Td
                            textTransform="capitalize"
                            textAlign={"right"}
                            p="12px"
                        >
                            {Number(el.value).toFixed(2)}
                        </Td>
                        <Td
                            textTransform="capitalize"
                            p="12px"
                            textAlign={"right"}
                        >
                            {el.action === "BUY"
                                ? `-${Number(el.total).toFixed(2)}`
                                : Number(el.total).toFixed(2)}
                        </Td>
                        <UserAction dateTransaction={handleUpdateDate} val={el} />
                    </Tr>
                );
            })}
        </Tbody>
    );
};

export default TableManagementTransaction;