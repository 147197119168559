import Cookies from "js-cookie";
import qoreContext from "../common/qoreContext";
import logo from "../../assets/logo.svg";
import { useNavigate, Link, useLocation, useSearchParams } from "react-router-dom";
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Text,
  Image,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { FiMenu, FiBell, FiSearch, FiChevronDown } from "react-icons/fi";
import { FaUser } from "react-icons/fa";

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, sidebarMode, ...rest }: any) => {
  const user = qoreContext.useCurrentUser();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  let breadCrumbs = location.pathname.split("/").filter((el) => el !== "");
  breadCrumbs = breadCrumbs.map((el) => el.split("-").join(" "));

  const nav = useNavigate();

  return (
    <Flex
      ml={{ base: 0, md: sidebarMode ? 10 : 60 }}
      px={{ base: 4, md: 4 }}
      pt="8px"
      height="100px"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      direction="column"
      {...rest}
    >
      <Flex
        px={{ base: 4, md: 4 }}
        w="100%"
        height="10"
        alignItems="center"
        direction="row"
        bg={useColorModeValue("white", "gray.900")}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        {...rest}
      >
        <Flex ml="0" mr="auto" w="100%">
          <Box
            fontSize="12px"
            fontFamily="Inter"
            fontWeight="400"
            textTransform="capitalize"
          >
            {location && breadCrumbs.length > 1 ? (
              breadCrumbs.map((el, index) => {
                if (index === 0) {
                  return (
                    <Text
                      key={index}
                      fontSize="12px"
                      color="rgba(69, 69, 69, 1)"
                      display="inline-block"
                    >
                      {el}
                    </Text>
                  );
                } else {
                  const parent = breadCrumbs[0].split(" ").join("-");

                  let newPath: any = [];
                  for (let i = 0; i < index; i++) {
                    breadCrumbs[i].split(" ").join("-");
                    newPath.push(breadCrumbs[i + 1].split(" ").join("-"));
                  }

                  newPath = newPath.join("/");

                  return (
                    <Box key={index} display="inline-block" pl="5px">
                      {el === "BOND" || el === "GBOND" || el === "CC" || (el === 'transactions log' && index === 3) ? (
                        <Text
                          pl="5px"
                          display="inline-block"
                          textTransform={index > 1 && el !== 'transactions log' ? "uppercase" : "capitalize"}
                          fontWeight={
                            index === breadCrumbs.length - 1 ? "600" : "400"
                          }
                        >
                          / {el}
                        </Text>
                      ) : parent === "management" ? (
                        <Link to={`/${parent}/${newPath}${searchParams.get('id') && index !== 1 ? `?id=${searchParams.get('id')}` : ''}`}>
                          /
                          <Text
                            pl="5px"
                            display="inline-block"
                            textTransform={"capitalize"}
                            fontWeight={
                              index === breadCrumbs.length - 1 ? "600" : "400"
                            }
                          >
                            {el}
                          </Text>
                        </Link>
                      ) : (
                        <Link to={`/${parent}/${newPath}`}>
                          /
                          <Text
                            pl="5px"
                            display="inline-block"
                            textTransform={
                              index > 1 ? "uppercase" : "capitalize"
                            }
                            fontWeight={
                              index === breadCrumbs.length - 1 ? "600" : "400"
                            }
                          >
                            {el}
                          </Text>
                        </Link>
                      )}
                    </Box>
                  );
                }
              })
            ) : (
              <Text
                fontSize="12px"
                color="rgba(69, 69, 69, 1)"
                display="inline-block"
              >
                Dashboard
              </Text>
            )}
          </Box>
        </Flex>
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <Text
          display={{ base: "flex", md: "none" }}
          fontSize="2xl"
          fontFamily="monospace"
          fontWeight="bold"
        >
          <Image borderRadius="sm" src={logo} />
        </Text>

        <HStack spacing={{ base: "0", md: "6" }}>
          <IconButton
            size="lg"
            variant="ghost"
            aria-label="open menu"
            icon={<FiSearch />}
          />
          <IconButton
            size="lg"
            variant="ghost"
            aria-label="open menu"
            icon={<FiBell />}
          />
          <Flex alignItems={"center"}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}
              >
                <HStack>
                  <Avatar
                    className="default-profile"
                    size={"sm"}
                    src={
                      user?.user?.profile_pic ? (
                        user?.user?.profile_pic
                      ) : (
                        <FaUser />
                      )
                    }
                  />
                  <VStack
                    display={{ base: "none", md: "flex" }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="xs" color="gray.600">
                      {user?.user?.role?.name}
                    </Text>
                  </VStack>
                  <Box display={{ base: "none", md: "flex" }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue("white", "gray.900")}
                borderColor={useColorModeValue("gray.200", "gray.700")}
              >
                <MenuItem>Profile</MenuItem>
                <MenuItem>Settings</MenuItem>
                <MenuDivider />
                <MenuItem
                  onClick={() => {
                    Cookies.remove("token");
                    if (Cookies.get("remember_me")) {
                      Cookies.remove("remember_me");
                    }
                    nav("/login");
                  }}
                >
                  Sign out
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
      <Box w="100%" pb="12px" mb="0" mt="auto" ml="4">
        <Text
          display="inline-block"
          textTransform="capitalize"
          fontWeight="600"
        >
          {
            breadCrumbs.length > 1
            ? breadCrumbs[breadCrumbs.length - 1]
            : "Dashboard"
          }

        </Text>
      </Box>
    </Flex>
  );
};

export default MobileNav;
