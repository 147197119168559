import {
    FETCH_CUSTOMERS,
    FETCH_FILTER_CUSTOMERS,
    FETCH_DATA_FILTER_CUSTOMERS,
    FETCH_WATCH_LIST,
    FETCH_ASSET_ALLOCATION,
    FETCH_VALUE_NETWORTH,
    FETCH_LABEL_NETWORTH,
    FETCH_TOTAL_NETWORTH,
    FETCH_ASSET_SUMMARY,
    FETCH_RM_ID,
    FETCH_ROLE
} from "../actionTypes/index";


const initialize = {
    dataCustomers: [],
    filterCustomer: [],
    dataFilterCustomer: [],
    dataAssetSummary: [],
    netWorthLabel: [],
    netWorthValue: [],
    dataAssetAllocation: [],
    watchList: [],
    totalNetworth: 0,
    rm_id: '',
    role: ''
}

const rmDataReducer = (state = initialize, action) => {
    switch (action.type) {
        case FETCH_CUSTOMERS:
            return { ...state, dataCustomers: action.payload }
        case FETCH_RM_ID:
            return { ...state, rm_id: action.payload }
        case FETCH_ROLE:
            return { ...state, role: action.payload }
        case FETCH_ASSET_SUMMARY:
            return { ...state, dataAssetSummary: action.payload }
        case FETCH_WATCH_LIST:
            return { ...state, watchList: action.payload }
        case FETCH_ASSET_ALLOCATION:
            return { ...state, dataAssetAllocation: action.payload }
        case FETCH_FILTER_CUSTOMERS:
            return { ...state, filterCustomer: action.payload }
        case FETCH_DATA_FILTER_CUSTOMERS:
            return { ...state, dataFilterCustomer: action.payload }
        case FETCH_VALUE_NETWORTH:
            return { ...state, netWorthValue: action.payload }
        case FETCH_LABEL_NETWORTH:
            return { ...state, netWorthLabel: action.payload }
        case FETCH_TOTAL_NETWORTH:
            return { ...state, totalNetworth: action.payload }
        default:
            return state;
    }
}

export default rmDataReducer