import { useRef } from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

const routesAnimationVariants = {
    hidden: {
        opacity: 0,
        x: "20px"
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: { delay: 0, duration: 0.6 }
    },
    exit: {
        x: "-10px",
        opacity: 0,
        transition: {
            duration: 0.4
        }
    }
};

const TabPanelContent = ({ children }: any) => {
    const currentPanelRef = useRef(document.createElement("div"));
    return (
        <Box
            as={motion.div}
            variants={routesAnimationVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            ref={currentPanelRef}
        >
            {children}
        </Box>
    );
};

export default TabPanelContent