import {
  Td,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  IconButton,
  Portal,
  Stack,
} from "@chakra-ui/react";
import { HiDotsHorizontal } from "react-icons/hi";
import { BsCurrencyExchange, BsCardList } from "react-icons/bs";
import { BiWallet } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const AssetAction = ({
  buy2,
  sell,
  el,
  getAssetData,
  formSell,
  formDuplicate,
  setValue2,
  setValue3,
  setAmount2,
  setAmount3,
  availableFund,
  handleTransactionDetail
}: any) => {
  const nav = useNavigate();
  const handleDetail = (params: string) => {
    nav(params);
  };
  return (
    <Td p="12px" maxW="30px" textAlign="center" className="user-action-wrapper">
      <Popover placement="left">
        <PopoverTrigger>
          <IconButton
            minW="24px"
            h="24px"
            border="1px"
            borderColor="#E9E9E9"
            variant="ghost"
            aria-label="open menu"
            _focus={{ outline: "none" }}
            icon={<HiDotsHorizontal width="10px" />}
            zIndex={9}
          />
        </PopoverTrigger>
        <Portal>
          <PopoverContent maxW="170px" _focus={{ outline: "none" }}>
            <PopoverBody p="0">
              <Stack direction="column" spacing={0}>
                {
                  availableFund !== 0 && (
                    <Button
                      onClick={() => {
                        getAssetData(el.id);
                        buy2.onOpen();
                        formDuplicate.setValue("amount", 0);
                        formDuplicate.setValue("value", 0);
                        setValue2(0);
                        setAmount2(2);
                      }}
                      leftIcon={
                        <BsCurrencyExchange
                          color="rgba(162, 162, 162, 1)"
                          fontSize="16px"
                        />
                      }
                      _hover={{ backgroundColor: "rgba(249, 247, 242, 1)" }}
                      color="rgba(69, 69, 69, 1)"
                      fontSize="14px"
                      fontWeight="400"
                      my="0"
                      p="16px"
                      w="100%"
                      justifyContent="flex-start"
                      variant="ghost"
                    >
                      Add Asset
                    </Button>
                  )
                }
                
                <Button
                  onClick={() => {
                    getAssetData(el.id);
                    sell.onOpen();
                    formSell.setValue("amount", 0);
                    formSell.setValue("value", 0);
                    setValue3(0);
                    setAmount3(2);
                  }}
                  leftIcon={
                    <BiWallet color="rgba(162, 162, 162, 1)" fontSize="16px" />
                  }
                  _hover={{ backgroundColor: "rgba(249, 247, 242, 1)" }}
                  color="rgba(69, 69, 69, 1)"
                  fontSize="14px"
                  fontWeight="400"
                  my="0"
                  p="16px"
                  w="100%"
                  justifyContent="flex-start"
                  variant="ghost"
                >
                  Sell Asset
                </Button>
                <Button
                  onClick={() => {
                    handleTransactionDetail(el)
                  }}
                  leftIcon={
                    <BsCardList color="rgba(162, 162, 162, 1)" fontSize="16px" />
                  }
                  _hover={{ backgroundColor: "rgba(249, 247, 242, 1)" }}
                  color="rgba(69, 69, 69, 1)"
                  fontSize="14px"
                  fontWeight="400"
                  my="0"
                  p="16px"
                  w="100%"
                  justifyContent="flex-start"
                  variant="ghost"
                >
                  Transaction Log
                </Button>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Td>
  );
};

export default AssetAction;
