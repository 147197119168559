import { useState, useEffect } from 'react'
import {
    Center, Box,
    Input, FormControl, FormLabel, Textarea,
    ModalFooter, ModalBody,
    Avatar,
    Text,
    Button,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    useDisclosure,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Collapse,
    Checkbox, CheckboxGroup,
    Stack,
    Flex,
} from "@chakra-ui/react";
import { BiEditAlt } from "react-icons/bi";

const UserAdminModal = (
    {   form, 
        modalMode, 
        handleAddRM, 
        handleUpdateRM, 
        encodeImageFileAsURL, 
        onClose,
        image,
        isOpen,
        handlePermission,
        // checkedItems,
        // setCheckedItems,
        idRows }: any) => {
    const { isOpen: collapseIsOpen, onOpen: onOpenCollapse, onClose: onCloseCollapse } = useDisclosure();
    const { isOpen: roleIsOpen, onOpen: onRoleOpen, onClose: onRoleClose } = useDisclosure();
    
    const [checkedItems, setCheckedItems] = useState([true, true, true, true, true, true, true, true])
    const allChecked = checkedItems.every(Boolean)
    const isIndeterminate = checkedItems.some(Boolean) && !allChecked

    const financialInstrument = [
        "Stock",
        "ETF",
        "Bonds",
        "Mutual Funds",
        "Forex",
        "Crypto",
        "Index",
    ]

    useEffect(() => {
        handlePermission(checkedItems);
    }, [checkedItems])

    const handleCheck = (params: any) => {
        let temp: any = []
        checkedItems.forEach((el: any, index: number) => {
            if (index === params) {
                el = el === true ? false : true
            }
            temp.push(el)
        })
        setCheckedItems(temp);
    }

    useEffect(() => {
        if (isOpen) {
            onOpenCollapse();
        }
    }, [isOpen]);

    // const handleModalClose = () => {
    //     onCloseCollapse();
    //     onRoleClose();
    //     onClose();
    // }

    return (
            <form onSubmit={form.handleSubmit((values: any) => modalMode ? handleAddRM(values) : handleUpdateRM(values, idRows)) }>
                <ModalBody>
                    <Tabs isLazy>
                        <TabList px="24px">
                            <Tab
                                _selected={{
                                    borderColor: "#C6B378",
                                    fontWeight: "600",
                                }}
                                onClick={() => {
                                    onOpenCollapse();
                                    onRoleClose();
                                }}
                                fontSize="12px"
                                px="0"
                                pb="12px"
                                mt="10px"
                                mr={'20px'}
                                _focus={{ outline: "none" }}
                            >
                                Identity
                            </Tab>
                            <Tab
                                onClick={() => {
                                    onRoleOpen();
                                    onCloseCollapse();
                                }}
                                _selected={{
                                    borderColor: "#C6B378",
                                    fontWeight: "600",
                                }}
                                fontSize="12px"
                                px="0"
                                pb="12px"
                                mt="10px"
                                _focus={{ outline: "none" }}
                            >
                                Role Management
                            </Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel px="24px">
                                <Collapse in={collapseIsOpen} animateOpacity>
                                    <Box>
                                    <FormControl pt='16px'>
                                        <FormLabel fontWeight='600' pb='10px' fontSize='14px' mb='8px' htmlFor="profile_pic">Avatar</FormLabel>
                                        <Center>
                                            {
                                                image ?
                                                <Avatar 
                                                    className='custom-chakra-avatar' 
                                                    w='80px' h='80px' 
                                                    src={image}>
                                                    <label className="custom-file-upload">
                                                        <Box >
                                                            <BiEditAlt />
                                                        </Box>
                                                        
                                                        <Input
                                                            className="custom-file-input"
                                                            maxW="150px"
                                                            fontSize="14px"
                                                            px="12px"
                                                            id="profile_pic"
                                                            type="file"
                                                            placeholder="Input Customer Avatar"
                                                            onChange={(e) => encodeImageFileAsURL(e.target)}
                                                        />
                                                    </label>
                                                </Avatar>
                                                :
                                                <Avatar 
                                                    className='default-profile' 
                                                    w='80px' h='80px' 
                                                    src={image}>
                                                    <label className="custom-file-upload">
                                                        <Box >
                                                            <BiEditAlt />
                                                        </Box>
                                                        
                                                        <Input
                                                            className="custom-file-input"
                                                            maxW="150px"
                                                            fontSize="14px"
                                                            px="12px"
                                                            id="profile_pic"
                                                            type="file"
                                                            placeholder="Input Customer Avatar"
                                                            onChange={(e) => encodeImageFileAsURL(e.target)}
                                                        />
                                                    </label>
                                                </Avatar>
                                            }
                                        </Center>
                                        <Box pt={'10px'}>
                                            <Text
                                                fontSize='12px'
                                                fontWeight='400'
                                                lineHeight='16px'
                                                color='rgba(162, 162, 162, 1)'>
                                                    The recommended image size for avatar is 100 
                                                    by 100 pixels with a maximum file size of 1 MB (megabyte) 
                                                    or 1024 KB (kilobytes)
                                            </Text>
                                        </Box>
                                    </FormControl>
                                    <FormControl pt='16px'>
                                        <FormLabel fontWeight='600' fontSize='14px' mb='8px' htmlFor="logo">Name</FormLabel>
                                        <Input
                                            fontSize='14px'
                                            px='12px'
                                            id="name"
                                            type="text"
                                            placeholder="Input Relationship Manager Name"
                                            {...form.register("name", { required: true })}
                                        />
                                    </FormControl>

                                    <FormControl pt='16px'>
                                        <FormLabel fontWeight='600' fontSize='14px' mb='8px' htmlFor="company">Email</FormLabel>
                                        <Input
                                            fontSize='14px'
                                            px='12px'
                                            id="external_id"
                                            type="email"
                                            placeholder="Input Relationship Manager Email"
                                            {...form.register("external_id", { required: true })}
                                        />
                                    </FormControl>
                                    { modalMode ?  
                                    <FormControl pt='16px'>
                                        <FormLabel fontWeight='600' fontSize='14px' mb='8px' htmlFor="password">Password</FormLabel>
                                        <Input
                                            fontSize='14px'
                                            px='12px'
                                            id="password"
                                            type="password"
                                            placeholder="Input Relationship Manager Password"
                                            {...form.register("password", { required: true })}
                                        />
                                    </FormControl>
                                    :
                                    <></>
                                    }
                                    </Box>
                                </Collapse>
                            </TabPanel>

                            <TabPanel px="24px">
                                <Collapse in={roleIsOpen} animateOpacity>
                                    <Center justifyContent={'start'}>
                                        <Box 
                                            fontSize={'12px'}
                                            fontWeight={600}
                                            color={'rgba(23, 23, 23, 1)'}
                                            backgroundColor={'rgba(245, 245, 245, 1)'}
                                            py={'12px'} 
                                            pl={'30px'} w={'200px'}>
                                                Pages
                                        </Box>
                                        <Box
                                            fontSize={'12px'}
                                            fontWeight={600}
                                            color={'rgba(23, 23, 23, 1)'}
                                            backgroundColor={'rgba(245, 245, 245, 1)'}
                                            py={'12px'} 
                                            w={'calc(100% - 200px)'}>Description</Box>
                                    </Center>
                                    <Box>
                                        <Center borderBottom={'2px solid rgba(245, 245, 245, 1)'} justifyContent={'start'}>
                                            <Box
                                                w={'200px'}
                                                pr={'12px'}
                                                py={'5px'}
                                                className="filter-checkbox-wrapper">
                                                <Checkbox
                                                    _focus={{outline: 'none'}}
                                                    _hover={{
                                                        backgroundColor: "rgba(249, 247, 242, 1)",
                                                    }}
                                                    pt={'5px'}
                                                    pl={'5px'}
                                                    className="filter-checkbox"
                                                    size={'sm'}
                                                    fontSize={'12px'}
                                                    fontWeight={400}
                                                    color={'rgba(23, 23, 23, 1)'}
                                                    isChecked={allChecked}
                                                    isIndeterminate={isIndeterminate}
                                                    onChange={(e) => {
                                                        let temp: any = []
                                                        for (let i = 0; i < 8; i++) {
                                                            let ya = e.target.checked
                                                            temp.push(ya)
                                                            
                                                        }
                                                        setCheckedItems(temp)
                                                    }}>
                                                        Financial Instrument
                                                </Checkbox>
                                            </Box>
                                            <Box
                                                fontSize={'12px'}
                                                fontWeight={400}
                                                color={'rgba(23, 23, 23, 1)'}
                                                pr={'12px'}
                                                py={'5px'}
                                                >Can view all financial instrument</Box>
                                        </Center>
                                        <Stack mb={0} mt={1} spacing={1}>
                                            {
                                                financialInstrument && financialInstrument.length > 0 && financialInstrument.map((el: any, index: number) => {
                                                    return (
                                                        <Center 
                                                            key={index}
                                                            borderBottom={'2px solid rgba(245, 245, 245, 1)'} justifyContent={'start'}>
                                                            <Box
                                                                pl={6}
                                                                w={'200px'}
                                                                pr={'12px'}
                                                                pb={'5px'}
                                                                className="filter-checkbox-wrapper">
                                                                <Checkbox
                                                                    _focus={{outline: 'none'}}
                                                                    _hover={{
                                                                        backgroundColor: "rgba(249, 247, 242, 1)",
                                                                    }}
                                                                    pt={'5px'}
                                                                    pl={'5px'}
                                                                    className="filter-checkbox"
                                                                    size={'sm'}
                                                                    fontSize={'12px'}
                                                                    fontWeight={400}
                                                                    color={'rgba(23, 23, 23, 1)'}
                                                                    isChecked={checkedItems[index]}
                                                                    onChange={(e) => {
                                                                        console.log(index, `index`)
                                                                        handleCheck(index)
                                                                    }}
                                                                >
                                                                    {el}
                                                                </Checkbox>
                                                            </Box>
                                                            <Box
                                                                fontSize={'12px'}
                                                                fontWeight={400}
                                                                color={'rgba(23, 23, 23, 1)'}
                                                                pr={'12px'}
                                                                py={'5px'}
                                                                >Can view all {el}</Box>
                                                        </Center>
                                                    )
                                                })
                                            }
                                        </Stack>
                                    </Box>
                                    </Collapse>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    {/* <FormControl pt='16px'>
                        <FormLabel fontWeight='600' pb='10px' fontSize='14px' mb='8px' htmlFor="profile_pic">Avatar</FormLabel>
                        <Center>
                            {
                                image ?
                                <Avatar 
                                    className='custom-chakra-avatar' 
                                    w='80px' h='80px' 
                                    src={image}>
                                    <label className="custom-file-upload">
                                        <Box >
                                            <BiEditAlt />
                                        </Box>
                                        
                                        <Input
                                            className="custom-file-input"
                                            maxW="150px"
                                            fontSize="14px"
                                            px="12px"
                                            id="profile_pic"
                                            type="file"
                                            placeholder="Input Customer Avatar"
                                            onChange={(e) => encodeImageFileAsURL(e.target)}
                                        />
                                    </label>
                                </Avatar>
                                :
                                <Avatar 
                                    className='default-profile' 
                                    w='80px' h='80px' 
                                    src={image}>
                                    <label className="custom-file-upload">
                                        <Box >
                                            <BiEditAlt />
                                        </Box>
                                        
                                        <Input
                                            className="custom-file-input"
                                            maxW="150px"
                                            fontSize="14px"
                                            px="12px"
                                            id="profile_pic"
                                            type="file"
                                            placeholder="Input Customer Avatar"
                                            onChange={(e) => encodeImageFileAsURL(e.target)}
                                        />
                                    </label>
                                </Avatar>
                            }
                        </Center>
                        <Box pt={'10px'}>
                            <Text
                                fontSize='12px'
                                fontWeight='400'
                                lineHeight='16px'
                                color='rgba(162, 162, 162, 1)'>
                                    The recommended image size for avatar is 100 
                                    by 100 pixels with a maximum file size of 1 MB (megabyte) 
                                    or 1024 KB (kilobytes)
                            </Text>
                        </Box>
                    </FormControl>
                    <FormControl pt='16px'>
                        <FormLabel fontWeight='600' fontSize='14px' mb='8px' htmlFor="logo">Name</FormLabel>
                        <Input
                            fontSize='14px'
                            px='12px'
                            id="name"
                            type="text"
                            placeholder="Input Relationship Manager Name"
                            {...form.register("name", { required: true })}
                        />
                    </FormControl>

                    <FormControl pt='16px'>
                        <FormLabel fontWeight='600' fontSize='14px' mb='8px' htmlFor="company">Email</FormLabel>
                        <Input
                            fontSize='14px'
                            px='12px'
                            id="external_id"
                            type="email"
                            placeholder="Input Relationship Manager Email"
                            {...form.register("external_id", { required: true })}
                        />
                    </FormControl>
                    { modalMode ?  
                    <FormControl pt='16px'>
                        <FormLabel fontWeight='600' fontSize='14px' mb='8px' htmlFor="password">Password</FormLabel>
                        <Input
                            fontSize='14px'
                            px='12px'
                            id="password"
                            type="password"
                            placeholder="Input Relationship Manager Password"
                            {...form.register("password", { required: true })}
                        />
                    </FormControl>
                    :
                    <></>
                    } */}
                    
                </ModalBody>

                <ModalFooter mr={'24px'} py='16px'>
                    <Button  
                        backgroundColor='#fff'
                        color='rgba(23, 23, 23, 1)'
                        fontSize='12px'
                        fontWeight='600'
                        border='1px'
                        borderColor='#E9E9E9'
                        h='32px'
                        mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button 
                        backgroundColor='rgba(198, 179, 120, 1)'
                        color='#fff'
                        border='1px'
                        fontSize='12px'
                        fontWeight='600'
                        h='32px'
                        _hover={{color: 'rgba(198, 179, 120, 1)', backgroundColor: '#fff', border: '1px'}}
                        type="submit">Submit</Button>
                </ModalFooter>
            </form>
    );
};

export default UserAdminModal;