import {
    Box,
    Input,
    ModalFooter, ModalBody,
    Button,
    Stack,
    FormErrorMessage,
    FormControl,
    FormHelperText
} from "@chakra-ui/react";


const ConfirmationRemoveModal = (
    {   
        onClose,
        setConfirmation,
        confirmation,
        handleAfterConfirmation,
        el,
        isError
    }: any) => {

    return (
        <>
                <ModalBody>
                    <Box 
                        pt={'12px'}
                        pb={'10px'}
                        lineHeight={'20px'}
                        color={'rgba(23, 23, 23, 1)'}
                        fontWeight={400}
                        fontSize={'14px'}>
                        Please type the user's Display Name to confirm that you want to delete it
                    </Box>

                    <Box>
                        <Stack spacing={3}>
                        <FormControl isInvalid={isError}>
                            <Input
                                _focus={{ outline: "none" }}
                                placeholder="Enter Display Name"
                                fontSize="12px"
                                onChange={(e: any) => {
                                    setConfirmation(e.target.value);
                                }}
                            />
                            {!isError ? (
                                <></>
                            ) : (
                                <FormErrorMessage>Wrong display name. Please enter the display name correctly.</FormErrorMessage>
                            )}
                            </FormControl>
                        </Stack>
                    </Box>
                    
                </ModalBody>

                <ModalFooter px='24px' py='16px'>
                <Button  
                    backgroundColor='#fff'
                    color='rgba(23, 23, 23, 1)'
                    fontSize='12px'
                    fontWeight='600'
                    border='1px'
                    borderColor='#E9E9E9'
                    h='32px'
                    _focus={{outline: 'none'}}
                    mr={3} onClick={onClose}>
                    Cancel
                </Button>
                {
                    confirmation !== '' && (
                        <Button 
                            _focus={{outline: 'none'}}
                            onClick={() => handleAfterConfirmation(el)}
                            backgroundColor={'rgba(224, 49, 56, 1)'}
                            color='#fff'
                            border='1px'
                            fontSize='12px'
                            fontWeight='600'
                            h='32px'
                            _hover={{color: 'rgba(224, 49, 56, 1)', backgroundColor: '#fff', border: '1px'}}
                            type="submit">Yes, Delete</Button>
                    )
                }
                </ModalFooter>
        </>
    );
};

export default ConfirmationRemoveModal;