import { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import {
    Box,
    Flex,
    Stack,
    Center,
    useColorModeValue,
    Button,
    AspectRatio
} from "@chakra-ui/react";
import html2canvas from "html2canvas";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";

import { useSelector, useDispatch } from "react-redux";
import {
    fetchNetWorthLabel,
    fetchNetWorthValue,
    fetchTotalNetworth,
} from "../../store/actionCreator/rm";

import zoom from "chartjs-plugin-zoom";
import qoreContext from "../../common/qoreContext";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { FiDownload } from "react-icons/fi";

import TamplatePDF from "./TamplatePDF";

import Helpers from "../../hooks/helpers";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoom,
    Filler
);
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);
import TitleBoxDetail from "../../components/DetailBox/TitleBoxDetail";
import ChartButtonFISingle from "../../components/Button/ChartButtonFISingle";

import { BiLineChart } from "react-icons/bi";
import FilterReports from "../../components/FilterAndSearch/FilterReports";

const GeneratePDF = ({ customer, customers, customerData }: any) => {
    const [pdfLink, setPdfLink] = useState("");
    const [category, setCategory] = useState<string>("");
    const [subCategory, setSubCategory] = useState<string>("");
    const [tabs, setTabs] = useState<string>("");
    const [params, setParams] = useState<string>("");
    const [orientation, setOrientation] = useState<string>("l");

    let chart_data = {
        labels: ["Mar", "Apr", "Mei", "June"],
        datasets: [
            {
                fillColor: "rgba(6, 118, 152, 0.71)",
                strokeColor: "rgba(220,220,220,1)",
                pointColor: "rgba(220,220,220,1)",
                pointStrokeColor: "#fff",
                pointHighlightFill: "#fff",
                pointHighlightStroke: "rgba(220,220,220,1)",
                data: [20, 34, 15, 64],
            },
        ],
    };

    const div2PDF = async (e: any) => {

        let id = "";
        if (category === "NetWorth") {
            id = "netWorth";
        } else if (category === "Portfolio") {
            if (subCategory === "Performance") {
                id = "performance";
            }
        }

        let doc: any;

        if (orientation === 'l') {
            doc = new jsPDF({
                putOnlyUsedFonts: true,
                orientation: 'l',
            });
        } else {
            doc = new jsPDF({
                putOnlyUsedFonts: true,
                orientation: 'p',
            });
        }

        let canvas = document.getElementById(id) as HTMLCanvasElement;
        // var width = doc.internal.pageSize.getWidth();
        // var height = doc.internal.pageSize.getHeight();
        // console.log(width, `height`)
        // console.log(height, `width`)
        window.scrollTo(0,0)
        await html2canvas(canvas, {
            allowTaint: true,
            useCORS: true,
            width: 2000,
            scale: 3,
        }).then((after) => {
            doc.addImage(
                after.toDataURL("image/png"),
                "PNG",
                10,
                15,
                450,
                190,
                "",
                "MEDIUM"
            );
        });
        doc.setFontSize(20);
        // doc.save("myChart");
        let string = doc.output("datauristring");
        setPdfLink(string);
    };

    const tableHead = ["Name", "1W", "1M", "3M", "YTD", "1Y", "5Y", "MAX"];

    const selectedChart = (category: any, subCategory: any, tabs: any) => {
        if (category === "NetWorth") {
            return (
                <Flex direction={"column"} w={"100%"}>
                    {
                        <Box py="10px">
                            <Box
                                w={"full"}
                                bg={useColorModeValue("white", "gray.800")}
                                boxShadow={"lg"}
                                rounded={"md"}
                                px="0"
                                mt={"24px"}
                            >
                                <Box px={"24px"}>
                                    <TitleBoxDetail
                                        fsIcon={"20px"}
                                        iconTitle={BiLineChart}
                                        boxTitle={`Net Worth`}
                                    />
                                </Box>

                                <Box w={{ base: "full" }} px={"24px"} pb={"24px"}>
                                    <Center my={"16px"}>
                                        <Box
                                            fontSize={"20px"}
                                            fontWeight={600}
                                            color={"rgba(23, 23, 23, 1)"}
                                        >
                                            <Box
                                                pl={"4px"}
                                                fontWeight={400}
                                                display={"inline-block"}
                                                fontSize={"12px"}
                                                color={"rgba(116, 116, 116, 1)"}
                                            >
                                                as of {currentDate}
                                            </Box>
                                        </Box>

                                        <ChartButtonFISingle
                                            chartFilter={chartFilterArr}
                                            handleChartFilter={setFilter}
                                        />
                                    </Center>

                                    <Box h={300} py="10px">
                                        <Line id={"netWorth"} data={data} options={option} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    }
                </Flex>
            );
        } else if (category === "Portfolio") {
            if (subCategory === "Performance") {
                return (
                    <Box my={'24px'} shadow={'lg'}>
                        <Box fontSize={'16px'} fontWeight={600} my={'24px'} px={'24px'} w={'100%'}>Preview PDF</Box>
                        <Box id={"performance"} w={"100%"}>
                            <TamplatePDF
                                tableHead={tableHead}
                                tabularData={tabularData}
                                dataPercentage={dataPercentage}
                                optionsPercentage={optionsPercentage}
                                customer={dataFilterCustomer}
                            />
                        </Box>
                    </Box>
                );
            }
        }
    };

    const [currentDate, setCurrentDate] = useState<string>("");
    const [assetSummary, setAssetSummary] = useState<any[]>([]);
    const [filter, setFilter] = useState<string>("MAX");

    // const [fromDate, setFromDate] = useState<string>("");
    // const [toDate, setToDate] = useState<string>("");
    const [filterDataCustomer, setFilterDataCustomer] = useState<any[]>([]);
    const user = qoreContext.useCurrentUser();

    const dispatch: any = useDispatch();
    const {
        netWorthLabel,
        netWorthValue,
        dataAssetSummary,
        totalNetworth,
        dataFilterCustomer,
    } = useSelector((state: any) => state.rm);

    const setSingleProfit = async () => {
        let tempData: any = {};
        let tempLabel: any = [];
        let tempValue: any = [];
        let initialValue: any = 0;
        let tempSummary: any = [];
        let existingDay: any[] = [];
        let totalSummary = 0;

        const filteredCustomer = await customerData.filter((obj: any) =>
            customers.includes(obj.name)
        );

        await setFilterDataCustomer(filteredCustomer);

        if (customerData.length > 0) {
            await filteredCustomer.forEach((customer: any) => {
                assetSummary.forEach((summary: any) => {
                    if (summary.customer_id === customer.id) {
                        tempSummary.push(summary);
                    }
                });
            });
        }

        const sortedSummary = await tempSummary.sort(function (a: any, b: any) {
            return (
                new Date(a.date_summary).getTime() - new Date(b.date_summary).getTime()
            );
        });
        tempSummary = await getFilterData(sortedSummary);

        tempSummary.forEach((el: any, index: number) => {
            if (el.date_summary) {
                let flag = false;
                existingDay.forEach((le: any) => {
                    if (`${le}` === `${el.date_summary}`) {
                        flag = true;
                    }
                });

                if (!flag) {
                    tempLabel.push(`${el.date_summary}`);
                    existingDay.push(`${el.date_summary}`);
                }

                if (tempData[`${el.date_summary}`]) {
                    tempData[`${el.date_summary}`].push(el);
                } else {
                    tempData[`${el.date_summary}`] = [];
                    tempData[`${el.date_summary}`].push(el);
                }
            }
        });

        setCurrentDate(tempSummary[tempSummary.length - 1].date_summary);

        tempData = Object.entries(tempData);
        const sortedDate = tempData.sort(function (a: any, b: any) {
            return new Date(a[0]).getTime() - new Date(b[0]).getTime();
        });

        tempLabel = tempLabel.map((el: any, index: number) => {
            if (filter === "YTD" && index === 0) {
                return `${el.split("-")[0]}`;
            } else {
                if (el.split("-")[0] === currentDate.split("-")[0]) {
                    return `${monthFormat(el.split("-")[1])} ${el.split("-")[2]}`;
                } else {
                    return `${el.split("-")[0]} ${monthFormat(el.split("-")[1])} ${el.split("-")[2]
                        }`;
                }
            }
        });

        dispatch(fetchNetWorthLabel(tempLabel));

        if (sortedDate.length > 0) {
            sortedDate.forEach((el: any, index: number) => {
                let totalDailyAsset = 0;
                el[1].forEach((le: any, indexLe: number) => {
                    if (index === 0) {
                        initialValue += Number(le.amount * le.eod_usd_price);
                        if (indexLe === el[1].length - 1) {
                            tempValue.push(initialValue);
                        }
                    } else {
                        totalDailyAsset += Number(le.amount * le.eod_usd_price);
                        if (indexLe === el[1].length - 1) {
                            tempValue.push(totalDailyAsset);
                        }
                    }
                });

                if (index === sortedDate.length - 1) {
                    el[1].forEach((le: any) => {
                        totalSummary += Number(le.amount * le.eod_usd_price);
                    });
                }
            });
        }

        setCurrentDate(tempSummary[tempSummary.length - 1].date_summary);
        dispatch(fetchTotalNetworth(totalSummary));
        dispatch(fetchNetWorthValue(tempValue));
    };

    const getFilterData = (tempSummary: any) => {
        let newArr: any = [];
        let dateRange: any;
        if (filter === "1M") {
            dateRange = Helpers.dateFormat(Helpers.getLast30Days());
            tempSummary = tempSummary.map((el: any) => {
                if (
                    new Date(el.date_summary).getTime() >= new Date(dateRange).getTime()
                ) {
                    newArr.push(el);
                }
            });
            tempSummary = newArr;
        } else if (filter === "3M") {
            dateRange = Helpers.getLast3Month(currentDate);
            tempSummary = tempSummary.map((el: any) => {
                if (
                    new Date(el.date_summary).getTime() >= new Date(dateRange).getTime()
                ) {
                    newArr.push(el);
                }
            });
            tempSummary = newArr;
        } else if (filter === "6M") {
            dateRange = Helpers.getLast3Month(currentDate);
            tempSummary = tempSummary.map((el: any) => {
                if (
                    new Date(el.date_summary).getTime() >= new Date(dateRange).getTime()
                ) {
                    newArr.push(el);
                }
            });
            tempSummary = newArr;
        } else if (filter === "YTD") {
            dateRange = Helpers.getYearToDate(currentDate.split("-")[0]);
            tempSummary = tempSummary.map((el: any) => {
                if (
                    new Date(el.date_summary).getTime() >= new Date(dateRange).getTime()
                ) {
                    newArr.push(el);
                }
            });
            tempSummary = newArr;
        } else if (filter === "1Y") {
            dateRange = Helpers.getLastYearDate(currentDate);
            tempSummary = tempSummary.map((el: any) => {
                if (
                    new Date(el.date_summary).getTime() >= new Date(dateRange).getTime()
                ) {
                    newArr.push(el);
                }
            });
            tempSummary = newArr;
        } else if (filter === "5Y") {
            dateRange = Helpers.getLast5YearDate(currentDate);
            tempSummary = tempSummary.map((el: any) => {
                if (
                    new Date(el.date_summary).getTime() >= new Date(dateRange).getTime()
                ) {
                    newArr.push(el);
                }
            });
            tempSummary = newArr;
        } else if (filter === "MAX") {
            newArr = tempSummary;
        }

        return newArr;
    };

    const setMultipleProfit = async () => {
        let tempData: any = {};
        let tempLabel: any = [];
        let tempValue: any = [];
        let initialValue: any = 0;
        let tempSummary: any = [];
        let existingDay: any[] = [];
        let totalSummary = 0;

        const filteredCustomer = await customerData.filter((obj: any) =>
            customers.includes(obj.name)
        );

        await setFilterDataCustomer(filteredCustomer);

        if (customerData.length > 0) {
            await filteredCustomer.forEach((customer: any) => {
                assetSummary.forEach((summary: any) => {
                    if (summary.customer_id === customer.id) {
                        tempSummary.push(summary);
                    }
                });
            });
        }

        const sortedSummary = await tempSummary.sort(function (a: any, b: any) {
            return (
                new Date(a.date_summary).getTime() - new Date(b.date_summary).getTime()
            );
        });

        tempSummary = await getFilterData(sortedSummary);

        await tempSummary.forEach((el: any, index: number) => {
            if (el.date_summary) {
                let flag = false;
                existingDay.forEach((le: any) => {
                    if (`${le}` === `${el.date_summary}`) {
                        flag = true;
                    }
                });

                if (!flag) {
                    tempLabel.push(`${el.date_summary}`);
                    existingDay.push(`${el.date_summary}`);
                }

                if (tempData[`${el.date_summary}`]) {
                    tempData[`${el.date_summary}`].push(el);
                } else {
                    tempData[`${el.date_summary}`] = [];
                    tempData[`${el.date_summary}`].push(el);
                }
            }
        });

        tempData = Object.entries(tempData);

        const sortedDate = await tempData.sort(function (a: any, b: any) {
            return new Date(a[0]).getTime() - new Date(b[0]).getTime();
        });

        tempLabel = await tempLabel.map((el: any, index: number) => {
            if (filter === "YTD" && index === 0) {
                return `${el.split("-")[0]}`;
            } else {
                return `${el.split("-")[0]} ${monthFormat(el.split("-")[1])} ${el.split("-")[2]
                    }`;
            }
        });

        await dispatch(fetchNetWorthLabel(tempLabel));

        if (sortedDate.length > 0) {
            await sortedDate.forEach((el: any, index: number) => {
                let totalDailyAsset = 0;
                el[1].forEach((le: any, indexLe: number) => {
                    if (index === 0) {
                        initialValue += Number(le.amount * le.eod_usd_price);
                        if (indexLe === el[1].length - 1) {
                            tempValue.push(initialValue);
                        }
                    } else {
                        totalDailyAsset += Number(le.amount * le.eod_usd_price);
                        if (indexLe === el[1].length - 1) {
                            tempValue.push(totalDailyAsset);
                        }
                    }
                });
                if (index === sortedDate.length - 1) {
                    el[1].forEach((le: any) => {
                        totalSummary += Number(le.amount * le.eod_usd_price);
                    });
                }
            });
        }
        if (tempSummary.length > 0) {
            await setCurrentDate(tempSummary[tempSummary.length - 1].date_summary);
        }
        await dispatch(fetchTotalNetworth(totalSummary));
        await dispatch(fetchNetWorthValue(tempValue));
    };

    const getAssetSummary = async () => {
        try {
            if (user?.user?.id) {
                const response = await qoreContext.client
                    .table("asset_summary")
                    .readRows({ condition: { rm_id_asset_summary: user?.user?.id } })
                    .toPromise();
                if (response?.data?.nodes) {
                    setAssetSummary(response?.data?.nodes);
                }
            }
        } catch (error) { }
    };

    useEffect(() => {
        if (customers.length > 0) {
            getAssetSummary();
            if (customers.length === 1) {
                setSingleProfit();
            } else {
                setMultipleProfit();
            }
        }
    }, [
        customers.length,
        customerData.length,
        assetSummary.length,
        filter,
        dataAssetSummary.length,
    ]);

    const monthFormat = (val: any) => {
        if (val == "01") return "Jan";
        if (val == "02") return "Feb";
        if (val == "03") return "Mar";
        if (val == "04") return "Apr";
        if (val == "05") return "May";
        if (val == "06") return "Jun";
        if (val == "07") return "Jul";
        if (val == "08") return "Aug";
        if (val == "09") return "Sep";
        if (val == "10") return "Oct";
        if (val == "11") return "Nov";
        if (val == "12") return "Dec";
    };

    const data: any = {
        labels: netWorthLabel,
        datasets: [
            {
                // label: `USD ${totalSummary}`,
                fill: false,
                lineTension: 0.1,
                backgroundColor: `rgba(0, 101, 255, 1)`,
                borderColor: `rgba(0, 101, 255, 1)`,
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: `rgba(0, 101, 255, 1)`,
                pointBackgroundColor: `rgba(0, 101, 255, 1)`,
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: `rgba(0, 101, 255, 1)`,
                pointHoverBorderColor: `rgba(0, 101, 255, 1)`,
                pointHoverBorderWidth: 3,
                pointRadius: 3,
                pointHitRadius: 10,
                data: netWorthValue,
                cubicInterpolationMode: "monotone",
            },
        ],
    };

    const option: any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: "bottom",
                labels: {
                    usePointStyle: true,
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const currencyFormat = Helpers.currencyFormaterUSD(context.raw);
                        return ` ${currencyFormat}`;
                    },
                },
            },
        },
        scales: {
            y: {
                // offset: false,
                // gridLines: {
                //     offsetGridLines: false,
                // },
                // title: {
                //     display: true,
                //     text: `${params === 'percentage' ? 'Percentage' : 'Profit'}`
                // },
                // grid: {
                //     display: false,
                //     drawBorder: true,
                //     drawOnChartArea: true,
                //     drawTicks: true,
                // },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: false,
                    getLabelForValue: function (value: any) {
                        return value;
                    },
                    callback: function (
                        val: any,
                        index: number,
                        tick: any,
                        label: any
                    ): any {
                        return this.getLabelForValue(val);
                    },
                },
            },
            x: {
                offset: false,
                gridLines: {
                    offsetGridLines: false,
                },
                title: {
                    display: true,
                },
                grid: {
                    display: false,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: false,
                    getLabelForValue: function (value: any) {
                        return value;
                    },
                    callback: function (val: any, index: number, tick: any) {
                        let tempMonths;
                        const newLabel = Helpers.labelData(
                            tick.length,
                            index,
                            this.getLabelForValue(val),
                            filter,
                            tempMonths
                        );
                        return newLabel;
                    },
                },
            },
        },
    };

    const chartFilterArr = ["1M", "3M", "6M", "YTD", "1Y", "5Y", "MAX"];

    const [label, setLabel] = useState<any>([]);
    const [value, setValue] = useState<any>([]);
    const [filterValues, setFilterValues] = useState<any>("MAX");
    const [currentDatePerformance, setCurrentDatePerformance] =
        useState<string>("");

    const getCurrentDate = () => {
        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth();
        const date = new Date().getUTCDate();
        if (month + 1 > 10) {
            const getDate = `${year}-${month + 1}-${date}`;
            setCurrentDatePerformance(getDate);
        } else {
            const getDate = `${year}-0${month + 1}-${date}`;
            setCurrentDatePerformance(getDate);
        }
    };

    const setSingleProfitPerformances = () => {
        let temp: any = {};
        let tempLabel: any = [];
        let tempValue: any = [];
        let dateRange: any;

        if (filterValues === "1W") {
            dateRange = Helpers.getLast7Days();
        } else if (filterValues === "1M") {
            dateRange = Helpers.getLast30Days();
        } else if (filterValues === "3M") {
            dateRange = Helpers.getLast3Month(currentDatePerformance);
        } else if (filterValues === "YTD") {
            dateRange = Helpers.getYearToDate(currentDatePerformance.split("-")[0]);
        } else if (filterValues === "1Y") {
            dateRange = Helpers.getLastYearDate(currentDatePerformance);
        } else if (filterValues === "5Y") {
            dateRange = Helpers.getLast5YearDate(currentDatePerformance);
        }

        dataAssetSummary.forEach((el: any) => {
            el.close = el.eod_usd_price;
        });

        dataAssetSummary.map((el: any) => {
            if (filterValues !== "MAX") {
                if (
                    new Date(dateRange).getTime() <= new Date(el.date_summary).getTime()
                ) {
                    if (temp[el.date_summary]) {
                        temp[el.date_summary].push(el);
                    } else {
                        temp[el.date_summary] = [];
                        temp[el.date_summary].push(el);
                    }
                }
            } else {
                if (temp[el.date_summary]) {
                    temp[el.date_summary].push(el);
                } else {
                    temp[el.date_summary] = [];
                    temp[el.date_summary].push(el);
                }
            }
        });

        temp = Object.entries(temp);

        const sortedDate = temp.sort(function (a: any, b: any) {
            return new Date(a[0]).getTime() - new Date(b[0]).getTime();
        });

        sortedDate.map((el: any, index: number) => {
            let tempData = {
                date: el[0],
                close: 0,
            };

            el[1].map((le: any) => {
                if (index === 0) {
                    tempData.close += Number(le.amount * le.avg_usd_price);
                } else {
                    tempData.close += Number(le.amount * le.eod_usd_price);
                }
            });

            tempLabel.push(el[0]);
            tempValue.push(tempData.close);
        });

        const newLabel = tempLabel.map((el: any) => {
            return (el = `${el.split("-")[0]} ${monthFormat(el.split("-")[1])} ${el.split("-")[2]
                }`);
        });

        setLabel(newLabel);
        setValue(tempValue);
    };

    const setMultipleProfitPerformances = () => {
        let temp: any = {};
        let tempLabel: any = [];
        let tempValue: any = [];
        let dateRange: any;

        if (filterValues === "1W") {
            dateRange = Helpers.getLast7Days();
        } else if (filterValues === "1M") {
            dateRange = Helpers.getLast30Days();
        } else if (filterValues === "3M") {
            dateRange = Helpers.getLast3Month(currentDatePerformance);
        } else if (filterValues === "YTD") {
            dateRange = Helpers.getYearToDate(currentDatePerformance.split("-")[0]);
        } else if (filterValues === "1Y") {
            dateRange = Helpers.getLastYearDate(currentDatePerformance);
        } else if (filterValues === "5Y") {
            dateRange = Helpers.getLast5YearDate(currentDatePerformance);
        }

        dataAssetSummary.forEach((el: any) => {
            el.close = el.eod_usd_price;
        });

        dataAssetSummary.map((el: any) => {
            if (filterValues !== "MAX") {
                if (
                    new Date(dateRange).getTime() <= new Date(el.date_summary).getTime()
                ) {
                    if (temp[el.date_summary]) {
                        temp[el.date_summary].push(el);
                    } else {
                        temp[el.date_summary] = [];
                        temp[el.date_summary].push(el);
                    }
                }
            } else {
                if (temp[el.date_summary]) {
                    temp[el.date_summary].push(el);
                } else {
                    temp[el.date_summary] = [];
                    temp[el.date_summary].push(el);
                }
            }
        });

        temp = Object.entries(temp);

        const sortedDate = temp.sort(function (a: any, b: any) {
            return new Date(a[0]).getTime() - new Date(b[0]).getTime();
        });

        sortedDate.map((el: any, index: number) => {
            let tempData = {
                date: el[0],
                close: 0,
            };

            el[1].map((le: any) => {
                if (index === 0) {
                    tempData.close += Number(le.amount * le.avg_usd_price);
                } else {
                    tempData.close += Number(le.amount * le.eod_usd_price);
                }
            });

            tempLabel.push(el[0]);
            tempValue.push(tempData.close);
        });

        const newLabel = tempLabel.map((el: any) => {
            return (el = `${el.split("-")[0]} ${monthFormat(el.split("-")[1])} ${el.split("-")[2]
                }`);
        });

        setLabel(newLabel);
        setValue(tempValue);
    };

    useEffect(() => {
        getCurrentDate();
        if (params !== "percentage") {
            if (customers.length > 0) {
                if (customers.length === 1) {
                    setSingleProfitPerformances();
                } else {
                    setMultipleProfitPerformances();
                }
            }
        }
    }, [customers, filterValues]);

    let tempMonths: any[] = [];

    const [chartData, setChartData] = useState<any>([]);
    useEffect(() => {
        if (dataAssetSummary.length > 0) {
            getSinglePerformance();
        }
    }, [dataAssetSummary.length]);

    const getSinglePerformance = () => {
        let temp: any = {};
        let tempValue: any = [];
        let initialValue;

        dataAssetSummary.forEach((el: any) => {
            el.close = el.eod_usd_price;
        });

        dataAssetSummary.map((el: any, index: number) => {
            if (index === 0) {
                initialValue = el.percentage;
            }
            if (temp[el.date_summary]) {
                temp[el.date_summary].push(el);
            } else {
                temp[el.date_summary] = [];
                temp[el.date_summary].push(el);
            }
        });

        let newTemp = Object.entries(temp);

        const sortedDate = newTemp.sort(function (a: any, b: any) {
            return new Date(a[0]).getTime() - new Date(b[0]).getTime();
        });

        sortedDate.map((el: any, index: number) => {
            let tempData = {
                date: el[0],
                close: 0,
            };

            el[1].map((le: any) => {
                if (index === 0) {
                    tempData.close += Number(le.amount * le.avg_usd_price);
                } else {
                    tempData.close += Number(le.amount * le.eod_usd_price);
                }
            });

            tempValue.push(tempData);
        });

        if (dataFilterCustomer.length === 1) {
            setChartData([
                {
                    name: dataFilterCustomer[0].name,
                    data: tempValue,
                    currency: "USD",
                },
            ]);
        } else {
            setChartData([
                {
                    name: `Customers`,
                    data: tempValue,
                    currency: "USD",
                },
            ]);
        }
    };

    const [filterPercentage, setFilterPercentage] = useState<any>("MAX");
    const [chartLabels, setChartLabels] = useState<any>([]);
    const [tabularData, setTabularData] = useState<any>([]);
    const [performanceDifference, setPerformanceDifference] = useState<any>([]);
    const [datasets, setDatasets] = useState<any>([]);
    const [dataPercentage, setDataPercentage] = useState<any>({
        labels: [],
        datasets: [],
    });

    const getDataPercentage = () => {
        if (chartData.length > 0) {
            const data = Helpers.getChartData(
                chartData,
                filterPercentage,
                currentDate
            );
            if (filterPercentage === "MAX") {
                if (data) {
                    setChartLabels(data[0]);
                    setTabularData(data[1]);
                    setDatasets(data[2]);
                    setPerformanceDifference(data[3]);
                }
            } else {
                if (data) {
                    setChartLabels(data[0]);
                    setDatasets(data[1]);
                    if (data[2]) {
                        if (data[2].length > 0) {
                            setTabularData(data[2]);
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        getCurrentDate();
    }, []);

    useEffect(() => {
        getDataPercentage();
    }, [chartData, filterPercentage, currentDate]);

    useEffect(() => {
        setDataPercentage({
            labels: chartLabels,
            datasets: datasets,
        });
    }, [chartLabels, datasets]);

    const optionsPercentage: any = {
        spanGaps: true,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
            mode: "index",
        },
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    padding: 20,
                },
                align: "center",
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const dataChart = chartData;
                        const month = Helpers.getFormatDateYYMMDD(
                            context.label.split(" ")[1]
                        );
                        const newFormat = `${context.label.split(" ")[0]}-${month}-${context.label.split(" ")[2]
                            }`;

                        let filtered = dataChart[context.datasetIndex].data.filter(
                            (el: any) => el.date === newFormat
                        );

                        if (filtered.length > 0) {
                            if (context.datasetIndex === 0) {
                                return ` ${Helpers.currencyFormaterUSD(filtered[0].close)} - (${context.raw
                                    } %)`;
                            } else {
                                const formatCurreny = Helpers.currencyFormater(
                                    filtered[0].close,
                                    dataChart[context.datasetIndex].currency
                                );
                                return `  ${formatCurreny} - ( ${context.raw} %)`;
                            }
                        }
                    },
                },
            },
        },
        scales: {
            x: {
                offset: false,
                gridLines: {
                    offsetGridLines: false,
                },
                title: {
                    display: true,
                },
                grid: {
                    display: false,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: false,
                    getLabelForValue: function (value: any) {
                        return value;
                    },
                    callback: function (val: any, index: number, tick: any) {
                        const newLabel = Helpers.labelData(
                            tick.length,
                            index,
                            this.getLabelForValue(val),
                            filterPercentage,
                            tempMonths
                        );
                        return newLabel;
                    },
                },
            },
        },
    };

    return (
        <Box minH={"500px"} py={6}>
            <Box
                w={"full"}
                bg={useColorModeValue("white", "gray.800")}
                boxShadow={"lg"}
                rounded={"md"}
                px="24px"
                pb={"20px"}
            >
                <TitleBoxDetail
                    fsIcon={"20"}
                    iconTitle={HiOutlineDocumentReport}
                    boxTitle={`Report Format`}
                />

                <FilterReports
                    category={category}
                    subCategory={subCategory}
                    setCategory={setCategory}
                    setSubCategory={setSubCategory}
                    tabs={tabs}
                    setTabs={setTabs}
                    chartFilter={chartFilterArr}
                    handleChartFilter={setFilterPercentage}
                    setOrientation={setOrientation}
                />


                {category && subCategory && (
                    <Box mt={"24px"} mr={"28px"}>
                        <Flex>
                            <Stack mr={0} ml={"auto"} direction="row" spacing={4}>
                                <Button
                                    onClick={(e: any) => div2PDF(e)}
                                    px={"12px"}
                                    py={"0"}
                                    fontSize={"12px"}
                                    leftIcon={<FiDownload />}
                                    color={"rgba(255,255,255,1)"}
                                    backgroundColor={"rgba(198, 179, 120, 1)"}
                                    variant="solid"
                                >
                                    Generate PDF
                                </Button>
                            </Stack>
                        </Flex>
                    </Box>
                )}

                <Box id={'add_to_me'}></Box>

                <Box>
                    {/* {
                        pdfLink && (
                            <a href={pdfLink} target='_blank'>lol</a>
                        )
                    } */}

                    {
                        pdfLink ? (
                                <Box mt={'24px'}>
                                    <AspectRatio ratio={1}>
                                        <iframe
                                            title='naruto'
                                            src={pdfLink}
                                            allowFullScreen
                                        />
                                    </AspectRatio>
                                </Box>
                        ) : (
                            <Center px={"10px"}>
                                {(user?.user?.role?.name === undefined ||
                                    user?.user?.role?.name !== "admin") &&
                                    selectedChart(category, subCategory, tabs)}
                            </Center>
                        )
                    }

                </Box>
            </Box>
        </Box>
    );
};

export default GeneratePDF;
