import { useEffect, useState } from 'react'
import {
    Flex,
    Box,
    useColorModeValue,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Divider,
    Select
} from "@chakra-ui/react";

import { useSelector, useDispatch } from 'react-redux';
import DashboardDoughnutChart from "../../components/Chart/DashboardDoughnutChart";

const AssetAllocationDetail = () => {

    const { dataAssetAllocation } = useSelector((state: any) => state.rm);
    const [filterChart, setFilterChart] = useState<string>('type');
    const [dataChart, setDataChart] = useState<any[]>([]);

    useEffect(() => {
        console.log(dataAssetAllocation, `dataAssetAllocation`)
        if (dataAssetAllocation) {
            getFilterChart();
        }
    }, [dataAssetAllocation, filterChart]);

    const getFilterChart = () => {
        if (filterChart === 'type') {
            setDataChart(dataAssetAllocation.TypeAllocation)
        } else if (filterChart === 'geography') {
            setDataChart(dataAssetAllocation.CountryAllocation)
        } else if (filterChart === 'currency') {
            setDataChart(dataAssetAllocation.CurrencyAllocation)
        } else if (filterChart === 'industry') {
            setDataChart(dataAssetAllocation.IndustryAllocation)
        }
    }


    return (
        <AccordionPanel pb={4}>
            <Box>
                <Select 
                    size={'sm'}
                    borderColor={'rgba(233, 233, 233, 1)'}
                    iconSize={'10px'}
                    fontSize={'12px'}
                    width={'150px'}
                    _focus={{outline: 'none'}}
                    defaultValue={'type'}
                    onChange={(e) => {
                        setFilterChart(e.target.value);
                    }}>
                    <option value='type'>Asset Type</option>
                    <option value='geography'>Geography</option>
                    <option value='currency'>Currency</option>
                    <option value='industry'>Industry</option>
                </Select>
            </Box>
            {
                dataChart && dataChart.length > 0 &&(
                    <DashboardDoughnutChart
                        allocationData={dataChart}
                    />
                )
            }
        </AccordionPanel>
    );
};

export default AssetAllocationDetail;