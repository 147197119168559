import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Center, Box, useColorModeValue, Flex, Spacer } from "@chakra-ui/react";
import qoreContext from "../../common/qoreContext";
import LocalLoader from "../../components/Loader/LocalLoader";
import EmptyData from "../../components/EmptyData";
import NoDataFound from "../../components/StatusPage/NoDataFound";
import AssetAllocationDetail from "./AssetAllocationDetail";
import { IconType } from "react-icons";
import { FiPieChart } from "react-icons/fi";
import ChartCard from "./Asset Chart/ChartCard";
import NoDataFoundTransaction from "../../components/StatusPage/NoDataFoundTransaction";
const AssetAllocation = ({ customer }: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [noDataFound, setNoDataFound] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const [search, setSearch] = useState<string>("");
    const [allocatedFunds, setAllocatedFunds] = useState<number>(0);
    const [limit, setLimit] = useState<number>(25);
    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [assets, setAssets] = useState<any>([]);
    const [typeAllocation, setTypeAllocation] = useState<any>({});
    const [countryAllocation, setCountryAllocation] = useState<any>([]);
    const [currencyAllocation, setCurrencyAllocation] = useState<any>([]);
    const [industryAllocation, setIndustryAllocation] = useState<any>([]);
    const user = qoreContext.useCurrentUser();
    const getCustomers = async () => {
        try {
            setIsLoading(true);
            let result;
            let customerCondition = { $and: [{ rm_id: user?.user?.id }] };
            if (customer.length > 0) {
                let tempOr = { $or: [] };
                await customer.forEach((el: any) => {
                    tempOr["$or"].push({ name: el });
                });
                customerCondition["$and"].push(tempOr);
            }
            if (user?.user?.id) {
                const response = await qoreContext.client
                    .table("customers")
                    .readRows({
                        condition: customerCondition,
                        populate: ["customer_id_items"],
                    })
                    .toPromise();
                if (response?.data?.nodes) {
                    setPages(Math.ceil(response?.data?.nodes.length / limit));
                    result = JSON.parse(JSON.stringify(response?.data?.nodes));
                    if (search !== "") {
                        result = result.filter((el: any) =>
                            el.name.toLowerCase().includes(search.toLowerCase())
                        );
                    }
                    setAssets(result);
                    if (response?.data?.nodes.length > 0) {
                        let temp = true;
                        response?.data?.nodes.forEach((le: any) => {
                            if (le.customer_id_items.length > 0) {
                                temp = false;
                            }
                        });
                        setNoDataFound(temp);
                    }
                    setData(response?.data?.nodes);
                    setIsLoading(false);
                }
            }
        } catch (error) { }
    };
    const setAssetsAllocation = () => {
        try {
            let type: any = {};
            let ETF: any = [];
            let stock: any = [];
            let fund: any = [];
            let bond: any = [];
            let forex: any = [];
            let crypto: any = [];
            let country: any = {};
            let currency: any = {};
            let industry: any = {};

            assets.map((el: any) => {
                // type
                el.customer_id_items.map((le: any) => {
                    if (le.type === "ETF") {
                        ETF.push(le);
                    } else if (le.type === "stock" || le.type === 'Common Stock') {
                        stock.push(le);
                    } else if (le.type === "forex") {
                        forex.push(le);
                    } else if (le.type === "CC") {
                        crypto.push(le);
                    } else if (le.type === "mutualFund") {
                        fund.push(le);
                    } else if (le.type === "bond") {
                        bond.push(le);
                    }
                    if (!country.hasOwnProperty(le.location)) {
                        country[le.location] = [];
                        country[le.location].push(le);
                    } else if (country[le.location].length > 0) {
                        country[le.location].push(le);
                    }
                    if (!currency.hasOwnProperty(le.currency)) {
                        currency[le.currency] = [];
                        currency[le.currency].push(le);
                    } else if (currency[le.currency].length > 0) {
                        currency[le.currency].push(le);
                    }
                    if (!industry.hasOwnProperty(le.industry)) {
                        industry[le.industry] = [];
                        industry[le.industry].push(le);
                    } else if (industry[le.industry].length > 0) {
                        industry[le.industry].push(le);
                    }
                });
            });
            country = Object.entries(country);
            currency = Object.entries(currency);
            industry = Object.entries(industry);
            setCountryAllocation(country);
            setCurrencyAllocation(currency);
            setIndustryAllocation(industry);
            if (ETF.length > 0) {
                type["ETF"] = ETF;
            }
            if (stock.length > 0) {
                type["Stock"] = stock;
            }
            if (fund.length > 0) {
                type["Mutual Fund"] = fund;
            }
            if (bond.length > 0) {
                type["Bonds"] = bond;
            }
            if (forex.length > 0) {
                type["Forex"] = forex;
            }
            if (crypto.length > 0) {
                type["Crypto"] = crypto;
            }
            type = Object.entries(type);
            setTypeAllocation(type);
        } catch (error) { }
    };
    useEffect(() => {
        getCustomers();
    }, [user?.user?.id, customer]);
    useEffect(() => {
        setAssetsAllocation();
    }, [assets]);

    const nav = useNavigate();

    // const handleSingle = (params: any) => {
    //   const singleAssetAllocation = typeAllocation.filter(
    //     (el: any) => el[0] === params
    //   );
    //   nav(`${params}`);
    // };

    return (
        <div>
            <Center pl="3" py={6}>
                {!isLoading ? (
                    !noDataFound ? (
                        <Flex wrap={"wrap"} w={"full"}>
                            <Box
                                minW={"380px"}
                                maxW={"380px"}
                                bg={useColorModeValue("white", "gray.800")}
                                boxShadow={"lg"}
                                rounded={"md"}
                                px="24px"
                                my={10}
                            >
                                <ChartCard
                                    allocationData={typeAllocation}
                                    chartTitle={"Asset Type"}
                                // handleSingle={handleSingle}
                                />
                            </Box>
                            <Spacer />
                            <Box
                                bg={useColorModeValue("white", "gray.800")}
                                boxShadow={"lg"}
                                rounded={"md"}
                                px="24px"
                                my={10}
                            >
                                <ChartCard
                                    allocationData={countryAllocation}
                                    chartTitle={"Geography"}
                                // handleSingle={handleSingle}
                                />
                            </Box>
                            <Spacer />
                            <Box
                                bg={useColorModeValue("white", "gray.800")}
                                boxShadow={"lg"}
                                rounded={"md"}
                                px="24px"
                                my={10}
                            >
                                <ChartCard
                                    allocationData={currencyAllocation}
                                    chartTitle={"Currency"}
                                />
                            </Box>
                            <Spacer />
                            <Box
                                bg={useColorModeValue("white", "gray.800")}
                                boxShadow={"lg"}
                                rounded={"md"}
                                px="24px"
                                my={10}
                            >
                                <ChartCard
                                    allocationData={industryAllocation}
                                    chartTitle={"Industry"}
                                />
                            </Box>
                        </Flex>
                    ) : (
                        <NoDataFoundTransaction instrument={"Asset Allocation"} />
                    )
                ) : (
                    <Box py="10"> {LocalLoader()} </Box>
                )}
            </Center>
        </div>
    );
};
export default AssetAllocation;
