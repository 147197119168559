import { Tr, Tbody, Td } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const TableMarketBody = ({ list, instrument }: any) => {

    const nav = useNavigate();

    const handleDetail = (params: string) => {
        nav(params);
    };

    return (
        <Tbody>
            {
                list.map((el: any, index: number) => {
                    return (
                        <Tr
                            key={index}
                            cursor="pointer"
                            _hover={{
                                backgroundColor: "rgba(249, 247, 242, 1)",
                            }}
                            onClick={() =>
                                handleDetail(`/financial-instrument/${instrument}/${el.Code}`)
                            }
                        >
                            <Td textTransform="capitalize" p="12px">{el.Name}</Td>
                            <Td textTransform="capitalize" p="12px">{el.Code}</Td>
                            <Td textTransform="capitalize" p="12px">{el.OperatingMIC}</Td>
                            <Td textTransform="capitalize" p="12px">{el.Country}</Td>
                            <Td textTransform="capitalize" p="12px">{el.Currency}</Td>
                        </Tr>
                    );
                })
            }
        </Tbody>
    );
};

export default TableMarketBody;