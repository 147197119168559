import { Tr, Th } from "@chakra-ui/react";

const CustomerAssetTableHead = () => {
  return (
    <Tr>
      <Th textTransform="capitalize" p="12px">
        Name
      </Th>
      <Th textTransform="capitalize" p="12px">
        Type
      </Th>
      <Th textTransform="capitalize" p="12px">
        Code
      </Th>
      <Th textTransform="capitalize" p="12px">
        Amount
      </Th>
      <Th textTransform="capitalize" p="12px">
        Price
      </Th>
      <Th textTransform="capitalize" p="12px">
        Total
      </Th>
      <Th textTransform="capitalize" w={'100px'} maxW="100px" p="12px">
        Total (USD)
      </Th>
      <Th textTransform="capitalize" w={'120px'} maxW="120px" p="12px">
        Updated
      </Th>
      <Th textTransform="capitalize" maxW="70px" p="12px">
        Action
      </Th>
    </Tr>
  );
};

export default CustomerAssetTableHead;
