// import { Link } from "react-router-dom";
// import {
//     Flex,
//     Icon,
//     FlexProps,
//     Accordion,
//     AccordionItem,
//     AccordionButton,
//     AccordionIcon,
//     AccordionPanel,
// } from "@chakra-ui/react";
// import { IconType } from "react-icons";
// import { ReactText } from "react";

// interface FILinkItemProps {
//     name: string;
//     path: string;
// }

// interface NavItemProps extends FlexProps {
//     icon: IconType;
//     children: ReactText;
//     path: Array<FILinkItemProps>;
// }

// const NavItem = ({ icon, path, children, ...rest }: NavItemProps) => {
//     return (
//         <Accordion allowMultiple mx="2">
//             <AccordionItem borderColor="transparent">
//                 <AccordionButton
//                     _focus={{ boxShadow: "none" }}
//                     pl="8px"
//                     borderRadius="4px"
//                     py="1.5"
//                     _expanded={{ bg: "#c6b378", color: "white", fontWeight: "600" }}
//                 >
//                     <Flex
//                         align="center"
//                         style={{ textDecoration: "none", fontSize: "12px" }}
//                         borderRadius="lg"
//                         role="group"
//                         {...rest}
//                     >
//                         {icon && <Icon mr="4" fontSize="16" as={icon} />}
//                         {children}
//                     </Flex>
//                     <AccordionIcon mr="0" ml="auto" />
//                 </AccordionButton>

//                 <AccordionPanel py="1" px="0">
//                     {path.map((el) => (
//                         <Link key={el.name} to={el.path}>
//                             <Flex
//                                 fontSize="12px"
//                                 align="center"
//                                 py="1"
//                                 pl="10"
//                                 borderRadius="4px"
//                                 role="group"
//                                 cursor="pointer"
//                                 _hover={{
//                                     bg: "#c6b378",
//                                     color: "white",
//                                 }}
//                                 {...rest}
//                             >
//                                 {el.name}
//                             </Flex>
//                         </Link>
//                     ))}
//                 </AccordionPanel>
//             </AccordionItem>
//         </Accordion>
//     );
// };

// export default NavItem;

import { Link } from "react-router-dom";
import {
    Flex,
    Icon,
    FlexProps,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { ReactText } from "react";

interface FILinkItemProps {
    name: string;
    path: string;
}

interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactText;
    path: Array<FILinkItemProps>;
}

const NavItem = ({ icon, path, children, ...rest }: NavItemProps) => {
    return (
        // <Accordion allowMultiple mx="2">
            <AccordionItem borderColor="transparent">
                <AccordionButton
                    _focus={{ boxShadow: "none" }}
                    pl="8px"
                    borderRadius="4px"
                    py="1.5"
                    _expanded={{ bg: "#c6b378", color: "white", fontWeight: "600" }}
                >
                    <Flex
                        align="center"
                        style={{ textDecoration: "none", fontSize: "12px" }}
                        borderRadius="lg"
                        role="group"
                        {...rest}
                    >
                        {icon && <Icon mr="4" fontSize="16" as={icon} />}
                        {children}
                    </Flex>
                    <AccordionIcon mr="0" ml="auto" />
                </AccordionButton>

                <AccordionPanel py="1" px="0">
                    {path.map((el) => (
                        <>
                            {
                                el !== null && 
                                <Link key={el?.name} to={el?.path}>
                                    <Flex
                                        fontSize="12px"
                                        align="center"
                                        py="1"
                                        pl="10"
                                        borderRadius="4px"
                                        role="group"
                                        cursor="pointer"
                                        _hover={{
                                            bg: "#c6b378",
                                            color: "white",
                                        }}
                                        {...rest}
                                    >
                                        {el?.name}
                                    </Flex>
                                </Link>
                            }
                        </>
                    ))}
                </AccordionPanel>
            </AccordionItem>
        // </Accordion>
    );
};

export default NavItem;
