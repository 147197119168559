import {
    useDisclosure,
    Center,
    Box,
    useColorModeValue,
} from "@chakra-ui/react";
import qoreContext from "../../common/qoreContext";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import TableUserFilter from "../../components/FilterAndSearch/TableUserFilter";
import PaginatorTableMarket from "../../components/Tables/PaginatorTableMarket";
import FundManagementTable from "../../components/Tables/FundManagementTable";
import LocalLoader from "../../components/Loader/LocalLoader";
import EmptyData from "../../components/EmptyData";
import AddFundModal from "../../components/Modal/AddFundModal";
import TakeFundModal from "../../components/Modal/TakeFundModal";
import LinkNoDataFound from "../../components/StatusPage/LinkNoDataFound";

import { useToast } from '@chakra-ui/react';
import { alertHandling } from "../../hooks/alertHandling";


function FundManagement() {
    const toast = useToast()
    const user = qoreContext.useCurrentUser();
    const { insertRow: insertTransaction } = qoreContext
        .table("fund_logs")
        .useInsertRow();
    const { updateRow, status } = qoreContext.table("customers").useUpdateRow();
    const addFund = useDisclosure();
    const takeFund = useDisclosure();
    const [loadButtonStatus, setLoadButtonStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [noDataFound, setNoDataFound] = useState<boolean>(true);
    const [takeFundError, setTakeFundError] = useState<any[]>([]);

    const [search, setSearch] = useState<string>("");
    const [allocatedFunds, setAllocatedFunds] = useState<number>(0);

    const [limit, setLimit] = useState<number>(25);
    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [asset, setAsset] = useState<any>("");

    const [data, setData] = useState<any[]>([]);
    const [customerData, setCustomerData] = useState<any>({});

    const getAssetData = async (id: any) => {
        const response = await qoreContext.client
            .table("assets")
            .readRow(id)
            .toPromise();
        setAsset(response?.data);
    };

    const getData = async () => {
        try {
            setIsLoading(true);
            let result;
            if (user?.user?.id) {
                const response = await qoreContext.client
                    .table("customers")
                    .readRows({
                        condition: { rm_id: user?.user?.id },
                        populate: ["customer_id_items"],
                    })
                    .toPromise();

                if (response?.data?.nodes) {
                    setPages(Math.ceil(response?.data?.nodes.length / limit));

                    result = JSON.parse(JSON.stringify(response?.data?.nodes));
                    if (search !== "") {
                        result = result.filter((el: any) =>
                            el.name.toLowerCase().includes(search.toLowerCase())
                        );
                    }

                    if (limit > 0) {
                        result = result.splice((currentPage - 1) * limit, limit);
                    }

                    setData(result);
                    if (response?.data?.nodes.length > 0) {
                        setNoDataFound(false);
                    }
                    setIsLoading(false);
                }
            }
        } catch (error) { }
    };

    const searchFilter = async (data: any) => {
        let result;

        if (search !== "") {
            result = await data.filter((el: any) =>
                el.name.toLowerCase().includes(search.toLowerCase())
            );
        }

        if (result) {
            setList(result);
        } else {
            setList(data);
        }
    };

    const getCustomerData = async (id: any) => {
        const response = await qoreContext.client
            .table("customers")
            .readRow(id)
            .toPromise();

        if (response?.data) {
            setCustomerData(response?.data);
        }
    };

    const [list, setList] = useState<any[]>([]);

    useEffect(() => {
        getData();
    }, [user?.user?.id]);

    useEffect(() => {
        if (data) {
            let allocatedFunds: number = 0;
            data.map((el: any, index: number) => {
                el.customer_id_items.forEach((le: any) => {
                    allocatedFunds += le.amount * le.updated_usd_value;
                });
            });
            setAllocatedFunds(allocatedFunds);
        }
        setList(data);
    }, [data]);

    useEffect(() => {
        searchFilter(data);
    }, [search]);

    const handleAddFund = async (values: any) => {
        values.fund = parseFloat(values.fund);
        const response = await updateRow(customerData?.id, {
            funds: Number(customerData?.funds) + Number(values.fund),
            unallocated_funds:
                Number(customerData?.unallocated_funds) + Number(values.fund),
        });
        let transactionData = {
            action: "DEPOSIT",
            amount: values.fund,
            customer_id_fund_logs: customerData?.id,
            previous_balance:
                Number(customerData?.unallocated_funds) -
                Number(customerData?.allocated_funds) +
                Number(allocatedFunds),
            balance:
                Number(customerData?.unallocated_funds) -
                Number(customerData?.allocated_funds) +
                Number(allocatedFunds) +
                Number(values.fund),
        };

        await insertTransaction(transactionData);
        getData();

        form.setValue("customer", "");
        form.setValue("fund", 0);
        addFund.onClose();
        alertHandling(toast, 'Success Add Fund', 'success', 'top-right');
    };

    const handleTakeFund = async (values: any) => {
        try {
            setLoadButtonStatus(true);
            if (Number(customerData?.funds) < Number(values.fund)) {
                throw { name : 'Not Enough Fund'}
            }

            setTakeFundError([]);
    
            values.fund = parseFloat(values.fund);
            let transactionData = {
                action: "WITHDRAWAL",
                amount: values.fund,
                customer_id_fund_logs: customerData?.id,
                previous_balance:
                    Number(customerData?.unallocated_funds) -
                    Number(customerData?.allocated_funds) +
                    Number(allocatedFunds),
                balance:
                    Number(customerData?.unallocated_funds) -
                    Number(customerData?.allocated_funds) +
                    Number(allocatedFunds) -
                    Number(values.fund),
            };
            let response;
            if (
                customerData?.funds >= values.fund &&
                customerData?.unallocated_funds - customerData?.allocated_funds >=
                values.fund
            ) {
                response = await updateRow(customerData?.id, {
                    funds: customerData?.funds - values.fund,
                    unallocated_funds: customerData?.unallocated_funds - values.fund,
                });
            } else if (
                customerData?.unallocated_funds - customerData?.allocated_funds >=
                values.fund
            ) {
                response = await updateRow(customerData?.id, {
                    funds: 0,
                    unallocated_funds: customerData?.unallocated_funds - values.fund,
                });
            } else {
                throw { name : 'Not Enough Fund'}
            }
    
            if (response) {
                await insertTransaction(transactionData);
            }
            getData();
    
            form2.setValue("fund", 0);
            setLoadButtonStatus(false);
            setTakeFundError([]);
            takeFund.onClose();
            alertHandling(toast, 'Success Take Fund', 'success', 'top-right');
        } catch (error) {
            let err = [`${error.name}`];
            setTakeFundError(err);
            setLoadButtonStatus(false);
        }
    };

    const form = useForm({
        defaultValues: {
            customer: "",
            fund: 0,
        },
    });

    const form2 = useForm({
        defaultValues: {
            fund: 0,
        },
    });

    const handlePageChange = (nextPage: number) => {
        setCurrentPage(nextPage);
    };

    const closedErrorTakeFund = () => {
        setTakeFundError([]);
    };

    return (
        <div>
            <AddFundModal
                onClose={addFund.onClose}
                isOpen={addFund.isOpen}
                form={form}
                handleAddFund={handleAddFund}
            />

            <TakeFundModal
                isOpen={takeFund.isOpen}
                onClose={takeFund.onClose}
                form2={form2}
                handleTakeFund={handleTakeFund}
                customerData={customerData}
                loadButtonStatus={loadButtonStatus}
                errorClosedButton={closedErrorTakeFund}
                takeFundError={takeFundError}
            />

            <Center pl="3" py={6}>
                {!isLoading ? (
                    !noDataFound ? (
                        <Box
                            w={"full"}
                            bg={useColorModeValue("white", "gray.800")}
                            boxShadow={"lg"}
                            rounded={"md"}
                            px="24px"
                        >
                            <Box w={{ base: "full" }}>
                                <TableUserFilter
                                    buttonText={"Add Funds"}
                                    setSearch={setSearch}
                                />
                            </Box>

                            {isLoading ? (
                                <Box py="10"> {LocalLoader()} </Box>
                            ) : (
                                <>
                                    {list.length > 0 ? (
                                        <>
                                            <FundManagementTable
                                                list={list}
                                                addFund={addFund}
                                                takeFund={takeFund}
                                                getCustomerData={getCustomerData}
                                            />

                                            <PaginatorTableMarket
                                                pages={pages}
                                                currentPage={currentPage}
                                                handlePageChange={handlePageChange}
                                            />
                                        </>
                                    ) : (
                                        <EmptyData />
                                    )}
                                </>
                            )}
                        </Box>
                    ) : (
                        <LinkNoDataFound
                            linkTo={"management/user-management"}
                            instrument={"customers"}
                        />
                    )
                ) : (
                    <Box py="10"> {LocalLoader()} </Box>
                )}
            </Center>
        </div>
    );
}

export default FundManagement;
