import {
    Box,
    Flex,
    Icon
} from "@chakra-ui/react";
import { IconType } from "react-icons";

const TitleBoxDetail = ({iconTitle, boxTitle, fsIcon} : any) => {

    interface TitleBoxProps {
        name: string;
        icon: IconType;
    }

    const titleBox: Array<TitleBoxProps> = [
        { name: boxTitle, icon: iconTitle },
    ];

    return (
            <Box>
                {titleBox.map((el: any, index: number) => {
                    return (
                        <Box key={index}>
                            <Flex
                                align="center"
                                py='22px'
                                style={{ 
                                    textDecoration: "none", 
                                    fontSize: "14px", 
                                    fontWeight: '600' }}
                                role="group"
                            >
                                {el.icon && 
                                    <Icon 
                                        color="rgba(151, 133, 77, 1)" 
                                        mr="4" fontSize={fsIcon} as={el.icon} />}
                                {el.name}
                            </Flex>
                        </Box>
                    )
                })

                }
            </Box>
    );
};

export default TitleBoxDetail;