const token = "620e19e49de326.23079426";
function sorting(result: any) {
  result = result.sort((a: any, b: any) => {
    (a = a.Name.toLowerCase()), (b = b.Name.toLowerCase());

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });
  return result;
}
// curl -X POST " /api/workflow/98b17a25-b692-4756-9593-e739d32a63b7/instance/9b723bdf-786c-4bc5-a88a-88e036c6e8e4/inbound" -H  "accept: */*" -H  "x-qonduit-admin-secret: 6WH5ipEBjYxbkMXwZDQifzcAfPOZZW37" -H  "Content-Type: application/json" -d "{\"test\":4}"
// https://eodhistoricaldata.com/api/exchanges-list/?api_token=620e19e49de326.23079426&fmt=json
// curl -X POST " /api/workflow/e992fa26-23bf-4ce4-89ab-2f2f6d41dd16/instance/75c403c7-0d35-4f10-8588-a1c75e67e7ca/inbound" -H  "accept: */*" -H  "x-qonduit-admin-secret: 6WH5ipEBjYxbkMXwZDQifzcAfPOZZW37" -H  "Content-Type: application/json" -d "{\"test\":4}"
class getAPI {
  // Exchange List
  static async getETFList() {
    try {
      // qore pipelinenya di hit disini harusnya
      const response = await fetch(
        `https://eodhistoricaldata.com/api/exchanges-list/?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      data = data.filter(
        (el: any) =>
          el.Code !== "COMM" &&
          el.Code !== "BOND" &&
          el.Code !== "FOREX" &&
          el.Code !== "EUFUND" &&
          el.Code !== "GBOND" &&
          el.Code !== "MONEY" &&
          el.Code !== "CC" &&
          el.Code !== "INDX" &&
          el.Code !== "AT" &&
          el.Code !== "LIM" &&
          el.Code !== "ETLX" &&
          el.Code !== "RO" &&
          el.Code !== "BA" &&
          el.Code !== "CM" &&
          el.Code !== "NFN" &&
          el.Code !== "IC" &&
          el.Code !== "IR" &&
          el.Code !== "KAR" &&
          el.Code !== "JK" &&
          el.Code !== "IS" &&
          el.Code !== "LU" &&
          el.Code !== "KQ" &&
          el.Code !== "RG" &&
          el.Code !== "VX" &&
          el.Code !== "TL" &&
          el.Code !== "V" &&
          el.Code !== "VN" &&
          el.Code !== "VS" &&
          el.Code !== "WAR" &&
          el.Code !== "ZSE"
      );
      // AT, LIM, ETLX, RO, BA, CM, NFN, IC, IR, KAR, JK, IS, LU, KQ, RG, VX, TL, V, VN, VS, WAR, ZSE  (no ETF)
      // AU, BE, BSE, MI, BUD, CN, SN, CO, DU, AS, BR, LS, PA, F, HM, HA, HE, HK, KO, JSE, MC, IL, LSE, KLSE, MU, MCX, MX, PSE, OL, NSE, NEO, NB,SHG, SR, SA, SHE, SG, SW, ST, STU, TW, TWO, TA, BK, TO, US, VI, XETRA (ETF)
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getStockList() {
    try {
      const response = await fetch(
        `https://eodhistoricaldata.com/api/exchanges-list/?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      data = data.filter(
        (el: any) =>
          el.Code !== "COMM" &&
          el.Code !== "BOND" &&
          el.Code !== "FOREX" &&
          el.Code !== "EUFUND" &&
          el.Code !== "GBOND" &&
          el.Code !== "MONEY" &&
          el.Code !== "CC" &&
          el.Code !== "INDX"
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getMutualFundList() {
    try {
      const response = await fetch(
        `https://eodhistoricaldata.com/api/exchanges-list/?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      data = data.filter(
        (el: any) =>
          el.Code === "EUFUND" ||
          el.Code === "AU" ||
          el.Code === "BE" ||
          el.Code === "MI" ||
          el.Code === "CO" ||
          el.Code === "HM" ||
          el.Code === "HK" ||
          el.Code === "LS" ||
          el.Code === "F" ||
          el.Code === "IR" ||
          el.Code === "KLSE" ||
          el.Code === "LSE" ||
          el.Code === "LU" ||
          el.Code === "MC" ||
          el.Code === "OL" ||
          el.Code === "SG" ||
          el.Code === "SW" ||
          el.Code === "TO" ||
          el.Code === "US"
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  // Exchange Market

  static async getETFMarket(params: any) {
    try {
      const response = await fetch(
        `https://eodhistoricaldata.com/api/exchange-symbol-list/${params.market}?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      data = await data.filter((el: any) => el.Type === "ETF");
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getIndex() {
    try {
      const response = await fetch(
        `https://eodhistoricaldata.com/api/exchange-symbol-list/INDX?api_token=${token}&fmt=json`
      );

      let data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getStockMarket(params: any) {
    try {
      const response = await fetch(
        `https://eodhistoricaldata.com/api/exchange-symbol-list/${params.market}?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      data = await data.filter((el: any) => el.Type === "Common Stock");
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getMutualFundMarket(params: any) {
    try {
      console.log("hehe");
      const response = await fetch(
        `https://eodhistoricaldata.com/api/exchange-symbol-list/${params.market}?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      data = await data.filter((el: any) => el.Type === "FUND");
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getBondMarket() {
    try {
      const response = await fetch(
        `https://eodhistoricaldata.com/api/exchange-symbol-list/BOND?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      const response2 = await fetch(
        `https://eodhistoricaldata.com/api/exchange-symbol-list/GBOND?api_token=${token}&fmt=json`
      );
      let data2 = await response2.json();
      data = data.concat(data2);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getForexMarket() {
    try {
      const response = await fetch(
        `https://eodhistoricaldata.com/api/exchange-symbol-list/FOREX?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getCryptoMarket() {
    try {
      const response = await fetch(
        `https://eodhistoricaldata.com/api/exchange-symbol-list/CC?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      data = await sorting(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getEOD(params: any) {
    try {
      const response = await fetch(
        `https://eodhistoricaldata.com/api/eod/${params.code}.${params.market}?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      return data[data.length - 1];
    } catch (error) {
      console.log(error);
    }
  }

  static async getAllEOD(params: any) {
    try {
      const response = await fetch(
        `https://eodhistoricaldata.com/api/eod/${params.code}.${params.market}?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  static async convertCurrency(params: any) {
    try {
      if (params !== `undefinedUSD`) {
        const response = await fetch(
          `https://eodhistoricaldata.com/api/eod/${params}.FOREX?api_token=${token}&fmt=json`
        );
        let data = await response.json();
        return data[data.length - 1];
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async convertCurrencyIDRtoUSD() {
    try {
      const response = await fetch(
        `https://eodhistoricaldata.com/api/eod/USDIDR.FOREX?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      return data[data.length - 1];
    } catch (error) {
      console.log(error);
    }
  }

  static getDetail = async (code: any, exchange_market: any) => {
    try {
      const response = await fetch(
        `https://eodhistoricaldata.com/api/fundamentals/${code}.${exchange_market}?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  static getListBySearch = async (code: any) => {
    try {
      const response = await fetch(
        `https://eodhistoricaldata.com/api/search/${code}?api_token=${token}&fmt=json`
      );
      let data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };
}

export default getAPI;
