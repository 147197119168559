function onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
}

class filterAPI {
    static allFilterData(allData: any, searchCurrency: any) {
        try {
            let tempCurr: any[] = [];
            let tempCountry: any[] = [];
            let tempExchange: any[] = [];
            allData.forEach((el: any) => {
                if (el.Currency) tempCurr.push(el.Currency);
                if (el.Country) tempCountry.push(el.Country);
                if (el.Exchange) tempExchange.push(el.Exchange);
            });
            if (tempCurr.length > 0) tempCurr = tempCurr.filter(onlyUnique);
            if (tempCountry.length > 0) tempCountry = tempCountry.filter(onlyUnique);
            if (tempExchange.length > 0)
                tempExchange = tempExchange.filter(onlyUnique);

            // let a = ["CC", "СС"];
            // a[0] === a[1] ? console.log(true) : console.log(false);

            // console.log(tempExchange, "ini temp exchange");

            if (searchCurrency !== "") {
                if (tempCurr.length > 0) {
                    tempCurr = tempCurr.filter((el: any) => {
                        return el.toUpperCase().includes(searchCurrency.toUpperCase());
                    });
                }
                if (tempCountry.length > 0) {
                    tempCountry = tempCountry.filter((el: any) => {
                        return el.toUpperCase().includes(searchCurrency.toUpperCase());
                    });
                }
                if (tempExchange.length > 0) {
                    tempExchange = tempExchange.filter((el: any) => {
                        return el.toUpperCase().includes(searchCurrency.toUpperCase());
                    });
                }
            }

            if (tempCurr.length > 0) tempCurr = tempCurr.sort();
            if (tempCountry.length > 0) tempCountry = tempCountry.sort();
            if (tempExchange.length > 0) tempExchange = tempExchange.sort();

            if (
                tempCurr.length > 0 &&
                tempCountry.length > 0 &&
                tempExchange.length > 0
            ) {
                return [tempCurr, tempCountry, tempExchange];
            } else if (
                tempCurr.length > 0 &&
                tempCountry.length > 0 &&
                tempExchange.length === 0
            ) {
                return [tempCurr, tempCountry, []];
            } else if (
                tempCurr.length > 0 &&
                tempCountry.length === 0 &&
                tempExchange.length > 0
            ) {
                return [tempCurr, [], tempExchange];
            } else if (
                tempCurr.length === 0 &&
                tempCountry.length > 0 &&
                tempExchange.length > 0
            ) {
                return [[], tempCountry, tempExchange];
            } else if (
                tempCurr.length > 0 &&
                tempCountry.length === 0 &&
                tempExchange.length === 0
            ) {
                return [tempCurr, [], []];
            } else if (
                tempCurr.length === 0 &&
                tempCountry.length > 0 &&
                tempExchange.length === 0
            ) {
                return [[], tempCountry, []];
            } else if (
                tempCurr.length === 0 &&
                tempCountry.length === 0 &&
                tempExchange.length > 0
            ) {
                return [[], [], tempExchange];
            }
        } catch (error) {
            console.log(error, "ini error");
        }
    }

    static async allFilterChecked(
        currencies: any,
        countries: any,
        exchanges: any
    ) {
        let tempCheckedCurr: any[] = [];
        let tempCheckedCountries: any[] = [];
        let tempCheckedExchanges: any[] = [];
        if (currencies.length > 0) {
            for (let i = 0; i < currencies.length; i++) {
                tempCheckedCurr.push(false);
            }
        }
        if (countries.length > 0) {
            for (let i = 0; i < countries.length; i++) {
                tempCheckedCountries.push(false);
            }
        }
        if (exchanges.length > 0) {
            for (let i = 0; i < exchanges.length; i++) {
                tempCheckedExchanges.push(false);
            }
        }

        if (
            tempCheckedCurr.length > 0 &&
            tempCheckedCountries.length > 0 &&
            tempCheckedExchanges.length > 0
        ) {
            return [tempCheckedCurr, tempCheckedCountries, tempCheckedExchanges];
        } else if (
            tempCheckedCurr.length > 0 &&
            tempCheckedCountries.length > 0 &&
            tempCheckedExchanges.length === 0
        ) {
            return [tempCheckedCurr, tempCheckedCountries];
        } else if (
            tempCheckedCurr.length > 0 &&
            tempCheckedCountries.length === 0 &&
            tempCheckedExchanges.length > 0
        ) {
            return [tempCheckedCurr, tempCheckedExchanges];
        } else if (
            tempCheckedCurr.length === 0 &&
            tempCheckedCountries.length > 0 &&
            tempCheckedExchanges.length > 0
        ) {
            return [tempCheckedCountries, tempCheckedExchanges];
        } else if (
            tempCheckedCurr.length > 0 &&
            tempCheckedCountries.length === 0 &&
            tempCheckedExchanges.length === 0
        ) {
            return [tempCheckedCurr];
        } else if (
            tempCheckedCurr.length === 0 &&
            tempCheckedCountries.length > 0 &&
            tempCheckedExchanges.length === 0
        ) {
            return [tempCheckedCountries];
        } else if (
            tempCheckedCurr.length === 0 &&
            tempCheckedCountries.length === 0 &&
            tempCheckedExchanges.length > 0
        ) {
            return [tempCheckedExchanges];
        }
    }

    static filterList(
        country: any,
        currency: any,
        exchange: any,
        data: any,
        search: any
    ) {
        let temp: any = [];
        let result: any[] = [];

        exchange.forEach((el: any) => {
            if (
                el === 'All Markets' ||
                el === 'US Markets' ||
                el === 'Other' ||
                el === 'Asia Markets' ||
                el === 'Europe Markets'
            ) {
                temp.push(el);
            }
        });


        if (temp.length > 0) {
            let usMarket: any = [
                'DJT',
                'DJU',
                'MID',
                'NDX',
                'NYA',
                'RUT'
            ];
            let eurMarket: any = [
                'AEX',
                'BFX',
                'FCHI',
                'FTSE',
                'FTMIB',
                'GDAXI',
                'IMOEX',
                'STOXX',
                'OMXC25CAP',
                'OMXHPI',
                'OMXS30',
                'PSI20',
                'SSMI',
            ];
            let asiaMarket: any = [
                'AXJO',
                'FTFCNBCA',
                'HSI',
                'IECNCGP',
                'KLSE',
                'KS11',
                'N255',
                'NSEI',
                'NZ50',
                'SETI',
                'SSEC',
                'STI',
                'SZI',
                'TWII'
            ];

            let otherMarket: any = [];

            const filteredUSMarket = data.filter((obj: any) => !usMarket.includes(obj.Code));
            console.log(data, `data`)
            const filteredAsiaMarket = filteredUSMarket.filter((obj: any) => !asiaMarket.includes(obj.Code));
            const filteredEuropeMarket = filteredAsiaMarket.filter((obj: any) => !eurMarket.includes(obj.Code));

            temp.forEach((el: any) => {
                if (el === 'All Markets') {
                    data.forEach((index: any) => {
                        result.push(index);
                    })
                } else if (el === 'US Markets') {
                    usMarket.forEach((market: any) => {
                        data.forEach((index: any) => {
                            if (market === index.Code) {
                                result.push(index);
                            }
                        })
                    })
                } else if (el === 'Europe Markets') {
                    eurMarket.forEach((market: any) => {
                        data.forEach((index: any) => {
                            if (market === index.Code) {
                                result.push(index);
                            }
                        })
                    })
                } else if (el === 'Asia Markets') {
                    asiaMarket.forEach((market: any) => {
                        data.forEach((index: any) => {
                            if (market === index.Code) {
                                result.push(index);
                            }
                        })
                    })
                } else if (el === 'Other') {
                    filteredEuropeMarket.forEach((market: any) => {
                        result.push(market);
                    })
                }
            });
            return result
        } else {
            if (country.length > 0 || currency.length > 0 || exchange.length > 0) {
                if (country.length > 0 && currency.length > 0 && exchange.length > 0) {
                    country.forEach((el: any) => {
                        currency.forEach((li: any) => {
                            exchange.forEach((il: any) => {
                                result.push(
                                    data.filter(
                                        (le: any) =>
                                            le.Currency === li &&
                                            le.Country === el &&
                                            le.Exchange === il
                                    )
                                );
                            });
                        });
                    });
                } else if (
                    country.length > 0 &&
                    currency.length > 0 &&
                    exchange.length === 0
                ) {
                    country.forEach((el: any) => {
                        currency.forEach((li: any) => {
                            result.push(
                                data.filter((le: any) => le.Currency === li && le.Country === el)
                            );
                        });
                    });
                } else if (
                    country.length > 0 &&
                    currency.length === 0 &&
                    exchange.length > 0
                ) {
                    country.forEach((el: any) => {
                        exchange.forEach((li: any) => {
                            result.push(
                                data.filter((le: any) => le.Exchange === li && le.Country === el)
                            );
                        });
                    });
                } else if (
                    country.length === 0 &&
                    currency.length > 0 &&
                    exchange.length > 0
                ) {
                    exchange.forEach((el: any) => {
                        currency.forEach((li: any) => {
                            result.push(
                                data.filter((le: any) => le.Currency === li && le.Exchange === el)
                            );
                        });
                    });
                } else if (
                    country.length === 0 &&
                    currency.length > 0 &&
                    exchange.length === 0
                ) {
                    currency.forEach((li: any) => {
                        result.push(data.filter((le: any) => le.Currency === li));
                    });
                } else if (
                    country.length > 0 &&
                    currency.length === 0 &&
                    exchange.length === 0
                ) {
                    country.forEach((el: any) => {
                        result.push(data.filter((le: any) => le.Country === el));
                    });
                } else if (
                    country.length === 0 &&
                    currency.length === 0 &&
                    exchange.length > 0
                ) {
                    exchange.forEach((el: any) => {
                        result.push(data.filter((le: any) => le.Exchange === el));
                    });
                }

                console.log(result, "ini result");
                result = Array.prototype.concat.apply([], result);
                result = result.filter(onlyUnique);
            } else {
                result = JSON.parse(JSON.stringify(data));
            }

            if (search !== "") {
                result = result.filter((el: any) =>
                    el.Code.toLowerCase().includes(search.toLowerCase())
                );
            }

            result = result.sort((a, b) => {
                (a = a.Name.toLowerCase()), (b = b.Name.toLowerCase());

                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0;
            });

            return result;
        }

    }

    static forexRegion(
        country: any,
        currency: any,
        exchange: any,
        data: any,
        search: any
    ) {
        let temp: any = [];
        let result: any[] = [];
        if(country.length > 0 || currency.length > 0 || exchange.length > 0) {
            if (country.length > 0 && currency.length > 0 && exchange.length > 0) {
                country.forEach((el: any) => {
                    currency.forEach((li: any) => {
                        exchange.forEach((il: any) => {
                            result.push(
                                data.filter(
                                    (le: any) =>
                                        le.Currency === li &&
                                        le.Country === el &&
                                        le.Exchange === il
                                )
                            );
                        });
                    });
                });
            } else if (
                country.length > 0 &&
                currency.length > 0 &&
                exchange.length === 0
            ) {
                country.forEach((el: any) => {
                    currency.forEach((li: any) => {
                        result.push(
                            data.filter((le: any) => le.Currency === li && le.Country === el)
                        );
                    });
                });
            } else if (
                country.length > 0 &&
                currency.length === 0 &&
                exchange.length > 0
            ) {
                country.forEach((el: any) => {
                    exchange.forEach((li: any) => {
                        result.push(
                            data.filter((le: any) => le.Exchange === li && le.Country === el)
                        );
                    });
                });
            } else if (
                country.length === 0 &&
                currency.length > 0 &&
                exchange.length > 0
            ) {
                exchange.forEach((el: any) => {
                    currency.forEach((li: any) => {
                        result.push(
                            data.filter((le: any) => le.Currency === li && le.Exchange === el)
                        );
                    });
                });
            } else if (
                country.length === 0 &&
                currency.length > 0 &&
                exchange.length === 0
            ) {
                currency.forEach((li: any) => {
                    result.push(data.filter((le: any) => le.Currency === li));
                });
            } else if (
                country.length > 0 &&
                currency.length === 0 &&
                exchange.length === 0
            ) {
                country.forEach((el: any) => {
                    result.push(data.filter((le: any) => le.Country === el));
                });
            } else if (
                country.length === 0 &&
                currency.length === 0 &&
                exchange.length > 0
            ) {
                exchange.forEach((el: any) => {
                    if (el === 'Dollar') {

                        data.filter((asset: any) => {
                            if (asset.Code.split('')[0]+asset.Code.split('')[1]+asset.Code.split('')[2] === 'USD') {
                                result.push(asset);
                            }
                        })
                    } else if (el === 'Euro') {
                        data.filter((asset: any) => {
                            if (asset.Code.split('')[0]+asset.Code.split('')[1]+asset.Code.split('')[2] === 'EUR') {
                                result.push(asset);
                            }
                        })
                    } else if (el === 'Other') {
                        result = [];
                        data.filter((asset: any) => {
                            result.push(asset);
                        }) 
                    }
                });
            }

            result = Array.prototype.concat.apply([], result);
            result = result.filter(onlyUnique);
        } else {
            result = JSON.parse(JSON.stringify(data));
        }

        if (search !== "") {
            result = result.filter((el: any) =>
                el.Code.toLowerCase().includes(search.toLowerCase())
            );
        }

        result = result.sort((a, b) => {
            (a = a.Name.toLowerCase()), (b = b.Name.toLowerCase());

            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });

        return result;

    }

    static exchangeMarketSearch(search: any, searchFilter: any, data: any) {
        let result: any[] = [];

        result = JSON.parse(JSON.stringify(data));

        if (search !== "") {
            if (searchFilter === "ISIN") {
                result = result.filter((el: any) => el.Isin);
                result = result.filter((el: any) =>
                    el.Isin.toLowerCase().includes(search.toLowerCase())
                );
            } else if (searchFilter === "Name") {
                result = result.filter((el: any) =>
                    el.Name.toLowerCase().includes(search.toLowerCase())
                );
            } else if (searchFilter === "Code") {
                result = result.filter((el: any) =>
                    el.Code.toLowerCase().includes(search.toLowerCase())
                );
            }
        }

        result = result.sort((a, b) => {
            (a = a.Name.toLowerCase()), (b = b.Name.toLowerCase());

            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });
        return result;
    }

    static chartFilter() { }
}

export default filterAPI;
