import { DatePicker } from "chakra-ui-date-input";
import {
    Box,
    Stack,
    Flex,
} from "@chakra-ui/react";

import FundLogTable from "../../components/Tables/FundLogTable";

function CustomerFundLog({
    transactions,
    toDate,
    fromDate,
    setToDate,
    setFromDate,
}: any) {
    return (
        <>
            <Box w={{ base: "full" }}>
                <Flex direction="row" py="20px">
                    <Stack
                        fontSize="14px"
                        fontFamily="Inter"
                        h="32px"
                        className="datepicker-wrapper"
                        spacing={3}
                        mr="12px"
                        direction="row"
                    >
                        <DatePicker
                            defaultValue={fromDate.split("-").reverse().join("/")}
                            placeholder="From"
                            name="fromDate"
                            onChange={(date: string) =>
                                setFromDate(date.split("/").reverse().join("-"))
                            }
                        />

                        <DatePicker
                            defaultValue={toDate.split("-").reverse().join("/")}
                            placeholder="To"
                            name="toDate"
                            onChange={(date: string) =>
                                setToDate(date.split("/").reverse().join("-"))
                            }
                        />
                    </Stack>
                </Flex>
            </Box>

            <FundLogTable transactions={transactions} />
        </>
    );
}

export default CustomerFundLog;
