import { useState } from 'react';
import {
    Table, Thead, Tr, Tbody, Td, Modal,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    useDisclosure,
    Icon,
    Flex
} from "@chakra-ui/react";
import CustomerNotesTableHead from "./TableHead/CustomerNotesTableHead";
import Helpers from "../../hooks/helpers";
import NotesAction from './ActionColumn/NotesAction';
import qoreContext from "../../common/qoreContext";
import ConfirmationRemoveModal from '../Modal/ConfirmationRemoveModal';
import { BiTrash } from "react-icons/bi";

const CustomerNotesTable = ({ notes, handleUpdateNotes, afterRemoveNotes, getNotes }: any) => {
    const { deleteRow } = qoreContext.table('notes').useDeleteRow();
    const [confirmation, setConfirmation] = useState<string>('');
    const [el, setEl] = useState<any>({});
    const [isError, setIsError] = useState<boolean>(false);

    const { isOpen, onOpen: modalOpen, onClose } = useDisclosure();

    const handleRemoveNotes = async (el: any) => {
        const response = await deleteRow(el.id);
        await getNotes()
    }

    // const handleConfirmation = (params: any) => {
    //     console.log(params, `params`)
    //     console.log(el, `el`)
    //     if (confirmation === params.name) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    // const handleAfterConfirmation = async (el: any) => {
    //     const response = await handleConfirmation(el);
    //     setIsError(false);
    //     if (response) {
    //         const response = await deleteRow(el.id);
    //         afterRemoveNotes();
    //     } else {
    //         setIsError(true)
    //     }
    // }

    const handleCloseModal = () => {
        // setConfirmation('');
        onClose();
    }

    return (
        <Table
            variant="simple"
            size="md"
            fontSize="12px"
            mt="0"
            mb="20px">
            <Modal isCentered isOpen={isOpen} onClose={handleCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader
                        px='24px'
                        py='16px'
                        fontSize='16px'
                        fontWeight='600'
                        borderBottom='1px'
                        borderColor='#E9E9E9' >
                        <Flex alignItems={'center'} gap={'1'}>
                            <Icon color={'rgba(224, 49, 56, 1)'} fontSize={'20px'} as={BiTrash}></Icon>
                        Are you sure want to delete this user ?
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton _focus={{ outline: 'none' }} fontSize='8px' maxW='10px' maxH='10px' mt='15px' mr='20px' />
                    {/* <ConfirmationRemoveModal
                        isError={isError}
                        el={el}
                        handleAfterConfirmation={handleAfterConfirmation}
                        confirmation={confirmation}
                        setConfirmation={setConfirmation}
                        onClose={onClose} /> */}
                </ModalContent>
            </Modal>
            <Thead bg="rgba(246, 246, 246, 1)">
                <CustomerNotesTableHead />
            </Thead>
            <Tbody>
                {notes && notes.length > 0 && notes.reverse().map((el: any, index: number) => {
                    let date = new Date(el.date_notes)
                    let formatDate = date.toUTCString()
                    let newDate = formatDate.split(' ')
                    let formatedDate = `${newDate[0]} ${newDate[1]} ${newDate[2]} ${newDate[3]}`
                    return (
                        <Tr key={index}>
                            <Td textTransform='capitalize' p='12px'>{formatedDate}</Td>
                            <Td textTransform='capitalize' textAlign='center' p='12px'>{el.extention}</Td>
                            <Td textTransform='capitalize' p='12px'>{el.bank_statement}</Td>
                            <Td textTransform='capitalize' p='12px'>
                                <NotesAction
                                    handleRemove={() => handleRemoveNotes(el)}
                                    handleUpdate={handleUpdateNotes}
                                    val={el}
                                />
                            </Td>
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
}

export default CustomerNotesTable;
