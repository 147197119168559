import { Box, Text, Image, Center, Button } from "@chakra-ui/react";
import bgEmpty from "../../../assets/empty-data.png";
import AddPlusButton from "../Button/AddPlusButton";

const NoDataNotesFound = ({ instrument, onOpen }: any) => {
    const handleOpenModal = () => {
        onOpen()
    };

    return (
        <Center>
            <Box textAlign="center" py={10} px={6}>
                <Center>
                    <Image src={bgEmpty} />
                </Center>
                <Center>
                    <Box maxW="250px">
                        <Text fontSize="16px" fontWeight="600" mt={3} mb={2}>
                            Your {instrument} is empty
                        </Text>
                        <Text fontSize="14px" color={"gray.500"} mb={6}>
                            You haven't added and configured any sources yet.
                        <a href="">Learn more</a>
                        </Text>
                        <AddPlusButton
                            handleOpenModal={handleOpenModal}
                            buttonText={`Add New ${instrument}`}
                        />
                    </Box>
                </Center>
            </Box>
        </Center>
    );
};

export default NoDataNotesFound;
