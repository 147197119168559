import { useState, useEffect } from 'react'
import {
    Flex,
    Button,
} from "@chakra-ui/react";

const ChartButtonFISingle = (
    {
        handleChartFilter, 
        chartFilter,
        customDate,
        setCustomDate
    }: any) => {
    const [filterType, setFilterType] = useState<string>('MAX');

    const handleActiveBUtton = (params: any) => {
        if (setCustomDate) {
            setCustomDate(false);
        }
        setFilterType(params);
    }

    useEffect(() => {
        handleCustomDate();
    }, [customDate]);

    const handleCustomDate = () => {
        if (customDate) {
            setFilterType('')
        }
    }

    return (
        <Flex ml="auto" mr="0">
            {
                chartFilter.map((el: any, index: number) => {
                    return (
                        <Button
                            key={index}
                            backgroundColor={filterType === el ? 'rgba(233, 233, 233, 1)' : 'transparent'}
                            _focus={{outline: 'none'}}
                            _hover={{backgroundColor: 'rgba(233, 233, 233, 1)', transform: 'scale(1.14)'}}
                            fontSize={'12px'}
                            h={'32px'}
                            w={'32px'}
                            mr="5px" onClick={(e) => {
                                handleChartFilter(el);
                                handleActiveBUtton(el);
                            }}>
                            {el}
                        </Button>
                    )
                })
            }
        </Flex>
    );
};

export default ChartButtonFISingle;