import { Box } from "@chakra-ui/react";
import zoom from "chartjs-plugin-zoom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Helpers from "../../hooks/helpers";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoom,
    Filler,
);

const LinePDFChart = ({ dataChart, code, filterString }: any) => {
    const chartLabels: any[] = [];
    let tempMonths: any[] = [];

    const monthFormat = (val: any) => {
        if (val == "01") return "Jan";
        if (val == "02") return "Feb";
        if (val == "03") return "Mar";
        if (val == "04") return "Apr";
        if (val == "05") return "May";
        if (val == "06") return "Jun";
        if (val == "07") return "Jul";
        if (val == "08") return "Aug";
        if (val == "09") return "Sep";
        if (val == "10") return "Oct";
        if (val == "11") return "Nov";
        if (val == "12") return "Dec";
    };

    for (let i = 0; i < dataChart.length; i++) {
        const date = `${dataChart[i].date.split("-")[0]} ${monthFormat(
            dataChart[i].date.split("-")[1]
        )} ${dataChart[i].date.split("-")[2]}`;
        chartLabels.push(date);
    }

    const chartDataClose = [];

    for (let i = 0; i < dataChart.length; i++) {
        if (!dataChart[i].close) {
            chartDataClose.push(dataChart[i].price);
        } else {
            chartDataClose.push(dataChart[i].close);
        }
    }
    function ascent(a: any, b: any) {
        return a - b;
    }
    let value = JSON.parse(JSON.stringify(chartDataClose));

    const data: any = {
        labels: chartLabels.reverse(),
        datasets: [
            {
                label: ` ${code} `,
                fill: false,
                lineTension: 0.1,
                backgroundColor: "rgba(0, 101, 255, 1)",
                borderColor: "rgba(0, 101, 255, 1)",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "rgba(0, 101, 255, 1)",
                pointBackgroundColor: "rgba(0, 101, 255, 1)",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "rgba(0, 101, 255, 1)",
                pointHoverBorderColor: "rgba(0, 101, 255, 1)",
                pointHoverBorderWidth: 4,
                pointRadius: 1,
                pointHitRadius: 10,
                data: chartDataClose.reverse(),
            },
        ],
    };

    const options: any = {
        layout: {
            padding: {
                right: 50
            },
            autoPadding: false
        },
        spanGaps: true,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    padding: 20
                },
                align: 'center',
            },
        },
        scales: {
            x: {
                offset: false,
                gridLines: {
                    offsetGridLines: false,
                },
                title: {
                    display: true,
                },
                grid: {
                    display: false,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: false,
                    getLabelForValue: function(value: any) {
                        return value
                    },
                    callback: function (val: any, index: number, tick: any) {
                        const newLabel = Helpers.labelData(tick.length, index, this.getLabelForValue(val), filterString, tempMonths);
                        return newLabel
                    },
                },
            },
        },
    };


    return (
        <Box>
            <Box
                color={"rgba(116, 116, 116, 1)"} 
                fontSize={'12px'}>
                {
                    data?.labels.length > 0 && (
                        <Box>as of {data.labels[0]} - {data.labels[data.labels.length - 1]}</Box>
                    )
                }
            </Box>
            <Box h={300} py="10px">
                {
                    data?.labels.length > 0 && (
                        <Line options={options} data={data} />
                    )
                }
            </Box>
        </Box>
    );
};

export default LinePDFChart;
