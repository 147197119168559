import { ReactNode } from 'react'
import {
    Flex,
    Box,
    useColorModeValue,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Divider
} from "@chakra-ui/react";
import TitleBoxDetail from '../../components/DetailBox/TitleBoxDetail';

const AccordionBox = (
    { boxTitle, iconTitle, children }: 
    { boxTitle: any, iconTitle: any, children: ReactNode}) => {
    return (
        <AccordionItem
            w={"full"}
            bg={useColorModeValue("white", "gray.800")}
            boxShadow={"lg"}
            rounded={"md"}
            px="0"
            mt={'24px'}>
            <AccordionButton
                h={'60px'}
                _focus={{ outline: 'none' }}>
                <TitleBoxDetail
                    fsIcon={"20px"}
                    iconTitle={iconTitle}
                    boxTitle={boxTitle}
                />
                <AccordionIcon mr={0} ml={'auto'} />
            </AccordionButton>
            {children}
        </AccordionItem>

    );
};

export default AccordionBox;