import ExchangeList from "../../components/ExchangeList";

function Bond() {
  return (
    <div>
      <ExchangeList instrument="bonds"></ExchangeList>
    </div>
  );
}

export default Bond;
