import React, { useEffect, useState, createRef } from "react";
import { Box } from "@chakra-ui/react";
import zoom from "chartjs-plugin-zoom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Helpers from "../../hooks/helpers";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoom,
    Filler,
);

const BarChart = ({ dataChart, code, filterString }: any) => {
    const chartLabels: any[] = [];

    const [months, setMonths] = useState<any[]>([]);
    let tempMonths: any[] = [];
    let maxMonths: any[] = [];
    let maxYear: any[] = [];
    let tempUnusedMonths: any[] = [];

    const monthFormat = (val: any) => {
        if (val == "01") return "Jan";
        if (val == "02") return "Feb";
        if (val == "03") return "Mar";
        if (val == "04") return "Apr";
        if (val == "05") return "May";
        if (val == "06") return "Jun";
        if (val == "07") return "Jul";
        if (val == "08") return "Aug";
        if (val == "09") return "Sep";
        if (val == "10") return "Oct";
        if (val == "11") return "Nov";
        if (val == "12") return "Dec";
    };

    for (let i = 0; i < dataChart.length; i++) {
        const date = `${dataChart[i].date.split("-")[0]} ${monthFormat(
            dataChart[i].date.split("-")[1]
        )} ${dataChart[i].date.split("-")[2]}`;
        chartLabels.push(date);
    }

    const chartDataClose = [];

    for (let i = 0; i < dataChart.length; i++) {
        if (!dataChart[i].close) {
            chartDataClose.push(dataChart[i].price);
        } else {
            chartDataClose.push(dataChart[i].close);
        }
    }
    function ascent(a: any, b: any) {
        return a - b;
    }
    let value = JSON.parse(JSON.stringify(chartDataClose));
    let lowestValue = value.sort(ascent)[0];
    let highestValue = value.sort(ascent)[value.length - 1];

    const data: any = {
        labels: chartLabels.reverse(),
        datasets: [
            {
                label: ` ${code} `,
                fill: false,
                lineTension: 0.1,
                backgroundColor: "rgba(0, 101, 255, 1)",
                borderColor: "rgba(0, 101, 255, 1)",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "rgba(0, 101, 255, 1)",
                pointBackgroundColor: "rgba(0, 101, 255, 1)",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "rgba(0, 101, 255, 1)",
                pointHoverBorderColor: "rgba(0, 101, 255, 1)",
                pointHoverBorderWidth: 4,
                pointRadius: 1,
                pointHitRadius: 10,
                data: chartDataClose.reverse(),
            },
        ],
    };

    const options: any = (setMinX: any, setMaxX: any) => {

        // let minY = Math.floor(lowestValue);
        let minY = (highestValue - lowestValue) > 10 ? Math.floor(lowestValue) :  lowestValue
        // let maxY = Math.ceil(highestValue);
        let maxY = (highestValue - lowestValue) > 10 ? Math.ceil(highestValue) :  highestValue;
        let minRange;
        let maxYRange;

        if ((highestValue - lowestValue) < 1) {
            maxYRange = (maxY / 100)
        } else {
            maxYRange = maxY / 10
        }

        if ((highestValue - lowestValue) < 1) {
            minRange = 0
        } else {
            minRange = Math.ceil(highestValue) - Math.floor(lowestValue);
        }

        return {
            layout: {
                padding: {
                    right: 50
                },
                autoPadding: false
            },
            spanGaps: true,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                    position: "bottom",
                    labels: {
                      usePointStyle: true,
                      padding: 20
                    },
                    align: 'center',
                },
                // tooltip: {
                //     callbacks: {
                //         label: function (context: any) {

                            // console.log(context, `context`)
    
                            // const dataChart = getDataFromTop();
                            // const month = Helpers.getFormatDateYYMMDD(context.label.split(' ')[1]);
                            // const newFormat = `${context.label.split(' ')[0]}-${month}-${context.label.split(' ')[2]}`;
    
                            // let filtered = dataChart[context.datasetIndex].data.filter((el: any) => el.date === newFormat);
    
                            // if (filtered.length > 0) {
                            //     if (context.datasetIndex === 0) {
                            //         // if (context.datasetIndex === context.dataset.data.length - 1) {   
                            //         //     setPerformance(` ${Helpers.currencyFormaterUSD(filtered[0].close)} - (${context.raw} %)`);
                            //         // }
                            //         return ` ${Helpers.currencyFormaterUSD(filtered[0].close)} - (${context.raw} %)`;
                            //     } else {
                            //         const formatCurreny = Helpers.currencyFormater(filtered[0].close, dataChart[context.datasetIndex].currency)
                            //         return `  ${formatCurreny} - ( ${context.raw} %)`
                            //     }
                            // } 
    
                //         },
                //     },
                // },
                zoom: {
                    limits: {
                        x: { min: 0, max: dataChart.length },
                        y: { min: minY - maxYRange, max: maxY + maxYRange },
                    },
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true,
                        },
                        mode: "xy",
                        drag: {
                            enabled: false,
                            backgroundColor: "transparent",
                            mode: "xy",
                        },
                        onZoomComplete: function ({ chart }: any) {
                            // console.log(`I was zoomed!!!`);

                            // console.log("X MIN: ", chart.scales.x._userMin);
                            // console.log("X MAX: ", chart.scales.x._userMax + 1);
                            // setMinX(chart.scales.x._userMin);
                            // setMaxX(chart.scales.x._userMax);

                            // if (chart.scales.x._userMin > 999 && chart.scales.x._userMin < 2000) {
                            //     console.log(`1000-2000`)
                            // }
                        },
                        pan: {
                            enabled: true,
                        }
                    },
                    pan: {
                        enabled: true,
                        mode: "xy",
                    },
                },
            },
            scales: {
                x: {
                    offset: false,
                    gridLines: {
                        offsetGridLines: false,
                    },
                    grid: {
                        display: false,
                        drawBorder: true,
                        drawOnChartArea: true,
                        drawTicks: true,
                    },
                    ticks: {
                        maxRotation: 0,
                        minRotation: 0,
                        autoSkip: false,
                        getLabelForValue: function(value: any) {
                            return value
                        },
                        // callback: function (val: any, index: number): any {
                        //     if (filterString === "1M") {
                        //         return index % 4 === 0
                        //             ? `${this.getLabelForValue(val).split(" ")[1]} ${this.getLabelForValue(val).split(" ")[2]
                        //             }`
                        //             : "";
                        //     } else if (filterString === "3M") {
                        //         return index % 8 === 0
                        //             ? `${this.getLabelForValue(val).split(" ")[1]} ${this.getLabelForValue(val).split(" ")[2]
                        //             }`
                        //             : "";
                        //     } else if (filterString === "YTD") {
                        //         return index % 12 === 0
                        //             ? `${this.getLabelForValue(val).split(" ")[1]} ${this.getLabelForValue(val).split(" ")[2]
                        //             }`
                        //             : "";
                        //     } else if (filterString === "1Y") {
                        //         let flag = false;
                        //         tempMonths.forEach((el: any) => {
                        //             if (
                        //                 el ===
                        //                 `${this.getLabelForValue(val).split(" ")[1]}-${this.getLabelForValue(val).split(" ")[0]
                        //                 }`
                        //             ) {
                        //                 flag = true;
                        //             }
                        //         });

                        //         if (!flag) {
                        //             if (
                        //                 (this.getLabelForValue(val).split(" ")[2] === "01" ||
                        //                     this.getLabelForValue(val).split(" ")[2] === "02" ||
                        //                     this.getLabelForValue(val).split(" ")[2] === "03" ||
                        //                     this.getLabelForValue(val).split(" ")[2] === "04" ||
                        //                     this.getLabelForValue(val).split(" ")[2] === "05") &&
                        //                 this.getLabelForValue(val).split(" ")[1] !== "Jan"
                        //             ) {
                        //                 tempMonths.push(
                        //                     `${this.getLabelForValue(val).split(" ")[1]}-${this.getLabelForValue(val).split(" ")[0]
                        //                     }`
                        //                 );
                        //                 return this.getLabelForValue(val).split(" ")[1];
                        //             } else {
                        //                 tempMonths.push(
                        //                     `${this.getLabelForValue(val).split(" ")[1]}-${this.getLabelForValue(val).split(" ")[0]
                        //                     }`
                        //                 );
                        //                 return this.getLabelForValue(val).split(" ")[0];
                        //             }
                        //         }
                        //     } else if (filterString === "3Y") {
                        //         return index % 2 === 0 ? this.getLabelForValue(val) : "";
                        //     } else if (filterString === "5Y") {
                        //         return index % 2 === 0 ? this.getLabelForValue(val) : "";
                        //     } else if (filterString === "MAX") {
                        //         if (dataChart.length < 10000 && dataChart.length > 5000) {
                        //             return index % 1000 === 0 ? this.getLabelForValue(val) : "";
                        //         } else if (dataChart.length < 5000 && dataChart.length > 3000) {
                        //             return index % 500 === 0 ? this.getLabelForValue(val) : "";
                        //         } else if (dataChart.length < 3000 && dataChart.length > 1000) {
                        //             return index % 250 === 0 ? this.getLabelForValue(val).split(' ')[0] : "";
                        //         } else if (dataChart.length < 1000 && dataChart.length > 100) {
                        //             return index % 100 === 0 ? this.getLabelForValue(val) : "";
                        //         } else if (dataChart.length < 100 && dataChart.length > 10) {
                        //             return index % 10 === 0 ? this.getLabelForValue(val) : "";
                        //         }
                        //     } else {
                        //         return this.getLabelForValue(val);
                        //     }
                        // },
                        callback: function (val: any, index: number, tick: any) {
                            const newLabel = Helpers.labelData(tick.length, index, this.getLabelForValue(val), filterString, tempMonths);
                            return newLabel
                        },
                    },
                },
            },
        };
    };
    
    const myChartRef = createRef();

    const [minX, setMinX] = useState();
    const [maxX, setMaxX] = useState();

    return (
        <Box>
            <Box
                color={"rgba(116, 116, 116, 1)"} 
                fontSize={'12px'}>
                {
                    data?.labels.length > 0 && (
                        <Box>as of {data.labels[0]} - {data.labels[data.labels.length - 1]}</Box>
                    )
                }
            </Box>
            <Box h={300} py="10px">
                {
                    data?.labels.length > 0 && (
                        <Line options={ options(setMinX, setMaxX) } data={data} />
                    )
                }
            </Box>
        </Box>
    );
};

export default BarChart;
