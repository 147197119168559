import { useState, useEffect } from "react";
import logo from "../../assets/logo.png";
import NavItem from "../components/NavItem";
import { Link } from "react-router-dom";
import qoreContext from "../common/qoreContext";
import {
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  Image,
  Center,
  BoxProps,
  Icon,
  Text,
  Accordion,
} from "@chakra-ui/react";
import { FiHome, FiTrendingUp } from "react-icons/fi";
import { BiAnalyse, BiArea, BiDollarCircle } from "react-icons/bi";
import { BsClipboardData } from "react-icons/bs";
import { MdManageAccounts } from "react-icons/md";
import { RiFlowChart } from "react-icons/ri";
import { IconType } from "react-icons";

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({
  onClose,
  sidebarMode,
  setSidebarMode,
  ...rest
}: any) => {
  const [sidebarHover, setSidebarHover] = useState(false);
  const [sidebarShow, setSidebarShow] = useState("full");
  const [showButton, setShowButton] = useState("240px");
  const [marginTop, setMarginTop] = useState("10px");
  const [mode, setMode] = useState("show");
  const [role, setRole] = useState("Relationship Manager");
  const [permission, setPermission] = useState<any>([]);
  const [linkItems, setLinkItems] = useState<any>([])

  interface FILinkItemProps {
    name: string;
    path: string;
  }

  useEffect(() => {
    if (permission.length > 0 && linkItems.length === 0) {
      handleLinkItems();
    }
  }, [permission.length])

  const handleLinkItems = () => {
    let linkItem = [
      permission[0] === true ? { name: "Stocks", path: "/financial-instrument/stock" } : null,
      permission[1] === true ? { name: "ETF", path: "/financial-instrument/ETF" }  : null,
      permission[2] === true ? { name: "Bonds", path: "/financial-instrument/bonds" }  : null,
      permission[3] === true ? { name: "Mutual Funds", path: "/financial-instrument/mutual-funds" }  : null,
      permission[4] === true ? { name: "Forex", path: "/financial-instrument/forex" }  : null,
      permission[5] === true ? { name: "CryptoCurrency", path: "/financial-instrument/crypto" }  : null,
      permission[6] === true ? { name: "Index", path: "/financial-instrument/index" }  : null,
    ];
    setLinkItems(linkItem)

  }

  const FILinkItems: Array<FILinkItemProps> = linkItems

  const ALinkItems: Array<FILinkItemProps> = [
    { name: "Portfolio Summary", path: "/analytics/portofolio-summary" }, 
    // { name: "Fund Look Through", path: "/analytic/FundLookThrough" },
    // { name: "PE OverView", path: "/analytic/FundLookThrough" },
    // { name: "Performance Metrics", path: "/analytic/FundLookThrough" },
  ];

  const ILinkItems: Array<FILinkItemProps> = [
    // { name: "Cashflow", path: "/analytic/FundLookThrough" },
    // { name: "Explainer", path: "/analytic/FundLookThrough" },
    { name: "Comparison", path: "/insight/comparison" },
  ];

  const PLinkItems: Array<FILinkItemProps> = [
    { name: "Asset Allocation", path: "/portfolio/asset-allocation" },
    { name: "Holdings", path: "/portfolio/holdings" },
    { name: "Performance", path: "/portfolio/performance" },
    { name: "Transactions", path: "/portfolio/transactions" },
  ];

  const RLinkItems: Array<FILinkItemProps> = [
    { name: "Generate Reports", path: "/reports/generate-reports" },
    // { name: "Documents", path: "/analytic/FundLookThrough" },
    // { name: "Excel Add-in", path: "/analytic/FundLookThrough" },
  ];

  const AMLinkItems: Array<FILinkItemProps> = [
    { name: "User Management", path: "management/admin-management" },
  ];

  const RmMLinkItems: Array<FILinkItemProps> = [
    {
      name: "User Management",
      path: "/management/user-management",
    },
    { name: "Funds Management", path: "/management/fund-management" },
  ];

  interface MenuItemProps {
    name: string;
    icon: IconType;
    path: Array<FILinkItemProps>;
  }

  const RmMenuItems: Array<MenuItemProps> = [
    { name: "Financial Instrument", icon: BiDollarCircle, path: FILinkItems },
    { name: "Management", icon: MdManageAccounts, path: RmMLinkItems },
    { name: "Analytics", icon: BiAnalyse, path: ALinkItems },
    { name: "Portfolio", icon: FiTrendingUp, path: PLinkItems },
    { name: "Insight", icon: RiFlowChart, path: ILinkItems },
    { name: "Report", icon: BsClipboardData, path: RLinkItems },
  ];

  const AMenuItems: Array<MenuItemProps> = [
    { name: "Management", icon: MdManageAccounts, path: AMLinkItems },
  ];

  const handleSidebar = () => {
    let sideBarMode = mode === "show" ? "hide" : "show";
    setMode(sideBarMode);
    let newMode = sidebarHover === false ? true : false;
    let buttonPosition = showButton === "240px" ? "58px" : "240px";
    let sidebarContentPosition = marginTop === "10px" ? "100px" : "10px";
    setSidebarHover(newMode);
    setShowButton(buttonPosition);
    setMarginTop(sidebarContentPosition);
    handelModeSidebar(sidebarMode);
  };

  const handelModeSidebar = (sidebarMode: any) => {
    console.log(sidebarMode, `ini apaan ?`);
    const newMode = sidebarMode ? false : true;
    setSidebarMode(newMode);
  };

  const user = qoreContext.useCurrentUser();
  useEffect(() => {
    if (sidebarHover) {
      let newWidth = sidebarShow === "full" ? "50px!important" : "full";
      let sidebarContentPosition = marginTop === "10px" ? "100px" : "10px";
      setMarginTop(sidebarContentPosition);
      setSidebarShow(newWidth);
    }
  }, [sidebarHover]);

  useEffect(() => {
    setRole(user?.user?.role?.name);
    if (user?.user?.permission && permission.length === 0) {
      let userPermision: any = user?.user?.permission.split(',')
      let temp: any = []
        if (userPermision.length > 0) {
          userPermision.forEach((el: any) => {
                if (el === 'true') {
                    el = true
                } else {
                    el = false
                }
                temp.push(el)
            });
        }
      setPermission(temp)
    }
  }, [user]);

  return (
    <Box
      className={`navbar-wrapper-${mode}`}
      transition=".3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      pos="fixed"
      h="full"
      overflowY="scroll"
      {...rest}
    >
      <Center my={'20px'}>
        <Image maxW={'150px'} borderRadius="md" src={logo} />
      </Center>
      <Flex
        h="20"
        display={{ base: "flex", md: "none" }}
        alignItems="center"
        mx="8"
        justifyContent="space-between"
      >
        <CloseButton onClick={onClose} />
      </Flex>
      <Box className="navbar-item">
        <Link to="/" style={{ textDecoration: "none", fontSize: "12px" }}>
          <Flex
            align="center"
            d="flex!important"
            pt="3"
            pb="2"
            mx="4"
            borderRadius="lg"
            role="group"
            {...rest}
          >
            {FiHome && <Icon mr="4" fontSize="16" as={FiHome} />}
            <Text>Dashboard</Text>
          </Flex>
        </Link>
        {role === "admin" ? (
          <Accordion allowToggle mx="2">
            {AMenuItems.map((link) => (
              <NavItem key={link.name} icon={link.icon} path={link.path}>
                {link.name}
              </NavItem>
            ))}
          </Accordion>
        ) : (
          <Accordion allowToggle mx="2">
            {RmMenuItems.map((link) => (
              <NavItem key={link.name} icon={link.icon} path={link?.path}>
                {link?.name}
              </NavItem>
            ))}
          </Accordion>
        )}
      </Box>
      <Box
        onClick={handleSidebar}
        boxShadow="md"
        className="show-sidebar-wrapper"
      >
        <Box position="relative">
          <Box className="show-sidebar">
            <Icon as={BiArea} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarContent;
