export const alertHandling = (toast: any, title : any, status : any, position : any) => {
    return toast({
        title,
        status,
        position,
        isClosable: true,
        containerStyle: {
          fontSize: '12px',
        },
      })
};
