import {
    Center, Box,
    Input, FormControl, FormLabel, Textarea,
    ModalFooter, ModalBody,
    Avatar,
    Text,
    Button,
} from "@chakra-ui/react";
import { BiEditAlt } from "react-icons/bi";

const UserRmModal = (
    {   form, 
        modalMode, 
        handleAddCustomer, 
        handleUpdateCustomer, 
        encodeImageFileAsURL, 
        onClose,
        image,
        idRows }: any) => {

    return (
        <form
            onSubmit={form.handleSubmit((values: any) =>
            modalMode
                ? handleAddCustomer(values)
                : handleUpdateCustomer(values, idRows)
            )}>

            <ModalBody>
                <FormControl pt="16px">
                    <FormLabel pb="10px" fontSize="14px" mb="8px" htmlFor="avatar">
                    Avatar
                    </FormLabel>
                    <Center>
                        {
                            image ?
                            <Avatar 
                                className='custom-chakra-avatar' 
                                w='80px' h='80px' 
                                src={image}>
                                <label className="custom-file-upload">
                                    <Box >
                                        <BiEditAlt />
                                    </Box>
                                    
                                    <Input
                                        className="custom-file-input"
                                        maxW="150px"
                                        fontSize="14px"
                                        px="12px"
                                        id="profile_pic"
                                        type="file"
                                        placeholder="Input Customer Avatar"
                                        onChange={(e) => encodeImageFileAsURL(e.target)}
                                    />
                                </label>
                            </Avatar>
                            :
                            <Avatar 
                                className='default-profile' 
                                w='80px' h='80px' 
                                src={image}>
                                <label className="custom-file-upload">
                                    <Box >
                                        <BiEditAlt />
                                    </Box>
                                    
                                    <Input
                                        className="custom-file-input"
                                        maxW="150px"
                                        fontSize="14px"
                                        px="12px"
                                        id="profile_pic"
                                        type="file"
                                        placeholder="Input Customer Avatar"
                                        onChange={(e) => encodeImageFileAsURL(e.target)}
                                    />
                                </label>
                            </Avatar>
                        }
                    </Center>
                    <Box pt={'10px'}>
                        <Text
                            fontSize='12px'
                            fontWeight='400'
                            lineHeight='16px'
                            color='rgba(162, 162, 162, 1)'>
                                The recommended image size for avatar is 100 
                                by 100 pixels with a maximum file size of 1 MB (megabyte) 
                                or 1024 KB (kilobytes)
                        </Text>
                    </Box>
                </FormControl>

                <FormControl pt="16px">
                    <FormLabel fontSize="14px" mb="8px" htmlFor="name">
                    Name
                    </FormLabel>
                    <Input
                    fontSize="14px"
                    px="12px"
                    id="name"
                    type="text"
                    placeholder="Fill Customer Name"
                    {...form.register("name", { required: true })}
                    />
                </FormControl>
            </ModalBody>

            <ModalFooter px="24px" py="16px">
                <Button
                    backgroundColor="#fff"
                    color="rgba(23, 23, 23, 1)"
                    fontSize="12px"
                    fontWeight="600"
                    border="1px"
                    borderColor="#E9E9E9"
                    h="32px"
                    mr={3}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    backgroundColor="rgba(198, 179, 120, 1)"
                    color="#fff"
                    border="1px"
                    fontSize="12px"
                    fontWeight="600"
                    h="32px"
                    _hover={{
                    color: "rgba(198, 179, 120, 1)",
                    backgroundColor: "#fff",
                    border: "1px",
                    }}
                    type="submit"
                >
                    Submit
                </Button>
            </ModalFooter>
        </form>
    );
};

export default UserRmModal;