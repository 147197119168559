import {
    Box,
    Flex,
    Circle,
    Center,
} from "@chakra-ui/react";


const SingleAssetChartDetail = ({
    backgroundColor,
    el,
    value,
    percentage,
    chartTitle,
}: any) => {
    return (
        <Flex direction={"column"} >
            <Center w={"full"} textAlign={"left"} justifyContent={'start'}>
                <Circle size="10px" bg={backgroundColor} />
                <Center>
                    <Box textTransform={'capitalize'} fontWeight={600} fontSize={"12px"} pl="10px">
                        {el}
                    </Box>
                    <Box px={"5px"}>-</Box>
                    <Box fontSize={"12px"} color={"rgba(116, 116, 116, 1)"}>
                        {percentage}
                    </Box>
                </Center>
            </Center>
            <Box fontSize={"12px"} pl="20px" color={"rgba(116, 116, 116, 1)"}>
                {value}
            </Box>
        </Flex>
    );
};

export default SingleAssetChartDetail;
