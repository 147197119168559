import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { 
    fetchNetWorthLabel, 
    fetchNetWorthValue,
    fetchTotalNetworth
} from '../store/actionCreator/rm';

import { 
    Center, 
    Flex, 
    Box,
} from "@chakra-ui/react";
import zoom from "chartjs-plugin-zoom";
import qoreContext from '../common/qoreContext';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

import NetWorthBox from '../components/DetailBox/NetWorthBox';
import LeftSideBox from '../home/LeftSideBox';
import RightSideBox from './RightSideBox';
import Helpers from "../hooks/helpers";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoom,
    Filler
);

const Dashboard = ({ customers, customerData }: any) => {
    const [currentDate, setCurrentDate] = useState<string>("");
    const [assetSummary, setAssetSummary] = useState<any[]>([]);
    const [filter, setFilter] = useState<string>('MAX');
    // const [fromDate, setFromDate] = useState<string>("");
    // const [toDate, setToDate] = useState<string>("");
    const [filterDataCustomer, setFilterDataCustomer] = useState<any[]>([])
    const user = qoreContext.useCurrentUser();

    const dispatch: any = useDispatch();
    const { netWorthLabel, netWorthValue, dataAssetSummary, totalNetworth, dataFilterCustomer } = useSelector((state: any) => state.rm);

    // const getDaysArray = (start: any, end: any) => {
    //     for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    //         arr.push(new Date(dt));
    //     }
    //     return arr;
    // };

    // const getListDate = (fromDate: any, toDate: any) => {
    //     let daylist = getDaysArray(new Date(fromDate.split("/").reverse().join("-")), new Date(toDate.split("/").reverse().join("-")));
    //     let dateToDate = daylist.map((v) => v.toISOString().slice(0, 10));
    //     return dateToDate
    // }

    // const getDataBeetweenDate = (dataList: any, dateList: any) => {
    //     const intersection = dataList[0].filter((element: any) => dateList.includes(element));
    //     return intersection
    // }

    // const getCurrentDate = () => {
    //     const year = new Date().getUTCFullYear();
    //     const month = new Date().getUTCMonth();
    //     const date = new Date().getUTCDate();
    //     if (month + 1 > 10) {
    //         const getDate = `${year}-${month + 1}-${date > 9 ? date : `0${date}`}`;
    //         setCurrentDate(getDate);
    //     } else {
    //         const getDate = `${year}-0${month + 1}-${date > 9 ? date : `0${date}`}`;
    //         setCurrentDate(getDate);
    //     }
    // };

    const setSingleProfit = async () => {
        let tempData: any = {};
        let tempLabel: any = [];
        let tempValue: any = [];
        let initialValue: any = 0;
        let tempSummary: any = []
        let existingDay: any[] = [];
        // let today: any = new Date(currentDate).getTime();
        let totalSummary = 0

        const filteredCustomer = await customerData.filter((obj: any) => customers.includes(obj.name));
        await setFilterDataCustomer(filteredCustomer)

        if (customerData.length > 0) {
            await filteredCustomer.forEach((customer: any) => {
                assetSummary.forEach((summary: any) => {
                    if (summary.customer_id === customer.id) {
                        tempSummary.push(summary);
                    }
                })
            })
        }

        const sortedSummary = await tempSummary.sort(function (a: any, b: any) {
            return new Date(a.date_summary).getTime() - new Date(b.date_summary).getTime()
        })

        // setFromDate(sortedSummary[0].date_summary.split("-").reverse().join("/"));
        // setToDate(sortedSummary[sortedSummary.length - 1].date_summary.split("-").reverse().join("/"));
        tempSummary = await getFilterData(sortedSummary);

        tempSummary.forEach((el: any, index: number) => {
            if (el.date_summary) {
                let flag = false;
                existingDay.forEach((le: any) => {
                    if (`${le}` === `${el.date_summary}`) {
                        flag = true;
                    }
                });

                if (!flag) {
                    tempLabel.push(`${el.date_summary}`);
                    existingDay.push(`${el.date_summary}`);

                }

                if (tempData[`${el.date_summary}`]) {
                    tempData[`${el.date_summary}`].push(el)
                } else {
                    tempData[`${el.date_summary}`] = []
                    tempData[`${el.date_summary}`].push(el)
                }
            }
        });

        setCurrentDate(tempSummary[tempSummary.length - 1].date_summary);

        tempData = Object.entries(tempData);
        const sortedDate = tempData.sort(function (a: any, b: any) {
            return new Date(a[0]).getTime() - new Date(b[0]).getTime()
        })

        tempLabel = tempLabel.map((el: any, index: number) => {
            if (filter === 'YTD' && index === 0) {
                return `${el.split("-")[0]}`
            } else {
                if (el.split("-")[0] === currentDate.split('-')[0]) {
                    return `${monthFormat(el.split("-")[1])} ${el.split("-")[2]}`
                } else {
                    return `${el.split("-")[0]} ${monthFormat(el.split("-")[1])} ${el.split("-")[2]}`
                }
            }
        })

        dispatch(fetchNetWorthLabel(tempLabel));

        if (sortedDate.length > 0) {
            sortedDate.forEach((el: any, index: number) => {
                let totalDailyAsset = 0
                el[1].forEach((le: any, indexLe: number) => {
                    if (index === 0) {
                        initialValue += Number(le.amount * le.eod_usd_price);
                        if (indexLe === (el[1].length - 1)) {
                            tempValue.push(initialValue)
                        }
                    } else {
                        totalDailyAsset += Number(le.amount * le.eod_usd_price);
                        if (indexLe === (el[1].length - 1)) {
                            tempValue.push(totalDailyAsset)
                        }
                    }
                });

                if (index === sortedDate.length - 1) {
                    el[1].forEach((le: any) => {
                        totalSummary += Number(le.amount * le.eod_usd_price);
                    })
                }
            })
        }

        setCurrentDate(tempSummary[tempSummary.length - 1].date_summary)
        dispatch(fetchTotalNetworth(totalSummary));
        dispatch(fetchNetWorthValue(tempValue));
    }

    const getFilterData = (tempSummary: any) => {
        let newArr: any = []
        let dateRange: any;
        if (filter === "1M") {
            dateRange = Helpers.dateFormat(Helpers.getLast30Days());
            tempSummary = tempSummary.map((el: any) => {
                if (new Date(el.date_summary).getTime() >= new Date(dateRange).getTime()) {
                    newArr.push(el);
                }
            })
            tempSummary = newArr
        } else if (filter === '3M'){
            dateRange = Helpers.getLast3Month(currentDate)
            tempSummary = tempSummary.map((el: any) => {
                if (new Date(el.date_summary).getTime() >= new Date(dateRange).getTime()) {
                    newArr.push(el);
                }
            })
            tempSummary = newArr
        }  else if (filter === '6M'){
            dateRange = Helpers.getLast3Month(currentDate)
            tempSummary = tempSummary.map((el: any) => {
                if (new Date(el.date_summary).getTime() >= new Date(dateRange).getTime()) {
                    newArr.push(el);
                }
            })
            tempSummary = newArr
        } else if (filter === 'YTD'){
            dateRange = Helpers.getYearToDate(currentDate.split('-')[0]);
            tempSummary = tempSummary.map((el: any) => {
                if (new Date(el.date_summary).getTime() >= new Date(dateRange).getTime()) {
                    newArr.push(el);
                }
            })
            tempSummary = newArr
        } else if (filter === '1Y'){
            dateRange = Helpers.getLastYearDate(currentDate)
            tempSummary = tempSummary.map((el: any) => {
                if (new Date(el.date_summary).getTime() >= new Date(dateRange).getTime()) {
                    newArr.push(el);
                }
            })
            tempSummary = newArr
        } else if (filter === '5Y'){
            dateRange = Helpers.getLast5YearDate(currentDate)
            tempSummary = tempSummary.map((el: any) => {
                if (new Date(el.date_summary).getTime() >= new Date(dateRange).getTime()) {
                    newArr.push(el);
                }
            })
            tempSummary = newArr
        } else if (filter === 'MAX') {
            newArr = tempSummary
        }

        return newArr
    }

    const setMultipleProfit = async () => {
        let tempData: any = {};
        let tempLabel: any = [];
        let tempValue: any = [];
        let initialValue: any = 0;
        let tempSummary: any = []
        let existingDay: any[] = [];
        // let today: any = new Date(currentDate).getTime();
        let totalSummary = 0

        const filteredCustomer = await customerData.filter((obj: any) => customers.includes(obj.name));

        await setFilterDataCustomer(filteredCustomer)

        if (customerData.length > 0) {
            await filteredCustomer.forEach((customer: any) => {
                assetSummary.forEach((summary: any) => {
                    if (summary.customer_id === customer.id) {
                        tempSummary.push(summary)
                    }
                })
            })
        }

        const sortedSummary = await tempSummary.sort(function (a: any, b: any) {
            return new Date(a.date_summary).getTime() - new Date(b.date_summary).getTime()
        })
        
        tempSummary = await getFilterData(sortedSummary);

        await tempSummary.forEach((el: any, index: number) => {
            if (el.date_summary) {
                let flag = false;
                existingDay.forEach((le: any) => {
                    if (`${le}` === `${el.date_summary}`) {
                        flag = true;
                    }
                });

                if (!flag) {
                    tempLabel.push(`${el.date_summary}`);
                    existingDay.push(`${el.date_summary}`);

                }

                if (tempData[`${el.date_summary}`]) {
                    tempData[`${el.date_summary}`].push(el)
                } else {
                    tempData[`${el.date_summary}`] = []
                    tempData[`${el.date_summary}`].push(el)
                }
            }
        });

        tempData = Object.entries(tempData);

        const sortedDate = await tempData.sort(function (a: any, b: any) {
            return new Date(a[0]).getTime() - new Date(b[0]).getTime()
        })

        tempLabel = await tempLabel.map((el: any, index: number) => {
            if (filter === 'YTD' && index === 0) {
                return `${el.split("-")[0]}`
            } else {
                return `${el.split("-")[0]} ${monthFormat(el.split("-")[1])} ${el.split("-")[2]}`
            }
        })

        await dispatch(fetchNetWorthLabel(tempLabel));

        if (sortedDate.length > 0) {
            await sortedDate.forEach((el: any, index: number) => {
                let totalDailyAsset = 0
                el[1].forEach((le: any, indexLe: number) => {
                    if (index === 0) {
                        initialValue += Number(le.amount * le.eod_usd_price);
                        if (indexLe === (el[1].length - 1)) {
                            tempValue.push(initialValue)
                        }
                    } else {
                        totalDailyAsset += Number(le.amount * le.eod_usd_price);
                        if (indexLe === (el[1].length - 1)) {
                            tempValue.push(totalDailyAsset)
                        }
                    }
                });
                if (index === sortedDate.length - 1) {
                    el[1].forEach((le: any) => {
                        totalSummary += Number(le.amount * le.eod_usd_price);
                    })
                }
            })


        }
        if (tempSummary.length > 0) {
            await setCurrentDate(tempSummary[tempSummary.length - 1].date_summary);
        }
        await dispatch(fetchTotalNetworth(totalSummary));
        await dispatch(fetchNetWorthValue(tempValue));
    }

    const getAssetSummary = async () => {
        try {
            if (user?.user?.id) {
                const response = await qoreContext.client
                    .table("asset_summary")
                    .readRows({ condition: { rm_id_asset_summary: user?.user?.id } })
                    .toPromise();
                if (response?.data?.nodes) {
                    setAssetSummary(response?.data?.nodes)
                }
            }
        } catch (error) { }
    };

    useEffect(() => {
        if (customers.length > 0) {
            getAssetSummary();
            if (customers.length === 1) {
                setSingleProfit();
            } else {
                setMultipleProfit();
            }
        }
    }, [customers.length, customerData.length, assetSummary.length, filter, dataAssetSummary.length]);

    const monthFormat = (val: any) => {
        if (val == "01") return "Jan";
        if (val == "02") return "Feb";
        if (val == "03") return "Mar";
        if (val == "04") return "Apr";
        if (val == "05") return "May";
        if (val == "06") return "Jun";
        if (val == "07") return "Jul";
        if (val == "08") return "Aug";
        if (val == "09") return "Sep";
        if (val == "10") return "Oct";
        if (val == "11") return "Nov";
        if (val == "12") return "Dec";
    };
    
    const data: any = {
        labels: netWorthLabel,
        datasets: [
            {
                // label: `USD ${totalSummary}`,
                fill: false,
                lineTension: 0.1,
                backgroundColor: `rgba(0, 101, 255, 1)`,
                borderColor: `rgba(0, 101, 255, 1)`,
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: `rgba(0, 101, 255, 1)`,
                pointBackgroundColor: `rgba(0, 101, 255, 1)`,
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: `rgba(0, 101, 255, 1)`,
                pointHoverBorderColor: `rgba(0, 101, 255, 1)`,
                pointHoverBorderWidth: 3,
                pointRadius: 3,
                pointHitRadius: 10,
                data: netWorthValue,
                cubicInterpolationMode: 'monotone',
            },
        ],
    };

    const option: any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: "bottom",
                labels: {
                    usePointStyle: true,
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const currencyFormat = Helpers.currencyFormaterUSD(context.raw);
                        return ` ${currencyFormat}`;
                    },
                },
            },
        },
        scales: {
            y: {
                // offset: false,
                // gridLines: {
                //     offsetGridLines: false,
                // },
                // title: {
                //     display: true,
                //     text: `${params === 'percentage' ? 'Percentage' : 'Profit'}`
                // },
                // grid: {
                //     display: false,
                //     drawBorder: true,
                //     drawOnChartArea: true,
                //     drawTicks: true,
                // },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: false,
                    getLabelForValue: function(value: any) {
                        return value
                    },
                    callback: function (val: any, index: number, tick: any, label: any): any {
                        // console.log(this.getLabelForValue(val), label) 
                        return this.getLabelForValue(val)
                    },
                },
            },
            x: {
                offset: false,
                gridLines: {
                    offsetGridLines: false,
                },
                title: {
                    display: true,
                },
                grid: {
                    display: false,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: false,
                    getLabelForValue: function(value: any) {
                        return value
                    },
                    callback: function (val: any, index: number, tick: any) {
                        let tempMonths;
                        const newLabel = Helpers.labelData(tick.length, index, this.getLabelForValue(val), filter, tempMonths, tick);
                        return newLabel
                    },
                },
            }
        },
    };

    const chartFilterArr = [
        "1M",
        "3M",
        "6M",
        "YTD",
        "1Y",
        "5Y",
        "MAX",
    ]

    const handleActiveBUtton = (params: any) => {
        setFilter(params)
    }

    return (

        <Center px={'10px'}>
            {
                user?.user?.role?.name === undefined || user?.user?.role?.name !== 'admin' && (
                <Flex direction={"column"} w={"100%"}>
                    {
                        currentDate && (
                            <NetWorthBox
                                currentDate={currentDate}
                                totalSummary={totalNetworth}
                                chartFilterArr={chartFilterArr}
                                option={option}
                                data={data}
                                handleActiveBUtton={handleActiveBUtton}
                                filter={filter}
                                setFilter={setFilter} />
                        ) 
                    }
                    <Box w={'100%'}>
                        <Flex direction={'row'} justifyContent={'space-between'}>
                            <Box w={'48%'}>
                                {
                                    filterDataCustomer.length > 0 && (
                                        <LeftSideBox />
                                    )
                                }
                            </Box>

                            <Box w={'48%'}>
                                <RightSideBox />
                            </Box>
                            
                        </Flex>
                    </Box>
                </Flex>
                ) 
            }
        </Center>
    );
};

export default Dashboard;

