import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import qoreContext from "../common/qoreContext";

export default function RegisterPage() {
  const form = useForm({
    defaultValues: { email: "", password: "", confirmPassword: "" },
  });

  const nav = useNavigate();

  type Values = {
    email: string;
    password: string;
  };

  const insertUser = qoreContext.table("users").useInsertRow();

  const formSubmit = async function (values: Values) {
    insertUser.insertRow({
      external_id: values.email,
      password: values.password,
    });
  };

  return (
    <div>
      <form
        onSubmit={form.handleSubmit((values) => {
          formSubmit(values);
        })}
      >
        <label htmlFor="email">Email address</label>
        <input
          type="email"
          id="email"
          placeholder="Email address"
          {...form.register("email", { required: true })}
        />

        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          placeholder="Password"
          {...form.register("password", { required: true })}
        />
        <label htmlFor="confirm-password">Confirm password</label>
        <input
          type="confirm-password"
          id="confirm-password"
          placeholder="Confirm password"
          {...form.register("confirmPassword", { required: true })}
        />

        <button type="submit" disabled={insertUser.status === "loading"}>
          Register
        </button>
      </form>
      <button
        type="button"
        onClick={() => {
          nav("/login");
        }}
      >
        Login
      </button>
    </div>
  );
}
