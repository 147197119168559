import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Previous, Paginator, PageGroup, Next } from "chakra-paginator";
import { Stack, Flex, ButtonProps } from "@chakra-ui/react";

const PaginatorTableMarket = ({
  pages,
  currentPage,
  handlePageChange,
}: any) => {
  const activeStyles: ButtonProps = {
    _hover: {
      bg: "#F9F7F2",
      color: "#A19262",
      borderColor: "#D7CAA1",
    },
    bg: "#F9F7F2",
    color: "#A19262",
    borderColor: "#D7CAA1",
  };

  const normalStyles: ButtonProps = {
    _hover: {
      bg: "#F9F7F2",
    },
    bg: "#fff",
    color: "#171717",
  };

  return (
    <Flex py="20px" justify="flex-end">
      <Paginator
        pagesQuantity={pages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        activeStyles={activeStyles}
        normalStyles={normalStyles}
        innerLimit={2}
        outerLimit={2}
      >
        <Stack
          className="pagination-wrapper"
          direction="row"
          w="full"
          justify="flex-end"
        >
          <Previous
            w="32px"
            h="32px"
            fontSize="14px"
            className="page-controller"
            bg="#fff"
            size="xs"
          >
            <FiChevronLeft />
          </Previous>
          <PageGroup
            className="page-number-wrapper"
            spacing={4}
            direction="row"
            align="center"
            isInline
          />
          <Next
            fontSize="14px"
            w="32px"
            h="32px"
            className="page-controller"
            bg="#fff"
            size="xs"
          >
            <FiChevronRight />
          </Next>
        </Stack>
      </Paginator>
    </Flex>
  );
};

export default PaginatorTableMarket;
