import { useState, useEffect } from "react";
import {
    Box,
    Stack,
    Checkbox,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    Button,
    Portal,
    Flex,
    AvatarBadge,
    Center,
    useDisclosure,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from "@chakra-ui/react";
import AccordionBox from '../../home/DashboardBox/AccordionBox';
import ListCustomer from './ListBox/ListCustomer';

import { RiArrowDownSLine } from "react-icons/ri";
import { BiFilterAlt } from "react-icons/bi";

import SearchPopover from './SearchPopover';

function FilterCustomer({
    setSearchFilter,
    searchFilter,
    allChecked,
    isIndeterminate,
    setCheckedItems,
    setCustomers,
    customers,
    checkedItems,
    allCustomers,
    setCustomersValues,
    customer,
}: any) {
    // const [countFilterChecked, setCountFilterChecked] = useState<number>(0);
    const { onClose } = useDisclosure()

    // useEffect(() => {
    //     if (customer.length > 0) {
    //         setCountFilterChecked(customer?.length);
    //     }
    // }, [customer.length]);

    const handleOnClosed = () => {
        setSearchFilter('')
        onClose()
    }

    return (
        <Flex direction="row" ml={2}>
            {/* <Popover onClose={handleOnClosed}>
                <PopoverTrigger>
                    <Box position={"relative"}>
                        <Stack direction='row' spacing={4}>
                            <Button
                                rightIcon={<RiArrowDownSLine fontSize={'16px'} />}
                                justifyContent={'space-between'}
                                w={'250px'}
                                h={'32px'}
                                ml={3}
                                py="8px"
                                pl={'8px'}
                                pr={'10px'}
                                border="1px"
                                borderColor="#E9E9E9"
                                backgroundColor="#fff"
                                color={'rgba(162, 162, 162, 1)'}
                                fontSize={'12px'}
                                _focus={{ outline: "none" }}
                            >
                                Filter by Customer
                            </Button>
                        </Stack>
                        {countFilterChecked !== 0 && (
                            <AvatarBadge
                                fontSize={"12px"}
                                fontWeight={600}
                                color={"rgba(224, 49, 56, 1)"}
                                top={-2}
                                right={-2}
                                borderRadius={"md"}
                                borderColor="papayawhip"
                                bg="rgba(252, 234, 235, 1)"
                                boxSize="19px"
                            >
                                {countFilterChecked}
                            </AvatarBadge>
                        )}
                    </Box>
                </PopoverTrigger>
                <Portal>
                    <PopoverContent
                        maxW="250px"
                        boxShadow="base"
                        maxH="320px"
                        overflowY="hidden"
                        _focus={{ outline: "none" }}
                    >
                        <PopoverArrow />
                        <PopoverBody px="0">
                            <SearchPopover setSearchFilter={setSearchFilter} />
                            <Box my={2} border="1px" borderColor="gray.200" />
                            <Center>
                                <Stack
                                    mt={2}
                                    mb={1}
                                    w={'full'}
                                    // spacing={1}
                                    className="filter-checkbox-wrapper"
                                >
                                    {searchFilter ? (
                                        <Center>
                                            <Box
                                                mt="-12px"
                                                px="10px"
                                                py="8px"
                                                fontSize={'12px'}>Filtering...</Box>
                                        </Center>
                                    ) : (
                                        <Checkbox
                                            isChecked={allChecked}
                                            isIndeterminate={isIndeterminate}
                                            mt="-12px"
                                            px="10px"
                                            py="8px"
                                            w="100%"
                                            _hover={{
                                                backgroundColor: "rgba(249, 247, 242, 1)",
                                            }}
                                            size="sm"
                                            className="filter-checkbox"
                                            onChange={(e) => {
                                                let temp = [];
                                                for (let i = 0; i < customers.length; i++) {
                                                    temp.push(e.target.checked);
                                                }
                                                setCheckedItems(temp);
                                                if (!e.target.checked) {
                                                    setCustomers([]);
                                                } else {
                                                    let names: any = [];
                                                    customers.forEach((el: any) => {
                                                        names.push(el.name);
                                                    });
                                                    setCustomers(names);
                                                }

                                                let countTemp = temp.filter((e) => e === true);
                                                if (countTemp) {
                                                    setCountFilterChecked(countTemp.length);
                                                }
                                            }}
                                        >
                                            Select All
                                        </Checkbox>
                                    )}
                                </Stack>
                            </Center>

                            <Box border="1px" borderColor="gray.200" />
                            <Stack
                                mt={1}
                                spacing={1}
                                className="filter-checkbox-wrapper"
                                overflowY="scroll"
                                maxH="160px"
                            >
                                {customers.length > 0 ? customers.map((el: any, index: number) => {
                                    return (
                                        <Checkbox
                                            key={index}
                                            isChecked={checkedItems[allCustomers.indexOf(el)]}
                                            value={el.name}
                                            px="10px"
                                            py="8px"
                                            _hover={{
                                                backgroundColor: "rgba(249, 247, 242, 1)",
                                            }}
                                            className="filter-checkbox"
                                            size="sm"
                                            onChange={(e) => {
                                                const leng = checkedItems.length;
                                                let temp = [];
                                                for (let i = 0; i < leng; i++) {
                                                    if (i === allCustomers.indexOf(el)) {
                                                        temp.push(e.target.checked);
                                                    } else {
                                                        temp.push(checkedItems[i]);
                                                    }
                                                }
                                                setCheckedItems(temp);
                                                let countTemp = temp.filter((e) => e === true);
                                                if (countTemp) {
                                                    setCountFilterChecked(countTemp.length);
                                                }
                                                setCustomersValues(e.target.value);
                                            }}
                                        >
                                            {el.name}
                                        </Checkbox>
                                    );
                                }) : (
                                    <Center my={'10px'}>
                                        <Box fontSize={'12px'}>Not Found</Box>
                                    </Center>
                                )
                                }
                            </Stack>
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover> */}
            {/* <Accordion w={'full'} defaultIndex={[0]} allowMultiple>
                    <AccordionItem>
                        <AccordionButton
                            w={'full'}
                            h={'32px'}
                            ml={3}
                            py="20px"
                            // border="1px"
                            borderColor="#E9E9E9"
                            backgroundColor="#fff"
                            color={'rgba(23, 23, 23, 1)'}
                            fontSize={'14px'}
                            fontWeight={600}
                            _focus={{ outline: "none" }}
                        >
                            <BiFilterAlt color={'rgba(151, 133, 77, 1)'} fontSize={'16px'} />
                            <Box pl={'10px'}>
                                Filter by Customer
                            </Box>
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                commodo consequat.
                        </AccordionPanel>
                </AccordionItem>
            </Accordion> */}

            <Box w={'full'}>
                <Accordion 
                    defaultIndex={[0, 1]} allowMultiple>
                        <AccordionBox
                            boxTitle={'Filter Customer'}
                            iconTitle={BiFilterAlt}>
                            <ListCustomer
                            searchFilter={searchFilter}
                            allChecked={allChecked}
                            isIndeterminate={isIndeterminate}
                            setCheckedItems={setCheckedItems}
                            setCustomers={setCustomers}
                            customers={customers}
                            checkedItems={checkedItems}
                            allCustomers={allCustomers}
                            setCustomersValues={setCustomersValues}
                            customer={customer}
                            setSearchFilter={setSearchFilter} />
                        </AccordionBox>
                </Accordion>
            </Box>
        </Flex>
    );
}

export default FilterCustomer;
