import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import App from "./App";
import { Provider } from 'react-redux';
import store from './app/store';

import 'react-toastify/dist/ReactToastify.css';
import "./style/style.css";

ReactDOM.render(
  <Provider store={store}>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </Provider>,
  document.getElementById("app")
);
