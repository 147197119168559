import { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Icon,
  Box,
  Center
} from "@chakra-ui/react";
import { BsCardList } from "react-icons/bs";
import TitleBoxDetail from "./TitleBoxDetail";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/ai";
import Helpers from "../../hooks/helpers";

function DetailHoldings({ asset, customers }: any) {

  const [customModeStock, setCustomModeStock] = useState<string>("hide");
  const [customModeETF, setCustomModeETF] = useState<string>("hide");
  const [customModeForex, setCustomModeForex] = useState<string>("hide");
  const [customModeCrypto, setCustomModeCrypto] = useState<string>("hide");
  const [customModeBonds, setCustomModeBonds] = useState<string>("hide");
  const [duplicateList, setDuplicateList] = useState<any[]>([]);
  const [onShow, setOnShow] = useState<boolean>(false)
  

  const [customModeMutualFunds, setCustomModeMutualFunds] =
    useState<string>("hide");

  const [totalValue, setTotalValue] = useState<number>(0);

  const getTotalValue = () => {
    let tempValue = 0;
    asset.forEach((el: any) => {
      tempValue += el.total;
    });
    setTotalValue(tempValue);
  };

  useEffect(() => {
    getDuplicate();
    getTotalValue();
  }, [asset]);

  const getDuplicate = async () => {
    let tempArr: any = []

    await asset.forEach((el: any) => {
      if (el.data.list) {
        const entries = Object.entries(el.data.duplicate);
        tempArr.push(entries)
      }
    })

    customers.forEach((customer: any) => {
      tempArr.forEach((el: any) => {
        el.forEach((le: any) => {
          le[1].list.forEach((asset: any) => {
            if (customer.id === asset.customer_id) {
              asset.customerName = customer.name
            }
          })
        })
      })
    })

    if (tempArr.length > 0) {
      await tempArr[0].forEach((el: any) => {
        el.push('hide')
      })
      await setDuplicateList(tempArr)
    }
  }

  const customCollapse = (params: any) => {
    if (params === "Stock") {
      let newMode = customModeStock === "hide" ? "show" : "hide";

      if (newMode === 'hide') {
        duplicateList[0].forEach((el: any) => {
          if (el[1].list[0].type === 'stock') {
            el[2] = 'hide'
          }
        })
      }

      setCustomModeStock(newMode);
    } else if (params === "ETF") {
      let newMode = customModeETF === "hide" ? "show" : "hide";
      setCustomModeETF(newMode);
    } else if (params === "FOREX") {
      let newMode = customModeForex === "hide" ? "show" : "hide";
      setCustomModeForex(newMode);
    } else if (params === "Crypto") {
      let newMode = customModeCrypto === "hide" ? "show" : "hide";
      setCustomModeCrypto(newMode);
    } else if (params === "Bonds") {
      let newMode = customModeBonds === "hide" ? "show" : "hide";
      setCustomModeBonds(newMode);
    } else if (params === "Mutual Fund") {
      let newMode = customModeMutualFunds === "hide" ? "show" : "hide";
      setCustomModeMutualFunds(newMode);
    }
  };

  const handleClassName = (params: any) => {
    if (params.toLowerCase() === "stock") {
      return `custom-table-collapse-${customModeStock}`;
    } else if (params === "ETF") {
      return `custom-table-collapse-${customModeETF}`;
    } else if (params === "Forex") {
      return `custom-table-collapse-${customModeForex}`;
    } else if (params === "Crypto") {
      return `custom-table-collapse-${customModeCrypto}`;
    } else if (params === "Bonds") {
      return `custom-table-collapse-${customModeBonds}`;
    } else if (params === "Mutual Fund") {
      return `custom-table-collapse-${customModeMutualFunds}`;
    }
  };

  const handleArrowMode = (params: any) => {
    if (params === "Stock") {
      return customModeStock;
    } else if (params === "ETF") {
      return customModeETF;
    } else if (params === "Forex") {
      return customModeForex;
    } else if (params === "Crypto") {
      return customModeCrypto;
    } else if (params === "Bonds") {
      return customModeBonds;
    } else if (params === "Mutual Fund") {
      return customModeMutualFunds;
    }
  };

  const handleDuplicateClass = async (params: any) => {
    if (duplicateList.length > 0) {
       await duplicateList[0].forEach((el: any, index: number) => {
        if (index === params) {
          if (el[2] === 'hide') {
            el[2] = 'show';
            setOnShow(true)
          } else {
            el[2] = 'hide';
            setOnShow(true)
          }
        }
      });
      setDuplicateList(duplicateList)
    }
    setOnShow(false)
  }

  return (
    <div>
      <TitleBoxDetail
        fsIcon={"16"}
        iconTitle={BsCardList}
        boxTitle={"Holdings Detail"}
      />

      <Table variant="simple" size="md" fontSize="12px" mt="20px" mb="20px">
        <Thead bg="rgba(246, 246, 246, 1)">
          <Tr>
            <Th
              textTransform="capitalize"
              p="12px"
              minW={"200px"}
              maxW={"200px"}
            >
              Name
            </Th>
            <Th textAlign="center" textTransform="capitalize" p="12px">
              QTY
            </Th>
            <Th textAlign="center" textTransform="capitalize" p="12px">
              Avg Price
            </Th>
            <Th textAlign="right" textTransform="capitalize" p="12px">
              Market Price
            </Th>
            <Th textAlign="right" textTransform="capitalize" p="12px">
              Unrealize Profit
            </Th>
            <Th textAlign="right" textTransform="capitalize" p="12px">
              {`Total   Profit`}
            </Th>
            <Th textAlign="right" textTransform="capitalize" p="12px">
              Market Value
            </Th>
            <Th textAlign="right" textTransform="capitalize" p="12px">
              Percentage Profit
            </Th>
            <Th textAlign="right" textTransform="capitalize" p="12px">
              Market Value (USD)
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {asset.map((el: any, index: number) => {
              return (
                <>
                  <Tr
                    key={index}
                    _hover={{
                      backgroundColor: "rgba(249, 247, 242, 1)",
                    }}
                    cursor={"pointer"}
                    onClick={(e) => customCollapse(`${el.name}`)}
                  >
                    <Td textTransform="capitalize" p="12px" fontWeight={"600"}>
                      <Center justifyContent={'start'}>
                      {handleArrowMode(el.name) === "hide" ? (
                        <Icon mr="4" fontSize="18" as={AiOutlinePlusSquare} />
                      ) : (
                        <Icon mr="4" fontSize="18" as={AiOutlineMinusSquare} />
                      )}
                      {el.name} {`(${el.data.length > 0 ? el.data.length : Number(el.data.list.length + el.data.notDuplicate.length) })`}
                      </Center>
                    </Td>
                    <Td></Td>
                    <Td></Td>
                    {/* <Td></Td> */}
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td fontWeight={600} textAlign="right" textTransform="capitalize" p="12px">
                      {Helpers.currencyFormaterUSD(el.total)}
                    </Td>
                  </Tr>
                  {el.data.length > 0 ? 
                  
                  el.data.map((le: any, index: number) => {
                    const eodPrice = Number(le.amount * le.updated_value);
                    const avgPrice = Number(le.amount * le.value);

                    const profit = Helpers.currencyFormater(
                      Number(eodPrice - avgPrice),
                      le.currency
                    )

                    return (
                      <Tr key={index} className={handleClassName(el.name)}>
                        <Td textTransform="capitalize" p="12px" maxW={"150px"}>
                          {le.name}
                        </Td>
                        <Td
                          textAlign="right"
                          textTransform="capitalize"
                          p="12px"
                        >
                          {Number(le.amount)}
                        </Td>
                        <Td
                          textAlign="center"
                          textTransform="capitalize"
                          p="12px"
                        >
                          {Helpers.currencyFormater(le.value, le.currency)}
                        </Td>
                        <Td textAlign="right" textTransform="capitalize" p="12px">
                          {Helpers.currencyFormater(
                            le.updated_value,
                            le.currency
                          )}
                        </Td>
                        
                        <Td textAlign="right" textTransform="capitalize" p="12px">
                          {Helpers.currencyFormater(
                            eodPrice - avgPrice,
                            le.currency
                          )}
                        </Td>
                        <Td textAlign="right" textTransform="capitalize" p="12px">
                          {Helpers.currencyFormater(
                            le.updated_value * le.amount - le.value * le.amount,
                            le.currency
                          )}
                        </Td>
                        <Td textAlign="right" textTransform="capitalize" p="12px">
                          {Helpers.currencyFormater(
                            le.updated_value * le.amount,
                            le.currency
                          )}
                        </Td>
                        <Td textAlign="right" textTransform="capitalize" p="12px">
                          <Center justifyContent={'end'}>
                            {Helpers.percentageFormater(
                              Number(le.amount * le.updated_value),
                              le.amount * le.value
                            )}{" "}
                            %
                            {Helpers.percentageFormater(
                              Number(le.amount * le.updated_value),
                              le.amount * le.value
                            )[0] === "-" ? (
                              <Icon
                                pl={"2px"}
                                fontSize={'18px'}
                                as={BiDownArrowAlt}
                                color={"rgba(224, 49, 56, 1)"}
                              />
                            ) : (
                              <Icon
                                pl={"2px"}
                                fontSize={'18px'}
                                as={BiUpArrowAlt}
                                color={"rgba(50, 153, 34, 1)"}
                              />
                            )}
                          </Center>
                        </Td>
                        <Td textAlign="right" textTransform="capitalize" p="12px">
                          {Helpers.currencyFormaterUSD(
                            le.updated_usd_value * le.amount
                          )}
                        </Td>
                      </Tr>
                    );
                  }) : (
                    
                    <>
                      {
                        duplicateList.length > 0 && duplicateList[0].length > 0 && duplicateList[0].map((el: any, index: number) => {
                          let totalQty = 0
                          let totalUP = 0
                          let stringTotalUP = ''
                          let totalMarketVal = 0
                          let totalUsdVal = 0
                          let totalAvgPrice = 0
                          if (el.length > 0) {
                            el[1].list.forEach((qty: any) => {
                              totalQty += Number(qty.amount);
                              totalUP += Number(qty.updated_value * qty.amount - qty.value * qty.amount);
                              totalMarketVal += Number(qty.updated_value * qty.amount)
                              totalUsdVal += Number(qty.updated_usd_value * qty.amount)
                              totalAvgPrice += Number(qty.amount * qty.value)
                            })
                            
                          }
                          totalAvgPrice = Number((totalAvgPrice / totalQty).toFixed(2))
                          return (
                            <>
                                <Tr
                                  key={index}
                                  _hover={{
                                    backgroundColor: "rgba(249, 247, 242, 1)",
                                  }}
                                  cursor={"pointer"}
                                  onClick={(e) => handleDuplicateClass(index)}
                                  className={handleClassName(el[1].list[0].type)}
                                >
                                  <Td textTransform="capitalize" p="12px" fontWeight={"600"}>
                                    <Center pl={'30px'} justifyContent={'start'}>
                                    {el[2] === "hide" ? (
                                      <Icon mr="4" fontSize="18" as={AiOutlinePlusSquare} />
                                    ) : (
                                      <Icon mr="4" fontSize="18" as={AiOutlineMinusSquare} />
                                    )}
                                    {el[0]} 
                                    </Center>
                                  </Td>
                                  <Td
                                    textAlign="right"
                                    textTransform="capitalize"
                                    p="12px"
                                  >{totalQty} </Td>
                                  <Td
                                    textAlign="right"
                                    textTransform="capitalize"
                                    p="12px"
                                  >
                                    {Helpers.currencyFormater(
                                      totalAvgPrice,
                                      el[1].list[0].currency
                                    )}
                                  </Td>
                                  <Td textAlign="right" textTransform="capitalize" p="12px">
                                          {Helpers.currencyFormater(
                                            el[1].list[0].updated_value,
                                            el[1].list[0].currency
                                          )}
                                  </Td>
                                  <Td textAlign="right" textTransform="capitalize" p="12px">
                                    {Helpers.currencyFormater(
                                        totalUP,
                                        el[1].list[0].currency
                                      )}
                                  </Td>
                                  <Td textAlign="right" textTransform="capitalize" p="12px">
                                    {Helpers.currencyFormater(
                                        totalUP,
                                        el[1].list[0].currency
                                      )}
                                  </Td>
                                  <Td textAlign="right" textTransform="capitalize" p="12px">
                                    {Helpers.currencyFormater(
                                      totalMarketVal,
                                      el[1].list[0].currency
                                    )}
                                  </Td>
                                  <Td textAlign="right" textTransform="capitalize" p="12px">
                                    <Center justifyContent={'end'}>
                                      {Helpers.percentageFormater(
                                        Number(totalMarketVal),
                                        totalAvgPrice * totalQty
                                      )}{" "}
                                      %
                                      {Helpers.percentageFormater(
                                        Number(totalMarketVal),
                                        totalAvgPrice * totalQty
                                      )[0] === "-" ? (
                                        <Icon
                                          pl={"2px"}
                                          fontSize={'18px'}
                                          as={BiDownArrowAlt}
                                          color={"rgba(224, 49, 56, 1)"}
                                        />
                                      ) : (
                                        <Icon
                                          pl={"2px"}
                                          fontSize={'18px'}
                                          as={BiUpArrowAlt}
                                          color={"rgba(50, 153, 34, 1)"}
                                        />
                                      )}
                                    </Center>
                                  </Td>
                                  <Td fontWeight={600} textAlign="right" textTransform="capitalize" p="12px">
                                    {Helpers.currencyFormaterUSD(totalUsdVal)}
                                  </Td>
                                </Tr>
                              
                                {
                                  el[1].list.map((le: any, index2: number) => {
                                    return (
                                      <Tr key={index2} className={`custom-table-collapse-${el[2]}`}>
                                        <Td pl={'42px'} textTransform="capitalize" pr={"12px"} py={'12px'} maxW={"150px"}>
                                          {le.customerName}
                                        </Td>
                                        <Td
                                          textAlign="right"
                                          textTransform="capitalize"
                                          p="12px"
                                        >
                                          {Number(le.amount)}
                                        </Td>
                                        <Td
                                          textAlign="right"
                                          textTransform="capitalize"
                                          p="12px"
                                        >
                                          {Helpers.currencyFormater(le.value, le.currency)}
                                        </Td>
                                        <Td textAlign="right" textTransform="capitalize" p="12px">
                                          {Helpers.currencyFormater(
                                            le.updated_value,
                                            le.currency
                                          )}
                                        </Td>
                                        <Td textAlign="right" textTransform="capitalize" p="12px">
                                          {Helpers.currencyFormater(
                                            le.updated_value * le.amount - le.value * le.amount,
                                            le.currency
                                          )}
                                        </Td>
                                        <Td textAlign="right" textTransform="capitalize" p="12px">
                                          {Helpers.currencyFormater(
                                            le.updated_value * le.amount - le.value * le.amount,
                                            le.currency
                                          )}
                                        </Td>
                                        <Td textAlign="right" textTransform="capitalize" p="12px">
                                          {Helpers.currencyFormater(
                                            le.updated_value * le.amount,
                                            le.currency
                                          )}
                                        </Td>
                                        <Td textAlign="right" textTransform="capitalize" p="12px">
                                          <Center justifyContent={'end'}>
                                            {Helpers.percentageFormater(
                                              Number(le.amount * le.updated_value),
                                              le.amount * le.value
                                            )}{" "}
                                            %
                                            {Helpers.percentageFormater(
                                              Number(le.amount * le.updated_value),
                                              le.amount * le.value
                                            )[0] === "-" ? (
                                              <Icon
                                                pl={"2px"}
                                                fontSize={'18px'}
                                                as={BiDownArrowAlt}
                                                color={"rgba(224, 49, 56, 1)"}
                                              />
                                            ) : (
                                              <Icon
                                                pl={"2px"}
                                                fontSize={'18px'}
                                                as={BiUpArrowAlt}
                                                color={"rgba(50, 153, 34, 1)"}
                                              />
                                            )}
                                          </Center>
                                        </Td>
                                        <Td textAlign="right" textTransform="capitalize" p="12px">
                                          {Helpers.currencyFormaterUSD(
                                            le.updated_usd_value * le.amount
                                          )}
                                        </Td>
                                      </Tr>
                                    )
                                  })
                          
                                }
                              
                            </>
                            
                          )
                          
                        })
                      }

                      

                      {
                        
                        el.data.notDuplicate.map((le: any, index: number) => {
                          return (
                            <Tr key={index} className={handleClassName(el.name)}>
                              <Td pl={'42px'} textTransform="capitalize" pr={'12px'} py="12px" maxW={"150px"}>
                                {le.name}
                              </Td>
                              <Td
                                textAlign="right"
                                textTransform="capitalize"
                                p="12px"
                              >
                                {Number(le.amount)}
                              </Td>
                              <Td
                                textAlign="right"
                                textTransform="capitalize"
                                p="12px"
                              >
                                {Helpers.currencyFormater(le.value, le.currency)}
                              </Td>
                              <Td textAlign="right" textTransform="capitalize" p="12px">
                                {Helpers.currencyFormater(
                                  le.updated_value,
                                  le.currency
                                )}
                              </Td>
                              <Td textAlign="right" textTransform="capitalize" p="12px">
                                {Helpers.currencyFormater(
                                  ((le.updated_value * le.amount) - (le.value * le.amount)),
                                  le.currency
                                )}
                              </Td>
                              <Td textAlign="right" textTransform="capitalize" p="12px">
                                {Helpers.currencyFormater(
                                  ((le.updated_value * le.amount) - (le.value * le.amount)),
                                  le.currency
                                )}
                              </Td>
                              <Td textAlign="right" textTransform="capitalize" p="12px">
                                {Helpers.currencyFormater(
                                  le.updated_value * le.amount,
                                  le.currency
                                )}
                              </Td>
                              <Td textAlign="right" textTransform="capitalize" p="12px">
                                <Center justifyContent={'end'}>
                                  {Helpers.percentageFormater(
                                    Number(le.amount * le.updated_value),
                                    le.amount * le.value
                                  )}{" "}
                                  %
                                  {Helpers.percentageFormater(
                                    Number(le.amount * le.updated_value),
                                    le.amount * le.value
                                  )[0] === "-" ? (
                                    <Icon
                                      pl={"2px"}
                                      fontSize={'18px'}
                                      as={BiDownArrowAlt}
                                      color={"rgba(224, 49, 56, 1)"}
                                    />
                                  ) : (
                                    <Icon
                                      pl={"2px"}
                                      fontSize={'18px'}
                                      as={BiUpArrowAlt}
                                      color={"rgba(50, 153, 34, 1)"}
                                    />
                                  )}
                                </Center>
                              </Td>
                              <Td textAlign="right" textTransform="capitalize" p="12px">
                                {Helpers.currencyFormaterUSD(
                                  le.updated_usd_value * le.amount
                                )}
                              </Td>
                            </Tr>
                          );
                        })
                      }

                    </>

                  )
                }
              </>
            );
          })}
        </Tbody>
      </Table>
      <Box
        textAlign="right"
        textTransform="capitalize"
        p="12px"
        fontWeight={600}
      >
        {`Total Value : ` + Helpers.currencyFormaterUSD(totalValue)}
      </Box>
    </div>
  );
}

export default DetailHoldings;
