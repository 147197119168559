import { 
    Tr, Th
} from "@chakra-ui/react";

const AdminUserTableHead = () => {
    return (
        <Tr>
            <Th textTransform='capitalize' maxW='120px' p='12px' >Avatar</Th>
            <Th textTransform='capitalize' p='12px' >Full Name</Th>
            <Th textTransform='capitalize' p='12px' >Role</Th>
            <Th textTransform='capitalize' p='12px' >Email</Th>
            <Th textTransform='capitalize' p='12px' >Registration Date</Th>
            <Th textTransform='capitalize' maxW='70px' p='12px' >Action</Th>
        </Tr>
    );
};

export default AdminUserTableHead;