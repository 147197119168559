import { 
    Td,
    Popover, PopoverTrigger, PopoverContent, PopoverBody,
    Button, IconButton,
    Portal,
    Stack,
} from "@chakra-ui/react";
import { HiDotsHorizontal } from "react-icons/hi";
import { BiEditAlt, BiTrash } from "react-icons/bi";

const NotesAction = ({handleRemove, handleUpdate, val}: any) => {

    return (
            <Td p='12px' maxW='30px' textAlign='center' className="user-action-wrapper">
                <Popover placement='left'>
                    <PopoverTrigger>
                        <IconButton
                            minW='24px'
                            h='24px'
                            border='1px'
                            borderColor='#E9E9E9'
                            variant="ghost"
                            aria-label="open menu"
                            _focus={{ outline: 'none'}}
                            icon={<HiDotsHorizontal width='10px' />}
                        />
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent maxW='170px' _focus={{outline : 'none'}}>
                            <PopoverBody p='0'>
                            <Stack direction='column' spacing={0}>
                                <Button 
                                    _focus={{outline: 'none'}}
                                    onClick={() => handleUpdate(val)}
                                    leftIcon={<BiEditAlt color='rgba(162, 162, 162, 1)' fontSize='16px' />} 
                                    _hover={{backgroundColor: "rgba(249, 247, 242, 1)"}} 
                                    color='rgba(69, 69, 69, 1)'
                                    fontSize='14px'
                                    fontWeight='400'
                                    p='16px'
                                    w='100%'
                                    justifyContent='flex-start'
                                    variant='ghost'>
                                    Notes Detail
                                </Button>
                                <Button 
                                    _focus={{outline: 'none'}}
                                    onClick={() => handleRemove(val)}
                                    leftIcon={<BiTrash color='rgba(162, 162, 162, 1)' fontSize='16px' />} 
                                    _hover={{backgroundColor: "rgba(249, 247, 242, 1)"}} 
                                    color='rgba(69, 69, 69, 1)'
                                    fontSize='14px'
                                    fontWeight='400'
                                    my='0'
                                    p='16px'
                                    w='100%'
                                    justifyContent='flex-start'
                                    variant='ghost'>
                                    Remove Notes
                                </Button>
                            </Stack>
                            </PopoverBody>
                        </PopoverContent>
                    </Portal>
                </Popover>
            </Td>
    );
};

export default NotesAction;