import { Box, Flex, Icon } from "@chakra-ui/react";
import { FiPieChart } from "react-icons/fi";
import DoughnutChart from "../../../components/Chart/DoughnutChart";
import TitleBoxDetail from "../../../components/DetailBox/TitleBoxDetail";

const ChartCard = ({ allocationData, chartTitle, handleSingle }: any) => {
  return (
    <Box>
      <TitleBoxDetail
        fsIcon={"16"}
        iconTitle={FiPieChart}
        boxTitle={chartTitle}
      />
      <DoughnutChart
        allocationData={allocationData}
        handleSingle={handleSingle}
        chartTitle={chartTitle}
      />
    </Box>
  );
};

export default ChartCard;
