import {
    Box,
    Stack,
    Input,
    Flex,
    InputGroup,
    Select,
    InputRightAddon,
    InputLeftElement,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";

const SearchExchangeList = ({
    setCurrentPage,
    setSearch, handleSearchFilter, autoComplete
}: any) => {
    return (
        <Box w={{ base: "full" }}>
            <Flex direction="row" py="20px">
                <Stack spacing={3} maxW="350px" direction="row">
                    <InputGroup>
                        <InputLeftElement
                            h={'32px'}
                            pointerEvents="none"
                            children={<FiSearch color="rgba(162, 162, 162, 1)" />}
                        />
                        <Input
                            h={'32px'}
                            _focus={{ outline: 'none' }}
                            placeholder={`Type to search`}
                            onChange={(e: any) => {
                                setSearch(e.target.value);
                                setCurrentPage(1)
                            }}
                        />
                        <InputRightAddon h={'32px'} ps="0px" pe="0px" maxW="100px">
                            <Select
                                _focus={{ outline: 'none' }}
                                h={"32px"}
                                onChange={(e) => {
                                    handleSearchFilter(e.target.value);
                                }}
                            >
                                <option value="Code">Code</option>
                                <option value="Name">Name</option>
                                <option value="ISIN">ISIN</option>
                            </Select>
                        </InputRightAddon>
                    </InputGroup>
                </Stack>
            </Flex>
            {
                autoComplete && autoComplete.length > 0 && (
                    <Box>
                        <Stack>
                            {autoComplete.map((el: any, index:number) => {
                                return (
                                    index < 15 && <Box key={index}> {el.Code} </Box>
                                )
                            })}
                        </Stack>
                    </Box>
                )
            }
        </Box>
    );
};

export default SearchExchangeList;