import {
    FormLabel,
    Flex,
    Tooltip,
    Center
} from "@chakra-ui/react";
import { InfoOutlineIcon } from '@chakra-ui/icons'

const AmountAsset = ({ labelName, typeName, currency }: any) => {
    return (
        <Flex>
            <Center>
                <FormLabel
                    fontSize="14px"
                    mb="8px"
                    mr='8px'
                    htmlFor="amount"
                >
                    {labelName}
                </FormLabel>
                {typeName && (
                    <Tooltip
                    placement='right'
                    hasArrow
                    label={typeName === "CC" ? "Coin" : "Shares"}
                    bg='black' color='#fff'>
                    <InfoOutlineIcon w={'13px'} h={'13px'} mb={'8px'} />
                </Tooltip>
                )}
                {currency && (
                    <Tooltip
                        placement='right'
                        hasArrow
                        label={currency === "USD" ? "USD" : currency}
                        bg='black' color='#fff'>
                        <InfoOutlineIcon w={'13px'} h={'13px'} mb={'8px'} />
                    </Tooltip>
                )}
            </Center>
        </Flex>
    );
};

export default AmountAsset;