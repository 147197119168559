import { useState } from "react";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  Center,
  Image,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import qoreContext from "../common/qoreContext";

import ErrorAlertComponent from "../components/Alert/ErrorAlertComponent";

import logo from "../../assets/logo.png";
import * as BiIcons from "react-icons/bi";
import { alertHandling } from "../hooks/alertHandling";
import { useToast } from '@chakra-ui/react';

export default function LoginPage() {
  const toast = useToast()
  const [mode, setState] = useState(`hide`);
  const [showPassword, setShowPassword] = useState<string>("password");
  const [allError, setAllError] = useState<any[]>([]);
  const form = useForm({
    defaultValues: { email: "", password: "" },
  });

  const [isChecked, setChecked] = useState(false);

  const nav = useNavigate();

  function toggleMode() {
    let newMode = mode === "show" ? "hide" : "show";
    setState(newMode);
  }

  const handleShowPassword = (value: string) => {
    if (value === "text") {
      setChecked(false);
      setShowPassword("password");
    } else if (value === "password") {
      setChecked(true);
      setShowPassword("text");
    }
  };

  type formLogin = {
    email: string;
    password: string;
  };

  const onSubmit = async (values: formLogin) => {
    try {
      const token = await qoreContext.client.authenticate(
        values.email,
        values.password
      );

      if (isChecked) {
        Cookies.set("token", token, { secure: true });
        Cookies.set("remember_me", { status: true }, { secure: true });
      } else {
        const minute = new Date(new Date().getTime() + 60 * 60 * 1000);
        Cookies.set("token", token, { expires: minute, secure: true });
      }
      alertHandling(toast, 'Login Success', 'success', 'top-right');
      nav("/")
    } catch (e) {
      let error = ["Incorrect username or password"];
      setAllError(error);
      return;
    }
  };

  const errorClosedButton = () => {
    setAllError([]);
  };

  return (
    <Center h="100vh" w="100%" className="bg-login">
      <Box
        transition={"ease-in-out"}
        p="5"
        maxW="440px"
        w="100%"
        borderWidth="1px"
        bg="#fff"
        borderRadius="10px"
      >
        <Center mx={'auto'} maxW={'180px'} mb={"24px"}>
          <Image borderRadius="md" src={logo} />
        </Center>

        <Box mb={'24px'}>
          <ErrorAlertComponent
            errorClosedButton={errorClosedButton}
            allError={allError}
          />
        </Box>

        <form onSubmit={form.handleSubmit((values) => onSubmit(values))}>
          <FormControl mb="24px" isRequired>
            <FormLabel
              htmlFor="email"
              fontSize="14px"
              color="rgba(23, 23, 23, 1)"
            >
              Email address
            </FormLabel>
            <div className="icon-wrapper left">
              <BiIcons.BiUser></BiIcons.BiUser>
            </div>
            <Input
              id="email"
              type="email"
              placeholder={`Fill Email address...`}
              fontSize="14px"
              color="rgba(162, 162, 162, 1)"
              pl="40px"
              {...form.register("email", { required: true })}
            />
          </FormControl>

          <FormControl mb="24px" isRequired>
            <FormLabel
              htmlFor="password"
              fontSize="14px"
              color="rgba(23, 23, 23, 1)"
            >
              Password
            </FormLabel>
            <div className="icon-wrapper left">
              <BiIcons.BiKey></BiIcons.BiKey>
            </div>
            <Input
              id="password"
              type={showPassword}
              placeholder="Fill Your Password..."
              fontSize="14px"
              color="rgba(162, 162, 162, 1)"
              pl="40px"
              {...form.register("password", { required: true })}
            />
            <div className="icon-wrapper right cursor-pointer">
              <div className="form-block__toggle-block">
                {isChecked ? (
                  <BiIcons.BiShow
                    color="#c6b378"
                    onClick={() => handleShowPassword("text")}
                  ></BiIcons.BiShow>
                ) : (
                  <BiIcons.BiHide
                    onClick={() => handleShowPassword("password")}
                  ></BiIcons.BiHide>
                )}
              </div>
            </div>
          </FormControl>

          <Flex justifyContent="space-between">
            <Checkbox
              iconColor="#c6b378"
              colorScheme="#c6b378"
              onChange={() => {
                setChecked(true);
              }}
            >
              <Text fontSize="12px" color="rgba(90, 90, 90, 1)">
                Remember Me
              </Text>
            </Checkbox>
            <Box fontSize="12px" color="rgba(90, 90, 90, 1)">
              <Link to="/forget-password">
                <Text>Forget Password?</Text>
              </Link>
            </Box>
          </Flex>

          <Button
            boxShadow="lg"
            bg="#c6b378"
            color="#fff"
            w="100%"
            h="40px"
            size="lg"
            type="submit"
            my="24px"
            fontSize="14px"
            fontWeight="extrabold"
            _hover={{
              bg: "white",
              color: "#c6b378",
              border: "1px solid #c6b378",
            }}
            disabled={form.formState.isSubmitting}
          >
            Login
          </Button>
        </form>
      </Box>
    </Center>
  );
}
