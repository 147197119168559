import { 
    Accordion,
} from "@chakra-ui/react";
import AccordionBox from './DashboardBox/AccordionBox';
import PerformanceDetail from './DashboardBox/PerformanceDetail';
import WatchListBox from './DashboardBox/WatchListBox';
import CustomerHoldings from './DashboardBox/CustomerHoldings';

import { BiGroup, BiPieChartAlt } from "react-icons/bi";

const RightSideBox = () => {
    return (
        <Accordion 
            defaultIndex={[0, 1]} allowMultiple>
                <AccordionBox
                    boxTitle={'Customer'}
                    iconTitle={BiGroup}>
                    <CustomerHoldings />
                </AccordionBox>
                <AccordionBox
                    boxTitle={'Watchlist'}
                    iconTitle={BiGroup}>
                    <WatchListBox />
                </AccordionBox>
        </Accordion>
    );
};

export default RightSideBox;