import { Tr, Th } from "@chakra-ui/react";

const FundManagementTableHead = () => {
  return (
    <Tr>
      <Th textTransform="capitalize" maxW="120px" p="12px">
        Avatar
      </Th>
      <Th textTransform="capitalize" p="12px">
        Display Name
      </Th>
      <Th textTransform="capitalize" p="12px">
        Assets
      </Th>
      <Th textTransform="capitalize" p="12px">
        Unallocated Funds
      </Th>
      <Th textTransform="capitalize" p="12px">
        Allocated Funds
      </Th>
      <Th textTransform="capitalize" p="12px">
        Total Funds
      </Th>
      <Th textTransform="capitalize" p="12px">
        Initial Funds
      </Th>
      <Th textTransform="capitalize" maxW="70px" p="12px">
        Action
      </Th>
    </Tr>
  );
};

export default FundManagementTableHead;
