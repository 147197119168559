import { useState, useEffect } from 'react'
import {
    Input,
    FormControl,
    FormLabel,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Select,
    Flex,
    Box,
    Spinner,
    Center
} from "@chakra-ui/react";
import Helpers from "../../hooks/helpers";
import CalculateAssetBox from "../DetailBox/CalculateAssetBox";
import AmountAsset from "../Tooltip/AmountAsset";
import SearchListData from "../FilterAndSearch/SearchListData";
import SearchListMarket from "../FilterAndSearch/SearchListMarket";
import LocalLoader from '../Loader/LocalLoader';
import CustomModalFooter from './Modal Components/CustomModalFooter';
import ErrorAlertComponent from '../Alert/ErrorAlertComponent';
import SearchCode from '../FilterAndSearch/SearchCode';


const AddAssetModal = ({
    isOpen,
    onClose,
    form,
    availableFundVal,
    typeData,
    handleAddAsset,
    handleFetchList,
    handleFetchMarket,
    handleAssetValue,
    setAssetCurrency,
    setAssetName,
    setAssetCountry,
    marketDataList,
    typeName,
    setValue,
    setAmount,
    assetCurrency,
    assetCountry,
    value,
    amount,
    totalUSD,
    assetValue,
    USDValue,
    isSubmit,
    allError,
    setMarketDataList,
    setAllError,
    setMarket,
    addMarket,
    market
}: any) => {
    console.log(market, `market`)
    const [insturmentList, setInstrumentList] = useState<string>("");
    const [marketList, setMarketList] = useState<string>("");
    const [marketFetch, setMarketFetch] = useState<boolean>(true);
    const [assetFetch, setAssetFetch] = useState<boolean>(true);
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [modalMode, setModalMode] = useState<boolean>(false);

    useEffect(() => {
        getInstrumentStatus()
    }, [insturmentList])

    useEffect(() => {
        getMarketListStatus()
    }, [marketList])

    const getInstrumentStatus = () => {
        if (insturmentList !== "") {
            setMarketFetch(false)
        }
    }

    const getMarketListStatus = () => {
        if (marketList !== "") {
            setAssetFetch(false)
        }
    }

    const handleModalClose = () => {
        form.setValue("type", "");
        form.setValue("name", "");
        form.setValue("exchange_market", "");
        form.setValue("code", "");
        form.setValue("location", "");
        form.setValue("amount", 0);
        form.setValue("value", 0);
        setMarketDataList([]);
        setAllError([]);
        setAmount(0);
        setValue(0);
        setAssetCurrency('');
        setAssetName('');
        setAssetCountry('');
        setInstrumentList('');
        setMarketFetch(true);
        setAssetFetch(true);
        setInstrumentList('');
        setModalMode(isOpen);
        onClose();
    }

    useEffect(() => {
        if (isOpen) {
            form.setValue("type", "");
            form.setValue("name", "");
            form.setValue("exchange_market", "");
            form.setValue("code", "");
            form.setValue("location", "");
            form.setValue("amount", 0);
            form.setValue("value", 0);
            setMarketDataList([]);
            setAllError([]);
            setAmount(0);
            setValue(0);
            setAssetCurrency('');
            setAssetName('');
            setAssetCountry('');
            setInstrumentList('');
            setMarketFetch(true);
            setAssetFetch(true);
            setInstrumentList('');
        }
    }, [isOpen]);

    return (
        <Modal isCentered={false} size="md" isOpen={isOpen} onClose={handleModalClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    px="24px"
                    py="16px"
                    fontSize="16px"
                    fontWeight="600"
                    borderBottom="1px"
                    borderColor="#E9E9E9"
                >
                    Add Asset
                </ModalHeader>

                <ModalCloseButton
                    _focus={{ outline: "none" }}
                    fontSize="8px"
                    maxW="10px"
                    maxH="10px"
                    mt="15px"
                    mr="20px"
                />
                <CalculateAssetBox
                    title={[
                        "Available Fund",
                        "Current Price",
                        "Asset Price USD",
                        `Total ${assetCountry ? assetCurrency : ""}`,
                        "Total USD",
                    ]}
                    content={[
                        Helpers.currencyFormaterUSD(availableFundVal),

                        assetCurrency
                            ? Helpers.currencyFormater(assetValue, assetCurrency)
                            : 0,

                        Helpers.currencyFormaterUSD(USDValue),

                        assetCurrency
                            ? Helpers.currencyFormater(value * amount, assetCurrency)
                            : 0,

                        Helpers.currencyFormaterUSD(totalUSD),
                    ]}
                />
                <form
                    onSubmit={form.handleSubmit((values: any) => handleAddAsset(values))}
                >
                    <ModalBody pt={0}>
                        <Flex justifyContent={"space-between"}>
                            <Box w={"full"}>
                                <Box pt={'16px'}>
                                    <ErrorAlertComponent allError={allError} />
                                </Box>
                                <SearchCode 
                                    setMarket={setMarket}
                                    addMarket={addMarket}
                                    handleFetchMarket={handleFetchMarket}
                                    setAssetCountry={setAssetCountry}
                                    setAssetCurrency={setAssetCurrency}
                                    setAssetName={setAssetName}
                                    handleAssetValue={handleAssetValue}
                                    form={form} />
                                {/* <FormControl pt="16px">
                                    <FormLabel
                                        fontSize="14px"
                                        mb="8px"
                                        htmlFor="Type"
                                    >
                                        Type
                                    </FormLabel>
                                    <Select
                                        {...form.register("type", { required: true })}
                                        placeholder="Select Asset Type"
                                        id="type"
                                        onChange={(e) => {
                                            handleFetchList(e.target.value);
                                            setInstrumentList(e.target.value);
                                        }}
                                    >
                                        <option value="stock">Stock</option>
                                        <option value="ETF">ETF</option>
                                        <option value="forex">Forex</option>
                                        <option value="CC">CryptoCurrency</option>
                                        <option value="bond">Bonds</option>
                                        <option value="mutualFund">Mutual Funds</option>
                                    </Select>
                                </FormControl> */}

                                {/* <FormControl pt="16px">
                                    <FormLabel
                                        fontSize="14px"
                                        mb="8px"
                                        htmlFor="Market"
                                    >
                                        Market
                                    </FormLabel>
                                    <Select
                                        isDisabled={marketFetch}
                                        {...form.register("exchange_market", { required: true })}
                                        placeholder={"Select Asset Market"}
                                        id="Market"
                                        onChange={(e) => {
                                            handleFetchMarket(e.target.value);
                                            setMarketList(e.target.value);
                                        }}
                                    >
                                        {typeData.map((el: any, index: number) => {
                                            return <option key={index} value={el.Code}>{el.Name}</option>;
                                        })}
                                    </Select>
                                </FormControl> */}

                                {/* <SearchListMarket 
                                    isLoad={isLoad}
                                    setIsLoad={setIsLoad}
                                    handleAssetValue={handleAssetValue}
                                    form={form}
                                    setAssetName={setAssetName}
                                    setAssetCurrency={setAssetCurrency}
                                    setAssetCountry={setAssetCountry}
                                    params={'code'}
                                    assetFetch={assetFetch}
                                    dataFetch={marketDataList}/> */}

                                {/* {
                                    isLoad && (
                                        <Center>
                                            {LocalLoader()}
                                        </Center>
                                    )
                                } */}

                                {   
                                    assetCountry && assetCurrency && market !== 'INDX' && (
                                        <FormControl pt="16px">
                                            <FormLabel
                                                fontSize="14px"
                                                mb="8px"
                                                htmlFor="Market"
                                            >
                                                Transaction Date
                                            </FormLabel>
                                            <Input
                                                fontSize="14px"
                                                px="12px"
                                                id="trade_date"
                                                type="date"
                                                placeholder="Transaction Date"
                                                disabled={assetCountry && assetCurrency ? false : true}
                                                {...form.register("trade_date", { required: true })}
                                                // onChange={(e) => {
                                                //     setValue(e.target.value);
                                                // }}
                                            />
                                        </FormControl>
                                )}
                                {
                                    assetCountry && assetCurrency && market !== 'INDX' && (
                                        <FormControl pt="16px">
                                            <AmountAsset labelName={"Amount"} typeName={typeName} />
                                            <Input
                                                autoFocus
                                                fontSize="14px"
                                                px="12px"
                                                id="amount"
                                                type="number"
                                                step="any"
                                                placeholder="Input Fund"
                                                disabled={assetCountry && assetCurrency ? false : true}
                                                {...form.register("amount", { required: true })}
                                                onChange={(e) => {
                                                    setAmount(Number(e.target.value));
                                                }}
                                            />
                                        </FormControl>
                                    )
                                }

                                {   
                                    assetCountry && assetCurrency && market !== 'INDX' && (
                                        <FormControl pt="16px">
                                            <AmountAsset
                                                labelName={"Asset Price"}
                                                currency={assetCurrency}
                                            />
                                            <Input
                                                fontSize="14px"
                                                px="12px"
                                                id="value"
                                                type="number"
                                                step="any"
                                                placeholder="Asset Price"
                                                disabled={assetCountry && assetCurrency ? false : true}
                                                {...form.register("value", { required: true })}
                                                onChange={(e) => {
                                                    setValue(Number(e.target.value));
                                                }}
                                            />
                                        </FormControl>
                                )}

                            </Box>
                        </Flex>
                    </ModalBody>
                    <CustomModalFooter value={value} amount={amount} isSubmit={isSubmit} onClose={handleModalClose} />
                </form>
            </ModalContent>
        </Modal>
    );
};

export default AddAssetModal;
