import { useParams } from 'react-router-dom'
import { useEffect, useState } from "react";

import { Stack, Flex, Center, Box, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { DatePicker } from "chakra-ui-date-input";
import qoreContext from "../../../common/qoreContext";
import { useForm } from "react-hook-form";

import LocalLoader from "../../../components/Loader/LocalLoader";

import TitleBoxDetail from "../../../components/DetailBox/TitleBoxDetail";
import { BiHistory } from 'react-icons/bi'
import NoDataFoundTransaction from "../../../components/StatusPage/NoDataFoundTransaction";
import TampleTable from "../../../components/Tables/TemplateTable";
import TableManagementTransaction from "../../../components/Tables/TableBody/TableManagementTransaction";
import UpdateTradeDate from "../../../components/Modal/UpdateTradeDate";
import Helpers from '../../../hooks/helpers';
const TransactionsLog = ({ customer }: any) => {

    const [transactions, setTransactions] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [noDataFound, setNoDataFound] = useState<boolean>(true);
    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");

    const [list, setList] = useState<any[]>([]);
    const [limit, setLimit] = useState<number>(10);
    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [updatedData, setUpdatedData] = useState<any>({})
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [errorDate, setErrorDate] = useState<any[]>([]);
    

    const handlePageChange = (nextPage: number) => {
        setCurrentPage(nextPage);
    };

    const { isOpen, onOpen, onClose } = useDisclosure();
    const user = qoreContext.useCurrentUser();
    const { updateRow, status } = qoreContext.table("transactions").useUpdateRow();
    const params = useParams();

    const form = useForm({
        defaultValues: {
            trade_date: "",
        },
    });

    const getTransaction = async () => {
        setIsLoading(true);
        let result:any = [];
        let customerCondition = { $and: [{ rm_id: user?.user?.id }] };
        let response;
        let tempResult:any = [];
        response = await qoreContext.client
                .table("customers")
                .readRows({
                    condition: customerCondition,
                    populate: ["customer_id_transactions_items"],
                })
                .toPromise();
        if (response?.data?.nodes) {
            const filterCustomer = await response?.data?.nodes.filter((el: any) => el.name === params.company);

            if (filterCustomer.length > 0) {
                const filterAsset = await filterCustomer[0].customer_id_transactions_items.filter((el: any) => el.asset_code === params.code);
                if (filterAsset.length > 0) {
                    filterAsset.reverse().map((el: any) => {
                        return result.push(el);
                    });
                }

                setTransactions(filterAsset);
                result.map((el: any) => {
                    tempResult.push(el);
                });
                if (result.length > 0) {
                    setToDate(result[0].created_at.split("-").reverse().join("/"));
                    setFromDate(result[result.length - 1].created_at.split("-").reverse().join("/"));
                }
                setPages(Math.ceil(result.length / limit));

                const listFiltered = Helpers.filterPagination(result, result, limit, currentPage);
                setList(listFiltered); 
                setNoDataFound(false);
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {getList()}, [currentPage]);
    useEffect(() => {getListByDate()}, [fromDate, toDate, transactions]);
    useEffect(() => {getTransaction()}, []);

    const getList = async () => {

        let result:any = [];

        transactions.forEach((el: any) => {
            result.push(el)
        })

        const dateList = getListDate(fromDate, toDate);
        const filterByDate = getDataBeetweenDate(result, dateList);
        const listFiltered = Helpers.filterPagination(result, filterByDate, limit, currentPage);
        setList(listFiltered);     
    }

    const getListByDate = () => {
        let result:any = [];

        transactions.forEach((el: any) => {
            result.push(el)
        });

        const dateList = getListDate(fromDate, toDate);
        const filterByDate = getDataBeetweenDate(result, dateList);

        setPages(Math.ceil(filterByDate.length / limit));

        const listFiltered = Helpers.filterPagination(result, filterByDate, limit, currentPage);
        setList(listFiltered); 
        setCurrentPage(1);   
    }

    const dateValidation = () => {
        const newDate = new Date();
        return newDate
    }

    const getDaysArray = (start: any, end: any) => {
        for(var arr = [] ,dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1 )){
            arr.push(new Date(dt));
        }
        return arr;
    };

    const getListDate = (fromDate: any, toDate: any) => {
        let daylist = getDaysArray(new Date(fromDate.split("/").reverse().join("-")),new Date(toDate.split("/").reverse().join("-")));
        let dateToDate = daylist.map((v)=>v.toISOString().slice(0,10));
        return dateToDate
    }

    const getDataBeetweenDate = (dataList: any, dateList: any) => {
        const intersection = dataList.filter((element: any) => dateList.includes(element.created_at));
        return intersection
    }

    const handleUpdateDate = async (val: any) => {
        await setUpdatedData(val)
        onOpen()
    }

    const handleSubmitForm = async (values: any) => {
        try {
            setIsSubmit(true);
            const currentDate = new Date().getTime()
            const isValid = new Date(values.trade_date).getTime()

            if (currentDate <= isValid) {
                throw { name: 'Maximum Date Today' }
            } 

            const data: object = {
                // name: updatedData.name,
                // action: updatedData.action,
                // currency: updatedData.currency,
                // amount: updatedData.amount,
                // value: updatedData.value,
                // total: updatedData.total,
                // customer_id_transactions: updatedData.customer_id_transactions,
                trade_date: values.trade_date,
                // asset_code: updatedData.asset_code
            }
            let response
            if (updatedData?.id) {
                response = await updateRow(updatedData.id, data);
            }

            if (!response) {
                throw { name: 'Something Wrong, Please try again later'}
            }

            setIsSubmit(false);
            form.setValue("trade_date", "");
            getTransaction();
            onClose();
            
        } catch (error) {
            setErrorDate([error.name])
            setIsSubmit(false);
        }
    }

    const transactionLogHead = [
        "Date",
        "Name",
        "Trade Date",
        "Action Trade",
        "Currency (CCY)",
        "Amount (QTY)",
        "Value USD (Price)",
        "Settlement Amount USD (Total)",
        "Action"
    ]

    console.log(list, `list`)

    return (
        <div>
            <UpdateTradeDate 
                form={form}
                modalText={'Edit'}
                isOpen={isOpen}
                onClose={onClose}
                updatedData={updatedData}
                handleSubmitForm={handleSubmitForm}
                isSubmit={isSubmit}
                errorDate={errorDate}
                setErrorDate={setErrorDate}
            />
            <Center pl="3" py={6}>
                {!isLoading ? (
                    !noDataFound ? (
                        <Box
                            w={"full"}
                            bg={useColorModeValue("white", "gray.800")}
                            boxShadow={"lg"}
                            rounded={"md"}
                            px="24px"
                        >
                            {isLoading ? (
                                <Box py="10"> {LocalLoader()} </Box>
                            ) : (
                                <>
                                    <Box w={{ base: "full" }}>
                                        <TitleBoxDetail
                                            fsIcon={"16"}
                                            iconTitle={BiHistory}
                                            boxTitle={`${transactions.length > 0 && transactions[0].name} - History Log`}
                                        />
                                        <Flex direction="row" py="20px">
                                            <Stack className={'datepicker-wrapper'} spacing={3} mr="12px" direction="row">
                                                <DatePicker
                                                    h={'32px'}
                                                    defaultValue={fromDate}
                                                    placeholder="From"
                                                    name="fromDate"
                                                    onChange={(date: string) =>
                                                        setFromDate(date.split("/").reverse().join("-"))
                                                    }
                                                />
                                                <Box fontWeight={600} lineHeight={'32px'}>
                                                    {'-'}
                                                </Box>
                                                <DatePicker
                                                    h={'32px'}
                                                    defaultValue={toDate}
                                                    placeholder="To"
                                                    name="toDate"
                                                    onChange={(date: string) =>
                                                        setToDate(date.split("/").reverse().join("-"))
                                                    }
                                                />
                                            </Stack>
                                        </Flex>
                                        <TampleTable 
                                            instrument={false}
                                            tableHeadList={transactionLogHead}
                                            pages={pages}
                                            currentPage={currentPage}
                                            handlePageChange={handlePageChange}
                                            customTableHead={'CustomTableHeadTransactions'}
                                            >
                                            <TableManagementTransaction list={list} handleUpdateDate={handleUpdateDate} />
                                        </TampleTable>
                                    </Box>
                                </>
                            )}
                        </Box>
                    ) : (
                        <NoDataFoundTransaction instrument={"Transaction"} />
                    )
                ) : (
                    <Box py="10"> {LocalLoader()} </Box>
                )}
            </Center>
        </div>
    );
};

export default TransactionsLog;