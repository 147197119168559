import {
    FormHelperText,
} from "@chakra-ui/react";

const CustomFormHelper = ( { helperText } : any ) => {
    return (
        <FormHelperText 
            fontWeight={400} 
            fontSize={'12px'} 
            color={'rgba(162, 162, 162, 1)'}> 
            {helperText} 
        </FormHelperText>
    );
};

export default CustomFormHelper;