import { useState, useEffect } from "react";
import {
    Box,
    Stack,
    Checkbox,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    Button,
    Portal,
    Flex,
    AvatarBadge,
    Center,
    useDisclosure,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Select,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
} from "@chakra-ui/react";
import AccordionBox from "../../home/DashboardBox/AccordionBox";
import ListCustomer from "./ListBox/ListCustomer";

import { RiArrowDownSLine } from "react-icons/ri";
import { BiFilterAlt } from "react-icons/bi";

import SearchPopover from "./SearchPopover";

import { useSelector, useDispatch } from "react-redux";
import {
    fetchNetWorthLabel,
    fetchNetWorthValue,
    fetchTotalNetworth,
} from "../../store/actionCreator/rm";

import zoom from "chartjs-plugin-zoom";
import qoreContext from "../../common/qoreContext";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

import NetWorthBox from "../DetailBox/NetWorthBox";
import Helpers from "../../hooks/helpers";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoom,
    Filler
);

function FilterReports({
    category,
    subCategory,
    setCategory,
    setSubCategory,
    tabs,
    setTabs,
    chartFilter,
    handleChartFilter,
    setOrientation
}: any) {
    const [format, setFormat] = useState<string>("");
    const { onClose } = useDisclosure();

    const [filterType, setFilterType] = useState<string>("MAX");

    const handleActiveBUtton = (params: any) => {
        setFilterType(params);
    };

    const selectCategory = (category: string) => {
        if (category === "Portfolio") {
            return (
                <FormControl mt={'auto'} mb={'0'}>
                    <Select
                        _focus={{ outline: 'none' }}
                        cursor={'pointer'}
                        placeholder="Select Sub-Category"
                        onChange={(e) => setSubCategory(e.target.value)}
                    >
                        <option value="Performance">Performance</option>
                    </Select>
                </FormControl>
            );
        } else if (category === "FinancialInstrument") {
            return (
                <Select
                    placeholder="Select Sub-Category"
                    onChange={(e) => setSubCategory(e.target.value)}
                >
                    <option value="stocks">Stocks</option>
                    <option value="ETF">ETF</option>
                    <option value="forex">Forex</option>
                    <option value="CC">Cryptocurrency</option>
                    <option value="bonds">Bonds</option>
                    <option value="mutualFund">Mutual Fund</option>
                    <option value="index">Index</option>
                </Select>
            );
        }
    };

    const selectTabs = (subCategory: string) => {
        if (subCategory === "Performance") {
            return (
                <Select
                    placeholder="Select Tabs"
                    onChange={(e) => setTabs(e.target.value)}
                >
                    <option value="percentage">Percentage</option>
                    <option value="values">Values</option>
                    <option value="tabular">Tabular</option>
                </Select>
            );
        }
    };

    return (
        <Flex direction="row" ml={2}>
            <Box w={"full"}>
                <Stack spacing={3} px="28px">
                    <RadioGroup fontSize={"14px"} defaultValue="PDF" onChange={setFormat}>
                        <Stack direction="row">
                            <Radio isDisabled value="CSV">
                                CSV
                            </Radio>
                            <Radio
                                color={"rgba(69, 69, 69, 1)"}
                                borderColor={"rgba(198, 179, 120, 1)!important"}
                                backgroundColor={"rgba(198, 179, 120, 1)!important"}
                                value="PDF"
                            >
                                PDF
                            </Radio>
                        </Stack>
                    </RadioGroup>

                    {/* <Flex alignItems='center' gap='2'>
                        <FormControl isRequired pt="8px">
                            <FormLabel fontSize="14px" mb="8px" htmlFor="Type">
                                Page(s)
                            </FormLabel>
                            <Select
                                _focus={{ outline: 'none' }}
                                cursor={'pointer'}
                                placeholder="Select Category"
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <option value="Portfolio">Portfolio</option>
                            </Select>
                        </FormControl>

                        {selectCategory(category)}
                    </Flex> */}

                    <Flex alignItems='center' gap='2'>
                        <FormControl isRequired pt="8px">
                            <FormLabel fontSize="14px" mb="8px" htmlFor="Type">
                                Page(s)
                            </FormLabel>
                            <Select
                                _focus={{ outline: 'none' }}
                                cursor={'pointer'}
                                placeholder="Select Category"
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <option value="Portfolio">Portfolio</option>
                            </Select>
                        </FormControl>

                        {selectCategory(category)}
                    </Flex>

                    {category && subCategory ? (
                        <Select
                            placeholder="Select Date Filter"
                            onChange={(e) => {
                                handleChartFilter(e.target.value);
                                handleActiveBUtton(e.target.value);
                            }}
                        >
                            <option value="1M">1 Month</option>
                            <option value="3M">3 Month</option>
                            <option value="6M">6 Month</option>
                            <option value="YTD">Year to Date</option>
                            <option value="1Y">1 Year</option>
                            <option value="5Y">5 Year</option>
                            <option value="MAX">MAX</option>
                        </Select>

                        // <RadioGroup fontSize={"14px"} defaultValue="l" onChange={setOrientation}>
                        //     <Stack direction="row">
                        //         <Radio value="l">
                        //             Landscape
                        //         </Radio>
                        //         <Radio
                        //             color={"rgba(69, 69, 69, 1)"}
                        //             borderColor={"rgba(198, 179, 120, 1)!important"}
                        //             backgroundColor={"rgba(198, 179, 120, 1)!important"}
                        //             value="p"
                        //         >
                        //             Portrait
                        //         </Radio>
                        //     </Stack>
                        // </RadioGroup>
                    ) : (
                        //     <Flex ml="auto" mr="0">
                        //     {
                        //         chartFilter.map((el: any, index: number) => {
                        //             return (
                        //               <Select
                        //             placeholder="Select Date Filter"
                        //             onChange={(e) => {
                        //                       handleChartFilter(e.target.value);
                        //                       handleActiveBUtton(e.target.value);
                        //                   }}
                        //         >
                        //             <option value="1M">1 Month</option>
                        //             <option value="3M">3 Month</option>
                        //             <option value="6M">6 Month</option>
                        //             <option value="YTD">Year to Date</option>
                        //             <option value="1Y">1 Year</option>
                        //             <option value="5Y">5 Year</option>
                        //             <option value="MAX">MAX</option>
                        //         </Select>
                        //                 // <Button
                        //                 //     key={index}
                        //                 //     backgroundColor={filterType === el ? 'rgba(233, 233, 233, 1)' : 'transparent'}
                        //                 //     _focus={{outline: 'none'}}
                        //                 //     _hover={{backgroundColor: 'rgba(233, 233, 233, 1)', transform: 'scale(1.14)'}}
                        //                 //     fontSize={'12px'}
                        //                 //     h={'32px'}
                        //                 //     w={'32px'}
                        //                 //     mr="5px" onClick={(e) => {
                        //                 //         handleChartFilter(el);
                        //                 //         handleActiveBUtton(el);
                        //                 //     }}>
                        //                 //     {el}
                        //                 // </Button>
                        //             )
                        //         })
                        //     }
                        // </Flex>
                        <></>
                    )}
                    
                </Stack>
            </Box>
        </Flex>
    );
}

export default FilterReports;
