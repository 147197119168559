import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Spacer,
    Stack,
    Tbody,
    Tr,
    Td
} from "@chakra-ui/react";
import { BiLineChart } from "react-icons/bi";
import zoom from "chartjs-plugin-zoom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Helpers from "../../hooks/helpers";
import ChartButtonFISingle from '../Button/ChartButtonFISingle';
import TemplateTable from '../Tables/TemplateTable';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoom,
    Filler
);

import { BiTrash } from "react-icons/bi";

const PortfolioIndexChart = ({ setPerformance, dataChart, handleModalOpen, handleUpdateDataChart }: any) => {
    let tempMonths: any[] = [];
    const [currentDate, setCurrentDate] = useState<string>("");
    const [filter, setFilter] = useState<any>("MAX");
    const [chartLabels, setChartLabels] = useState<any>([]);
    const [tabularData, setTabularData] = useState<any>([]);
    const [performanceDifference, setPerformanceDifference] = useState<any>([]);
    const [datasets, setDatasets] = useState<any>([]);
    const [performanceRange, setPerformanceRange] = useState<any[]>([])
    const [data, setData] = useState<any>(
        {
            labels: [],
            datasets: []
        });

    const getDataFromTop = () => {
        return dataChart
    }

    const getCurrentDate = () => {
        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth();
        const date = new Date().getUTCDate();
        if (month + 1 > 10) {
            const getDate = `${year}-${month + 1}-${date}`;
            setCurrentDate(getDate);
        } else {
            const getDate = `${year}-0${month + 1}-${date}`;
            setCurrentDate(getDate);
        }
    };
    // const testNewEod = await GetEOD.comparePerformance(values.code, values.exchange_market, chartData[0].data[0].date, chartData[0].data[chartData[0].data.length - 1].date)
    const getData = () => {
        if (dataChart.length > 0) {
            console.log(dataChart, `dataChart`)
            const data = Helpers.getChartData(dataChart, filter, currentDate);
            console.log(data, `data`)
            // const newComparison = Helpers.getComparisonPerformance(dataChart, filter, currentDate)
            if (filter === "MAX") {
                if (data) {
                    setChartLabels(data[0]);
                    setTabularData(data[1]);
                    setDatasets(data[2]);
                    setPerformanceDifference(data[3]);
                }
            } else {
                if (data) {
                    setChartLabels(data[0]);
                    setDatasets(data[1]);
                    if (data[2]) {
                        if (data[2].length > 0) {
                            setTabularData(data[2]);
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        getCurrentDate();
    }, []);

    useEffect(() => {
        getData();
    }, [dataChart, filter, currentDate]);

    useEffect(() => {
        setData(
            {
                labels: chartLabels,
                datasets: datasets
            });
    }, [chartLabels, datasets]);



    const options: any = {
        spanGaps: true,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    usePointStyle: true,
                    padding: 20
                },
                align: 'center',
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {

                        const dataChart = getDataFromTop();
                        const month = Helpers.getFormatDateYYMMDD(context.label.split(' ')[1]);
                        const newFormat = `${context.label.split(' ')[0]}-${month}-${context.label.split(' ')[2]}`;

                        let filtered = dataChart[context.datasetIndex].data.filter((el: any) => el.date === newFormat);

                        if (filtered.length > 0) {
                            if (context.datasetIndex === 0) {
                                // if (context.datasetIndex === context.dataset.data.length - 1) {   
                                //     setPerformance(` ${Helpers.currencyFormaterUSD(filtered[0].close)} - (${context.raw} %)`);
                                // }
                                return ` ${Helpers.currencyFormaterUSD(filtered[0].close)} - (${context.raw} %)`;
                            } else {
                                const formatCurreny = Helpers.currencyFormater(filtered[0].close, dataChart[context.datasetIndex].currency)
                                return `  ${formatCurreny} - ( ${context.raw} %)`
                            }
                        } 

                    },
                },
            },
        },
        scales: {
            x: {
                offset: false,
                gridLines: {
                    offsetGridLines: false,
                },
                title: {
                    display: true,
                },
                grid: {
                    display: false,
                    drawBorder: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: false,
                    getLabelForValue: function(value: any) {
                        return value
                    },
                    callback: function (val: any, index: number, tick: any) {
                        const newLabel = Helpers.labelData(tick.length, index, this.getLabelForValue(val), filter, tempMonths, tick);
                        return newLabel
                    },
                },
            },
        },
    };

    const chartFilterArr = [
        "1W",
        "1M",
        "3M",
        "YTD",
        "1Y",
        "5Y",
        "MAX",
    ]

    const tableHead = [
        "Name",
        "1W",
        "1M",
        "3M",
        "YTD",
        "1Y",
        "5Y",
        "MAX",
        "Action"
    ]

    const handleAddAsset = () => {
        handleModalOpen();
    }

    const handleDeleteRow = async (val: any) => {
        const filter = await dataChart.filter((el: any) => el.name !== val.name ? val.name : val.label);
        handleUpdateDataChart(filter);

    };

    return (
        <Box w={{ base: "full" }}>
            <Flex
                direction={'row'}
                py={'20px'}>
                <Box
                    ml={0}
                    mr={'auto'}
                    color={'rgba(23, 23, 23, 1)'}
                    fontWeight={600}
                    fontSize={'16px'}>
                    Portfolio
                </Box>
                {
                    data.labels.length !== 1 && (
                        <Button
                            onClick={handleAddAsset}
                            leftIcon={<BiLineChart fontSize="14px" />}
                            fontWeight="600"
                            fontSize="12px"
                            color="#fff"
                            h="32px"
                            minW="150px"
                            _focus={{ outline: "none" }}
                            border="1px"
                            _hover={{
                                color: "rgba(198, 179, 120, 1)",
                                backgroundColor: "#fff",
                                border: "1px",
                            }}
                            backgroundColor="rgba(198, 179, 120, 1)"
                        >
                            Performance Compare
                        </Button>
                    ) 
                }
            </Flex>

            {   data.labels.length !== 1 ? (
                <Box>
                    <Box>
                        <Center my={'16px'}>
                            <ChartButtonFISingle
                                chartFilter={chartFilterArr}
                                handleChartFilter={setFilter} />
                        </Center>
                    </Box>
                    {
                        data?.labels?.length > 0 && (
                            <Box
                                pl={'8px'}
                                fontSize={'12px'}>
                                as of {`${data?.labels[0]} - ${data?.labels[data?.labels?.length - 1]}`}
                            </Box>
                        )
                    }

                    <Box h={355}>
                        <Line options={options} data={data} />
                    </Box>

                    <Box my={'16px'}>
                        <Divider></Divider>
                    </Box>

                    <Box pb={'20px'}>
                        <TemplateTable 
                            instrument={false}
                            tableHeadList={tableHead}
                            pages={1}
                            currentPage={1}
                            handlePageChange={null}
                            customTableHead={null}
                            >
                            <Tbody>
                                {tabularData.length > 0 && tabularData.map((el: any, index: number) => {
                                    // console.log(el, `el`, index)
                                    return (
                                        <>
                                            {
                                                index === 0 ? (
                                                    <>
                                                        <Tr key={index}>
                                                                <Td textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.name}</Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastWeek}</Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastMonth}</Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last3Month}</Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.YTD}</Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastYear}</Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last5Year}</Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.maxData}</Td>
                                                                <Td textAlign={'right'} p={'12px'}>
                                                                    <Button
                                                                        isDisabled
                                                                        onClick={(e) => handleDeleteRow(el)}
                                                                        w="32px"
                                                                        h="32px"
                                                                        p="0"
                                                                        textAlign={'center'}
                                                                        border="1px"
                                                                        borderColor="#E9E9E9"
                                                                        backgroundColor="#fff"
                                                                        _focus={{ outline: "none" }}
                                                                    >
                                                                        <BiTrash fontSize="14px" />
                                                                    </Button>
                                                                </Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td p={0}></Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastWeek === 'N/A' ? el.lastWeek : Helpers.currencyFormaterUSD(el.Val[0].lastWeek)}</Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastMonth === 'N/A' ? el.lastMonth : Helpers.currencyFormaterUSD(el.Val[0].lastMonth)}</Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last3Month === 'N/A' ? el.last3Month : Helpers.currencyFormaterUSD(el.Val[0].last3Month)}</Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.YTD === 'N/A' ? el.YTD : Helpers.currencyFormaterUSD(el.Val[0].YTD)}</Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastYear === 'N/A' ? el.lastYear : Helpers.currencyFormaterUSD(el.Val[0].lastYear)}</Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last5Year === 'N/A' ? el.last5Year : Helpers.currencyFormaterUSD(el.Val[0].last5Year)}</Td>
                                                                <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.maxData === 'N/A' ? el.maxData : Helpers.currencyFormaterUSD(el.Val[0].Max)}</Td>
                                                                <Td p={0}></Td>
                                                            </Tr>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Tr key={index}>
                                                            <Td textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.name}</Td>
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastWeek}</Td>
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastMonth}</Td>
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last3Month}</Td>
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.YTD}</Td>
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastYear}</Td>
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last5Year}</Td>
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.maxData}</Td>
                                                            <Td textAlign={'right'} p={'12px'}>
                                                                <Button
                                                                    onClick={(e) => handleDeleteRow(el)}
                                                                    w="32px"
                                                                    h="32px"
                                                                    p="0"
                                                                    textAlign={'center'}
                                                                    border="1px"
                                                                    borderColor="#E9E9E9"
                                                                    backgroundColor="#fff"
                                                                    _focus={{ outline: "none" }}
                                                                >
                                                                    <BiTrash fontSize="14px" />
                                                                </Button>
                                                            </Td>
                                                        </Tr>
                                                        <Tr>
                                                            <Td textTransform={'capitalize'} fontSize={'12px'} p={'12px'}></Td> 
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastWeek === 'N/A' ? el.lastWeek : Helpers.currencyFormater(el.Val[0].lastWeek, el.Val[0].currency)}</Td>
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastMonth === 'N/A' ? el.lastMonth : Helpers.currencyFormater(el.Val[0].lastMonth, el.Val[0].currency)}</Td>
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last3Month === 'N/A' ? el.last3Month : Helpers.currencyFormater(el.Val[0].last3Month, el.Val[0].currency)}</Td>
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.YTD === 'N/A' ? el.YTD : Helpers.currencyFormater(el.Val[0].YTD, el.Val[0].currency)}</Td>
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.lastYear === 'N/A' ? el.lastYear : Helpers.currencyFormater(el.Val[0].lastYear, el.Val[0].currency)}</Td>
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.last5Year === 'N/A' ? el.last5Year : Helpers.currencyFormater(el.Val[0].last5Year, el.Val[0].currency)}</Td>
                                                            <Td textAlign={'right'} textTransform={'capitalize'} fontSize={'12px'} p={'12px'}>{el.maxData === 'N/A' ? el.maxData : Helpers.currencyFormater(el.Val[0].Max, el.Val[0].currency)}</Td>
                                                            <Td p={0}></Td>
                                                        </Tr>
                                                    </>
                                                )
                                            }
                                        </>
                                    );
                                })}
                            </Tbody>
                        </TemplateTable>
                    </Box>
                </Box>
                ) : (
                    <Box>
                        The asset of this customer hasn't past today
                    </Box>
                )
            }
        </Box>
    );
};

export default PortfolioIndexChart;
