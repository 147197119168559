import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Center,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import qoreContext from "../../common/qoreContext";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import TableUserFilter from "../../components/FilterAndSearch/TableUserFilter";
import PaginatorTableMarket from "../../components/Tables/PaginatorTableMarket";
import TableUser from "../../components/Tables/TableUser";
import LocalLoader from "../../components/Loader/LocalLoader";
import EmptyData from "../../components/EmptyData";
import UserRmModal from "../../components/Modal/UserRmModal";
import NoDataFound from "../../components/StatusPage/NoDataFound";

function RMUserManagement({ getCustomers }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [noDataFound, setNoDataFound] = useState<boolean>(true);
  const [modalMode, setModalMode] = useState<boolean>(true);

  const [idRows, setIdRows] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [filterFor, setFilterFor] = useState<string>("");

  const [pages, setPages] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [data, setData] = useState<any[]>([]);
  const [image, setImage] = useState<any>("");
  const [list, setList] = useState<any[]>([]);

  let user = qoreContext.useCurrentUser();

  useEffect(() => {
    getData();
  }, [user?.user?.id]);
  useEffect(() => {
    setList(data);
  }, [data]);
  useEffect(() => {
    setUpFilter(modalMode);
  }, [filterFor]);
  useEffect(() => {
    searchFilter(data);
  }, [search]);

  const form = useForm({
    defaultValues: {
      avatar: "",
      name: "",
    },
  });

  const { insertRow, status } = qoreContext.table("customers").useInsertRow();
  const { insertRow: insertPerformances } = qoreContext
    .table("performances")
    .useInsertRow();
  const { updateRow } = qoreContext.table("customers").useUpdateRow();

  function encodeImageFileAsURL(element: any) {
    try {
      let file = element.files[0];
      if (file.size > 500000) {
        throw { name: "File too Big" };
      }
      let reader = new FileReader();
      reader.onloadend = function () {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    } catch (error: any) {
      if (error.name === "File too Big") {
        onClose();
      }
    }
  }

  const emptyFormValue = () => {
    form.setValue("avatar", "");
    form.setValue("name", "");
    setImage("");
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      let result;
      if (user?.user?.id) {
        const response = await qoreContext.client
          .table("customers")
          .readRows({ condition: { rm_id: user?.user?.id } })
          .toPromise();

        if (response?.data?.nodes) {
          setPages(Math.ceil(response?.data?.nodes.length / limit));
          result = JSON.parse(JSON.stringify(response?.data?.nodes));
          setData(result);
          if (response?.data?.nodes.length > 0) {
            setNoDataFound(false);
          }
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchFilter = async (data: any) => {
    let result;

    if (search !== "") {
      result = await data.filter((el: any) =>
        el.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (result) {
      setList(result);
    } else {
      setList(data);
    }
  };

  const setUpFilter = (modalMode: any) => {
    if (modalMode) setFilterFor("Add New Customer");
  };

  const handleAddCustomer = async (values: any) => {
    const data: object = {
      avatar: image,
      name: values.name,
      rm_id: user?.user?.id,
    };

    let response: any = await qoreContext.client
      .table("customers")
      .readRows({ condition: { name: values.name } })
      .toPromise();

    let customer = response.data;

    await insertRow(data);

    await insertPerformances({
      value: 0,
      customer_id_performances: customer.id,
      percentage: 0,
    });

    getData();
    emptyFormValue();
    onClose();
  };

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
  };

  const updateUser = async (el: any) => {
    setFilterFor(`Edit Customer ${el.name ? el.name : ""}`);

    const data: object = {
      avatar: image,
      name: el.name,
      email: el.email,
      phone: el.phone,
      address: el.address,
      country: el.country,
      rm_id: user?.user?.id,
    };

    form.setValue("avatar", `${el.avatar}`);
    form.setValue(
      "name",
      `${el.name ? el.name : `Please Fill the Name Field`}`
    );
    setImage(el.avatar);
    setIdRows(el.id);
    setModalMode(false);
  };

  const tryAddUser = () => {
    setModalMode(true);
    setFilterFor("Add New Customer");
    emptyFormValue();
    setImage("");
  };

  const handleUpdateCustomer = async (values: any, idRows: string) => {
    const data: object = {
      avatar: image,
      name: values.name,
      rm_id: user?.user?.id,
    };

    const response = await updateRow(idRows, data);

    getData();
    emptyFormValue();
    onClose();
  };

  const afterDelete = () => {
    getData();
  };

  useEffect(() => {
    getCustomers();
  }, [list]);

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            px="24px"
            py="16px"
            fontSize="16px"
            fontWeight="600"
            borderBottom="1px"
            borderColor="#E9E9E9"
          >
            {filterFor}
          </ModalHeader>
          <ModalCloseButton
            _focus={{ outline: "none" }}
            fontSize="8px"
            maxW="10px"
            maxH="10px"
            mt="15px"
            mr="20px"
          />

          <UserRmModal
            form={form}
            modalMode={modalMode}
            handleAddCustomer={handleAddCustomer}
            handleUpdateCustomer={handleUpdateCustomer}
            encodeImageFileAsURL={encodeImageFileAsURL}
            idRows={idRows}
            image={image}
            onClose={onClose}
          />
        </ModalContent>
      </Modal>

      <Center pl="3" py={6}>
        {!isLoading ? (
          !noDataFound ? (
            <Box
              w={"full"}
              bg={useColorModeValue("white", "gray.800")}
              boxShadow={"lg"}
              rounded={"md"}
              px="24px"
            >
              <Box w={{ base: "full" }}>
                <TableUserFilter
                  buttonText={"Add New Customer"}
                  tryAddUser={tryAddUser}
                  onOpen={onOpen}
                  setSearch={setSearch}
                />
              </Box>

              {isLoading ? (
                <Box py="10"> {LocalLoader()} </Box>
              ) : (
                <>
                  {list.length > 0 ? (
                    <>
                      <TableUser
                        instrument={"customers"}
                        list={list}
                        onOpen={onOpen}
                        updateUser={updateUser}
                        afterDelete={afterDelete}
                      />

                      <PaginatorTableMarket
                        pages={pages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                      />
                    </>
                  ) : (
                    <EmptyData />
                  )}
                </>
              )}
            </Box>
          ) : (
            <NoDataFound instrument={"customers"} onOpen={onOpen} />
          )
        ) : (
          <Box py="10"> {LocalLoader()} </Box>
        )}
      </Center>
    </div>
  );
}

export default RMUserManagement;
