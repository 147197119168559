import AssetAllocationTable from "../../components/Tables/AssetAllocationTable";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
const AllocationDetail = ({ customer }: any) => {
  const location = useLocation();
  useEffect(() => {
    console.log(location, `location`);
  }, [location]);
  return <AssetAllocationTable customer={customer} type={location.state} />;
};

export default AllocationDetail;
