import { Tr, Tbody, Td } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Helpers from "../../../hooks/helpers";

const TableMarketListBody = ({ list, instrument }: any) => {

    console.log(instrument, `instrument`);
    console.log(list, `list`)

    const nav = useNavigate();

    const handleDetail = (params: string) => {
        nav(params);
    };
    return (
        <Tbody>
            {list.map((el: any, index: number) => {
                return (
                    <Tr
                        key={index}
                        cursor="pointer"
                        _hover={{
                            backgroundColor: "rgba(249, 247, 242, 1)",
                        }}
                        onClick={() =>
                            instrument === "bonds"
                                ? handleDetail(
                                    `/financial-instrument/${instrument}/${el.Exchange}/${el.Code}`
                                )
                                : handleDetail(
                                    `/financial-instrument/${instrument}/${el.Code}`
                                )
                        }
                    >
                        {
                            instrument === 'forex' ? (
                                <Td textTransform="capitalize" p="12px">{Helpers.forexRegion(el.Code)}</Td>
                            ) : (
                                <Td textTransform="capitalize" p="12px">{el.Code}</Td>
                            )
                        }
                        <Td textTransform="capitalize" p="12px">{el.Name}</Td>

                        {
                            (instrument === 'bonds' || instrument === 'index') && (
                                <Td textTransform="capitalize" p="12px">{el.Country}</Td>
                            )
                        }

                        {
                            instrument === 'bonds' && (
                                <Td textTransform="capitalize" p="12px">{el.Exchange}</Td>
                            )
                        }

                        {
                            (instrument === 'bonds' || instrument === 'forex' || instrument === 'index') && (
                                <Td textTransform="capitalize" p="12px">{el.Currency}</Td>
                            )
                        }
                        
                    </Tr>
                );
            })}
        </Tbody>
    );
};

export default TableMarketListBody;