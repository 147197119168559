import { combineReducers } from 'redux';
import financialInstrumentReducer from "./financialInstrumentReducer";
import rmDataReducer from "./rmDataReducer";


const rootReducer = combineReducers(
    {
        financialInstrument: financialInstrumentReducer, 
        rm: rmDataReducer, 
    })

export default rootReducer
