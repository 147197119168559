import {
    Stack,
    Input,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";

const SearchPopover = ({setSearchFilter} : any) => {
    return (
        <Stack spacing={3} px="10px">
            <InputGroup>
                <InputRightElement
                    pointerEvents="none"
                    children={<FiSearch color="rgba(162, 162, 162, 1)" />}
                />
                <Input
                    placeholder="Search by Filter"
                    fontSize="12px"
                    _focus={{ outline: "none" }}
                    onChange={(e: any) => {
                        setSearchFilter(e.target.value);
                    }}
                />
            </InputGroup>
        </Stack>
    );
};

export default SearchPopover;