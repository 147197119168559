import axios from 'axios'
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import qoreContext from "../qoreContext";
import {
    useDisclosure,
    Center,
    Box,
    useColorModeValue,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Button
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import TableCustomerAssetFilter from "../../components/FilterAndSearch/TableCustomerAssetFilter";
import PaginatorTableMarket from "../../components/Tables/PaginatorTableMarket";
import getAPI from "../../API/getList";
import Helpers from "../../hooks/helpers";
import { AnimatePresence } from "framer-motion";
import DetailCustomerAsset from "../../components/DetailBox/DetailCustomerAsset";
import NoDataFoundAsset from "../../components/StatusPage/NoDataFoundAsset";
import NoDataNotesFound from "../../components/StatusPage/NoDataNotesFound";
import EmptyData from "../../components/EmptyData";
import LocalLoader from "../../components/Loader/LocalLoader";

import AddAssetModal from "../../components/Modal/AddAssetModal";
import UpdateAssetModal from "../../components/Modal/UpdateAssetModal";
import SellAssetModal from "../../components/Modal/SellAssetModal";

import AddNewNotes from '../../components/Modal/AddNewNotes';
import DetailNotes from '../../components/Modal/DetailNotes';

import CustomerAssetTable from "../../components/Tables/CustomerAssetTable";
import CustomerFundLog from "./CustomerFundLog";
import CustomerNotes from './CustomerNotes'
import TabPanelContent from "../../components/Tabs/TabPanelContent";

function CustomerAssets({setIsUpdate}: any) {
    
    const { insertRow: insertNotes } = qoreContext.table("notes").useInsertRow();
    const { updateRow: updateNotes } = qoreContext.table("notes").useUpdateRow();

    const { insertRow, status } = qoreContext.table("assets").useInsertRow();
    const { insertRow: insertTransaction } = qoreContext
        .table("transactions")
        .useInsertRow();

    const { updateRow } = qoreContext.table("customers").useUpdateRow();
    const { updateRow: updateAsset } = qoreContext.table("assets").useUpdateRow();
    const { deleteRow } = qoreContext.table("assets").useDeleteRow();

    const buy = useDisclosure();
    const addNewNotes = useDisclosure();
    const detailNotes = useDisclosure();
    const removeNotes = useDisclosure();
    const buy2 = useDisclosure();
    const sell = useDisclosure();

    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
    const [noDataFound, setNoDataFound] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const [customer, setCustomer] = useState<any>({});
    const [typeData, setTypeData] = useState<any[]>([]);
    const [typeName, setTypeName] = useState<string>("");
    const [marketDataList, setMarketDataList] = useState<any[]>([]);
    const [asset, setAsset] = useState<any>("");
    const [assetName, setAssetName] = useState<string>("");
    const [assetCountry, setAssetCountry] = useState<string>("");
    const [assetValue, setAssetValue] = useState<number>(0);
    const [market, setMarket] = useState<string>("");
    const [allData, setAllData] = useState<any[]>([]);
    const [search, setSearch] = useState<string>("");
    const [assetCurrency, setAssetCurrency] = useState<string>("");
    const [type, setType] = useState<any[]>([]);
    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [searchFilter, setSearchFilter] = useState<string>("");
    const [value, setValue] = useState<number>(0);
    const [amount, setAmount] = useState<number>(0);
    const [value3, setValue3] = useState<number>(0);
    const [amount3, setAmount3] = useState<number>(0);
    const [totalUSD, setTotalUSD] = useState<number>(0);
    const [value2, setValue2] = useState<number>(0);
    const [amount2, setAmount2] = useState<number>(0);
    const [totalUSD2, setTotalUSD2] = useState<number>(0);
    const [pages2, setPages2] = useState<number>(0);
    const [notesPages, setNotesPages] = useState<number>(0);
    const [currentPage2, setCurrentPage2] = useState<number>(1);
    const [currentPageNotesTable, setCurrentPageNotesTable] = useState<number>(1);
    const [USDValue, setUSDValue] = useState<number>(0);
    const [USDValue2, setUSDValue2] = useState<number>(0);
    const [allError, setAllError] = useState<any[]>([])

    const [checkedItems, setCheckedItems] = useState<any[]>([]);
    const allChecked = checkedItems.every(Boolean);

    const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

    // TRANSACTION
    const [transactions, setTransactions] = useState<any[]>([]);
    const [notes, setNotes] = useState<any[]>([]);
    const [singleNotes, setSingleNotes] = useState<any>({})
    const [allTransactions, setAllTransactions] = useState<any[]>([]);
    const [allNotes, setAllNotes] = useState<any[]>([]);
    const [oldestDate, setOldestDate] = useState<string>("");
    const [newestDate, setNewestDate] = useState<string>("");
    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [fromDateNotes, setFromDateNotes] = useState<string>("");
    const [toDateNotes, setToDateNotes] = useState<string>("");
    const [noDataFound2, setNoDataFound2] = useState<boolean>(true);
    const [noDataNotesFound, setNoDataNotesFound] = useState<boolean>(true);
    const [types, setTypes] = useState<any[]>([]);
    const [allTypes, setAllTypes] = useState<any[]>([]);

    const form = useForm({
        defaultValues: {
            type: "",
            name: "",
            code: "",
            exchange_market: "",
            location: "",
            amount: 0,
            value: 0,
            trade_date: ""
        },
    });

    const formDuplicate = useForm({
        defaultValues: {
            amount: 0,
            value: 0,
            trade_date: ""
        },
    });

    const formSell = useForm({
        defaultValues: {
            amount: 0,
            value: 0,
            trade_date: ""
        },
    });

    const notesForm = useForm({
        defaultValues: {
            date_notes: '',
            extention: '',
            remarks: '',
            bank_statement: ''
        },
    });

    function onlyUnique(value: any, index: number, self: any) {
        return self.indexOf(value) === index;
    }

    const getTransactions = async () => {
        try {
            setIsLoading(true);
            let result;
            let response;
            if (allTransactions.length === 0) {
                response = await qoreContext.client
                    .table("fund_logs")
                    .readRows({
                        condition: { customer_id_fund_logs: searchParams.get("id") },
                    })
                    .toPromise();
                if (response?.data?.nodes) {
                    result = JSON.parse(JSON.stringify(response?.data?.nodes));

                    setPages2(Math.ceil(result.length / limit));

                    if (limit > 0) {
                        result = result.splice((currentPage2 - 1) * limit, limit);
                    }

                    setTransactions(result);
                    setAllTransactions(response?.data?.nodes);

                    if (response?.data?.nodes.length > 0) {
                        setNewestDate(
                            response?.data?.nodes[response?.data?.nodes.length - 1].created_at
                        );
                        setOldestDate(response?.data?.nodes[0].created_at);
                        setToDate(
                            response?.data?.nodes[response?.data?.nodes.length - 1].created_at
                        );
                        setFromDate(response?.data?.nodes[0].created_at);
                        setNoDataFound(false);
                    }
                }
            } else {
                if (toDate && fromDate) {
                    response = await qoreContext.client
                        .table("fund_logs")
                        .readRows({
                            condition: {
                                $and: [
                                    { created_at: { $lte: toDate } },
                                    { created_at: { $gte: fromDate } },
                                    { customer_id_fund_logs: searchParams.get("id") },
                                ],
                            },
                        })
                        .toPromise();
                }
            }

            if (response?.data?.nodes) {
                result = JSON.parse(JSON.stringify(response?.data?.nodes));

                setPages2(Math.ceil(result.length / limit));

                if (limit > 0) {
                    result = result.splice((currentPage2 - 1) * limit, limit);
                }

                setTransactions(result);

                if (response?.data?.nodes.length > 0) {
                    setNoDataFound(false);
                }
            }

            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getTransactions();
    }, [fromDate, toDate, currentPage2]);

    const setTypeValues = (value: string) => {
        if (type.find((el: any) => el === value)) {
            setType(type.filter((el: any) => el !== value));
        } else {
            setType([...type, value]);
        }
    };

    const getCustomer = async () => {

        const id = searchParams.get("id")

        if (id) {
            const response = await qoreContext.client
                .table("customers")
                .readRow(id)
                .toPromise();
            setCustomer(response?.data);
            if (isInitialLoading) {
                setIsInitialLoading(false);
            }
        }
    };

    const getAssetData = async (id: any) => {
        const response = await qoreContext.client
            .table("assets")
            .readRow(id)
            .toPromise();
        setAsset(response?.data);
    };

    const getData = async () => {
        try {
            setIsLoading(true);
            const response = await qoreContext.client
                .table("assets")
                .readRows({ condition: { customer_id: searchParams.get("id") } })
                .toPromise();

            if (response?.data?.nodes) {
                if (response?.data?.nodes.length > 0) {
                    setNoDataFound(false);
                }
                setAllData(response?.data?.nodes);
                let result: any[] = [];
                if (type.length > 0) {
                    type.forEach((el: any) => {
                        result.push(
                            response?.data?.nodes.filter((le: any) => le.type === el)
                        );
                    });
                    result = Array.prototype.concat.apply([], result);
                    result = result.filter(onlyUnique);
                } else {
                    result = JSON.parse(JSON.stringify(response?.data?.nodes));
                }

                if (search !== "") {
                    result = result.filter((el: any) =>
                        el.name.toLowerCase().includes(search.toLowerCase())
                    );
                }

                setPages(Math.ceil(result.length / limit));

                result = result.sort((a, b) => {
                    (a = a.name.toLowerCase()), (b = b.name.toLowerCase());

                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                });
                if (limit > 0) {
                    result = result.splice((currentPage - 1) * limit, limit);
                }

                setData(result);
                setIsLoading(false);
            }
        } catch (error) { }
    };

    useEffect(() => {
        getCustomer();
    }, []);
    useEffect(() => {
        getData();
    }, [searchParams, type, search, currentPage]);

    useEffect(() => {
        handleUSD(value * amount);
    }, [value, amount]);

    useEffect(() => {
        handleUSDValue(value);
    }, [value]);

    useEffect(() => {
        handleUSDValue2(value2);
    }, [value2]);

    useEffect(() => {
        handleUSD2(value2 * amount2);
    }, [value2, amount2]);

    useEffect(() => {
        setCurrentPage(1);
    }, [search, type]);

    useEffect(() => {
        if (allData) {
            let tempTypes: any[] = [];
            allData.forEach((el) => {
                tempTypes.push(el.type);
            });
            tempTypes = tempTypes.filter(onlyUnique);
            if (searchFilter !== "") {
                tempTypes = tempTypes.filter((el: any) => {
                    return el.toUpperCase().includes(searchFilter.toUpperCase());
                });
            }
            tempTypes = tempTypes.sort();
            if (allTypes.length === 0) {
                setAllTypes(tempTypes);
            }
            setTypes(tempTypes);
        }
    }, [allData, searchFilter]);

    useEffect(() => {
        let tempCheckedType: any[] = [];
        for (let i = 0; i < types.length; i++) {
            tempCheckedType.push(false);
        }

        if (checkedItems.length === 0) {
            setCheckedItems(tempCheckedType);
        }
    }, [types.length]);

    const handlePageChange = (nextPage: number) => {
        setCurrentPage(nextPage);
    };
    const handlePageChange2 = (nextPage: number) => {
        setCurrentPage2(nextPage);
    };

    const handlePageChangeTableNotes = (nextPage: number) => {
        setCurrentPageNotesTable(nextPage);
    };

    let allocatedFunds: number = 0;
    allData.forEach((el: any) => {
        allocatedFunds += el.amount * el.updated_usd_value;
    });

    const isToday = (someDate: any) => {
        let today = new Date().toISOString().slice(0, 10)
        const currentDate = new Date(today).getTime();
        const submitDate = new Date(someDate).getTime();

        let isToday = true

        if (currentDate !== submitDate) {
            isToday = false
        }

        return isToday
    }

    const handleAddAsset = async (values: any) => {
        try {
            setIsSubmit(true)
            let data;
            let transactionData;
            let usd_value;
            let detail;
            let industry;

            if (
                values.exchange_market !== "BOND" &&
                values.exchange_market !== "GBOND" &&
                values.exchange_market !== "FUND"
            ) {
                detail = await getAPI.getDetail(values.code, values.exchange_market);

                if (detail.General) {
                    if (detail.General.Industry) {
                        industry = detail.General.Industry;
                    } else {
                        industry = "Unclassified";
                    }
                } else {
                    industry = "Unclassified";
                }
            } else {
                industry = "Unclassified";
            }

            if (assetCurrency === "USD") {
                usd_value = values.value;
                data = {
                    type: values.type,
                    name: assetName,
                    exchange_market: values.exchange_market,
                    code: values.code,
                    location: assetCountry,
                    amount: values.amount,
                    customer_id: customer.id,
                    value: values.value,
                    usd_value,
                    updated_value: values.value,
                    updated_usd_value: usd_value,
                    currency: assetCurrency,
                    industry,
                };
                transactionData = {
                    name: assetName,
                    action: "BUY",
                    currency: assetCurrency,
                    amount: values.amount,
                    value: values.value,
                    total: values.value * values.amount,
                    customer_id_transactions: customer.id,
                    trade_date: values.trade_date,
                    asset_code: values.code
                };
            } else {
                let converter: any;
                if (assetCurrency === 'IDR') {
                    converter = await getAPI.convertCurrencyIDRtoUSD();
                    if (converter.close) {
                        usd_value = values.value / converter.close;
                    } else {
                        usd_value = values.value / converter.price;
                    }
                } else {
                    converter = await getAPI.convertCurrency(`${assetCurrency}USD`);
                    if (converter.close) {
                        usd_value = converter.close * values.value;
                    } else {
                        usd_value = converter.price * values.value;
                    }
                }

                data = {
                    type: values.type,
                    name: assetName,
                    exchange_market: values.exchange_market,
                    code: values.code,
                    location: assetCountry,
                    amount: values.amount,
                    customer_id: customer.id,
                    value: values.value,
                    usd_value,
                    updated_value: values.value,
                    updated_usd_value: usd_value,
                    currency: assetCurrency,
                    industry,
                };
                transactionData = {
                    name: assetName,
                    action: "BUY",
                    currency: assetCurrency,
                    amount: values.amount,
                    value: values.value,
                    total: usd_value * values.amount,
                    customer_id_transactions: customer.id,
                    trade_date: values.trade_date,
                    asset_code: values.code
                };
            }

            if (
                customer?.unallocated_funds - customer?.allocated_funds <
                usd_value * values.amount
            ) {
                throw { name: "NOT_ENOUGH_FUND" };
            }

            const response = await qoreContext.client
                .table("assets")
                .readRows({ condition: { customer_id: customer?.id } })
                .toPromise();

            let { nodes }: any = response?.data;

            nodes = await nodes.filter((el: any) => {
                if (el.name === assetName) {
                    getAssetData(el.id);
                    return el.name === assetName;
                } else {
                    return el.name === assetName;
                }
            });

            if (nodes.length > 0) {
                const avgValue =
                    (Number(nodes[0].value) * Number(nodes[0].amount) +
                        Number(values.value) * Number(values.amount)) /
                    (Number(nodes[0].amount) + Number(values.amount));
                const amount = Number(nodes[0].amount) + Number(values.amount);

                let usd_value;
                let updated_usd_value;
                if (nodes[0].currency === "USD") {
                    usd_value = avgValue;
                    updated_usd_value = values.value;
                } else {
                    
                    let converter: any;
                    if (`${nodes[0].currency}` === 'IDR') {
                        converter = await getAPI.convertCurrencyIDRtoUSD();
                        if (converter.close) {
                            usd_value = avgValue / converter.close;
                            updated_usd_value = values.value / converter.close;
                        } else {
                            usd_value = values.value / converter.price;
                            updated_usd_value = values.value / converter.price;
                        }
                    } else {
                        converter = await getAPI.convertCurrency(`${nodes[0].currency}USD`);
                        if (converter.close) {
                            usd_value = converter.close * avgValue;
                            updated_usd_value = converter.close * values.value;
                        } else {
                            usd_value = converter.price * avgValue;
                            updated_usd_value = converter.price * values.value;
                        }
                    }
                }

                await updateRow(customer.id, {
                    allocated_funds:
                        Number(customer?.allocated_funds) -
                        Number(nodes[0].usd_value) * Number(nodes[0].amount),
                });

                await updateRow(customer.id, {
                    allocated_funds:
                        Number(customer?.allocated_funds) +
                        (Number(usd_value) *
                            (Number(values.amount) + Number(nodes[0].amount)) -
                            Number(nodes[0].usd_value) * Number(nodes[0].amount)),
                });

                const isTodaySubmited = isToday(values.trade_date)
                if (!isTodaySubmited) {
                    
                    await updateAsset(nodes[0].id, {
                        value: avgValue,
                        amount,
                        usd_value,
                        updated_value: nodes[0].id.updated_value,
                        updated_usd_value,
                    });

                } else {

                    await updateAsset(nodes[0].id, {
                        value: avgValue,
                        amount,
                        usd_value,
                        updated_value: values.value,
                        updated_usd_value,
                    });

                }

                await insertTransaction(transactionData);
            } else {
                await insertRow(data);

                const resp = await qoreContext.client
                    .table("customers")
                    .readRow(customer.id)
                    .toPromise();

                const resp2 = await updateRow(customer.id, {
                    allocated_funds:
                        Number(resp?.data?.allocated_funds) + values.amount * usd_value,
                });

                await insertTransaction(transactionData);
            }
            getCustomer();
            getData();

            form.setValue("type", "");
            form.setValue("name", "");
            form.setValue("exchange_market", "");
            form.setValue("code", "");
            form.setValue("location", "");
            form.setValue("amount", 0);
            form.setValue("value", 0);
            setMarketDataList([]);
            setIsSubmit(false);
            setIsUpdate(true);
            buy.onClose();
        } catch (err: any) {
            console.log(err, `err`)
            if (err.name === "NOT_ENOUGH_FUND") {
                setAllError(['Not Enough Fund']);
                setIsSubmit(false);
            } else {
                console.log(err);
            }
        }
    };

    const handleAddDuplicate = async (value: any) => {
        // asset lama / amount lama = asset baru / amount baru
        // asset lama * amount baru = asset baru * ammount lama
        // asset baru = asset lama * amount baru / amount lama
        try {
            setIsSubmit(true)

            let transactionData = {
                name: asset?.name,
                action: "BUY",
                currency: asset?.currency,
                amount: value.amount,
                value: value.value,
                total: value.value * value.amount,
                customer_id_transactions: customer.id,
                trade_date: value.trade_date,
                asset_code: asset?.code,
            };

            const avgValue =
                (JSON.parse(asset?.value) * Number(asset?.amount) +
                    Number(value.value) * Number(value.amount)) /
                (JSON.parse(asset?.amount) + Number(value.amount));
            const amount = JSON.parse(asset?.amount) + Number(value.amount);

            let usd_value;
            let updated_usd_value;

            if (asset?.currency === "USD") {
                usd_value = avgValue;
                updated_usd_value = value.value;
            } else {
                let converter: any;
                if (asset?.currency === "IDR") {
                    converter = await getAPI.convertCurrencyIDRtoUSD();
                    if (converter.close) {
                        usd_value = avgValue / converter.close;
                        updated_usd_value = value.value / converter.close;
                    } else {
                        usd_value = avgValue / converter.price;
                        updated_usd_value = asset?.value / converter.price;
                    }
                } else {
                    converter = await getAPI.convertCurrency(`${asset?.currency}USD`);
                    if (converter.close) {
                        usd_value = converter.close * avgValue;
                        updated_usd_value = converter.close * value.value;
                    } else {
                        usd_value = converter.price * avgValue;
                        updated_usd_value = converter.price * value.value;
                    }
                }
            }


            if (
                customer?.unallocated_funds - customer?.allocated_funds <
                usd_value * value.amount
            ) {
                throw { name: "NOT_ENOUGH_FUND" };
            }

            await updateRow(customer.id, {
                allocated_funds:
                    JSON.parse(customer?.allocated_funds) -
                    Number(asset?.usd_value) * Number(asset?.amount),
            });

            await updateRow(customer.id, {
                allocated_funds:
                    JSON.parse(customer?.allocated_funds) +
                    (Number(usd_value) * (Number(value.amount) + Number(asset?.amount)) -
                        Number(asset?.usd_value) * Number(asset?.amount)),
            });

            const isTodaySubmited = isToday(value.trade_date)
            if (!isTodaySubmited) {
                
                await updateAsset(asset?.id, {
                    value: avgValue,
                    amount,
                    usd_value,
                    updated_value: asset.updated_value,
                    updated_usd_value,
                });

            } else {

                await updateAsset(asset?.id, {
                    value: avgValue,
                    amount,
                    usd_value,
                    updated_value: value.value,
                    updated_usd_value,
                });

            }

            await insertTransaction(transactionData);

            getCustomer();
            getData();

            formDuplicate.setValue("amount", 0);
            formDuplicate.setValue("value", 0);
            formDuplicate.setValue("trade_date", "");
            setIsSubmit(false);
            setIsUpdate(true);
            buy2.onClose();
        } catch (err: any) {
            if (err.name === "NOT_ENOUGH_FUND") {
                console.log("NOT ENOUGH FUND HEHE");
            } else {
                console.log(err);
            }
        }
    };

    const handleSellAsset = async (value: any) => {
        try {
            setIsSubmit(true)
            let transactionData = {
                name: asset?.name,
                action: "SELL",
                currency: asset?.currency,
                amount: value.amount,
                value: value.value,
                total: value.value * value.amount,
                customer_id_transactions: customer.id,
                trade_date: value.trade_date,
                asset_code: asset?.code,
            };
            const sellValue = value.amount * value?.value;

            if (asset?.amount - value.amount > 0) {
                await updateRow(customer.id, {
                    unallocated_funds:
                        JSON.parse(customer?.unallocated_funds) + sellValue,
                });
                await updateAsset(asset?.id, {
                    amount: asset?.amount - value.amount,
                });
                await insertTransaction(transactionData);
                getData();
                getCustomer();

                sell.onClose();

                formSell.setValue("amount", 0);
                formSell.setValue("value", 0);
                formSell.setValue("trade_date", "");
            } else if (asset?.amount - value.amount === 0) {
                await updateRow(customer.id, {
                    unallocated_funds:
                        JSON.parse(customer?.unallocated_funds) + sellValue,
                });
                await deleteRow(asset?.id);
                await insertTransaction(transactionData);
                getData();
                getCustomer();
                setIsSubmit(false);
                setIsUpdate(true);
                sell.onClose();

                formSell.setValue("amount", 0);
                formSell.setValue("value", 0);
                formSell.setValue("trade_date", "");
            } else {
                throw { name: "NOT_ENOUGH_AMOUNT" };
            }
        } catch (err: any) {
            if (err.name === "NOT_ENOUGH_AMOUNT") {
                console.log("available amount not enough");
            }
        }
    };

    const handleUSDValue = async (value: any) => {
        if (value !== 0) {
            let usd_value;
            if (assetCurrency === "USD" || assetCurrency === "NA") {
                usd_value = value;
            } else {
                let converter: any;
                if (assetCurrency === 'IDR') {
                    converter = await getAPI.convertCurrencyIDRtoUSD();
                    if (converter.close) {
                        usd_value = (value / converter.close);
                    } else {
                        usd_value = (value / converter.price);
                    }
                } else {
                    converter = await getAPI.convertCurrency(`${assetCurrency}USD`);
                    if (converter.close) {
                        usd_value = converter.close * value;
                    } else {
                        usd_value = converter.price * value;
                    }
                }
            }
            setUSDValue(usd_value);
        }
    };
    const handleUSDValue2 = async (value: any) => {
        if (value !== 0) {
            let usd_value;
            if (asset?.currency === "USD") {
                usd_value = value;
            } else {
                let converter: any;
                if (asset?.currency === 'IDR') {
                    converter = await getAPI.convertCurrencyIDRtoUSD();
                    if (converter.close) {
                        usd_value = value / converter.close;
                    } else {
                        usd_value = value / converter.price;
                    }
                } else {
                    converter = await getAPI.convertCurrency(`${asset?.currency}USD`);
    
                    if (converter.close) {
                        usd_value = converter.close * value;
                    } else {
                        usd_value = converter.price * value;
                    }
                }
            }
            setUSDValue2(usd_value);
        }
    };

    const handleUSD = async (value: any) => {
        if (value !== 0) {
            let usd_value;
            if (assetCurrency === "USD") {
                usd_value = value;
            } else {
                let converter: any;
                if (assetCurrency === 'IDR') {
                    converter = await getAPI.convertCurrencyIDRtoUSD();
                    if (converter.close) {
                        usd_value = value / converter.close;
                    } else {
                        usd_value = value / converter.price;
                    }
                } else {
                    converter = await getAPI.convertCurrency(`${assetCurrency}USD`);

                    if (converter.close) {
                        usd_value = converter.close * value;
                    } else {
                        usd_value = converter.price * value;
                    }
                }
            }
            setTotalUSD(usd_value);
        }
    };

    const handleUSD2 = async (value: any) => {
        if (value !== 0) {
            let usd_value;
            if (assetCurrency === "USD") {
                usd_value = value;
            } else {
                let converter: any;
                if (assetCurrency === 'IDR') {
                    converter = await getAPI.convertCurrencyIDRtoUSD();
                    if (converter.close) {
                        usd_value = value / converter.close;
                    } else {
                        usd_value = value / converter.price;
                    }
                } else {
                    converter = await getAPI.convertCurrency(`${assetCurrency}USD`);

                    if (converter.close) {
                        usd_value = converter.close * value;
                    } else {
                        usd_value = converter.price * value;
                    }
                }
            }
            setTotalUSD2(usd_value);
        }
    };

    const handleFetchList = async (value: any) => {
        if (value === "stock") {
            setTypeName("stock");
            setTypeData(await getAPI.getStockList());
        } else if (value === "ETF") {
            setTypeName("ETF");
            setTypeData(await getAPI.getETFList());
        } else if (value === "forex") {
            setTypeName("forex");
            setTypeData(await [{ Code: "FOREX", Name: "FOREX" }]);
        } else if (value === "CC") {
            setTypeName("CC");
            setTypeData(await [{ Code: "CC", Name: "Cryptocurrencies" }]);
        } else if (value === "mutualFund") {
            setTypeName("mutualFund");
            setTypeData(await getAPI.getMutualFundList());
        } else if (value === "bond") {
            setTypeName("bond");
            setTypeData(
                await [
                    { Code: "BOND", Name: "Bond Virtual Exchange & Government Bonds" },
                ]
            );
        }
    };

    const handleFetchMarket = async (value: any) => {
        let params = {
            market: value,
        };
        setMarket(value);
        if (typeName === "stock") {
            setMarketDataList(await getAPI.getStockMarket(params));
        } else if (typeName === "ETF") {
            setMarketDataList(await getAPI.getETFMarket(params));
        } else if (typeName === "forex") {
            setMarketDataList(await getAPI.getForexMarket());
        } else if (typeName === "CC") {
            setMarketDataList(await getAPI.getCryptoMarket());
        } else if (typeName === "mutualFund") {
            setMarketDataList(await getAPI.getMutualFundMarket(params));
        } else if (typeName === "bond") {
            setMarketDataList(await getAPI.getBondMarket());
        }
    };

    const handleAssetValue = async (value: any) => {
        if (value.code ) {
            if (value.market !== 'INDX') {
                const params = { code: value.code, market: value.market };
                const data = await getAPI.getEOD(params);

                if (data.close) {
                    await setAssetValue(data.close);
                } else {
                    await setAssetValue(data.price);
                } 
            }
        } else {
            if (value !== '') {
                const params = { code: value, market };
                const data = await getAPI.getEOD(params);

                if (data.close) {
                    await setAssetValue(data.close);
                } else {
                    await setAssetValue(data.price);
                }
            }
        }
    };

    const handleNewNotes = async (value: any) => {
        try {

            const data: object = {
                date_notes: value.date_notes,
                extention: value.extention,
                remarks: value.remarks,
                bank_statement: value.bank_statement
            }

            const tryAddNewNotes = await insertNotes(data);

            if (tryAddNewNotes?.results?.data.length > 0) {
                const idRow = tryAddNewNotes?.results?.data[0].id

                let updateRelation = await axios.post(`https://staging-qore-data-city-731808.qore.dev/v1/execute`, {
                    "operations": [
                        {
                            "operation": "AddRelation", 
                            "instruction": {
                            "table": "notes",
                            "relation": {
                                    "name": "customer_notes_id",
                                    "data": {
                                        "origin" : idRow, 
                                        "target" : searchParams.get("id")
                                    },
                                }
                            }
                        },
                    ]
                }, { headers: { "x-qore-engine-admin-secret": "bnvfBSZg55kaOOkrTffJxoRLKtpbitp5", "Content-Type": "application/json", "accept": "application/json" } })

                if (updateRelation.data.results.undefined.length > 0) {
                    getNotes()
                    addNewNotes.onClose()
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    const getNotes = async () => {
        try {
            setIsLoading(true);
            let result;
            let response;
            if (allNotes.length === 0) {
                response = await qoreContext.client
                    .table("notes")
                    .readRows({
                        condition: { customer_notes_id: searchParams.get("id") },
                    })
                    .toPromise();

                if (response?.data?.nodes) {
                    if (response?.data?.nodes.length > 0) {
                        result = JSON.parse(JSON.stringify(response?.data?.nodes));

                        setNotesPages(Math.ceil(result.length / limit));
    
                        if (limit > 0) {
                            result = result.splice((currentPage2 - 1) * limit, limit);
                        }
    
                        setNotes(result);
                        setAllNotes(response?.data?.nodes);

                        setNewestDate(
                            response?.data?.nodes[response?.data?.nodes.length - 1].created_at
                        );
                        setOldestDate(response?.data?.nodes[0].created_at);
                        setToDateNotes(
                            response?.data?.nodes[response?.data?.nodes.length - 1].created_at
                        );
                        setFromDateNotes(response?.data?.nodes[0].created_at);
                        setNoDataNotesFound(false);
                    }
                }
            } else {
                if (toDateNotes && fromDateNotes) {
                    response = await qoreContext.client
                    .table("notes")
                    .readRows({
                        condition: { customer_notes_id: searchParams.get("id") },
                    })
                    .toPromise();
                }
            }

            if (response?.data?.nodes) {
                if (response?.data?.nodes.length > 0) {
                    result = JSON.parse(JSON.stringify(response?.data?.nodes));

                    setNotesPages(Math.ceil(result.length / limit));

                    if (limit > 0) {
                        result = result.splice((currentPageNotesTable - 1) * limit, limit);
                    }

                    setNotes(result);
                    setNoDataNotesFound(false);
                }
            }

            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getNotes()
    }, [toDateNotes, fromDateNotes, currentPageNotesTable]);

    const handleDetailNotes = (val: any) => {
        setSingleNotes(val);
        detailNotes.onOpen()
    }

    const handleUpdateNotes = async (value: any, idRow: any) => {
        const editData: object = {
            date_notes: value.date_notes,
            extention: value.extention,
            remarks: value.remarks,
            bank_statement: value.bank_statement
        }

        await updateNotes(idRow, {
            date_notes: value.date_notes,
            extention: value.extention,
            remarks: value.remarks,
            bank_statement: value.bank_statement
        });

        notesForm.setValue("date_notes", "");
        notesForm.setValue("extention", "");
        notesForm.setValue("bank_statement", "");
        notesForm.setValue("remarks", "");
        getNotes()
        detailNotes.onClose()
    }

    return (
        <div>
            <AddAssetModal
                market={market}
                allError={allError}
                isSubmit={isSubmit}
                USDValue={USDValue}
                getAssetData={getAssetData}
                totalUSD={totalUSD}
                value={value}
                amount={amount}
                assetCurrency={assetCurrency}
                assetCountry={assetCountry}
                setAmount={setAmount}
                setValue={setValue}
                typeName={typeName}
                marketDataList={marketDataList}
                setAssetCountry={setAssetCountry}
                setAssetName={setAssetName}
                setAssetCurrency={setAssetCurrency}
                handleAssetValue={handleAssetValue}
                typeData={typeData}
                handleFetchMarket={handleFetchMarket}
                availableFundVal={
                    customer?.unallocated_funds - customer?.allocated_funds
                }
                handleFetchList={handleFetchList}
                handleAddAsset={handleAddAsset}
                form={form}
                onClose={buy.onClose}
                isOpen={buy.isOpen}
                assetValue={assetValue}
                setMarketDataList={setMarketDataList}
                setAllError={setAllError}
                setMarket={setMarket}
                addMarket={true}
            />

            <UpdateAssetModal
                isSubmit={isSubmit}
                USDValue={USDValue2}
                setAmount2={setAmount2}
                availableFundVal={
                    customer?.unallocated_funds - customer?.allocated_funds
                }
                setValue2={setValue2}
                value2={value2}
                amount2={amount2}
                totalUSD2={totalUSD2}
                asset={asset}
                isOpen={buy2.isOpen}
                onClose={buy2.onClose}
                formDuplicate={formDuplicate}
                handleAddDuplicate={handleAddDuplicate}
            />

            <SellAssetModal
                isSubmit={isSubmit}
                value3={value3}
                setValue3={setValue3}
                amount3={amount3}
                setAmount3={setAmount3}
                asset={asset}
                isOpen={sell.isOpen}
                onClose={sell.onClose}
                formSell={formSell}
                handleSellAsset={handleSellAsset}
                value={Helpers.currencyFormaterUSD(asset?.updated_usd_value)}
            />

            <AddNewNotes 
                modalMode={true}
                header={'Add New Notes'}
                isOpen={addNewNotes.isOpen}
                onClose={addNewNotes.onClose}
                form={notesForm}
                handleNewNotes={handleNewNotes}
            />

            <DetailNotes 
                data={singleNotes}
                header={'Detail Notes'}
                isOpen={detailNotes.isOpen}
                onClose={detailNotes.onClose}
                form={notesForm}
                handleUpdateNotes={handleUpdateNotes}
            />

            {!isInitialLoading ? (
                <>
                    <DetailCustomerAsset
                        unallocatedFunds={Helpers.currencyFormaterUSD(
                            customer?.unallocated_funds - customer?.allocated_funds
                        )}
                        allocatedFunds={Helpers.currencyFormaterUSD(allocatedFunds)}
                        totalFunds={Helpers.currencyFormaterUSD(
                            customer?.unallocated_funds -
                            customer?.allocated_funds +
                            allocatedFunds
                        )}
                        initialFunds={Helpers.currencyFormaterUSD(customer?.funds)}
                        profit={Helpers.currencyFormaterUSD(
                            customer?.unallocated_funds -
                            customer?.allocated_funds +
                            allocatedFunds -
                            customer?.funds
                        )}
                    />
                    <Center pl="3" py={6}>
                        {!noDataFound ? (
                            <Box
                                w={"full"}
                                bg={useColorModeValue("white", "gray.800")}
                                boxShadow={"lg"}
                                rounded={"md"}
                                px="24px"
                            >
                                <Tabs isLazy={true}>
                                    <TabList px="10px">
                                        <Tab
                                            onClick={() => setSearch("")}
                                            _selected={{
                                                borderColor: "#C6B378",
                                                fontWeight: "600",
                                            }}
                                            fontSize="12px"
                                            px="0"
                                            pb="12px"
                                            mt="10px"
                                            mx="10px"
                                            _focus={{ outline: "none" }}
                                        >
                                            Asset
                                        </Tab>
                                        <Tab
                                            _selected={{
                                                borderColor: "#C6B378",
                                                fontWeight: "600",
                                            }}
                                            fontSize="12px"
                                            px="0"
                                            pb="12px"
                                            mt="10px"
                                            mx="10px"
                                            _focus={{ outline: "none" }}
                                        >
                                            Funds Log
                                        </Tab>
                                        <Tab
                                            _selected={{
                                                borderColor: "#C6B378",
                                                fontWeight: "600",
                                            }}
                                            fontSize="12px"
                                            px="0"
                                            pb="12px"
                                            mt="10px"
                                            mx="10px"
                                            _focus={{ outline: "none" }}
                                        >
                                            Notes
                                        </Tab>
                                    </TabList>

                                    <TabPanels>
                                        <TabPanel
                                            as={AnimatePresence}
                                            exitBeforeEnter
                                            pt="0"
                                            px="0"
                                        >
                                            <TabPanelContent>
                                                <Box w={{ base: "full" }}>
                                                    {allData.length > 0 ? (
                                                        <TableCustomerAssetFilter
                                                            availableFund={customer?.unallocated_funds - customer?.allocated_funds}
                                                            setValue={setValue}
                                                            setAmount={setAmount}
                                                            setSearch={setSearch}
                                                            setSearchFilter={setSearchFilter}
                                                            allChecked={allChecked}
                                                            isIndeterminate={isIndeterminate}
                                                            types={types}
                                                            setCheckedItems={setCheckedItems}
                                                            checkedItems={checkedItems}
                                                            setType={setType}
                                                            setTypeValues={setTypeValues}
                                                            allTypes={allTypes}
                                                            searchFilter={searchFilter}
                                                            onOpen={buy.onOpen}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Box>

                                                {isLoading ? (
                                                    <Box py="10"> {LocalLoader()} </Box>
                                                ) : (
                                                    <>
                                                        {data.length > 0 ? (
                                                            <>
                                                                <CustomerAssetTable
                                                                    customer={customer}
                                                                    availableFund={customer?.unallocated_funds - customer?.allocated_funds}
                                                                    getAssetData={getAssetData}
                                                                    sell={sell}
                                                                    buy2={buy2}
                                                                    data={data}
                                                                    formSell={formSell}
                                                                    setValue3={setValue3}
                                                                    setAmount3={setAmount3}
                                                                    setValue2={setValue2}
                                                                    setAmount2={setAmount2}
                                                                    setValue={setValue}
                                                                    setAmount={setAmount}
                                                                    formDuplicate={formDuplicate}
                                                                />
                                                                <PaginatorTableMarket
                                                                    pages={pages}
                                                                    currentPage={currentPage}
                                                                    handlePageChange={handlePageChange}
                                                                />
                                                            </>
                                                        ) : allData.length > 0 ? (
                                                            <EmptyData />
                                                        ) : (
                                                            <Center pl="3" py={6}>
                                                                <NoDataFoundAsset
                                                                    instrument={"assets"}
                                                                    onOpen={buy.onOpen}
                                                                />
                                                            </Center>
                                                        )}
                                                    </>
                                                )}
                                            </TabPanelContent>
                                        </TabPanel>
                                        <TabPanel as={AnimatePresence} exitBeforeEnter>
                                            <TabPanelContent>
                                                <CustomerFundLog
                                                    transactions={transactions}
                                                    toDate={toDate}
                                                    fromDate={fromDate}
                                                    setFromDate={setFromDate}
                                                    setToDate={setToDate}
                                                    noDataFound={noDataFound2}
                                                />
                                                <PaginatorTableMarket
                                                    pages={pages2}
                                                    currentPage={currentPage2}
                                                    handlePageChange={handlePageChange2}
                                                />
                                            </TabPanelContent>
                                        </TabPanel>
                                        <TabPanel as={AnimatePresence} exitBeforeEnter>
                                            <TabPanelContent>
                                                {
                                                    notes && notes.length > 0 ? (
                                                        <Box>
                                                            <CustomerNotes
                                                                getNotes={getNotes}
                                                                handleRemoveNotes={removeNotes.onOpen}
                                                                handleUpdateNotes={handleDetailNotes}
                                                                notes={notes}
                                                                toDate={toDateNotes}
                                                                fromDate={fromDateNotes}
                                                                setFromDate={setFromDate}
                                                                setToDate={setToDate}
                                                                noDataFound={noDataNotesFound}
                                                                onOpen={addNewNotes.onOpen}
                                                            />
                                                                    {/* <Button
                                                                        handleOpenModal={addNewNotes.onOpen}
                                                                        buttonText={buttonText}
                                                                    />

                                                                    <Button> */}
                                                            <PaginatorTableMarket
                                                                pages={pages2}
                                                                currentPage={currentPage2}
                                                                handlePageChange={handlePageChange2}
                                                            />
                                                        </Box>
                                                    ) : (
                                                        <NoDataNotesFound instrument={"notes"} onOpen={addNewNotes.onOpen} />
                                                    )
                                                }
                                            </TabPanelContent>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Box>
                        ) : (
                            <NoDataFoundAsset instrument={"assets"} onOpen={buy.onOpen} />
                        )}
                    </Center>
                </>
            ) : (
                <Box py="10"> {LocalLoader()} </Box>
            )}
        </div>
    );
}

export default CustomerAssets;
