import { useEffect, useState } from "react";
import qoreContext from "../../common/qoreContext";
import { Center, Box, useColorModeValue } from "@chakra-ui/react";
import { DatePicker } from "chakra-ui-date-input";
import LocalLoader from "../../components/Loader/LocalLoader";
import EmptyData from "../../components/EmptyData";
import NoDataFound from "../../components/StatusPage/NoDataFound";
import DetailHoldings from "../../components/DetailBox/DetailHoldings";
import NoDataFoundTransaction from "../../components/StatusPage/NoDataFoundTransaction";
import Helpers from "../../hooks/helpers";

function Holdings({ customer }: any) {
  const user = qoreContext.useCurrentUser();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noDataFound, setNoDataFound] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [limit, setLimit] = useState<number>(25);
  const [pages, setPages] = useState<number>(0);
  const [asset, setAsset] = useState<any>("");
  const [customers, setCustomers] = useState<any[]>([]);

  const getCustomers = async () => {
    try {
      setIsLoading(true);
      let result;
      let customerCondition = { $and: [{ rm_id: user?.user?.id }] };
      if (customer.length > 0) {
        let tempOr: any = { $or: [] };
        await customer.forEach((el: any) => {
          tempOr["$or"].push({ name: el });
        });
        customerCondition["$and"].push(tempOr);
      }
      if (user?.user?.id) {
        const response = await qoreContext.client
          .table("customers")
          .readRows({
            condition: customerCondition,
            populate: ["customer_id_items"],
          })
          .toPromise();

        if (response?.data?.nodes) {
          setPages(Math.ceil(response?.data?.nodes.length / limit));

          result = JSON.parse(JSON.stringify(response?.data?.nodes));
          if (search !== "") {
            result = result.filter((el: any) =>
              el.name.toLowerCase().includes(search.toLowerCase())
            );
          }
          setCustomers(result);
          if (response?.data?.nodes.length > 0) {
            let temp = true;
            response?.data?.nodes.forEach((le: any) => {
              if (le.customer_id_items.length > 0) {
                temp = false;
              }
            });
            setNoDataFound(temp);
          }
          setIsLoading(false);
        }
      }
    } catch (error) {}
  };

  const getAssets = async () => {
    try {
      let assets: any = [];
      let ETF: any = [];
      let ETFValue: any = 0;
      let stock: any = [];
      let stockValue: any = 0;
      let fund: any = [];
      let fundValue: any = 0;
      let bond: any = [];
      let bondValue: any = 0;
      let forex: any = [];
      let forexValue: any = 0;
      let crypto: any = [];
      let cryptoValue: any = 0;
      customers.map((el: any) => {
        el.customer_id_items.map((le: any) => {
          console.log(le, `le`)
          if (le.type === "ETF") {
            ETF.push(le);
            ETFValue += le.amount * le.updated_usd_value;
          } else if (le.type === "stock" || le.type === "Common Stock") {
            stock.push(le);
            stockValue += le.amount * le.updated_usd_value;
          } else if (le.type === "forex") {
            forex.push(le);
            forexValue += le.amount * le.updated_usd_value;
          } else if (le.type === "CC") {
            crypto.push(le);
            cryptoValue += le.amount * le.updated_usd_value;
          } else if (le.type === "mutualFund") {
            fund.push(le);
            fundValue += le.amount * le.updated_usd_value;
          } else if (le.type === "bond") {
            bond.push(le);
            bondValue += le.amount * le.updated_usd_value;
          }
        });
      });

      if (ETF.length > 0) {
        ETF.sort((a: any, b: any) => a.name.localeCompare(b.name))
        assets.push({ name: "ETF", data: ETF, total: ETFValue });
      }
      if (stock.length > 0) {
        stock.sort((a: any, b: any) => a.name.localeCompare(b.name));
        const newHoldings = await Helpers.findDupe(stock);

        if (newHoldings.list.length > 0) {
          assets.push({ name: "Stock", data: newHoldings, total: stockValue });
        } else {
          assets.push({ name: "Stock", data: stock, total: stockValue });
        }

        // stock.sort((a: any, b: any) => a.name.localeCompare(b.name));
        // assets.push({ name: "Stock", data: newHoldings, total: stockValue });

      }

      if (fund.length > 0) {
        fund.sort((a: any, b: any) => a.name.localeCompare(b.name))
        assets.push({ name: "Mutual Fund", data: fund, total: fundValue });
      }
      if (bond.length > 0) {
        bond.sort((a: any, b: any) => a.name.localeCompare(b.name))
        assets.push({ name: "Bonds", data: bond, total: bondValue });
      }
      if (forex.length > 0) {
        forex.sort((a: any, b: any) => a.name.localeCompare(b.name))
        assets.push({ name: "Forex", data: forex, total: forexValue });
      }
      if (crypto.length > 0) {
        crypto.sort((a: any, b: any) => a.name.localeCompare(b.name))
        assets.push({ name: "Crypto", data: crypto, total: cryptoValue });
      }

      // const mergeAsset = await sortedAsset(asset)
      setAsset(assets);
    } catch (error) {}
  };


  useEffect(() => {
    getCustomers();
  }, [user?.user?.id, customer]);

  useEffect(() => {
    getAssets();
  }, [customers]);

  return (
    <div>
      <Center pl="3" py={6}>
        {!isLoading ? (
          !noDataFound ? (
            <Box
              w={"full"}
              bg={useColorModeValue("white", "gray.800")}
              boxShadow={"lg"}
              rounded={"md"}
              px="24px"
              pb="24px"
            >
              {isLoading ? (
                <Box py="10"> {LocalLoader()} </Box>
              ) : (
                <DetailHoldings customers={customers} asset={asset} />
              )}
            </Box>
          ) : (
            <NoDataFoundTransaction instrument={"Holdings"} />
          )
        ) : (
          <Box py="10"> {LocalLoader()} </Box>
        )}
      </Center>
    </div>
  );
}

export default Holdings;
