import { DatePicker } from "chakra-ui-date-input";
import {
    Box,
    Stack,
    Flex,
    Button
} from "@chakra-ui/react";
import { BsPlusLg } from "react-icons/bs";
import CustomerNotesTable from "../../components/Tables/CustomerNotesTable";

function CustomerNotes({
    notes,
    toDate,
    fromDate,
    setToDate,
    setFromDate,
    handleRemoveNotes,
    handleUpdateNotes,
    onOpen,
    getNotes
}: any) {
    const handleOpenModal = () => {
        onOpen()
    };
    return (
        <>
            <Box w={{ base: "full" }}>
                <Flex direction="row" py="20px">
                    {/* <Stack
                        fontSize="14px"
                        fontFamily="Inter"
                        h="32px"
                        className="datepicker-wrapper"
                        spacing={3}
                        mr="12px"
                        direction="row"
                    >
                        <DatePicker
                            defaultValue={fromDate.split("-").reverse().join("/")}
                            placeholder="From"
                            name="fromDate"
                            onChange={(date: string) =>
                                setFromDate(date.split("/").reverse().join("-"))
                            }
                        />

                        <DatePicker
                            defaultValue={toDate.split("-").reverse().join("/")}
                            placeholder="To"
                            name="toDate"
                            onChange={(date: string) =>
                                setToDate(date.split("/").reverse().join("-"))
                            }
                        />
                    </Stack> */}
                    <Stack ml="auto" mr="0" direction="row" spacing={4}>
                        <Button
                            onClick={() => {
                                handleOpenModal();
                            }}
                            leftIcon={<BsPlusLg fontSize="10px" />}
                            fontWeight="600"
                            fontSize="12px"
                            color="#fff"
                            maxW="150px"
                            _focus={{ outline: "none" }}
                            h="32px"
                            border="1px"
                            _hover={{
                                color: "rgba(198, 179, 120, 1)",
                                backgroundColor: "#fff",
                                border: "1px",
                            }}
                            backgroundColor="rgba(198, 179, 120, 1)"
                        >
                            Add New Notes
                        </Button>
                    </Stack>
                </Flex>
            </Box>

            <CustomerNotesTable 
                getNotes={getNotes}
                handleRemoveNotes={handleRemoveNotes}
                handleUpdateNotes={handleUpdateNotes}
                notes={notes} />
        </>
    );
}

export default CustomerNotes;
