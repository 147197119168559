import { 
    Tr, Th
} from "@chakra-ui/react";

const CustomerFundLogTableHead = () => {
    return (
        <Tr>
            <Th textTransform='capitalize' p='12px'>Action</Th>
            <Th textTransform='capitalize' textAlign='center' p='12px'>Currency (CCY)</Th>
            <Th textTransform='capitalize' p='12px'>Amount</Th>
            <Th textTransform='capitalize' p='12px'>Previous Balance</Th>
            <Th textTransform='capitalize' p='12px'>Balance</Th>
            <Th textTransform='capitalize' p='12px'>Date</Th>
        </Tr>
    );
};

export default CustomerFundLogTableHead;