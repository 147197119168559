import { 
    Tr, Th
} from "@chakra-ui/react";

const CustomerNotesTableHead = () => {
    return (
        <Tr>
            <Th textTransform='capitalize' p='12px'>Date</Th>
            <Th textTransform='capitalize' textAlign='center' p='12px'>Extension</Th>
            <Th textTransform='capitalize' p='12px'>Bank Statement</Th>
            <Th textTransform='capitalize' p='12px'>Action</Th>
        </Tr>
    );
};

export default CustomerNotesTableHead;